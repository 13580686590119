import Button from "components/Button";
import TextInput from "components/TextInput";
import { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useChangePasswordMutation, useGetUserQuery } from "../profileApi";
import LoginContainer from "./LoginContainer";
import classnames from "classnames";
import { csrfInit } from "utils/requests";
import usePasswordConstraints from "utils/hooks/usePasswordConstraints";

const LoginPasswordReset = () => {
  const { data: profile, isLoading: isProfileLoading } = useGetUserQuery();

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [requestDone, setRequestDone] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [requestError, setRequestError] = useState<string | undefined>();
  const { constraints, satisfied } = usePasswordConstraints(
    newPassword,
    confirmPassword
  );
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("t");

  document.title = "Password reset | Relata";

  const handleRequest = async () => {
    try {
      if (!token) return;
      if (!(await csrfInit())) throw new Error("No CSRF");
      await changePassword({
        password: newPassword,
        confirmPassword,
        token,
      }).unwrap();
      setRequestDone(true);
    } catch (error) {
      setRequestError(
        "It was not possible to change your password. Try again in a few moments."
      );
      console.error(error);
    }
  };

  const handleChange = (target: string, value: string) => {
    switch (target) {
      case "new-password":
        setNewPassword(value);
        break;
      case "confirm-password":
        setConfirmPassword(value);
        break;
    }
  };

  if (!token || (!isProfileLoading && profile)) {
    return <Navigate to={"/"} replace />;
  }

  if (requestDone)
    return (
      <LoginContainer text="Password reset complete">
        <p className="mt-4 text-center text-metal">
          Your password has now been reset.
          <br />
          <br />
          You can now continue to your account.
        </p>
        <div className="mb-4 mt-8 flex flex-col px-4">
          <Button
            color="navy"
            text="Go to your account"
            onClick={() => {
              navigate("/account");
            }}
          />
        </div>
      </LoginContainer>
    );
  return (
    <LoginContainer text="Reset your Relata password">
      <div className="mx-4 mt-4">
        <div className="flex flex-col gap-2 rounded bg-dust p-4">
          <div className="font-bold text-navy">
            The following criteria must be met:
          </div>
          {constraints.map(({ label, status }, index) => (
            <div
              key={index}
              className={classnames("flex flex-row gap-2", {
                "text-metal": status,
                "font-bold text-navy": !status,
              })}
            >
              <i
                className={classnames("text-lg", {
                  "icon-check": status,
                  "icon-cross": !status,
                })}
              ></i>
              {label}
            </div>
          ))}
        </div>
        <div className="mt-6 flex-grow text-lg font-bold text-navy">
          New password
        </div>
        <div className="mb-4 mt-2 flex flex-row gap-2">
          <TextInput
            type={showNewPassword ? "text" : "password"}
            onChangeText={(value) => handleChange("new-password", value)}
          />
          <Button
            color="dust"
            text={showNewPassword ? "Hide" : "Show"}
            onClick={() => setShowNewPassword(!showNewPassword)}
          />
        </div>
        <div className="flex-grow text-lg font-bold text-navy">
          Confirm password
        </div>
        <div className="mb-4 mt-2 flex flex-row gap-2">
          <TextInput
            type={showConfirmPassword ? "text" : "password"}
            onChangeText={(value) => handleChange("confirm-password", value)}
          />
          <Button
            color="dust"
            text={showConfirmPassword ? "Hide" : "Show"}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
        {requestError && (
          <div className="font-bold text-red-dark">{requestError}</div>
        )}
        <div className="mb-4 mt-2 flex flex-col">
          <Button
            isLoading={isLoading}
            color="navy"
            iconRight
            disabled={!satisfied}
            icon="icon-right"
            text="Reset password"
            onClick={handleRequest}
          />
        </div>
      </div>
    </LoginContainer>
  );
};

export default LoginPasswordReset;
