import classNames from "classnames";

const ToggleSwitch = ({
  value,
  onClick,
  labels,
}: {
  value: boolean;
  labels: { on: string; off: string };
  onClick: VoidFunction;
}) => (
  <div className="flex cursor-pointer items-center rounded-full border border-dust bg-white p-px hover:border-dust-dark">
    <div
      className={classNames("flex h-full items-center rounded-full px-4", {
        "bg-dust text-metal-dark": value,
        "text-metal hover:text-navy": !value,
      })}
      onClick={() => {
        if (!value) {
          onClick();
        }
      }}
      data-testid="toggle-switch-on"
    >
      {labels.on}
    </div>
    <div
      className={classNames("flex h-full items-center rounded-full px-4", {
        "bg-dust text-metal-dark": !value,
        "text-metal hover:text-navy": value,
      })}
      onClick={() => {
        if (value) {
          onClick();
        }
      }}
      data-testid="toggle-switch-off"
    >
      {labels.off}
    </div>
  </div>
);

export default ToggleSwitch;
