import { useState } from "react";
import { format, subDays } from "date-fns";
import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";
import { concat, map, pluck } from "ramda";

import TextInput from "components/TextInput";
import ActivityItem, {
  ActivityItemSkeleton,
} from "pages/explore/common/ActivityView/ActivityItem";
import useDebounce from "utils/hooks/useDebounce";
import Pagination from "components/table/Pagination";

const TopicRelatedEmails = ({ topic }: { topic: { id: any; name: any } }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const limit = 50;

  const onSearchChange = (value: string) => {
    setPage(1);
    setSearchValue(value);
  };

  const afterDate = format(subDays(new Date(), 90), "yyyy-MM-dd");
  const { resultSet, isLoading } = useCubeQuery({
    measures: [],
    dimensions: [
      "dim_email.subject",
      "dim_email.mailbox_full_name",
      "dim_email.message_sent_date_time",
      "fact_email_topic.email_message_id",
    ],
    limit,
    total: true,
    offset: (page - 1) * limit,
    order: [["dim_email.message_sent_date_time", "desc"]],
    filters: [
      {
        member: "dim_date.date_actual",
        operator: "afterDate",
        values: [afterDate],
      },
      {
        member: "dim_email.email_direction",
        operator: "equals",
        values: ["outbound"],
      },
      { member: "dim_topic.id", operator: "equals", values: [topic.id] },
      ...(debouncedSearchValue !== ""
        ? [
            {
              member: "dim_email.subject",
              operator: "contains",
              values: [debouncedSearchValue],
            },
          ]
        : []),
    ] as Filter[],
  });
  const results = (resultSet?.tablePivot() || []) as any[];
  // @ts-ignore
  const total = resultSet?.loadResponse.results[0].total;
  const totalPages = total ? Math.ceil(total / limit) : 0;

  const activityIds = map(
    (id) => `eml-${id}`,
    pluck("fact_email_topic.email_message_id", results)
  ) as string[];
  const { resultSet: contactParticipantResults } = useCubeQuery(
    {
      dimensions: [
        "fact_activity.engagement_type",
        "dim_contact.full_name",
        "fact_activity.uuid",
      ],
      limit: limit * 10,
      order: [
        ["fact_activity.row_number", "asc"],
        ["fact_activity.engagement_type", "desc"],
      ],
      filters: [
        {
          member: "fact_activity.uuid",
          operator: "equals",
          values: activityIds,
        },
      ],
    },
    { skip: activityIds.length === 0 }
  );
  const contactParticipants = contactParticipantResults?.tablePivot() || [];
  const { resultSet: userParticipantsResults } = useCubeQuery(
    {
      dimensions: [
        "fact_activity.engagement_type",
        "dim_user.full_name",
        "fact_activity.uuid",
      ],
      limit: limit * 10,
      order: [
        ["fact_activity.row_number", "asc"],
        ["fact_activity.engagement_type", "desc"],
      ],
      filters: [
        {
          member: "fact_activity.uuid",
          operator: "equals",
          values: activityIds,
        },
      ],
    },
    { skip: activityIds.length === 0 }
  );
  const userParticipants = userParticipantsResults?.tablePivot() || [];
  const participants = concat(userParticipants, contactParticipants);

  return (
    <div className="flex flex-col gap-4 p-4 text-base">
      <TextInput
        placeholder="Search for an email by subject"
        onChangeText={onSearchChange}
        value={searchValue}
        icon="icon-search"
        size="large"
        clearable
      />
      <div className="flex h-[90vh] w-full flex-col overflow-scroll md:h-[70vh]">
        {isLoading ? (
          <>
            <ActivityItemSkeleton />
            <ActivityItemSkeleton />
            <ActivityItemSkeleton />
            <ActivityItemSkeleton />
            <ActivityItemSkeleton />
          </>
        ) : (
          results.map((result, index) => (
            <ActivityItem
              key={index}
              type="email-sent"
              subject={result["dim_email.subject"]}
              user={result["dim_email.mailbox_full_name"]}
              date={new Date(result["dim_email.message_sent_date_time"])}
              searchValue={searchValue}
              participants={
                participants.filter(
                  (participant) =>
                    participant["fact_activity.uuid"] ===
                    `eml-${result["fact_email_topic.email_message_id"]}`
                ) as any[]
              }
            />
          ))
        )}
        {results.length === 0 && !isLoading && debouncedSearchValue !== "" && (
          <div className="mt-20 flex flex-col items-center gap-2 text-navy">
            <i className="icon-search text-3xl" />
            <div className="text-lg font-bold">No emails found</div>
            <div className="text-base text-metal-dark">
              Try adjusting your spelling
            </div>
          </div>
        )}
        {!isLoading && totalPages > 1 && (
          <div className="p-4">
            <Pagination current={page} total={totalPages} onChange={setPage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicRelatedEmails;
