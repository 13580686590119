import { useTrack } from "use-analytics";
import { useEffect, useState } from "react";
import { formatSeconds } from "utils/dates";
import { useDispatch, useSelector } from "react-redux";
import MiniStatistic from "components/MiniStatistic";

import { changeBreadcrumb } from "app/breadcrumbSlice";
import SelectOption from "components/SelectOption";
import Button from "components/Button";
import CompanyTable from "components/table/CompanyTable";
import EmptyTableState from "components/table/EmptyTableState";
import { ITableField } from "components/table/Table.interfaces";

import useClientServiceLevel from "../hooks/useClientServiceLevel";
import useClientServiceLevelCompanies from "../hooks/useClientServiceLevelCompanies";
import { useGetTeamsQuery } from "features/team/teamApi";
import { useGetLensesQuery } from "features/lens/lensApi";

import { selectCubeLastRefresh } from "app/cubeLastRefreshSlice";
import { formatDistanceToNow } from "date-fns";
import Pagination from "components/table/Pagination";
import downloadCSV from "utils/downloadCSV";

const SkeletonHeader = () => (
  <div className="flex flex-col gap-4">
    <div className="flex h-8 w-[120px] animate-pulse items-center justify-center rounded bg-dust"></div>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <div
            key={i}
            className="relative flex w-full items-center gap-4 rounded-lg border border-dust-dark bg-white px-3 py-4"
          >
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dust"></div>
            <div className="flex flex-col">
              <div className="h-3 w-[100px] rounded bg-dust-dark"></div>
            </div>
          </div>
        ))}
    </div>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {Array(2)
        .fill(null)
        .map((_, i) => (
          <div
            key={i}
            className="relative flex w-full items-center gap-4 rounded-lg border border-dust-dark bg-white px-3 py-4"
          >
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dust"></div>
            <div className="flex flex-col">
              <div className="h-3 w-[100px] rounded bg-dust-dark"></div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

const ClientServiceLevelReport = () => {
  const track = useTrack();

  const [lensOption, setLensOption] = useState("top-companies");
  const [timeOption, setTimeOption] = useState("Last 180 days");
  const [teamOption, setTeamOption] = useState("all-teams");
  const [companyPage, setCompanyPage] = useState(1);
  const limit = 100;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
        subsection: "Live Report",
        link: "/report/",
      })
    );
  }, [dispatch]);

  const { data: teams = [], isLoading: isTeamsLoading } = useGetTeamsQuery();
  const { data: lensGroups = [], isLoading: isLensesLoading } =
    useGetLensesQuery();

  const allLenses = lensGroups
    .map((group) => {
      return [
        ...group.lenses.map((lens) => {
          return { id: +lens.id, name: lens.name };
        }),
      ];
    })
    .reduce((pre, cur) => pre.concat(cur), []);

  const { result, isLoading } = useClientServiceLevel({
    timeOption: timeOption,
    teamOption: teamOption,
    lensOption: lensOption,
  });

  const { resultSet: companyResultSet, isLoading: isCompanyLoading } =
    useClientServiceLevelCompanies({
      timeOption: timeOption,
      teamOption: teamOption,
      lensOption: lensOption,
      limit: limit,
      offset: (companyPage - 1) * limit,
    });

  const lastRefreshed = useSelector(selectCubeLastRefresh);
  const companies = companyResultSet["results"];
  const totalCompanies = companyResultSet["total"];
  const totalCompanyPages = totalCompanies
    ? Math.ceil(totalCompanies / limit)
    : 0;

  const fields: ITableField[] = [
    {
      field: "relationshipScore",
      label: "Relationship score",
      type: "relationship-scores",
    },
    {
      field: "emailsSent",
      label: "Emails sent",
      type: "text",
    },
    {
      field: "emailsReceived",
      label: "Emails received",
      type: "text",
    },
    {
      field: "numberMeetings",
      label: "Meetings",
      type: "text",
    },
    {
      field: "durationMeetings",
      label: "Total meeting duration",
      type: "text",
    },
    {
      field: "avgResponseTimeOut",
      label: "Average response time to them",
      type: "text",
    },
    {
      field: "avgResponseTime",
      label: "Average response time from them",
      type: "text",
    },
    {
      field: "numberContacts",
      label: "Known Contacts",
      type: "text",
    },
    {
      field: "numberActiveContacts",
      label: "Active contacts",
      type: "text",
    },
    {
      field: "numberNewContacts",
      label: "New contacts",
      type: "text",
    },
  ];

  const handleExportReport = () => {
    track("Downloaded client service report as CSV", {
      category: "Data export",
      label: "CSV download",
    });
    let exportDict = [
      {
        "Company Name": "Global",
        "Relationship Score": "N/A",
        "Email Sent": result["outboundMessages"].toString(),
        "Email Received": result["inboundMessages"].toString(),
        Meetings: result["meetings"].toString(),
        "Total meetings duration": formatSeconds(
          Number(result["meetingsDuration"]),
          true
        ),
        "Known Contacts": result["contacts"].toString(),
        "Active Contacts": result["activeContacts"].toString(),
        "New Contacts": result["newContacts"].toString(),
        "Average response time to them": formatSeconds(
          Number(result["responseTimeOut"])
        ),
        "Average response time from them": formatSeconds(
          Number(result["responseTime"])
        ),
      },
    ];
    exportDict = exportDict.concat(
      companies.map((o) => {
        return {
          "Company Name": o["dim_company.display_name"],
          "Relationship Score":
            o["fact_table.relationship_score_current"].toString(),
          "Email Sent": o["fact_email.outbound"].toString(),
          "Email Received": o["fact_email.inbound"].toString(),
          "Known Contacts": o["dim_contact.count"].toString(),
          "Active Contacts": o["active_contact.count"].toString(),
          "New Contacts": o["new_contact.count"].toString(),
          Meetings: o["dim_calendar.count"].toString(),
          "Total meetings duration": formatSeconds(
            o["dim_calendar.duration"],
            true
          ),
          "Average response time to them": formatSeconds(
            o["fact_email.avg_reply_out_response_time"]
          ),
          "Average response time from them": formatSeconds(
            o["fact_email.avg_reply_response_time"]
          ),
        };
      })
    );
    const title = `client_service_level_${timeOption}_${Date.now()}`
      .toLowerCase()
      .replace(/\s/g, "")
      .replace(/-/g, " ");
    downloadCSV(exportDict, title);
  };

  const outbound = result["outboundMessages"];
  const engaged = result["outboundEngagedMessages"];
  const averageEngement =
    outbound > 0 ? ((engaged / outbound) * 100).toFixed(1) : 0;

  return (
    <div className="flex w-full flex-col gap-4 lg:w-[1000px]">
      <div className="min-h-[380px] w-full overflow-scroll rounded-lg border border-dust-dark bg-white shadow lg:h-[620px] lg:w-[1000px]">
        <div className="relative flex flex-col">
          <div className="sticky top-0 z-10 flex w-full flex-col gap-4 rounded-lg bg-white p-4">
            <div className="flex items-start gap-2">
              <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-b from-orange to-orange-dark text-white">
                <i className="icon-relationships text-xl" />
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="font-bold text-navy">Client service levels</div>
                <div className="flex max-w-full flex-col text-metal md:flex-row md:gap-2">
                  {lastRefreshed.time
                    ? `Last updated ${formatDistanceToNow(
                        lastRefreshed.time
                      )} ago `
                    : "Live report "}
                  – Monitor your service levels to key clients
                </div>
              </div>
            </div>
            <div className="absolute right-4 top-4">
              <div className="flex gap-2">
                <Button
                  icon="icon-export"
                  color="dust"
                  tooltip="Export data"
                  onClick={handleExportReport}
                  disabled={isLoading || isCompanyLoading}
                />
              </div>
            </div>
            <div className="w-full border-t border-t-dust"></div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
              <SelectOption
                disabled={isLensesLoading}
                options={[
                  {
                    value: "top-companies",
                    label: "Showing all companies",
                  },
                  {
                    value: "sep",
                    label: "---",
                  },
                ].concat(
                  allLenses.map((lens) => {
                    return {
                      value: lens.id.toString(),
                      label: lens.name,
                    };
                  })
                )}
                value={lensOption}
                onChange={setLensOption}
              />
              <SelectOption
                disabled={isTeamsLoading}
                options={[
                  {
                    value: "all-teams",
                    label: "All teams",
                  },
                  {
                    value: "sep",
                    label: "---",
                  },
                ].concat(
                  teams
                    .filter((team) => team["teamMembers"].length > 0)
                    .map((team) => {
                      return {
                        value: team.id.toString(),
                        label: team.name,
                      };
                    })
                )}
                value={teamOption}
                onChange={setTeamOption}
              />
              <SelectOption
                disabled={isLoading}
                options={[
                  {
                    value: "Last 30 days",
                    label: "Over previous 30 days",
                  },
                  {
                    value: "sep",
                    label: "---",
                  },
                  {
                    value: "Last 90 days",
                    label: "Over previous 3 months",
                  },
                  {
                    value: "Last 180 days",
                    label: "Over previous 6 months",
                  },
                  {
                    value: "set",
                    label: "---",
                  },
                  {
                    value: "this month",
                    label: "Over this month to date",
                  },
                  {
                    value: "last month",
                    label: "Over last month",
                  },
                ]}
                value={timeOption}
                onChange={setTimeOption}
              />
            </div>
          </div>
          <div className="px-4 pb-4">
            <div data-testid="client-service-stats">
              {isLoading ? (
                <SkeletonHeader />
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="font-bold text-navy">Email engagement</div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <MiniStatistic
                      icon="icon-email-out"
                      description="Emails sent"
                      value={result["outboundMessages"]}
                    />
                    <MiniStatistic
                      icon="icon-email-in"
                      description="Emails received"
                      value={result["inboundMessages"]}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <MiniStatistic
                      icon="icon-email-schedule"
                      description="Average response time to them"
                      value={formatSeconds(Number(result["responseTimeOut"]))}
                    />
                    <MiniStatistic
                      icon="icon-email-schedule"
                      description="average response time from them"
                      value={formatSeconds(Number(result["responseTime"]))}
                    />
                    <MiniStatistic
                      icon="icon-graph-up"
                      description="Average Engagement"
                      value={`${averageEngement}%`}
                    />
                  </div>
                  <div className="font-bold text-navy">Meeting engagement</div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <MiniStatistic
                      icon="icon-calendar"
                      description="Meetings"
                      value={result["meetings"]}
                    />
                    <MiniStatistic
                      icon="icon-calendar"
                      description="Total meeting duration"
                      value={formatSeconds(
                        Number(result["meetingsDuration"]),
                        true
                      )}
                    />
                  </div>
                  <div className="font-bold text-navy">Network statistics</div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <MiniStatistic
                      icon="icon-email-schedule"
                      description="Known contacts"
                      value={result["contacts"]}
                    />
                    <MiniStatistic
                      icon="icon-email-schedule"
                      description="Active contacts"
                      value={result["activeContacts"]}
                    />
                    <MiniStatistic
                      icon="icon-email-schedule"
                      description="New contacts"
                      value={result["newContacts"]}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-[680px] rounded-lg border border-dust-dark bg-white shadow">
        <div>
          <CompanyTable
            fields={fields}
            dataSource={companies}
            isLoading={isLoading}
            sorting={null}
            personal={false}
            nameSource="dim_company.display_name"
            setSorting={(value) => {}}
          />
          {!isLoading && companies && totalCompanies === 0 && (
            <EmptyTableState title="No companies found" />
          )}
          <div className="px-8 py-6">
            {totalCompanyPages > 1 && (
              <Pagination
                current={companyPage}
                total={totalCompanyPages}
                onChange={setCompanyPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientServiceLevelReport;
