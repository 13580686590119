import { Filter, QueryOrder } from "@cubejs-client/core";
import { useState } from "react";
import { useParams } from "react-router-dom";

import FilterButton from "components/FilterButton";
import { BaseFrame } from "components/frames";
import ContactTable from "components/table/ContactTable";
import Pagination from "components/table/Pagination";
import ErrorState from "components/ErrorState";
import FilterTile, { FilterTileSkeleton } from "components/FilterTile";
import { ITableField } from "components/table/Table.interfaces";
import useCompanyContactStats from "features/company/useCompanyContactStats";
import useExtraCube from "utils/hooks/useExtraCube";
import { useGetUserQuery } from "features/profile/profileApi";
import { useSelector } from "react-redux";
import { selectCubeFilters } from "app/filtersSlice";
import {
  ActivityType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import CubeLastRefresh from "components/CubeLastRefresh";

const CompanyContactsView = () => {
  const params = useParams();
  const [page, setPage] = useState(1);
  const { data: user } = useGetUserQuery();

  const [localFilter, setLocalFilter] = useState("all");

  const limit = 20;

  const [sorting, setSorting] = useState(
    "-fact_table.relationship_score_current"
  );
  const fields: ITableField[] = [
    {
      field: "relationshipScore",
      label: "Relationship scores",
      type: "relationship-scores",
      source: "fact_table.relationship_score_current",
    },
    {
      field: "interest",
      label: "Interest",
      type: "trend",
      source: "fact_table.interest_current",
    },
    {
      field: "effort",
      label: "Effort",
      type: "trend",
      source: "fact_table.effort_current",
    },
    {
      field: "viewerLastInteraction",
      label: "Your last interaction",
      type: "interaction",
    },
    {
      field: "colleagueLastInteraction",
      label: "Recent colleague interaction",
      type: "interaction",
      source: "fact_table.last_interaction_details",
    },
    {
      field: "viewerEmailBreakdown",
      label: "Your email breakdown",
      type: "email-breakdown",
    },
    {
      field: "allEmailBreakdown",
      label: "All email breakdown",
      type: "email-breakdown",
    },
    {
      field: "viewerLastMeeting",
      label: "Your last meeting",
      type: "interaction",
    },
    {
      field: "colleagueLastMeeting",
      label: "Recent colleague meeting",
      type: "interaction",
    },
  ];

  const cubeFilters = useSelector(selectCubeFilters);

  const additionalFilters = (): Filter[] => {
    const result = [...cubeFilters];
    if (localFilter === "strengthening") {
      result.push({
        member: "fact_table.trend",
        operator: "equals",
        values: [String(TrendType.Strengthening)],
      } as Filter);
    } else if (localFilter === "weakening") {
      result.push({
        member: "fact_table.trend",
        operator: "equals",
        values: [String(TrendType.Weakening)],
      } as Filter);
    } else if (localFilter === "active") {
      result.push({
        member: "fact_table.activity",
        operator: "equals",
        values: [String(ActivityType.Active)],
      } as Filter);
    } else if (localFilter === "inactive") {
      result.push({
        member: "fact_table.activity",
        operator: "equals",
        values: [String(ActivityType.Inactive)],
      } as Filter);
    } else if (localFilter === "dormant") {
      result.push({
        member: "fact_table.activity",
        operator: "equals",
        values: [String(ActivityType.Dormant)],
      } as Filter);
    } else if (localFilter === "new") {
      result.push({
        member: "fact_table.segment",
        operator: "equals",
        values: [String(SegmentType.New)],
      } as Filter);
    }
    return result;
  };

  const { results: quickStats, isLoading: isQuickStatsLoading } =
    useCompanyContactStats(params.id as string, cubeFilters);

  const { resultSet, isLoading, error, total } = useExtraCube(
    {
      dimensions: [
        "dim_contact.full_name",
        "dim_contact.uuid",
        "dim_contact.email",
        "dim_contact.is_assistant",
        "dim_contact.is_leaver",
        ...(user?.showContactSignatures
          ? ["dim_contact.roles", "dim_contact.phone_numbers"]
          : []),
      ],
      measures: [
        "fact_table.relationship_score_current",
        "fact_table.rank",
        "fact_table.interest_current",
        "fact_table.interest_prev",
        "fact_table.effort_current",
        "fact_table.effort_prev",
        "fact_table.activity",
        "fact_table.trend",
        "fact_table.segment",
        "fact_table.last_interaction_details",
        "fact_table.last_meeting_details",
        "fact_table.inbound_current",
        "fact_table.outbound_current",
      ],
      order: {
        [sorting.replace("-", "")]: (sorting[0] === "-"
          ? "desc"
          : "asc") as QueryOrder,
      },
      filters: [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [params.id as string],
        },
        ...additionalFilters(),
        ...(!sorting
          ? []
          : [
              {
                member: sorting.replace("-", ""),
                operator: "set",
              },
            ]),
      ] as Filter[],
      offset: (page - 1) * limit,
      limit,
      total: true,
    },
    {
      secondary: {
        key: "dim_contact.uuid",
        filters: [
          {
            member: "dim_user.email",
            operator: "equals",
            values: [user?.email || ""],
          },
          ...additionalFilters(),
        ],
        measures: [
          "fact_table.relationship_score_current",
          "fact_table.last_interaction_details",
          "fact_table.last_meeting_details",
          "fact_table.inbound_current",
          "fact_table.outbound_current",
          "fact_table.interest_current",
          "fact_table.effort_current",
          "fact_table.trend",
          "fact_table.activity",
        ],
      },
    }
  );

  if (error) {
    console.error(error);
    return <ErrorState title="Company details" />;
  }

  const totalPages = total ? Math.ceil(total / limit) : 0;

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <BaseFrame>
        <div className="-mx-4 -my-4 flex flex-col gap-2 rounded-t-lg bg-gradient-to-b from-dust-light to-transparent p-4">
          <div className="text-metal">
            <i className="icon-filter pr-1" />
            Quick insights
          </div>
          <div className="flex gap-2 overflow-y-auto">
            {isQuickStatsLoading ? (
              <>
                {Array(5)
                  .fill(null)
                  .map((_, i) => (
                    <FilterTileSkeleton key={i} />
                  ))}
              </>
            ) : (
              <>
                <FilterTile
                  selected={localFilter === "all"}
                  onToggle={() => setLocalFilter("all")}
                  value={quickStats.all}
                  label="Contacts"
                  color="metal"
                />
                <FilterTile
                  selected={localFilter === "strengthening"}
                  onToggle={() => setLocalFilter("strengthening")}
                  value={quickStats.trend[TrendType.Strengthening] || 0}
                  label="Strengthening"
                  color="green"
                />
                <FilterTile
                  selected={localFilter === "weakening"}
                  onToggle={() => setLocalFilter("weakening")}
                  value={quickStats.trend[TrendType.Weakening] || 0}
                  label="Weakening"
                  color="red"
                />
                <FilterTile
                  selected={localFilter === "active"}
                  onToggle={() => setLocalFilter("active")}
                  value={quickStats.activity[ActivityType.Active] || 0}
                  label="Active"
                  color="green"
                />
                <FilterTile
                  selected={localFilter === "inactive"}
                  onToggle={() => setLocalFilter("inactive")}
                  value={quickStats.activity[ActivityType.Inactive] || 0}
                  label="Inactive"
                  color="red"
                />
                <FilterTile
                  selected={localFilter === "dormant"}
                  onToggle={() => setLocalFilter("dormant")}
                  value={quickStats.activity[ActivityType.Dormant] || 0}
                  label="Dormant"
                  color="red"
                />
                <FilterTile
                  selected={localFilter === "new"}
                  onToggle={() => setLocalFilter("new")}
                  value={quickStats.segment[SegmentType.New] || 0}
                  label="New"
                  color="green"
                />
              </>
            )}
          </div>
        </div>
        <div className="overflow-x-auto whitespace-nowrap">
          <ContactTable
            dataSource={resultSet}
            fields={fields}
            personal={false}
            nameSource="dim_contact.full_name"
            isLoading={isLoading}
            sorting={sorting}
            setSorting={(value) => {
              setPage(1);
              setSorting(value);
            }}
          />
        </div>
        {totalPages > 1 && (
          <div className="px-8 py-6">
            <Pagination current={page} total={totalPages} onChange={setPage} />
          </div>
        )}
      </BaseFrame>
    </div>
  );
};

export default CompanyContactsView;
