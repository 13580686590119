import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import Cookies from "js-cookie";

import prepareBaseQuery from "../prepareBaseQuery";
import { ITeam, IUser } from "./user.interface";
import transformUser from "features/profile/transformUser";

export const profileApi = createApi({
  reducerPath: "profile",
  tagTypes: ["profile"],
  baseQuery: prepareBaseQuery({
    baseUrl: "/",
  }),
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => "user/profile",
      providesTags: ["profile"],
      transformResponse: (response: any): IUser =>
        transformUser(camelizeKeys(response).user),
    }),
    getTeams: builder.query<ITeam[], void>({
      query: () => "pbi/user/me/team",
      transformResponse: (response: any): ITeam[] =>
        camelizeKeys(response).results as ITeam[],
    }),
    updateUser: builder.mutation<any, any>({
      invalidatesTags: ["profile"],
      query: (user) => ({
        url: "user/profile",
        method: "PATCH",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    unimpersonate: builder.mutation<any, void>({
      query: () => ({
        url: "user/unimpersonate",
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    impersonate: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: `user/impersonate/${email}`,
        method: "POST",
        mode: "cors",
        redirect: "follow",
        cache: "no-cache",
        credentials: "include",
        validateStatus: (response) => {
          return response.status === 200 || response.status === 302;
        },
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    getLogin: builder.query<any, any>({
      query: (_: any) => "/login",
    }),
    login: builder.mutation<any, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: "user/login",
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: `email=${encodeURIComponent(email)}&password=${encodeURIComponent(
          password
        )}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "user/logout",
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    requestOtp: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: "user/request_otp",
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: `email=${encodeURIComponent(email)}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    loginOtp: builder.mutation<any, { email: string; secret: string }>({
      query: ({ email, secret }) => ({
        url: "user/login_otp",
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify({
          email: email,
          verification_code: secret,
        }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    resetPassword: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: "user/reset_password",
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: `email=${encodeURIComponent(email)}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    changePassword: builder.mutation<
      any,
      { password: string; confirmPassword: string; token: string }
    >({
      query: ({ password, confirmPassword, token }) => ({
        url: "user/confirm_reset_password",
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: `password=${password}&confirm_password=${confirmPassword}&reset_password_token=${token}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetLoginQuery,
  useGetTeamsQuery,
  useLoginOtpMutation,
  useRequestOtpMutation,
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useUnimpersonateMutation,
  useImpersonateMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
} = profileApi;
