import { useState } from "react";
import { useSelector } from "react-redux";
import { Filter, QueryOrder } from "@cubejs-client/core";

import useLocalStorage from "utils/hooks/useLocalStorage";
import { useGetUserQuery } from "features/profile/profileApi";
import useExtraCube from "utils/hooks/useExtraCube";

import Button from "components/Button";
import FilterButton from "components/FilterButton";
import Pagination from "components/table/Pagination";
import ContactTable from "components/table/ContactTable";
import ErrorState from "components/ErrorState";
import EmptyTableState from "components/table/EmptyTableState";
import contactTableFields from "./contactTableFields";

import { selectCubeFilters, selectFiltersActive } from "app/filtersSlice";
import ContactTableOptionsModal from "pages/explore/modals/ContactTableOptionsModal";
import SlowQueryWarningModal from "pages/explore/common/SlowQueryWarningModal";
import ToggleSwitch from "components/atoms/ToggleSwitch";

const ContactsView = () => {
  const { data: user } = useGetUserQuery();

  const [personal, setPersonal] = useLocalStorage(
    "contact-personal-mode",
    "false"
  );
  const personalBool = personal === "true";

  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const [sorting, setSorting] = useState(
    "-fact_table.relationship_score_current"
  );
  const [limit, setLimit] = useLocalStorage("contact-limit", "20");

  const [columns, setColumns] = useLocalStorage("contact-columns", [
    "relationshipScore",
    "activity",
    "interest",
    "effort",
    "viewerLastInteraction",
    "colleagueLastInteraction",
    "colleaguesInteracting",
    "viewerEmailBreakdown",
    "allEmailBreakdown",
  ]);

  const [showFlags, setShowFlags] = useLocalStorage("contact-flags", "true");

  const cubeFilters = useSelector(selectCubeFilters);
  const filtersActive = useSelector(selectFiltersActive);
  const fields = user?.showContactSignatures
    ? contactTableFields
    : contactTableFields.filter(
        ({ field }) => field !== "phoneNumber" && field !== "role"
      );

  const { resultSet, isLoading, total, error } = useExtraCube(
    {
      dimensions: [
        "dim_contact.full_name",
        "dim_contact.uuid",
        "dim_contact.is_assistant",
        "dim_contact.is_leaver",
        "dim_company.display_name",
        "dim_contact.email",
        ...(user?.showContactSignatures
          ? ["dim_contact.roles", "dim_contact.phone_numbers"]
          : []),
      ],
      measures: [
        "fact_table.relationship_score_current",
        "fact_table.colleague_count_current",
        "fact_table.interest_current",
        "fact_table.interest_prev",
        "fact_table.effort_current",
        "fact_table.effort_prev",
        "fact_table.activity",
        "fact_table.trend",
        "fact_table.rank",
        "fact_table.segment",
        "fact_table.last_interaction_details",
        "fact_table.last_meeting_details",
        "fact_table.inbound_current",
        "fact_table.outbound_current",
      ],
      filters: [
        ...cubeFilters,
        ...(personalBool
          ? [
              {
                member: "dim_user.email",
                operator: "equals",
                values: [user ? (user.email as string) : ""],
              },
            ]
          : []),
        ...(!sorting
          ? []
          : [
              {
                member: sorting.replace("-", ""),
                operator: "set",
              },
            ]),
      ] as Filter[],
      order: {
        [sorting.replace("-", "")]: (sorting[0] === "-"
          ? "desc"
          : "asc") as QueryOrder,
      },
      offset: (page - 1) * +limit,
      limit: +limit,
      total: true,
    },
    {
      secondary: {
        key: "dim_contact.uuid",
        filters: !personalBool
          ? [
              {
                member: "dim_user.email",
                operator: "equals",
                values: [user ? (user.email as string) : ""],
              },
            ]
          : [],
        measures: [
          "fact_table.relationship_score_current",
          "fact_table.last_interaction_details",
          "fact_table.last_meeting_details",
          "fact_table.colleague_count_current",
          "fact_table.inbound_current",
          "fact_table.outbound_current",
          "fact_table.interest_current",
          "fact_table.effort_current",
          "fact_table.trend",
          "fact_table.rank",
          "fact_table.activity",
        ],
      },
    }
  );

  if (error) {
    console.error(error);
    return <ErrorState title="All contacts" />;
  }

  const totalPages = total ? Math.ceil(total / +limit) : 0;
  const totalWithCommas = total.toLocaleString();

  return (
    <>
      <div className="flex justify-between p-4">
        <div className="flex items-center gap-4">
          <FilterButton />
          {!isLoading && (
            <div className="hidden text-metal sm:block">
              <div>
                Showing <span className="font-bold">{totalWithCommas} </span>
                {total === 1 ? "contact" : "contacts"}.
              </div>
              {filtersActive && total === 0 && (
                <div>Change the filters to view contacts.</div>
              )}
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <ToggleSwitch
            value={!personalBool}
            onClick={() => setPersonal(String(!personalBool))}
            labels={{ on: "All contacts", off: "Contacts you know" }}
          />
          <Button
            icon="icon-filter-settings"
            tooltip="Contact table options"
            color="transparent"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <SlowQueryWarningModal isLoading={isLoading} />
      <ContactTable
        dataSource={resultSet}
        isLoading={isLoading}
        sorting={sorting}
        personal={personalBool}
        showFlags={showFlags === "true"}
        fields={fields.filter((option) => columns.includes(option.field))}
        nameSource="dim_contact.full_name"
        setSorting={(value) => {
          setPage(1);
          setSorting(value);
        }}
      />
      {!isLoading && resultSet && total === 0 && (
        <EmptyTableState title="No contacts found" />
      )}
      <div className="px-8 py-6">
        {totalPages > 1 && (
          <Pagination current={page} total={totalPages} onChange={setPage} />
        )}
      </div>
      <ContactTableOptionsModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        options={fields}
        flags={showFlags === "true"}
        active={columns}
        pages={limit}
        onUpdate={({
          pages,
          active,
          flags,
        }: {
          pages: number;
          active: string[];
          flags: boolean;
        }) => {
          setLimit(pages);
          setColumns(active);
          setShowFlags(String(flags));
        }}
      />
    </>
  );
};

export default ContactsView;
