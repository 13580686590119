import { InteractionEventType } from "features/interaction/interaction.interface";
import { useGetTimeSeriesInsightQuery } from "features/message/messageApi";
import enhancedInteractions from "features/interaction/enhancedInteractions.json";
import { useTrackFilterOptions } from "utils/trackOptions";
import { stackedBarOptions } from "charts/defaultOptions";

export const useBarInsights = ({
  id,
  barMetric,
}: {
  id: string;
  barMetric: { hour: string; day: string };
}) => {
  const [trackOptions] = useTrackFilterOptions();
  const queryOptions = { id, trackOptions };
  const { data: interactionsByHour = [], refetch: refetchInteractionsByHour } =
    useGetTimeSeriesInsightQuery({
      ...queryOptions,
      granularity: "hour",
    });
  const { data: interactionsByDay = [], refetch: refetchInteractionsByDay } =
    useGetTimeSeriesInsightQuery({
      ...queryOptions,
      granularity: "day",
    });
  const refetchStackedBarData = () => {
    refetchInteractionsByHour();
    refetchInteractionsByDay();
  };

  const interactionByDayLabels = [
    "1st day",
    "2nd day",
    "3rd day",
    "4th day",
    "5th day",
    "6th day",
    "7th day",
  ];

  const interactionByHourLabels = Array(24)
    .fill(0)
    .map((_, index) => `${index + 1}hr`);

  const interactionColors = {
    read: "#65D398",
    quickRead: "#D1F3E1",
    potentialForward: "#F2A5FF",
    click: "#A3EAE1",
    glance: "#FFE871",
    privacyRead: "#F0F8FF",
    reply: "#52738F",
  };

  const interactionFilter = (
    eInteraction: typeof enhancedInteractions[0],
    engagement: string
  ) => {
    if (eInteraction.id === "revisit") return false;
    if (engagement === "all") {
      return true;
    }
    return eInteraction.engagement === engagement;
  };

  const interactionMapping = (
    eInteraction: typeof enhancedInteractions[0],
    variant: "day" | "hour"
  ) => ({
    label: eInteraction.label,
    data:
      variant === "hour"
        ? interactionsByHour.map(
            (interaction) =>
              interaction[
                eInteraction.id as Exclude<InteractionEventType, "revisit">
              ]
          )
        : interactionsByDay.map(
            (interaction) =>
              interaction[
                eInteraction.id as Exclude<InteractionEventType, "revisit">
              ]
          ),
    borderColor:
      interactionColors[
        eInteraction.id as Exclude<InteractionEventType, "revisit">
      ],
    backgroundColor:
      interactionColors[
        eInteraction.id as Exclude<InteractionEventType, "revisit">
      ],
    barPercentage: 0.9,
    categoryPercentage: 0.9,
  });

  const interactionByHourDataset = enhancedInteractions
    .filter((eInteraction) => interactionFilter(eInteraction, barMetric.hour))
    .map((eInteraction) => interactionMapping(eInteraction, "hour"));

  const interactionByDayDataset = enhancedInteractions
    .filter((eInteraction) => interactionFilter(eInteraction, barMetric.day))
    .map((eInteraction) => interactionMapping(eInteraction, "day"));

  const interactionByHourData = {
    labels: interactionByHourLabels,
    datasets: interactionByHourDataset,
  };

  const interactionByDayData = {
    labels: interactionByDayLabels,
    datasets: interactionByDayDataset,
  };
  const interactionData = (variant: "day" | "hour") =>
    variant === "day" ? interactionByDayData : interactionByHourData;

  const customStackedBarOptions = (variant: "day" | "hour") => ({
    ...stackedBarOptions,
    scales: {
      ...stackedBarOptions.scales,
      x: {
        ...stackedBarOptions.scales.x,
        ticks: {
          ...stackedBarOptions.scales.x.ticks,
          maxTicksLimit: variant === "hour" ? 5 : 7,
        },
      },
    },
  });

  return {
    barData: interactionData,
    barOptions: customStackedBarOptions,
    refetchBarData: refetchStackedBarData,
  };
};
