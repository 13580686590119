import Button, { ButtonColor } from "components/Button";
import useNotifications from "utils/hooks/useNotifications";
import { IToast, ToastButtonType } from "./toast.interfaces";

const Toast = ({ id, text, icon, buttonType }: IToast) => {
  const { removeNotification } = useNotifications();

  const getButtonProps = (type: ToastButtonType, id: string) => {
    switch (type) {
      case "refresh":
        return {
          color: "white" as ButtonColor,
          text: "Refresh",
          onClick: () => window.location.reload(),
        };
      case "remove":
        return {
          color: "white" as ButtonColor,
          icon: "icon-menu-open",
          onClick: () => removeNotification(id),
        };
    }
  };
  return (
    <div className="mb-4 flex w-full justify-between rounded-md bg-navy-dark p-2 px-4 text-white sm:w-auto">
      <div className="flex space-x-4 px-2">
        {icon && <i className={`flex text-2xl text-white ${icon}`}></i>}
        <div className="flex py-2">{text}</div>
      </div>
      <div className="flex">
        {buttonType && <Button {...getButtonProps(buttonType, id as string)} />}
      </div>
    </div>
  );
};
export default Toast;
