import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";

import { add, fromPairs, max, reduce } from "ramda";

const useCompanyContactStats = (
  companyId: string,
  additionalFilters: Filter[]
) => {
  const objectify = (
    result?: any[]
  ): {
    [index: number]: number;
  } =>
    result
      ? fromPairs(
          result.map((item): [number, number] => {
            const values: number[] = Object.values(item);
            return [+values[0], +values[1]];
          })
        )
      : {};

  const dimensionQuery = (dimension: string): Query => ({
    measures: ["fact_table.contact_count"],
    dimensions: [dimension],
    filters: [
      {
        member: "dim_company.uuid",
        operator: "equals",
        values: [companyId],
      },
      ...additionalFilters,
    ],
  });
  const { resultSet: activityResults, isLoading: isActivityLoading } =
    useCubeQuery(dimensionQuery("dim_contact.activity_status"));
  const activity = objectify(activityResults?.tablePivot());

  const { resultSet: rankResults, isLoading: isRankLoading } = useCubeQuery(
    dimensionQuery("dim_contact.rank_status")
  );
  const rank = objectify(rankResults?.tablePivot());

  const { resultSet: segmentResults, isLoading: isSegmentLoading } =
    useCubeQuery(dimensionQuery("dim_contact.segment_status"));
  const segment = objectify(segmentResults?.tablePivot());

  const { resultSet: trendResults, isLoading: isTrendLoading } = useCubeQuery(
    dimensionQuery("dim_contact.trend_status")
  );
  const trend = objectify(trendResults?.tablePivot());

  const sum: (list: number[]) => number = reduce(add, 0);
  const metrics = [activity, rank, segment, trend];
  const all = reduce(
    max,
    0,
    metrics.map((metric) => sum(Object.values(metric)))
  );

  return {
    results: {
      all,
      activity,
      rank,
      segment,
      trend,
    },
    isLoading:
      isActivityLoading || isRankLoading || isSegmentLoading || isTrendLoading,
  };
};

export default useCompanyContactStats;
