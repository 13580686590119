import { useEffect, useState } from "react";
import { mergeRight, pluck } from "ramda";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button";
import Modal from "components/Modal";
import FilterTag from "pages/explore/modals/FiltersModal/FilterTag";

import { useGetTeamsQuery } from "features/team/teamApi";
import { useGetLensesQuery } from "features/lens/lensApi";
import {
  changeFilters,
  defaultCompanyFilters,
  defaultContactFilters,
  resetFilters,
  selectFilters,
  setDefaultFilterState,
} from "app/filtersSlice";
import {
  ContactFiltersTab,
  CompanyFiltersTab,
  TeamFiltersTab,
  CustomFiltersTab,
} from "./FiltersModalTabs";
import same from "utils/same";

const FiltersModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  const { data: teams = [], isLoading: isTeamsLoading } = useGetTeamsQuery();
  const { data: lenses = [], isLoading: isLensesLoading } = useGetLensesQuery();
  const {
    contact,
    company,
    teams: selectedTeams,
    custom: selectedCustom,
  } = useSelector(selectFilters);
  const dispatch = useDispatch();

  const defaultTeams = pluck("id", teams);
  const defaultCustom = lenses.reduce(
    (obj, group) => ({
      ...obj,
      [group.id]: [...group.lenses.map((lens) => +lens.id), -1 * +group.id],
    }),
    {}
  );

  useEffect(() => {
    if (!isTeamsLoading && !isLensesLoading) {
      dispatch(setDefaultFilterState({ defaultCustom, defaultTeams }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamsLoading, isLensesLoading]);

  const [tempFilters, setTempFilters] = useState({
    contact,
    company,
    teams: selectedTeams,
    custom: selectedCustom,
  });
  const [activeTab, setActiveTab] = useState("contact");

  const close = () => {
    setTempFilters({
      contact,
      company,
      teams: selectedTeams,
      custom: selectedCustom,
    });
    onClose();
  };

  const clearFilters = () => {
    dispatch(resetFilters());
    setTempFilters({
      contact: defaultContactFilters,
      company: defaultCompanyFilters,
      teams: defaultTeams,
      custom: defaultCustom,
    });
    onClose();
  };

  const applyFilters = () => {
    dispatch(
      changeFilters({
        contact: mergeRight(contact, tempFilters.contact),
        company: mergeRight(company, tempFilters.company),
        teams: tempFilters.teams,
        custom: tempFilters.custom,
      })
    );
    onClose();
  };

  const tags = [
    {
      icon: "icon-contact",
      name: "Contact",
      id: "contact",
      isDefault: same(defaultContactFilters, contact),
      visible: true,
    },
    {
      icon: "icon-company",
      name: "Company",
      id: "company",
      isDefault: same(defaultCompanyFilters, company),
      visible: true,
    },
    {
      icon: "icon-team",
      name: "Team",
      id: "team",
      isDefault:
        same(defaultTeams, selectedTeams) || selectedTeams.length === 0,
      visible: teams.length > 0,
    },
    {
      icon: "icon-filter",
      name: "Custom filters",
      id: "custom",
      isDefault: same(defaultCustom, selectedCustom),
      visible: lenses.length > 0,
    },
  ];

  const filtersBody = () => {
    switch (activeTab) {
      case "contact":
        return (
          <ContactFiltersTab
            {...tempFilters.contact}
            onUpdate={(data) =>
              setTempFilters({
                ...tempFilters,
                contact: mergeRight(tempFilters.contact, data),
              })
            }
          />
        );
      case "company":
        return (
          <CompanyFiltersTab
            {...tempFilters.company}
            onUpdate={(data) =>
              setTempFilters({
                ...tempFilters,
                company: mergeRight(tempFilters.company, data),
              })
            }
          />
        );
      case "team":
        return (
          <TeamFiltersTab
            teams={teams}
            selected={tempFilters.teams}
            updateSelected={(updated) =>
              setTempFilters({ ...tempFilters, teams: updated })
            }
          />
        );
      case "custom":
        return (
          <CustomFiltersTab
            lenses={lenses}
            selected={tempFilters.custom}
            updateSelected={(updated) =>
              setTempFilters({ ...tempFilters, custom: updated })
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      usePadding={false}
      width="large"
      height="large"
      visible={visible}
      onClose={close}
    >
      <div className="flex h-full flex-col text-base">
        <div className="flex items-center p-4">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-watchlist-add"></i>
            Apply a filter
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={close} />
          </div>
        </div>
        <div className="flex shrink-0 justify-center gap-4 overflow-x-auto border-y border-dust-dark bg-dust-light p-4 sm:pl-[120px] lg:pl-4">
          {tags
            .filter(({ visible }) => visible)
            .map((tag) => (
              <FilterTag
                icon={tag.icon}
                name={tag.name}
                key={tag.id}
                isDefault={tag.isDefault}
                active={activeTab === tag.id}
                onClick={() => setActiveTab(tag.id)}
              />
            ))}
        </div>
        <div className="overflow-y-auto px-8 py-6">{filtersBody()}</div>
        <div className="mt-auto flex justify-end gap-4 border-t border-dust-dark p-4">
          <Button
            color="dust"
            text="Clear all filters"
            onClick={clearFilters}
          />
          <Button color="navy" text="Apply changes" onClick={applyFilters} />
        </div>
      </div>
    </Modal>
  );
};

export default FiltersModal;
