import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";

import { ICompanyDetail, IContactDetail } from "./entity.interfaces";
import prepareBaseQuery from "../prepareBaseQuery";
import Cookies from "js-cookie";

export const entityApi = createApi({
  reducerPath: "entity",
  tagTypes: ["contact", "company"],
  baseQuery: prepareBaseQuery({
    baseUrl: "/pbi",
  }),
  endpoints: (builder) => ({
    getCompany: builder.query<ICompanyDetail, { id: string }>({
      query: ({ id }) => {
        return `/company/${id}`;
      },
      providesTags: ["company"],
      transformResponse: (response: any): ICompanyDetail =>
        camelizeKeys(response) as ICompanyDetail,
    }),
    getContact: builder.query<IContactDetail, { id: string }>({
      query: ({ id }) => {
        return `/contact/${id}`;
      },
      providesTags: ["contact"],
      transformResponse: (response: any): IContactDetail => {
        return camelizeKeys(response) as IContactDetail;
      },
    }),
    updateContact: builder.mutation<any, any>({
      invalidatesTags: ["contact"],
      query: ({ id, ...patch }) => ({
        url: `/contact/${id}`,
        method: "PATCH",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(patch),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
    updateCompany: builder.mutation<any, any>({
      invalidatesTags: ["company"],
      query: ({ id, ...patch }) => ({
        url: `/company/${id}`,
        method: "PATCH",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(patch),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useGetContactQuery,
  useUpdateContactMutation,
  useUpdateCompanyMutation,
} = entityApi;
