import { format } from "date-fns";
import {
  PersonalDetailPanel,
  PersonalisationPanel,
  TeamsPanel,
  LoginOptionsPanel,
  AccessPanel,
} from "./AccountPanels";
import {
  useGetTeamsQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../profileApi";

const Account = () => {
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const [update, { isLoading }] = useUpdateUserMutation();

  const { data: teams = [], isLoading: isTeamsLoading } = useGetTeamsQuery();

  if (isUserLoading || isTeamsLoading || !user) return <></>;
  return (
    <div>
      <div className="mt-16 grid h-28 w-full place-items-center bg-dust bg-account-header bg-auto bg-center bg-no-repeat">
        <div>
          <div className="text-center text-2xl font-bold text-metal-dark">
            Hello, {user?.name} {user?.surname}!
          </div>
          <div className="text-center text-metal-dark">
            {`You've been a user since ${format(
              new Date(user?.dateJoined),
              "MMMM y"
            )}`}
          </div>
        </div>
      </div>
      <div className="grid place-items-center px-4 sm:px-6">
        <div className="mt-6 columns-1 md:columns-2 xl:columns-3">
          <PersonalDetailPanel
            user={user}
            updateUser={update}
            isLoading={isLoading}
          />
          <PersonalisationPanel />
          <TeamsPanel teams={teams} />
          <LoginOptionsPanel user={user} />
          <AccessPanel user={user} />
        </div>
      </div>
    </div>
  );
};

export default Account;
