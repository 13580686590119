const Tooltip = ({ content }: { content: string; orientation?: string }) => {
  return (
    <div className="group relative duration-300">
      <i
        data-tooltip-content={content}
        data-tooltip-id="default-tooltip"
        className="icon-help cursor-pointer text-lg text-metal group-hover:text-navy-dark"
      />
    </div>
  );
};

export default Tooltip;
