import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../Login/LoginContainer";

const LogoutError = () => {
  const navigate = useNavigate();
  return (
    <LoginContainer icon="icon-warning-hex" text="Logging out has failed">
      <p className="mt-4 text-center text-metal">
        We are unable to logout from your account.
        <br />
        <br />
        You can go back and try again.
        <br />
        If this error persists, contact our support team.
      </p>

      <div className="mt-8 mb-4 flex flex-col gap-4 px-4">
        <Button
          color="dust"
          text="Go back"
          onClick={() => {
            navigate("/account");
          }}
        />
        <Button
          id="contact-support-login"
          color="dust"
          text="Contact support"
        />
      </div>
    </LoginContainer>
  );
};

export default LogoutError;
