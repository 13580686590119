const SkeletonReportContact = () => (
  <div className="flex w-full flex-col rounded border border-l-8 border-dust px-4 py-2 md:flex-row md:items-center md:justify-between">
    <div className="flex h-12 gap-2 md:items-center md:justify-between">
      <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
      <div className="flex flex-col gap-1">
        <div className="h-4 w-[120px] animate-pulse rounded-sm bg-dust-light"></div>
      </div>
    </div>
    <div className="flex flex-col gap-1 md:ml-16">
      <div className="h-4 w-[317px] animate-pulse rounded-sm bg-dust"></div>
    </div>
    <div className="block pb-2 pt-4 md:ml-auto md:p-0">
      <div className="flex h-8 w-[120px] animate-pulse items-center justify-center rounded bg-dust"></div>
    </div>
  </div>
);
export default SkeletonReportContact;
