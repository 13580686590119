import classNames from "classnames";
import Button from "components/Button";
import DayLine from "components/DayLine";
import { format, isSameDay } from "date-fns";
import { IInteraction } from "features/interaction/interaction.interface";
import { useGetInteractionsQuery } from "features/interaction/interactionApi";
import { InteractionSkeleton } from "pages/track/interaction/InteractionSkeletons";
import { groupBy } from "ramda";
import { useState } from "react";
import useScrolling from "utils/hooks/useScrolling";
import { useTrackFilterOptions } from "utils/trackOptions";
import { getZonedDate } from "utils/dates";
import EmailDetailError from "./EmailDetailError";
import Interaction from "pages/track/interaction/Interaction";

const EmailDetailInteractionList = ({
  interactions,
  fetchMore,
  hasNoMore,
  isFetching,
  sendTimestamp,
  messageSubject,
}: {
  interactions: IInteraction[];
  fetchMore: () => void;
  hasNoMore: boolean;
  isFetching: boolean;
  sendTimestamp?: string;
  messageSubject?: string;
}) => {
  const {
    ref: scrollingRef,
    scrollToTop,
    fromTop,
    sentryRef,
  } = useScrolling({ callback: fetchMore, isFetching });

  const byDay = groupBy((interaction: IInteraction) =>
    format(new Date(interaction.actionCreationTimestamp), "yyyy-MM-dd")
  );
  const interactionsByDay = byDay(interactions);
  const days = Object.keys(interactionsByDay);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const sentInADifferentDay =
    sendTimestamp &&
    interactions.filter((interaction) =>
      isSameDay(
        new Date(interaction.actionCreationTimestamp),
        new Date(sendTimestamp)
      )
    ).length === 0;

  return (
    <div
      data-testid="detail-interactions-scroll"
      ref={scrollingRef}
      className="h-full w-full overflow-y-scroll"
    >
      <div className="absolute right-0 top-0 z-50 p-4">
        {fromTop > 200 && (
          <Button
            color="transparent"
            icon="icon-increase text-metal"
            text="Today"
            onClick={scrollToTop}
          />
        )}
      </div>
      <div className="absolute left-16 top-0 z-0 h-full border-r border-dust-dark" />
      <div className="z-10 flex flex-col gap-4 pb-32 pt-4">
        {days.map((day, i) => (
          <div key={i} className="pl-4">
            <DayLine
              date={getZonedDate(new Date(`${day}T00:00:00`), timezone)}
            />
            <div className="flex flex-col gap-2 pt-2">
              {interactionsByDay[day].map((interaction) => (
                <Interaction
                  variant="email-detail"
                  key={interaction.uuid}
                  interaction={interaction}
                />
              ))}
            </div>
          </div>
        ))}

        {hasNoMore && (
          <div
            className={classNames("pl-4", { "-mt-2": !sentInADifferentDay })}
          >
            {sentInADifferentDay && <DayLine date={new Date(sendTimestamp)} />}
            <div
              className={classNames(
                "group flex flex-row gap-x-2 pl-4 text-base text-metal",
                { "pt-2": sentInADifferentDay }
              )}
            >
              <div
                data-testid="sent-action"
                className="z-[5] flex flex-row items-center gap-x-4 rounded-md border border-dust bg-dust-light p-2 pr-4 font-bold"
              >
                <div className="rounded-sm border border-dust-darker px-2 py-1 text-metal-dark">
                  Sent
                </div>
                <div className="text-metal-dark">{messageSubject}</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                {!!sendTimestamp && format(new Date(sendTimestamp), "HH:mm")}
              </div>
            </div>
          </div>
        )}
        {(isFetching || !hasNoMore) && (
          <div className="z-[5] w-[333px] pl-8" ref={sentryRef}>
            <InteractionSkeleton />
          </div>
        )}
      </div>
    </div>
  );
};

const EmailDetailInteractionListSkeleton = () => (
  <div className="relative z-10 flex h-full flex-col p-4">
    <div className="absolute left-16 z-0 h-full border-r border-dust-dark" />
    <div className="z-[5] flex h-12 flex-row items-center bg-white pl-4">
      <div className="flex flex-col gap-0.5">
        <div className="h-4 w-[155px] rounded-full bg-dust" />
        <div className="h-4 w-[93px] rounded-full bg-dust-light" />
      </div>
    </div>
    <div className="z-[5] w-[350px] px-4">
      <InteractionSkeleton />
    </div>
  </div>
);

const EmailDetailInteractionTimeline = ({
  id,
  sendTimestamp,
  messageSubject,
}: {
  id: string;
  sendTimestamp?: string;
  messageSubject?: string;
}) => {
  const [cursor, setCursor] = useState<string | undefined>();
  const [trackOptions] = useTrackFilterOptions();
  const {
    data: interactionResponse,
    isError,
    isLoading,
    isFetching,
  } = useGetInteractionsQuery({
    messageId: id,
    cursor,
    trackOptions,
  });

  const fetchMore = () => {
    if (interactionResponse?.next) {
      setCursor(interactionResponse.next);
    }
  };

  if (isError) return <EmailDetailError />;
  return (
    <div className="relative flex h-full w-full overflow-hidden">
      {isLoading ? (
        <EmailDetailInteractionListSkeleton />
      ) : (
        <EmailDetailInteractionList
          hasNoMore={!interactionResponse?.next}
          isFetching={isFetching}
          interactions={interactionResponse?.results || []}
          sendTimestamp={sendTimestamp}
          messageSubject={messageSubject}
          fetchMore={fetchMore}
        />
      )}
    </div>
  );
};
export default EmailDetailInteractionTimeline;
