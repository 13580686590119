import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IContactDetail } from "features/entity/entity.interfaces";
import { capitalizeFirstLetter } from "utils/string";

type TContactModalState = {
  showCallModal: boolean;
  showEmailModal: boolean;
  showFlagsModal: boolean;
  contact: Partial<IContactDetail> | undefined;
};

const initialState: TContactModalState = {
  showCallModal: false,
  showEmailModal: false,
  showFlagsModal: false,
  contact: undefined,
};

const contactModalsSlice = createSlice({
  name: "contactModals",
  initialState,
  reducers: {
    closeContactModals: (state) => {
      return initialState;
    },
    showContactModal(state, action) {
      const { modal, contact } = action.payload as {
        modal: "call" | "email" | "flags";
        contact: any;
      };
      const key = `show${capitalizeFirstLetter(modal)}Modal`;
      return {
        ...initialState,
        contact,
        [key]: true,
      };
    },
  },
});
export const selectContactModals = (state: RootState) => state.contactModals;

export const { closeContactModals, showContactModal } =
  contactModalsSlice.actions;
export default contactModalsSlice.reducer;
