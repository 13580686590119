import { find, propEq, propOr } from "ramda";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useGetUserQuery } from "features/profile/profileApi";
import useEntityWaffleCharts from "features/entity/useEntityWaffleCharts";
import useEntityPieMetrics from "features/entity/useEntityPieMetrics";
import formatPieChartLabels from "utils/charts/formatPieChartLabels";
import { WaffleChart, PieChart, pieOptions, OneDimensionChart } from "charts";
import FilterButton from "components/FilterButton";
import { SelectionFrame } from "components/frames";
import {
  emailEngagementMetricOptions,
  emailsMetricOptions,
  sentimentMetricOptions,
} from "./ActivityViewOptions";
import { selectCubeFilters } from "app/filtersSlice";
import { nonZeroValues } from "utils/objects";
import { EngagementType } from "features/entity/entity.interfaces";
import { engagementColors } from "utils/colorMapping";
import { beginningOfMonthsAgo } from "utils/dates";

import ActivityTable from "./ActivityTable";
import CubeLastRefresh from "components/CubeLastRefresh";
import { useSentiment } from "features/sentiment/useSentiment";

const EmailActivityView = ({ type }: { type: "contact" | "company" }) => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const activityMonthRanges = [3, 6, 12];

  const queryData = () => {
    switch (type) {
      case "contact":
        return { contactId: params.id as string };
      case "company":
        return { companyId: params.id as string };
    }
  };

  // sentiment
  const [sentimentMonthRange, setSentimentMonthRange] = useState(6);
  const { results: sentiment, isLoading: isSentimentLoading } = useSentiment({
    additionalFilters: cubeFilters,
    monthRange: sentimentMonthRange,
    ...queryData(),
  });

  // waffle
  const { results: waffleSeries, isLoading: isWaffleLoading } =
    useEntityWaffleCharts({
      ...queryData(),
      user,
      additionalFilters: cubeFilters,
      type: "emails",
    });

  const [emailsMetric, setEmailsMetric] = useState("all_emails");
  const [emailsMonthRange, setEmailsMonthRange] = useState(6);

  const activeEmailLegendLabel = propOr(
    "",
    "legend",
    find(propEq("value", emailsMetric), emailsMetricOptions)
  );

  const emailsMetricData = waffleSeries
    .filter(
      (record) =>
        new Date(record.timeLabel as string) >=
        beginningOfMonthsAgo(emailsMonthRange)
    )
    .map((record) => ({
      day: record.timeLabel,
      value: propOr(0, emailsMetric, record),
    }));

  // pie
  const [emailEngagementMetric, setEmailEngagementMetric] =
    useState("engagement");
  const [emailEngagementMonthRange, setEmailEngagementMonthRange] = useState(6);

  const { results: emailEngagements, isLoading: isEngagementsLoading } =
    useEntityPieMetrics({
      user,
      type: "engagement",
      additionalFilters: cubeFilters,
      monthRange: emailEngagementMonthRange,
      ...queryData(),
    });

  const engagementData: object = propOr(
    {},
    emailEngagementMetric,
    emailEngagements
  );

  const engagementChartData = {
    labels: formatPieChartLabels(engagementData, {
      [EngagementType.Unengaged]: "Unengaged",
      [EngagementType.Engaged]: "Engaged",
      [EngagementType.HighlyEngaged]: "Highly engaged",
    }),
    datasets: [
      {
        data: nonZeroValues(engagementData),
        backgroundColor: Object.keys(engagementData).map((key) =>
          propOr("#65D398", key, engagementColors)
        ),
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <SelectionFrame
          options={emailsMetricOptions}
          value={emailsMetric}
          onChange={setEmailsMetric}
          monthRanges={activityMonthRanges}
          monthRangeValue={emailsMonthRange}
          onMonthRangeChange={setEmailsMonthRange}
        >
          <WaffleChart
            id="email"
            tooltip={(date, value) =>
              `${activeEmailLegendLabel}<br />${date}: ${value}`
            }
            data={emailsMetricData}
            isLoading={isWaffleLoading}
          />
        </SelectionFrame>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
          <SelectionFrame
            options={emailEngagementMetricOptions}
            value={emailEngagementMetric}
            onChange={setEmailEngagementMetric}
            monthRanges={activityMonthRanges}
            monthRangeValue={emailEngagementMonthRange}
            onMonthRangeChange={setEmailEngagementMonthRange}
            isLoading={isUserLoading || isEngagementsLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart data={engagementChartData} options={pieOptions} />
            </div>
          </SelectionFrame>
          <SelectionFrame
            options={sentimentMetricOptions}
            monthRanges={activityMonthRanges}
            monthRangeValue={sentimentMonthRange}
            onMonthRangeChange={setSentimentMonthRange}
            isLoading={isSentimentLoading}
          >
            <OneDimensionChart
              title="Sentiment"
              data={sentiment}
              noDataTitle="No sentiment available"
              noDataDescription="We have not collected enough recent data to determine the sentiment"
            />
          </SelectionFrame>
        </div>
        <ActivityTable type={type} subtype="emails" />
      </div>
    </div>
  );
};

export default EmailActivityView;
