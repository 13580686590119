import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";
import { IDemoTag } from "./demo.interface";

export const demoApi = createApi({
  reducerPath: "demo",
  baseQuery: prepareBaseQuery({
    baseUrl: "/",
  }),
  endpoints: (builder) => ({
    getDemoTags: builder.query<IDemoTag[], void>({
      query: () => ({
        url: "demo_tag",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      transformResponse: (response: any): IDemoTag[] =>
        camelizeKeys(response) as IDemoTag[],
    }),
  }),
});

export const { useGetDemoTagsQuery } = demoApi;
