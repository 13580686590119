import { filter, map, pluck, propEq } from "ramda";

import downloadCSV from "utils/downloadCSV";
import {
  IMessage,
  IMessageInteraction,
  IMessageRecipient,
} from "features/message/message.interface";
import { ContactEngagementType } from "features/entity/entity.interfaces";
import { InteractionEventType } from "features/interaction/interaction.interface";

const formatEngagement = (engagement: ContactEngagementType) => {
  switch (engagement) {
    case "none":
      return "Unengaged";
    case "high":
      return "Highly engaged";
    case "standard":
      return "Engaged";
  }
};

const countInteractions = (
  interactions: IMessageInteraction[],
  eventType: InteractionEventType
) => filter(propEq("eventType", eventType), interactions).length;

export const formatMessageRecipient = ({
  contact,
  engagement,
  interactions,
}: IMessageRecipient) => ({
  Contact: contact.fullName,
  Email: contact.email,
  Company: contact.company?.displayName || "",
  Engagement: formatEngagement(engagement),
  Revisits: interactions.filter(({ isRevisit }) => isRevisit).length,
  "Potential forwards": countInteractions(interactions, "potentialForward"),
  Clicks: countInteractions(interactions, "click"),
  Replies: countInteractions(interactions, "reply"),
  Reads: countInteractions(interactions, "read"),
  "Quick Reads": countInteractions(interactions, "quickRead"),
  Glances: countInteractions(interactions, "glance"),
  "Privacy Reads": countInteractions(interactions, "privacyRead"),
  "URLs clicked": pluck(
    "url",
    interactions.filter(({ eventType }) => eventType === "click")
  ).join(";"),
});

const downloadMessageAsCSV = (message: IMessage) =>
  downloadCSV(
    map(formatMessageRecipient, message.recipients),
    message.messageSubject
  );

export default downloadMessageAsCSV;
