import classNames from "classnames";
import Button from "components/Button";

const LiveReportPreviewBadge = ({
  value,
  color,
  description,
  openReport,
}: {
  value: number;
  color: "sky" | "purple" | "red" | "metal";
  description: { singular: string; plural: string };
  openReport: VoidFunction;
}) => {
  return (
    <div
      className={classNames(
        "flex h-[176px] w-[146px] flex-shrink-0 flex-col items-center justify-center rounded bg-gradient-to-b p-6 text-center text-white",
        {
          "from-sky to-sky-dark": color === "sky",
          "from-purple to-purple-dark": color === "purple",
          "from-metal to-metal-dark": color === "metal",
          "from-red to-red-dark": color === "red",
        }
      )}
    >
      <div className="text-3xl">{value.toLocaleString()}</div>
      <div className="text-xl">
        {value > 1 ? description.plural : description.singular}
      </div>
      {value > 1 && (
        <div className="mb-2 mt-4">
          <Button
            color="transparent-dark"
            text="See all"
            icon="icon-right"
            iconRight
            onClick={openReport}
          />
        </div>
      )}
    </div>
  );
};

export default LiveReportPreviewBadge;
