import { useState } from "react";

import { SelectionFrame } from "components/frames";
import ColleagueTable from "components/table/ColleagueTable";
import TeamTable from "components/table/TeamTable";
import Pagination from "components/table/Pagination";
import { ITableField } from "components/table/Table.interfaces";
import useEntityQuery from "features/entity/useEntityQuery";
import { selectCubeFilters } from "app/filtersSlice";
import { useSelector } from "react-redux";

const tableMetricOptions = [
  {
    value: "colleagues",
    label: "Showing all colleagues",
  },
  {
    value: "teams",
    label: "Showing all teams",
  },
];

const tableFields: ITableField[] = [
  {
    field: "firstInteraction",
    label: "First interaction",
    type: "text",
    source: "fact_table.first_interaction",
  },
  {
    field: "lastInteraction",
    label: "Recent interaction",
    type: "text",
    source: "fact_table.last_interaction",
  },
  {
    field: "relationshipScore",
    label: "Relationship score",
    type: "progress-bar",
    source: "fact_table.relationship_score_current",
  },
  {
    field: "averageResponseTimeOut",
    label: "Average response time to them",
    type: "average-time",
    source: "fact_table.reply_out_response_time_current",
  },
  {
    field: "averageResponseTime",
    label: "Average response time from them",
    type: "average-time",
    source: "fact_table.reply_response_time_current",
  },
  {
    field: "allEmailBreakdown",
    label: "All email breakdown",
    type: "email-breakdown",
  },
  {
    field: "lastMeeting",
    label: "Recent meeting",
    type: "text",
    source: "fact_table.last_meeting",
  },
];

const RelationshipTables = ({
  companyId,
  contactId,
}: {
  companyId?: string;
  contactId?: string;
}) => {
  const defaultSorting = "-fact_table.relationship_score_current";
  const [metric, setMetric] = useState("colleagues");
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState(defaultSorting);
  const cubeFilters = useSelector(selectCubeFilters);

  const {
    results: colleagues,
    isLoading: colleaguesLoading,
    totalPages: colleaguesTotalPages,
  } = useEntityQuery({
    companyId,
    contactId,
    page,
    sorting,
    dimensions: ["dim_user.full_name", "dim_user.uuid", "dim_user.email"],
    additionalFilters: cubeFilters,
  });

  const {
    results: teams,
    isLoading: teamsLoading,
    totalPages: teamsTotalPages,
  } = useEntityQuery({
    companyId,
    contactId,
    page,
    sorting,
    dimensions: ["dim_team.name", "dim_team.color", "dim_team.uuid"],
    additionalFilters: [
      ...cubeFilters,
      {
        member: "dim_team.uuid",
        operator: "set",
      },
    ],
  });

  const changeMetric = (value: string) => {
    setMetric(value);
    setPage(1);
    setSorting(defaultSorting);
  };

  return (
    <SelectionFrame
      options={tableMetricOptions}
      value={metric}
      onChange={changeMetric}
    >
      {metric === "colleagues" ? (
        <>
          <ColleagueTable
            fields={tableFields}
            dataSource={colleagues}
            isLoading={colleaguesLoading}
            nameSource="dim_user.full_name"
            sorting={sorting}
            setSorting={(value) => {
              setPage(1);
              setSorting(value);
            }}
          />
          <div className="px-8 py-6">
            {colleaguesTotalPages > 1 && (
              <Pagination
                current={page}
                total={colleaguesTotalPages}
                onChange={setPage}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <TeamTable
            fields={tableFields}
            dataSource={teams}
            isLoading={teamsLoading}
            nameSource="dim_team.name"
            sorting={sorting}
            setSorting={(value) => {
              setPage(1);
              setSorting(value);
            }}
          />
          <div className="px-8 py-6">
            {teamsTotalPages > 1 && (
              <Pagination
                current={page}
                total={teamsTotalPages}
                onChange={setPage}
              />
            )}
          </div>
        </>
      )}
    </SelectionFrame>
  );
};

export default RelationshipTables;
