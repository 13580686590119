import { IdentifierType } from "components/atoms/Identifier";
import EntityIdentifier from "components/EntityIdentifier";

const ContactItem = ({
  fullName,
  description,
  position,
  email,
  phoneNumbers,
  type,
  id,
}: {
  fullName: string;
  description?: string;
  position?: number;
  email?: string;
  phoneNumbers?: any[];
  type: IdentifierType;
  id: string;
}) => (
  <div className="flex h-14 items-center gap-2 rounded bg-white p-4">
    {position && <div className="text-lg font-bold text-navy">#{position}</div>}
    <EntityIdentifier
      type={type}
      title={fullName}
      description={description}
      meta={{ email, phoneNumbers }}
      id={id}
    />
  </div>
);

export default ContactItem;
