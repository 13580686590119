import { propOr, sum } from "ramda";
import { startCase } from "lodash";
import { capitalizeFirstLetter } from "utils/string";
import countries from "utils/countries.json";
import { nonZeroValues } from "utils/objects";

export const formatPieChartCountryLabels = (
  object: {
    [key: string]: number;
  },
  variant: "countryName" | "countryCode"
) => {
  return Object.keys(object).map(
    (key) =>
      (variant === "countryCode" || key === "Unknown"
        ? key
        : countries.find(({ value }) => value === key)?.label) +
      ` (${Math.round((object[key] / sum(Object.values(object))) * 100)}%)`
  );
};

const formatPieChartLabels = (object: object, labelMap?: object) => {
  if (labelMap && nonZeroValues(object).length === 0) {
    return [];
  }
  const totalValues = sum(Object.values(object));
  const proportion = (a: number, b: number) => Math.round((a / b) * 100);

  return Object.keys(object)
    .filter(
      (key) => proportion(propOr(0, key, object) as number, totalValues) !== 0
    )
    .map(
      (key) =>
        (labelMap
          ? propOr("", key, labelMap)
          : capitalizeFirstLetter(startCase(key).toLowerCase())) +
        ` (${proportion(propOr(0, key, object) as number, totalValues)}%)`
    );
};

export default formatPieChartLabels;
