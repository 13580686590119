import { changeBreadcrumb } from "app/breadcrumbSlice";
import { useAppDispatch } from "app/store";
import { useEffect } from "react";
import { useMatches } from "react-router-dom";

const getBreadcrumbs = (matches: any) => {
  const crumbs = matches
    .map((match: any) => match.handle?.crumb)
    .filter((i: string | undefined) => !!i);
  return crumbs;
};

const useSetPageTitle = () => {
  const matches = useMatches();
  const dispatch = useAppDispatch();

  const [mainSection, section] = getBreadcrumbs(matches);

  useEffect(() => {
    if (mainSection && section) {
      document.title = `${section} | ${mainSection} | Relata`;
    } else if (mainSection) {
      document.title = `${mainSection} | Relata`;
    }
    dispatch(
      changeBreadcrumb({
        main: mainSection,
        link: `/${(mainSection || "").toLowerCase()}/`,
      })
    );
  }, [dispatch, mainSection, section]);
};

export default useSetPageTitle;
