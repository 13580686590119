import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import Cookies from "js-cookie";

import prepareBaseQuery from "../prepareBaseQuery";
import { IWatchListItem, IWatchListResponse } from "./watchlist.interfaces";

export const watchlistApi = createApi({
  reducerPath: "watchlist",
  tagTypes: ["watchlist"],
  baseQuery: prepareBaseQuery({
    baseUrl: "/pbi",
  }),
  endpoints: (builder) => ({
    getWatchlist: builder.query<IWatchListItem[], void>({
      query: () => "/watchlist",
      providesTags: ["watchlist"],
      transformResponse: (response: any) => {
        const camelized = camelizeKeys(response) as IWatchListResponse;
        return camelized.items;
      },
    }),
    updateWatchlist: builder.mutation<IWatchListItem[], IWatchListItem[]>({
      query(data) {
        return {
          url: "/watchlist",
          method: "PUT",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") || "",
          },
          body: JSON.stringify({
            items: decamelizeKeys(data),
          }),
        };
      },
      invalidatesTags: ["watchlist"],
    }),
  }),
});

export const { useGetWatchlistQuery, useUpdateWatchlistMutation } =
  watchlistApi;
