import Button from "components/Button";
import { LoginComponentType } from ".";
import LoginContainer from "./LoginContainer";

const LoginForbidden = ({
  switchComponent,
}: {
  switchComponent: (component: LoginComponentType) => void;
}) => {
  return (
    <LoginContainer icon="icon-warning-hex" text="Unable to access account">
      <p className="mt-4 text-center text-metal">
        We are unable to access your account.
        <br />
        <br />
        You can go back and try again, carefully checking
        <br />
        your email and/or password.
        <br />
        <br />
        If this error persists, contact our support team.
      </p>

      <div className="mt-8 mb-4 flex flex-col gap-4 px-4">
        <Button
          color="dust"
          text="Go back"
          onClick={() => {
            switchComponent("standard");
          }}
        />
        <Button
          id="contact-support-login"
          color="dust"
          text="Contact support"
        />
      </div>
    </LoginContainer>
  );
};

export default LoginForbidden;
