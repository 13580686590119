import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import { pathOr, sortBy } from "ramda";
import prepareBaseQuery from "../prepareBaseQuery";

export interface ITeam {
  id: number;
  color: string;
  name: string;
  teamMembers: ITeamUser[];
}

interface ITeamUser {
  user: {
    email: string;
    fullName: string;
    uuid: string;
  };
}

export const teamApi = createApi({
  reducerPath: "team",
  baseQuery: prepareBaseQuery({
    baseUrl: "/pbi/team",
  }),
  endpoints: (builder) => ({
    getTeams: builder.query<ITeam[], void>({
      query: () => "",
      transformResponse: (response: any): ITeam[] => {
        const teams = camelizeKeys(response).results as ITeam[];
        const otherTeam = {
          id: -1,
          color: "#F0F8FF", // hardcoded for teamless users
          name: "Colleagues not assigned to a team",
          teamMembers: [],
        };
        const sortedTeams = teams.map((team) => ({
          ...team,
          teamMembers: sortBy(
            pathOr("", ["user", "fullName"]),
            team.teamMembers
          ),
        }));

        return [...sortedTeams, otherTeam];
      },
    }),
  }),
});

export const { useGetTeamsQuery } = teamApi;
