import FilterToggleItem from "./FilterToggleItem";

interface FilterToggleOption {
  name: string;
  key: string;
  active?: boolean;
}

const FilterToggle = ({
  options,
  activeIndex,
  onChange,
}: {
  options: FilterToggleOption[];
  activeIndex: number;
  onChange: any;
}) => {
  return (
    <div className="flex gap-2">
      {options.map((option, index) => (
        <FilterToggleItem
          {...option}
          active={activeIndex === index}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
};

export default FilterToggle;
