import classnames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import { useGetUserQuery } from "../features/profile/profileApi";
import Button from "./Button";
import ProductItem from "./ProductItem";
import useGreetings from "utils/hooks/useGreetings";
import { IUser } from "features/profile/user.interface";
import { getInitials } from "utils/string";
import { selectBreadcrumbs } from "app/breadcrumbSlice";

import LogoFull from "assets/LogoFull.svg";
import LogoShort from "assets/LogoShort.svg";

const getUserInitials = (profile?: IUser) => {
  if (profile && (profile.name || profile.surname)) {
    return getInitials(`${profile.name} ${profile.surname}`);
  } else {
    return "";
  }
};

const Breadcrumb = ({
  main,
  subsection,
  link,
}: {
  main: string;
  subsection?: string;
  link?: string;
}) => {
  const navigate = useNavigate();
  if (main && !subsection) {
    return <span className="px-4 text-lg font-bold text-navy">{main}</span>;
  }
  return (
    <>
      <Button
        color="transparent"
        text={main}
        onClick={() => link && navigate(link)}
      />
      <i className="icon-right hidden text-xl text-metal-dark md:block" />
      <span className="hidden text-lg font-bold text-navy md:block">
        {subsection}
      </span>
    </>
  );
};

const MainNavigation = () => {
  const breadcrumb = useSelector(selectBreadcrumbs);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const greetingMessage = useGreetings();
  const userInitials = getUserInitials(user);

  const openProduct = (to: string) => {
    navigate(to);
    setOpened(false);
  };

  return (
    <nav
      className={classnames(
        "fixed top-0 z-40 w-full border border-dust-dark bg-white-96 shadow backdrop-blur",
        {
          "h-16": !opened,
          "top-12": user?.impersonating,
        }
      )}
    >
      <a className="absolute left-[50%] top-[21px] -translate-x-[50%]" href="/">
        <img src={LogoShort} className="h-6 sm:hidden" alt="logo" />
        <img src={LogoFull} className="hidden h-6 sm:block" alt="logo" />
      </a>
      <div
        className={classnames(
          "flex w-full items-center justify-between space-x-4 p-4 px-4 text-white",
          { hidden: isUserLoading }
        )}
      >
        <Button
          id="navigation-button"
          icon={opened ? "icon-menu-open" : "icon-menu-closed"}
          color="navy"
          onClick={() => setOpened(!opened)}
        />
        {opened ? (
          <div className="flex flex-1 pl-4">
            <div className="hidden space-x-2 sm:block">
              <span className="text-lg font-bold text-navy">
                {greetingMessage} {user?.name}.
              </span>
              <span className="hidden text-lg text-metal lg:inline-flex">
                Let's get started...
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 items-center space-x-3">
            <Breadcrumb {...breadcrumb} />
          </div>
        )}

        <div className="flex flex-none items-center justify-between gap-3 align-middle">
          {opened && user?.isStaff && (
            <Button
              color="transparent"
              icon="icon-settings text-2xl"
              tooltip="Admin"
              onClick={() => navigate("/admin/")}
            />
          )}
          <Button
            color="transparent"
            icon="icon-help text-2xl"
            tooltip="Help"
            onClick={() => window.open("https://help.relata.ai", "_blank")}
          />
          <Tooltip className="default-tooltip" id="profile-tooltip" />
          <div
            data-tooltip-id="profile-tooltip"
            data-tooltip-content="Account"
            id="user-profile-button"
            className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-navy text-sm font-bold"
            onClick={() => navigate("/account")}
          >
            {userInitials ? (
              userInitials
            ) : (
              <i className="icon-contact text-xl" />
            )}
          </div>
        </div>
      </div>
      <div
        className={classnames(
          "mx-2 mt-6 mb-4 flex flex-col justify-center gap-2 sm:mx-0 sm:flex-row sm:gap-6 sm:px-4",
          {
            "w-full": opened,
            "hidden w-0": !opened,
          }
        )}
      >
        <ProductItem
          type="mint"
          text="Track"
          icon="icon-cursor"
          onClick={() => openProduct("/track")}
          description="See who is interacting with your content."
        />
        <ProductItem
          type="navy"
          text="Explore"
          icon="icon-search"
          onClick={() => openProduct("/explore")}
          description="Dig deeper into your relationships."
        />
        <ProductItem
          type="sky"
          text="Report"
          icon="icon-report"
          onClick={() => openProduct("/report")}
          description="Insights on demand. Answer questions now."
        />
      </div>
    </nav>
  );
};

export default MainNavigation;
