import { useState } from "react";
import { prop, sortBy } from "ramda";

import env from "config";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import { IMailbox } from "features/message/message.interface";
import toggleListItem from "utils/toggleListItem";
import { useTrackFilterOptions } from "utils/trackOptions";

const DISABLE_INTERNAL_FILTER: boolean =
  env("REACT_APP_DISABLE_INTERNAL_FILTER") === "true";

const FurtherTrackOption = ({
  checked,
  onClick,
  label,
  description,
}: {
  checked: boolean;
  onClick: VoidFunction;
  label: string;
  description: string;
}) => (
  <div>
    <div className="flex flex-col">
      <Checkbox
        checked={checked}
        onClick={onClick}
        size="small"
        label={label}
      />
    </div>
    <div className="px-8 text-metal">{description}</div>
  </div>
);

const TrackOptionsModal = ({
  visible,
  onClose,
  mailboxes,
  onChange,
}: {
  mailboxes: IMailbox[];
  visible: boolean;
  onClose: () => void;
  onChange: (value: any) => void;
}) => {
  const [trackOptions, setTrackOptions] = useTrackFilterOptions(mailboxes);

  const [options, setOptions] = useState({
    mailboxes: trackOptions?.mailboxes || [],
    showInternals: !!trackOptions?.showInternals,
    showPersonalEmailProviders: !!trackOptions?.showPersonalEmailProviders,
    showPersonalRelationships: !!trackOptions?.showPersonalRelationships,
  });

  const sorted = sortBy(prop("email"), mailboxes);

  const handleRequest = () => {
    setTrackOptions(options);
    onChange(options);
    onClose();
  };

  return (
    <Modal visible={visible} onClose={onClose} height="auto">
      <div className="flex h-full flex-col text-base">
        <div className="flex items-center justify-center text-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-filter-settings"></i>
            Track options
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="flex flex-col pt-6">
          <div className="text-lg font-bold text-navy">Shared mailboxes</div>
          <div className="text-metal">
            Select which of your mailboxes contribute with emails and
            interactions to the Track experience.
          </div>
          <div
            className="my-4 flex max-h-[40vh] flex-col gap-2 overflow-auto"
            data-testid="mailboxes"
          >
            {sorted.map(({ email }, index) => (
              <Checkbox
                checked={options.mailboxes.includes(email)}
                onClick={() =>
                  setOptions({
                    ...options,
                    mailboxes: toggleListItem(email, options.mailboxes),
                  })
                }
                key={index}
                size="small"
                label={email}
              />
            ))}
          </div>
        </div>
        <div className="pb-4 text-lg font-bold text-navy">Further options</div>
        <div className="flex flex-col gap-4 pb-6">
          {!DISABLE_INTERNAL_FILTER && (
            <FurtherTrackOption
              checked={options.showInternals}
              onClick={() =>
                setOptions({
                  ...options,
                  showInternals: !options.showInternals,
                })
              }
              label="Show colleague interactions"
              description="When selected, we display interaction data such as Reads and
             Glances for colleagues within your organisation."
            />
          )}
          <FurtherTrackOption
            checked={options.showPersonalRelationships}
            onClick={() =>
              setOptions({
                ...options,
                showPersonalRelationships: !options.showPersonalRelationships,
              })
            }
            label="Show personal contacts"
            description="When selected, we show emails sent to contacts who have been tagged as Personal contacts in Explore."
          />
          <FurtherTrackOption
            checked={options.showPersonalEmailProviders}
            onClick={() =>
              setOptions({
                ...options,
                showPersonalEmailProviders: !options.showPersonalEmailProviders,
              })
            }
            label="Show emails from personal email providers"
            description="When selected, we show emails sent to contacts with personal email providers such as gmail.com."
          />
        </div>
        <div className="mt-auto">
          <Button
            color="navy"
            size="large"
            text="Apply changes"
            id="apply-track-options-button"
            onClick={handleRequest}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default TrackOptionsModal;
