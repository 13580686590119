import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";
import { ILinksResponse } from "./link.interface";
import { trackOptionsType, trackSearchParams } from "utils/trackOptions";

export const linkApi = createApi({
  reducerPath: "link",
  baseQuery: prepareBaseQuery({
    baseUrl: "/track",
  }),
  endpoints: (builder) => ({
    getLinks: builder.query<
      ILinksResponse,
      {
        cursor?: string;
        search?: string;
        messageId?: string;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ search, cursor, messageId, trackOptions }) => {
        const searchParams = new URLSearchParams();
        if (cursor) return cursor;
        if (search) {
          searchParams.append("search_text", search);
        }
        if (messageId) {
          searchParams.append("message_id", messageId);
        }
        const searchParamsWithTrackOptions = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/link-tracking?" + searchParamsWithTrackOptions;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { search, messageId, trackOptions } = queryArgs;
        return {
          search,
          messageId,
          trackOptions,
        };
      },
      merge: (currentCache, newResponse, { arg }) => {
        if (!arg.cursor) {
          currentCache = newResponse;
        } else {
          currentCache.next = newResponse.next;
          currentCache.results.push(...newResponse.results);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: any): ILinksResponse =>
        camelizeKeys(response) as ILinksResponse,
    }),
  }),
});

export const { useGetLinksQuery } = linkApi;
