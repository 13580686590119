export enum TrendType {
  Strengthening,
  Consistent,
  Weakening,
}

export enum RankType {
  Gold,
  Silver,
  Bronze,
  Unranked,
}

export enum SegmentType {
  New,
  Ongoing,
  Revived,
  Lapsing,
  Historic,
}

export enum ActivityType {
  Active,
  Inactive,
  Dormant,
  Undefined,
}

export enum EngagementType {
  Unengaged,
  Engaged,
  HighlyEngaged,
}

export enum ResponseBreakdownType {
  Soon,
  Mid,
  Late,
}

export const CompanyFlagTypes = ["service_provider"] as const;
export type CompanyFlagType = typeof CompanyFlagTypes[number];

interface IFlag {
  value: boolean;
  editedBy: string;
  lastModified: Date | string;
  dateEffective: Date | string;
}
export interface ICompanyFlag extends IFlag {
  flag: CompanyFlagType;
}

export interface ICompanyDetail {
  uuid: string;
  displayName: string;
  businessId: string;
  logoUrl?: string;
  originalBrandColour?: string;
  flags: ICompanyFlag[];
}

export interface IContactLocation {
  location: string;
  timezone: string;
}

export interface IPhoneNumber {
  type?: string;
  phoneNumber: string;
}

export const ContactFlagTypes = [
  "leaver",
  "assistant",
  "personal_contact",
] as const;
export type ContactFlagType = typeof ContactFlagTypes[number];

export interface IContactFlag extends IFlag {
  flag: ContactFlagType;
}
export interface IContactDetail {
  uuid: string;
  email: string;
  fullName: string;
  flags: IContactFlag[];
  company?: ICompanyDetail;
  roles?: string[];
  locations?: IContactLocation[];
  phoneNumbers?: IPhoneNumber[];
}

export const contactEngagementTypes = ["high", "standard", "none"] as const;

export type ContactEngagementType = typeof contactEngagementTypes[number];
