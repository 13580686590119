const EmptyRelationshipSignals = () => (
  <div className="flex flex-col items-center">
    <i className="icon-activity text-2xl text-navy" />
    <div className="text-xl text-navy">No relationship signals</div>
    <div className="text-center text-metal">
      We haven't yet observed any relationship signals for this contact. Check
      back later.
    </div>
  </div>
);
export default EmptyRelationshipSignals;
