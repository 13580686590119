import { isEmpty, symmetricDifference } from "ramda";

/**
 * same - simplified comparison for objects / arrays / other data types
 *      - usually something like `equals` from ramda would do the trick
 *      - however it doesn't ignore order of items in arrays.
 * @param {foo} any
 * @param {bar} any
 */
const same = (foo: any, bar: any): boolean => {
  const arrayEq = (a: any[], b: any[]) => isEmpty(symmetricDifference(a, b));

  if (typeof foo !== typeof bar) {
    return false;
  } else if (Array.isArray(foo) && Array.isArray(bar)) {
    return arrayEq(foo, bar);
  } else if (foo instanceof Object && bar instanceof Object) {
    if (!arrayEq(Object.keys(foo), Object.keys(bar))) {
      return false;
    }
    for (const key of Object.keys(foo)) {
      if (!same(foo[key], bar[key])) {
        return false;
      }
    }
    return true;
  } else {
    return foo === bar;
  }
};

export default same;
