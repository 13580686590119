import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";
import { head } from "ramda";
import { useCubejsApi } from "utils/hooks/useCubejsApi";
import { useGetUserQuery } from "features/profile/profileApi";
import { format } from "date-fns";
import { beginningOfMonthsAgo } from "utils/dates";

const useSentimentQuery = ({
  query,
  skip = false,
}: {
  query: Query;
  skip?: boolean;
}) => {
  const { resultSet, isLoading, error } = useCubeQuery(query as Query, {
    skip,
    cubejsApi: useCubejsApi("sentiment"),
  });

  if (error) {
    console.error(error);
  }

  const rows = resultSet?.tablePivot() || [];
  const results: { averageSentiment: number }[] = rows.map((row) => ({
    averageSentiment: +row["fact_email.average_sentiment"],
  }));
  const sentiment = head(results)?.averageSentiment;

  return {
    sentiment,
    isLoading,
  };
};

const useSentiment = ({
  companyId,
  contactId,
  additionalFilters,
  monthRange,
}: {
  companyId?: string;
  contactId?: string;
  additionalFilters: Filter[];
  monthRange: number;
}) => {
  const { data: user } = useGetUserQuery();

  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };

  const dateFilterRange = [
    format(beginningOfMonthsAgo(monthRange || 6), "yyyy-MM-dd"),
    format(new Date(), "yyyy-MM-dd"),
  ];

  const baseQuery: Query = {
    dimensions: [],
    measures: ["fact_email.average_sentiment"],
    filters: [
      ...additionalFilters,
      ...entityFilters(),
      {
        member: "fact_email.average_sentiment",
        operator: "set",
      },
      {
        dimension: "dim_email.message_sent_date_time",
        operator: "inDateRange",
        values: dateFilterRange,
      },
    ],
  };

  const { sentiment: companySentiment, isLoading: isCompanySentimentLoading } =
    useSentimentQuery({
      query: baseQuery,
      skip: !user,
    });

  const {
    sentiment: strongestColleagueSentiment,
    isLoading: isStrongestColleagueSentimentLoading,
  } = useSentimentQuery({
    query: {
      ...baseQuery,
      dimensions: ["dim_user.uuid"],
      order: [["fact_email.average_sentiment", "desc"]],
      limit: 1,
    },
    skip: !user,
  });

  const { sentiment: userSentiment, isLoading: isUserSentimentLoading } =
    useSentimentQuery({
      query: {
        ...baseQuery,
        filters: [
          ...(baseQuery.filters as Filter[]),
          {
            member: "dim_user.email",
            operator: "equals",
            values: [user?.email || ""],
          },
        ],
      },
      skip: !user,
    });

  const results = [
    {
      label: "All company average sentiment",
      value: companySentiment,
      color: "dust-darker",
      tooltip: "All company",
    },
    {
      label: "Strongest colleague average sentiment",
      value: strongestColleagueSentiment,
      color: "mint",
      tooltip: "Strongest colleague",
    },
    {
      label: "Your average sentiment",
      value: userSentiment,
      color: "sky",
      tooltip: "You",
    },
  ];

  return {
    results,
    isLoading:
      isCompanySentimentLoading ||
      isStrongestColleagueSentimentLoading ||
      isUserSentimentLoading,
  };
};

export { useSentiment };
