import { useCubeQuery } from "@cubejs-client/react";
import { Filter, QueryOrder } from "@cubejs-client/core";

const useEntityQuery = ({
  contactId,
  companyId,
  dimensions,
  page,
  sorting,
  additionalFilters,
}: {
  contactId?: string;
  companyId?: string;
  dimensions: string[];
  page?: number;
  sorting?: string;
  additionalFilters?: Filter[];
}) => {
  const limit = 20;
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };
  const order = (
    sorting
      ? {
          [sorting.replace("-", "")]: (sorting[0] === "-"
            ? "desc"
            : "asc") as QueryOrder,
        }
      : { "fact_table.relationship_score_current": "desc" }
  ) as {
    string: QueryOrder;
  };

  const { resultSet, isLoading, error } = useCubeQuery({
    dimensions,
    measures: [
      "fact_table.relationship_score_current",
      "fact_table.trend",
      "fact_table.first_interaction",
      "fact_table.last_interaction",
      "fact_table.last_meeting",
      "fact_table.inbound_current",
      "fact_table.outbound_current",
      "fact_table.reply_response_time_current",
      "fact_table.reply_response_time_prev",
      "fact_table.reply_out_response_time_current",
      "fact_table.reply_out_response_time_prev",
    ],
    order,
    filters: [...entityFilters(), ...(additionalFilters || [])],
    offset: page ? (page - 1) * limit : 0,
    limit: limit,
    total: true,
  });

  if (error) {
    console.error(error);
  }

  const dataSource = resultSet?.tablePivot();
  // @ts-ignore
  const total = resultSet?.loadResponse.results[0].total;
  const totalPages = total ? Math.ceil(total / limit) : 0;

  return {
    results: dataSource,
    totalPages,
    isLoading,
  };
};

export default useEntityQuery;
