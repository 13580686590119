import Button from "components/Button";
import TextInput from "components/TextInput";
import { useState } from "react";
import { csrfInit } from "utils/requests";
import { isValidEmail } from "utils/emailValidation";
import { useResetPasswordMutation } from "../profileApi";
import LoginContainer from "./LoginContainer";
import { LoginComponentType } from ".";

const LoginPasswordResetRequest = ({
  switchComponent,
}: {
  switchComponent: (component: LoginComponentType) => void;
}) => {
  const [requestResetLink, { isLoading }] = useResetPasswordMutation();
  const [requestDone, setRequestDone] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>();

  const handleRequest = async () => {
    if (email.length === 0) {
      setError("Enter an email address.");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Enter a valid email address.");
      return;
    }

    try {
      if (!(await csrfInit())) throw new Error("No CSRF");
      await requestResetLink({ email }).unwrap();
    } catch (error) {
      console.error(error);
    }
    setRequestDone(true);
  };

  const handleChange = (value: string) => {
    setEmail(value);
    setError(undefined);
  };

  if (requestDone)
    return (
      <LoginContainer text="Reset your Relata password">
        <p className="mt-4 text-center text-metal">
          We've sent an email to
          <br />
          <span className="text-center font-bold text-navy">{email}</span>
          <br />
          which contains a secure link that will allow you to
          <br />
          reset your Relata password.
          <br />
          <br />
          Open this email, and click the link to continue.
        </p>
        <div className="mb-4 mt-8 flex flex-col px-4">
          <Button
            color="dust"
            text="Go back"
            onClick={() => {
              setEmail("");
              setRequestDone(false);
            }}
          />
        </div>
      </LoginContainer>
    );
  return (
    <LoginContainer text="Reset your Relata password">
      <div className="ml-4 mt-6 flex-grow text-lg font-bold text-navy">
        Email address
      </div>
      <div className="mb-4 mt-2 flex flex-col gap-4 px-4">
        <TextInput
          error={error}
          type="email"
          placeholder="name@company.com"
          onChangeText={handleChange}
        />
        <Button
          isLoading={isLoading}
          color="navy"
          iconRight
          icon="icon-right"
          text="Request a password reset link"
          onClick={handleRequest}
        />
        <Button
          color="dust"
          text="Go back to login"
          onClick={() => switchComponent("standard")}
        />
      </div>
    </LoginContainer>
  );
};

export default LoginPasswordResetRequest;
