import { mergeRight } from "ramda";
import { faker } from "@faker-js/faker";
import {
  IInteraction,
  IInteractionResponse,
  IInteractionStatsResponse,
} from "features/interaction/interaction.interface";
import fakeContact from "./contact";
import { addDays } from "date-fns";
import {
  DESKTOP_AGENTS,
  MOBILE_AGENTS,
  WINDOWS_AGENTS,
  IOS_AGENTS,
  LINUX_AGENTS,
  ANDROID_AGENTS,
} from "utils/devices";

const fakeMessage = () => {
  const contactSample = fakeContact({});
  return {
    contact: contactSample,
    messageId: faker.datatype.uuid(),
    messageGroupId: faker.datatype.uuid(),
    messageSubject: faker.lorem.sentence(),
    emailSender: contactSample.email,
    sendTimestamp: faker.date.recent(10, addDays(new Date(), -3)).toISOString(),
  };
};

const fakeInteraction = (override: Partial<IInteraction>): IInteraction => {
  const messageData = fakeMessage();
  const eventType = faker.helpers.arrayElement([
    "human_forward:peer",
    "human_reply:peer",
    "human_link_click:peer",
    "human_read:peer",
    "human_quick_read:peer",
    "human_glance:peer",
    "privacy_read:peer",
  ]);
  return mergeRight(
    {
      ...messageData,
      uuid: faker.datatype.uuid(),
      actionCreationTimestamp: faker.date
        .soon(3, messageData.sendTimestamp)
        .toISOString(),
      eventType,
      isRevisit: faker.datatype.boolean(),
      deviceType: faker.helpers.maybe(
        () =>
          faker.helpers.arrayElement([
            ...DESKTOP_AGENTS,
            ...MOBILE_AGENTS,
            ...WINDOWS_AGENTS,
            ...IOS_AGENTS,
            ...LINUX_AGENTS,
            ...ANDROID_AGENTS,
          ]),
        { probability: 0.5 }
      ),
      duration: "",
      url:
        eventType === "human_link_click:peer"
          ? faker.internet.url()
          : undefined,
    },
    override
  ) as IInteraction;
};

const fakeInteractions = (
  items: any[] = [],
  amount: number = 20
): IInteractionResponse => {
  const messages = [...Array(faker.datatype.number({ min: 10, max: 40 }))].map(
    () => fakeMessage()
  );

  return {
    prev: undefined,
    next: undefined,
    results: [
      ...items,
      ...[...Array(amount - items.length)].map(() => {
        const messageData = faker.helpers.arrayElement(messages);
        return fakeInteraction(messageData);
      }),
    ].sort(
      (a, b) =>
        new Date(b.actionCreationTimestamp).getTime() -
        new Date(a.actionCreationTimestamp).getTime()
    ),
  };
};

const fakeInteractionsStats = (): IInteractionStatsResponse => ({
  total: {
    interactions: faker.datatype.number({ min: 10, max: 40 }),
    revisit: faker.datatype.number({ min: 10, max: 40 }),
    potentialForward: faker.datatype.number({ min: 10, max: 40 }),
    click: faker.datatype.number({ min: 10, max: 40 }),
    read: faker.datatype.number({ min: 10, max: 40 }),
    quickRead: faker.datatype.number({ min: 10, max: 40 }),
    glance: faker.datatype.number({ min: 10, max: 40 }),
    reply: faker.datatype.number({ min: 10, max: 40 }),
    privacyRead: faker.datatype.number({ min: 10, max: 40 }),
  },
  searched: {
    interactions: faker.datatype.number({ min: 10, max: 40 }),
    revisit: faker.datatype.number({ min: 10, max: 40 }),
    potentialForward: faker.datatype.number({ min: 10, max: 40 }),
    click: faker.datatype.number({ min: 10, max: 40 }),
    read: faker.datatype.number({ min: 10, max: 40 }),
    quickRead: faker.datatype.number({ min: 10, max: 40 }),
    glance: faker.datatype.number({ min: 10, max: 40 }),
    reply: faker.datatype.number({ min: 10, max: 40 }),
    privacyRead: faker.datatype.number({ min: 10, max: 40 }),
  },
});

export { fakeInteractions, fakeInteraction, fakeInteractionsStats };
