export interface IUser {
  id: number;
  email: string;

  permissions: Permissions[];
  isProUser?: boolean;
  isDemoUser?: boolean;
  isStaff?: boolean;
  isRelataUser?: boolean;
  showContactSignatures?: boolean;
  roleType?: string;
  realUser?: string;

  intercomUserHash?: string;
  name?: string;
  surname?: string;
  timezone?: string;
  city?: string;
  country?: string | null;
  fullName?: string;
  dateJoined: string;

  hasUsablePassword?: boolean;
  emailgroups?: { email: string }[];
  impersonating?: boolean;
  hashedEmail?: string;
  version?: string;
}

export enum Permissions {
  /* most important permissions */
  USE_WATCHLIST = "fdsk_use_watchlist",
  USE_REPORT = "fdsk_download_list_report",
  USE_TRACK = "rlt_can_access_track_experience",
  IS_STAFF_USER = "fdsk_staff_user",
  IS_DEMO_USER = "fdsk_user_demo_tag",

  /* permissions for detail views */
  ACCESS_CONTACT_TABLE = "rlt_can_access_contact_league_table",
  ACCESS_COMPANY_TABLE = "rlt_can_access_company_league_table",
  ACCESS_CONTACT_DETAILS = "rlt_can_access_contact_details",
  ACCESS_CONTACT_EXTRA = "rlt_can_access_contact_extra",
  ACCESS_COMPANY_DETAILS = "rlt_can_access_company_details",
  ACCESS_COMPANY_EXTRA = "rlt_can_access_company_extra",
  ACCESS_REPORT_EXTRA = "fdsk_request_new_report",

  /* filter functionalities */
  USE_CONTACT_SIGNATURE = "rlt_can_access_contact_signature", // phone numbers, locations and roles
  USE_FILTER_EXTRA = "rlt_can_filter_extra",

  /* mostly legacy permissions */
  LIST_TEAMS = "fdsk_list_teams",
  LIST_LENSES = "fdsk_list_lenses",
}

export interface ITeam {
  id: number;
  color: string;
  name: string;
  teamMembers: ITeamUser[];
}

interface ITeamUser {
  email: string;
  fullName: string;
  uuid: string;
}
