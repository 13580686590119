import { propEq } from "ramda";
import classnames from "classnames";

import TooltipFrame from "components/frames/TooltipFrame";
import EmptyRelationshipSignals from "./EmptyRelationshipSignals";

export interface IRelationshipSignal {
  positive: boolean;
  label: string;
}

const RelationshipSignal = ({ signal }: { signal: IRelationshipSignal }) => (
  <div className="flex gap-2 border-b border-b-dust p-2">
    <div
      className={classnames("w-0.5 shrink-0", {
        "bg-green-dark": signal.positive,
        "bg-red-dark": !signal.positive,
      })}
    ></div>
    <div className="text-metal">{signal.label}</div>
  </div>
);

const RelationshipSignalSkeleton = () => (
  <div className="flex animate-pulse gap-2 border-b border-b-dust p-2">
    <div className="h-4 w-0.5 shrink-0 bg-dust" />
    <div className="h-4 w-52 rounded bg-dust-light" />
  </div>
);

const SkeletonSignals = () => (
  <TooltipFrame title="Relationship signals">
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="text-3xl text-metal">0</div>
          <div className="text-metal">Positive signals</div>
        </div>
        <div className="flex flex-col items-end">
          <div className="text-3xl text-metal">0</div>

          <div className="text-metal">Negative signals</div>
        </div>
      </div>
      <div className="h-4 animate-pulse rounded bg-dust"></div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
        </div>
        <div>
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
          <RelationshipSignalSkeleton />
        </div>
      </div>
    </div>
  </TooltipFrame>
);

const RelationshipSignals = ({
  signals,
  isLoading,
}: {
  signals: IRelationshipSignal[];
  isLoading: boolean;
}) => {
  const positives = signals.filter(propEq("positive", true)).length;
  const negatives = signals.filter(propEq("positive", false)).length;
  const total = positives + negatives;
  const positivePercentage = total > 0 ? (positives / total) * 100 : 0;

  if (isLoading) {
    return <SkeletonSignals />;
  }
  return (
    <TooltipFrame title="Relationship signals">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div
              className={classnames("text-3xl", {
                "text-green-dark": positives > 0,
                "text-metal": positives === 0,
              })}
            >
              {positives}
            </div>
            <div className="text-metal">Positive signals</div>
          </div>
          <div className="flex flex-col items-end">
            <div
              className={classnames("text-3xl", {
                "text-red-dark": negatives > 0,
                "text-metal": negatives === 0,
              })}
            >
              {negatives}
            </div>
            <div className="text-metal">Negative signals</div>
          </div>
        </div>
        <div
          className={classnames("h-4 rounded", {
            "bg-dust": total === 0,
            "bg-red-dark": total > 0,
          })}
        >
          <div
            className={classnames("h-4 rounded-bl rounded-tl bg-green-dark", {
              rounded: positives === signals.length,
            })}
            style={{ width: `${positivePercentage}%` }}
          ></div>
        </div>
        {signals.length === 0 ? (
          <EmptyRelationshipSignals />
        ) : (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              {signals.filter(propEq("positive", true)).map((signal, index) => (
                <RelationshipSignal
                  signal={signal}
                  key={`signal-pos-${index}`}
                />
              ))}
            </div>
            <div>
              {signals
                .filter(propEq("positive", false))
                .map((signal, index) => (
                  <RelationshipSignal
                    signal={signal}
                    key={`signal-negative-${index}`}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </TooltipFrame>
  );
};

export default RelationshipSignals;
