import { head, propOr } from "ramda";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { camelizeKeys } from "humps";

import { showContactModal } from "app/contactModalsSlice";
import Identifier from "components/atoms/Identifier";
import Button from "components/Button";
import { getInitials } from "utils/string";
import { highlightText } from "utils/highlightText";
import { IPhoneNumber } from "features/entity/entity.interfaces";

const ContactIdentifier = ({
  id,
  meta,
  name,
  description,
  searchValue,
}: {
  id?: string;
  meta?: {
    email?: string;
    phoneNumbers?: IPhoneNumber[];
    onlyIdentifier?: boolean;
  };
  name: string;
  description?: string;
  searchValue?: string;
}) => {
  const email = propOr("", "email", meta);
  const phoneNumbers = camelizeKeys(propOr([], "phoneNumbers", meta));
  const dispatch = useDispatch();

  const onEmailClick = (contact: any) =>
    dispatch(
      showContactModal({
        modal: "email",
        contact,
      })
    );
  const onCallClick = (contact: any) => {
    if (contact.phoneNumbers.length > 1) {
      dispatch(
        showContactModal({
          modal: "call",
          contact,
        })
      );
    } else {
      const phoneNumber = propOr(
        null,
        "phoneNumber",
        head(contact.phoneNumbers)
      );
      if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      }
    }
  };
  const highlightedName = highlightText(name, searchValue);

  return (
    <div className="group relative">
      <div className="flex items-center gap-2 border border-transparent text-lg text-navy group-hover:absolute group-hover:-top-[17px] group-hover:z-10 group-hover:rounded-full group-hover:border-dust-dark group-hover:bg-white group-hover:shadow">
        <Identifier type="contact" text={getInitials(name)} />
        <div className="w-[200px] group-hover:w-fit">
          <div className="flex flex-col group-hover:flex-row group-hover:items-center group-hover:gap-2">
            <div
              className="max-w-[60vw] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold"
              data-testid="contact-identifier-link"
            >
              {!!id && (
                <Link to={`/explore/contact/${id}/at-a-glance`}>
                  {highlightedName}
                </Link>
              )}
              {!id && highlightedName}
            </div>
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-metal group-hover:hidden ">
              {highlightText(description, searchValue)}
            </div>
            <div className="ml-auto hidden group-hover:visible group-hover:mr-4 group-hover:flex">
              <>
                {email && (
                  <div className="text-sm text-metal">
                    <Button
                      color="transparent"
                      tooltip="Email contact"
                      icon="icon-send"
                      square
                      onClick={(event) => {
                        event.stopPropagation();
                        onEmailClick({
                          email: email as string,
                          fullName: name,
                        });
                      }}
                    />
                  </div>
                )}
                {phoneNumbers && phoneNumbers.length > 0 && (
                  <div className="text-sm text-metal">
                    <Button
                      color="transparent"
                      tooltip="Call contact"
                      icon="icon-mobile"
                      square
                      onClick={(event) => {
                        event.stopPropagation();
                        onCallClick({
                          phoneNumbers,
                          fullName: name,
                        });
                      }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactIdentifier;
