import { useCubeQuery } from "@cubejs-client/react";
import { Filter, TimeDimension } from "@cubejs-client/core";
import { IUser } from "features/profile";
import { oneYearAgoToNowRange } from "utils/dates";

const useEntityTimeSeries = ({
  companyId,
  contactId,
  user,
  additionalFilters,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters?: Filter[];
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };
  const emailsMeasures = [
    "dim_contact.count",
    "fact_email.interest",
    "fact_email.effort",
    "fact_email.outbound",
    "fact_email.inbound",
    "fact_email.unsolicited_inbound",
    "fact_email.avg_reply_response_time",
    "fact_email.avg_reply_out_response_time",
  ];
  const scoreMeasures = ["fact_relationship_score.relationship_score"];
  const calendarMeasures = ["dim_calendar.count"];
  const timeDimensions: TimeDimension[] = [
    {
      dimension: "dim_date.date_actual",
      granularity: "month",
      dateRange: oneYearAgoToNowRange(),
    },
  ];

  const newContactMeasures = ["fact_time_series.contact_count"];
  const newContactTimeDimensions: TimeDimension[] = [
    {
      dimension: "fact_time_series.first_interaction",
      granularity: "month",
      dateRange: oneYearAgoToNowRange(),
    },
  ];

  const filters = [...entityFilters(), ...(additionalFilters || [])];
  const viewerFilters = [
    {
      member: "dim_user.email",
      operator: "equals",
      values: [user?.email || ""],
    },
    ...filters,
  ] as Filter[];

  const { resultSet, isLoading } = useCubeQuery([
    {
      measures: emailsMeasures,
      timeDimensions,
      filters,
    },
    {
      measures: scoreMeasures,
      timeDimensions,
      filters,
    },
    {
      measures: calendarMeasures,
      timeDimensions,
      filters,
    },
    {
      measures: newContactMeasures,
      timeDimensions: newContactTimeDimensions,
      filters,
    },
  ]);

  const { resultSet: viewerResultSet, isLoading: isViewerLoading } =
    useCubeQuery([
      {
        measures: emailsMeasures,
        timeDimensions,
        filters: viewerFilters,
      },
      {
        measures: scoreMeasures,
        timeDimensions,
        filters: viewerFilters,
      },
      {
        measures: calendarMeasures,
        timeDimensions,
        filters: viewerFilters,
      },
      {
        measures: newContactMeasures,
        timeDimensions: newContactTimeDimensions,
        filters: viewerFilters,
      },
    ]);

  return {
    results: resultSet?.tablePivot(),
    viewerResults: viewerResultSet?.tablePivot(),
    isLoading: isLoading || isViewerLoading,
  };
};

export default useEntityTimeSeries;
