import { useGetLinksQuery } from "features/link/linkApi";
import { useTrackFilterOptions } from "utils/trackOptions";
import { useState } from "react";
import FeedbackMessage from "components/FeedbackMessage";
import { LinkTrackingError } from "pages/track/link/LinkTrackingError";
import { LinkTrackingListSkeleton } from "pages/track/link/LinkTrakingSkeletons";
import LinkTrackingList from "pages/track/link/LinkTrackingList";

const EmailDetailLinkTrackingEmpty = () => (
  <FeedbackMessage
    icon="icon-cursor"
    title="There have been no clicks"
    paragraps={[
      "When a contact clicks a link in this email, the interactions will appear here.",
    ]}
  />
);

const EmailDetailLinkTracking = ({ id }: { id: string }) => {
  const [trackOptions] = useTrackFilterOptions();
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const {
    data: links,
    isError: isLinksError,
    isLoading: isLinksLoading,
    isFetching: isLinksFetching,
  } = useGetLinksQuery({ messageId: id, cursor, trackOptions });

  const fetchMore = () => {
    if (links?.next) {
      setCursor(links.next);
    }
  };
  if (isLinksError) return <LinkTrackingError />;
  if (isLinksLoading) return <LinkTrackingListSkeleton />;
  if (links?.results.length === 0) return <EmailDetailLinkTrackingEmpty />;
  return (
    <div className="overflow-y-scroll text-base">
      <LinkTrackingList
        links={links?.results || []}
        isFetching={isLinksFetching || isLinksLoading}
        fetchMore={fetchMore}
        messageId={id}
        hasNoMore={!links?.next}
      />
    </div>
  );
};

export default EmailDetailLinkTracking;
