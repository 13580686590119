import classNames from "classnames";
import { ContactEngagementType } from "features/entity/entity.interfaces";

const QuickInsightSkeleton = () => (
  <div className="flex h-16 w-[148px] min-w-[148px] animate-pulse flex-row gap-2 rounded-lg border border-dust-dark bg-white p-2 shadow">
    <div className="rounded-full bg-dust-darker px-0.5" />
    <div className="flex flex-col gap-px py-1">
      <div className="h-6 w-8 rounded bg-dust" />
      <div className="h-4 w-[102px] rounded-full bg-dust-light" />
    </div>
  </div>
);
const QuickInsightsSkeleton = () => (
  <div className="pb-6">
    <div className="flex items-center pb-2 text-base text-metal">
      <i className="icon-filter" />
      Quick insights
    </div>
    <div className="flex flex-row gap-x-2 overflow-hidden">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <QuickInsightSkeleton key={i} />
        ))}
    </div>
  </div>
);

const EmailEngagedBucketSkeleton = ({
  engagement,
}: {
  engagement: ContactEngagementType;
}) => (
  <div
    className={classNames(
      "flex flex-grow flex-col rounded-xl border bg-gradient-to-b via-white to-white",
      {
        "border-green-dark from-green-lightest": engagement === "high",
      }
    )}
  >
    <div
      className={classNames(
        "flex flex-row justify-between rounded-t-xl p-4 text-base",
        {
          "border-b border-green-dark bg-green": engagement === "high",
          "border-b border-yellow-dark bg-yellow": engagement === "standard",
          "border-b border-dust-dark bg-dust": engagement === "none",
        }
      )}
    >
      <div>
        <div
          className={classNames("h-4 w-[176px] rounded-full", {
            "bg-green-dark": engagement === "high",
            "bg-yellow-dark": engagement === "standard",
            "bg-dust-dark": engagement === "none",
          })}
        />
      </div>
    </div>
    <div className="flex h-48 flex-grow flex-col overflow-y-scroll lg:flex-grow"></div>
  </div>
);

const EmailDetailOverviewSkeleton = () => (
  <div className="flex flex-grow flex-col bg-gradient-to-b from-dust-light via-white to-white px-4 pt-4">
    <QuickInsightsSkeleton />
    <div className="flex w-full flex-grow flex-col gap-2 lg:flex-row lg:overflow-auto">
      <EmailEngagedBucketSkeleton engagement="high" />
      <EmailEngagedBucketSkeleton engagement="standard" />
      <EmailEngagedBucketSkeleton engagement="none" />
    </div>
  </div>
);

export default EmailDetailOverviewSkeleton;
