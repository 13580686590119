import Cookies from "js-cookie";
import env from "config";

export const csrfInit = async (): Promise<boolean> => {
  Cookies.remove("csrftoken");
  try {
    return (await fetch(`${env("REACT_APP_API_URL")}/user/login`)).ok;
  } catch (e) {
    return false;
  }
};
