import { useTrackFilterOptions } from "utils/trackOptions";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useGetInteractionsQuery } from "features/interaction/interactionApi";
import { ILink } from "features/link/link.interface";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { highlightText } from "utils/highlightText";
import toggleListItem from "utils/toggleListItem";

import Button from "components/Button";
import Interaction from "../interaction/Interaction";
import { InteractionSkeleton } from "../interaction/InteractionSkeletons";
import { LinkTrackingError } from "./LinkTrackingError";

const LinkTrackingHeader = ({
  link,
  searchValue,
  open,
  onClick,
  isDetail,
}: {
  link: ILink;
  searchValue?: string;
  open: boolean;
  onClick: VoidFunction;
  isDetail?: boolean;
}) => {
  const highlightedUrl = highlightText(link.url, searchValue);
  return (
    <div className="relative flex flex-row items-start justify-between p-4 text-metal">
      {open && (
        <div className="absolute left-8 top-4 z-0 h-full border-r border-dust-dark md:hidden" />
      )}
      <div className="z-10 flex w-full min-w-0 flex-row items-start gap-4 md:items-center">
        <div className="whitespace-nowrap">
          <div className="flex h-8 w-8 items-center justify-center rounded bg-dust">
            <i className="icon-link text-lg" />
          </div>
        </div>
        <div className="flex w-full min-w-0 flex-col items-start gap-2 md:flex-row md:items-center md:justify-between">
          <div className="flex w-full min-w-0 items-center justify-between md:w-auto md:justify-start">
            <div className="block w-full truncate text-lg font-bold text-navy">
              {highlightedUrl}
            </div>
            <div className="whitespace-nowrap">
              <Button
                icon="icon-popup"
                tooltip="Open link in new tab"
                onClick={() => window.open(link.url, "_blank")}
                color="transparent"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 whitespace-nowrap md:flex-row md:items-center md:gap-x-8 md:px-4">
            <div>
              Last click{" "}
              <span className="font-bold">
                {formatDistanceToNow(new Date(link.lastInteractionTimestamp))}{" "}
                ago
              </span>
            </div>
            {!isDetail && (
              <div className="flex items-center gap-2">
                <i className="icon-email text-xl" />
                <div>
                  <span className="font-bold">
                    {link.totalEmails.toLocaleString()}
                  </span>{" "}
                  {link.totalEmails > 1 ? "emails" : "email"}
                </div>
              </div>
            )}
            <div className="flex items-center gap-2">
              <i className="icon-cursor text-xl" />
              <div>
                <span className="font-bold">
                  {link.totalClicks.toLocaleString()}
                </span>{" "}
                clicks
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whitespace-nowrap">
        <Button
          icon={classNames(open ? "icon-up" : "icon-down")}
          onClick={onClick}
          color="transparent"
        />
      </div>
    </div>
  );
};

const LinkClickInteractions = ({
  linkId,
  messageId,
}: {
  linkId: string;
  messageId?: string;
}) => {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [trackOptions] = useTrackFilterOptions();

  const {
    data: linkInteractions,
    isLoading: isLinkInteractionsLoading,
    isFetching: isLinkInteractionsFetching,
    isError: isLinkInteractionsError,
  } = useGetInteractionsQuery({
    cursor,
    linkId,
    messageId,
    trackOptions,
  });

  const fetchMore = () => {
    if (linkInteractions?.next) setCursor(linkInteractions.next);
  };
  if (isLinkInteractionsError)
    return (
      <div className="h-80">
        <LinkTrackingError />
      </div>
    );
  const isDetail = !!messageId;
  return (
    <div className="relative">
      <div
        className={classNames(
          "absolute left-8 top-0 h-full border-r border-dust-dark md:left-[72px]",
          isDetail ? "z-0" : "-z-10"
        )}
      />
      <div className="flex flex-col gap-y-2 pb-8 pt-4 md:pl-8">
        {linkInteractions?.results.map((interaction) => (
          <Interaction
            key={interaction.uuid}
            interaction={{
              ...interaction,
              url: isDetail ? interaction.url : undefined,
            }}
            onClick={
              isDetail
                ? () => {}
                : () => navigate(`email/${interaction.messageId}`)
            }
            variant={isDetail ? "email-detail" : "link-tracking"}
            dateFormat="eee do MMM, HH:mm"
          />
        ))}
        {(isLinkInteractionsLoading || isLinkInteractionsFetching) && (
          <div className="-mt-2 max-w-sm px-4">
            <InteractionSkeleton />
          </div>
        )}
        {linkInteractions?.next && (
          <div className="mx-auto pt-4">
            <Button color="dust" text="Load more" onClick={fetchMore} />
          </div>
        )}
      </div>
    </div>
  );
};

const LinkTracking = ({
  link,
  searchValue,
  messageId,
}: {
  link: ILink;
  searchValue?: string;
  messageId?: string;
}) => {
  const [showLinkDetail, setShowLinkDetail] = useState<string[]>([]);

  return (
    <div
      data-testid="link-items"
      className="flex w-full flex-col border-b border-dust"
    >
      <LinkTrackingHeader
        link={link}
        searchValue={searchValue}
        open={showLinkDetail.includes(link.uuid)}
        onClick={() =>
          setShowLinkDetail(toggleListItem(link.uuid, showLinkDetail))
        }
        isDetail={!!messageId}
      />
      {showLinkDetail.includes(link.uuid) && (
        <LinkClickInteractions messageId={messageId} linkId={link.uuid} />
      )}
    </div>
  );
};

export default LinkTracking;
