import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";
import {
  IMailbox,
  IMessage,
  ITimeSeriesInsight,
  IMessageInteraction,
  IMessageRecipient,
  IMessageResponse,
} from "./message.interface";
import enhancedInteractions from "../interaction/enhancedInteractions.json";
import { SearchTargetType } from "pages/track/email/EmailSearch";
import { trackOptionsType, trackSearchParams } from "utils/trackOptions";

export const transformRecipient = (
  recipient: IMessageRecipient
): IMessageRecipient => ({
  ...recipient,
  interactions: recipient.interactions.map(
    (interaction: IMessageInteraction) => ({
      ...interaction,
      eventType: enhancedInteractions.find(
        (eInteraction) => eInteraction.rawLabel === interaction.eventType
      )?.id,
      enhancedEvent: enhancedInteractions.find(
        (eInteraction) => eInteraction.rawLabel === interaction.eventType
      ),
    })
  ) as IMessageInteraction[],
});

export const messageApi = createApi({
  reducerPath: "message",
  baseQuery: prepareBaseQuery({
    baseUrl: "/track",
  }),
  endpoints: (builder) => ({
    getMessages: builder.query<
      IMessageResponse,
      {
        cursor?: string;
        search?: string;
        searchTarget?: SearchTargetType;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ search, searchTarget, cursor, trackOptions }) => {
        const searchParams = new URLSearchParams();
        if (cursor) return cursor;
        if (search) {
          searchParams.append("search_text", search);
        }
        if (search && searchTarget === "messageSubject") {
          searchParams.append("only_email_subject", "true");
        }
        if (search && searchTarget === "fullName") {
          searchParams.append("only_contact_name", "true");
        }
        searchParams.append("maximum_contact_per_email", "10");
        const searchParamsWithTrack = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/emails?" + searchParamsWithTrack;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { search, searchTarget, trackOptions } = queryArgs;
        return { search, searchTarget, trackOptions };
      },
      merge: (currentCache, newResponse, { arg }) => {
        if (!arg.cursor) {
          currentCache = newResponse;
        } else {
          currentCache.next = newResponse.next;
          currentCache.results.push(...newResponse.results);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: IMessageResponse): IMessageResponse => {
        return camelizeKeys(response) as IMessageResponse;
      },
    }),
    getNewMessages: builder.query<
      IMessageResponse,
      {
        search?: string;
        searchTarget?: SearchTargetType;
        happenedAfter?: string;
        round?: number;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ search, searchTarget, happenedAfter, trackOptions }) => {
        const searchParams = new URLSearchParams();
        if (search) {
          searchParams.append("search_text", search);
        }
        if (search && searchTarget === "messageSubject") {
          searchParams.append("only_email_subject", "true");
        }
        if (search && searchTarget === "fullName") {
          searchParams.append("only_contact_name", "true");
        }
        if (happenedAfter) {
          searchParams.append("happened_after", happenedAfter);
        }
        searchParams.append("maximum_contact_per_email", "10");
        const searchParamsWithTrack = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/emails?" + searchParamsWithTrack;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { search, searchTarget, round, trackOptions } = queryArgs;
        return {
          search,
          searchTarget,
          round,
          trackOptions,
        };
      },
      merge: (currentCache, newResponse) => {
        if (!newResponse.next) {
          currentCache.results = [
            ...newResponse.results,
            ...currentCache.results,
          ];
        }
        currentCache.next = newResponse.next;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: IMessageResponse): IMessageResponse => {
        return camelizeKeys(response) as IMessageResponse;
      },
    }),
    getMessage: builder.query<
      IMessage,
      { id: string; trackOptions?: trackOptionsType }
    >({
      query: ({ id, trackOptions }) => {
        const searchParams = new URLSearchParams();
        const searchParamsWithTrack = trackSearchParams(
          searchParams,
          trackOptions
        );
        return `/email/${encodeURIComponent(id)}?${searchParamsWithTrack}`;
      },
      transformResponse: (response: IMessage): IMessage => {
        const message = camelizeKeys(response) as IMessage;
        const recipients = message.recipients.map(transformRecipient);
        return { ...message, recipients } as IMessage;
      },
    }),
    getTimeSeriesInsight: builder.query<
      ITimeSeriesInsight[],
      {
        id: string;
        granularity?: "day" | "hour";
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ id, granularity, trackOptions }) => {
        const searchParams = new URLSearchParams();
        if (granularity) {
          searchParams.append("granularity", granularity);
        }
        const searchParamsWithTrack = trackSearchParams(
          searchParams,
          trackOptions
        );
        return (
          `/email/${encodeURIComponent(id)}/insights/time-series-breakdown?` +
          searchParamsWithTrack
        );
      },
      transformResponse: (response: any): ITimeSeriesInsight[] => {
        return camelizeKeys(response) as ITimeSeriesInsight[];
      },
    }),
    getPieInsight: builder.query<
      any,
      {
        id: string;
        variant:
          | "engagement-breakdown"
          | "interaction-by-hour-breakdown"
          | "interaction-by-device-breakdown"
          | "interaction-per-contact-breakdown"
          | "recipient-by-country-breakdown";
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ id, variant, trackOptions }) => {
        const searchParams = new URLSearchParams();
        const searchParamsWithTrack = trackSearchParams(
          searchParams,
          trackOptions
        );
        return `/email/${encodeURIComponent(
          id
        )}/insights/${variant}?${searchParamsWithTrack}`;
      },
      transformResponse: (response: any): any => {
        return camelizeKeys(response);
      },
    }),
    getMailboxes: builder.query<IMailbox[], void>({
      query: () => {
        return "/mailboxes";
      },
      transformResponse: (response: any): IMailbox[] =>
        camelizeKeys(response).availableMailboxes as IMailbox[],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetNewMessagesQuery,
  useGetMessageQuery,
  useGetTimeSeriesInsightQuery,
  useGetPieInsightQuery,
  useGetMailboxesQuery,
} = messageApi;
