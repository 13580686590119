import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import transformToCubeFilters from "utils/cube/transformToCubeFilters";
import same from "utils/same";

export const defaultContactFilters = {
  activity: [ActivityType.Active, ActivityType.Inactive, ActivityType.Dormant],
  rank: [RankType.Gold, RankType.Silver, RankType.Bronze, RankType.Unranked],
  trend: [TrendType.Strengthening, TrendType.Consistent, TrendType.Weakening],
  segment: [
    SegmentType.New,
    SegmentType.Ongoing,
    SegmentType.Revived,
    SegmentType.Lapsing,
    SegmentType.Historic,
  ],
  colleaguesInteracting: { value: 0, operator: "gte" },
  relationshipScore: { min: 0, max: 100 },
  flags: { showLeavers: false, showAssistants: true },
  personalProvider: false,
};

export const defaultCompanyFilters = {
  activity: [ActivityType.Active, ActivityType.Inactive, ActivityType.Dormant],
  rank: [RankType.Gold, RankType.Silver, RankType.Bronze, RankType.Unranked],
  trend: [TrendType.Strengthening, TrendType.Consistent, TrendType.Weakening],
  flags: { showServiceProviders: false },
  segment: [
    SegmentType.New,
    SegmentType.Ongoing,
    SegmentType.Revived,
    SegmentType.Lapsing,
    SegmentType.Historic,
  ],
  colleaguesInteracting: { value: 0, operator: "gte" },
  relationshipScore: { min: 0, max: 100 },
  contacts: { value: 0, operator: "gte" },
};

export type ContactFiltersType = typeof defaultContactFilters;
export type CompanyFiltersType = typeof defaultCompanyFilters;
export type CustomFiltersType = { [key: string]: number[] };
export type TFilters = {
  active: boolean;
  contact: ContactFiltersType;
  company: CompanyFiltersType;
  teams: number[];
  custom: CustomFiltersType;
  defaultTeams: number[];
  defaultCustom: CustomFiltersType;
  initialCustom: boolean;
};

const initialState: TFilters = {
  active: false,
  contact: defaultContactFilters,
  company: defaultCompanyFilters,
  teams: [],
  custom: {},
  defaultTeams: [],
  defaultCustom: {},
  initialCustom: true,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    changeFilters(state, action: PayloadAction<Partial<typeof initialState>>) {
      if (action.payload.contact) {
        state.contact = action.payload.contact;
      }
      if (action.payload.company) {
        state.company = action.payload.company;
      }
      if (action.payload.teams) {
        state.teams = action.payload.teams;
      }
      if (action.payload.custom) {
        state.custom = action.payload.custom;
      }

      state.active =
        !same(state.contact, initialState.contact) ||
        !same(state.company, initialState.company) ||
        !(same(state.teams, state.defaultTeams) || state.teams.length === 0) ||
        !same(state.custom, state.defaultCustom);
    },
    setDefaultFilterState(
      state,
      action: PayloadAction<Partial<typeof initialState>>
    ) {
      if (action.payload.defaultTeams) {
        state.defaultTeams = action.payload.defaultTeams;
      }
      if (action.payload.defaultCustom) {
        state.defaultCustom = action.payload.defaultCustom;

        // making sure the custom values are set to default if the state wasn't initiated
        if (state.initialCustom) {
          state.custom = action.payload.defaultCustom;
          state.initialCustom = false;
        }
      }
    },
    resetFilters(state) {
      state.contact = defaultContactFilters;
      state.company = defaultCompanyFilters;
      state.teams = state.defaultTeams;
      state.custom = state.defaultCustom;
      state.active = false;
    },
  },
});

export const selectFilters = (state: RootState) => state.filters;

export const selectFiltersActive = (state: RootState) => state.filters.active;
export const selectCubeFilters = (state: RootState) =>
  transformToCubeFilters(state.filters);

export const { changeFilters, resetFilters, setDefaultFilterState } =
  filtersSlice.actions;

export default filtersSlice.reducer;
