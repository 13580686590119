import classNames from "classnames";
import { useState } from "react";

import Identifier from "components/atoms/Identifier";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { ITeam } from "features/team/teamApi";
import { pluck } from "ramda";

const TeamRow = ({
  team,
  checked,
  onCheck,
}: {
  team: ITeam;
  checked: boolean;
  onCheck: () => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const teamLength = team.teamMembers.length;
  const colleagueLabel =
    teamLength > 0 ? `${teamLength} colleague${teamLength > 1 ? "s" : ""}` : "";
  return (
    <div>
      <div className="flex items-center gap-2 p-4">
        <Checkbox size="small" checked={checked} onClick={onCheck} />
        <div className="flex flex-1 cursor-pointer gap-2" onClick={onCheck}>
          <div
            className="h-4 w-4 rounded-full"
            style={{ background: team.color }}
          ></div>
          <div className="text-navy">{team.name}</div>
        </div>
        <div className="text-metal">{colleagueLabel}</div>
        <Button
          disabled={!team.teamMembers.length}
          onClick={toggle}
          color="transparent"
          icon={collapsed ? "icon-down" : "icon-up"}
        />
      </div>
      <div
        className={classNames("flex gap-4 pb-4 pl-16", {
          hidden: collapsed,
          visible: !collapsed,
        })}
      >
        <div className="border-l border-dust-darker"></div>
        <div className="flex flex-col gap-2">
          {team.teamMembers.map((member) => (
            <div className="flex gap-2" key={member.user.uuid}>
              <Identifier size="tiny" type="colleague" text="" />
              <div className="font-bold text-navy">{member.user.fullName}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TeamFiltersTab = ({
  teams,
  selected,
  updateSelected,
}: {
  teams: ITeam[];
  selected: number[];
  updateSelected: (updated: number[]) => void;
}) => {
  const allSelected = selected.length === teams.length;
  const allTeams = pluck("id", teams);
  const toggle = () =>
    allSelected ? updateSelected([]) : updateSelected(allTeams);

  return (
    <>
      <div className="mt-auto mb-4 flex justify-between">
        <div className="basis-1/2 text-metal">
          Filtering by team allows you to see only the relationships which
          involve colleagues from the teams selected.
        </div>
        <Button
          color="dust"
          size="small"
          text={allSelected ? "Deselect all" : "Select all"}
          onClick={toggle}
        />
      </div>
      <div className="flex flex-col divide-y rounded-lg border border-dust-dark">
        {teams.map((team) => (
          <TeamRow
            team={team}
            key={team.id}
            checked={selected.includes(team.id)}
            onCheck={() => {
              if (selected.includes(team.id)) {
                updateSelected(selected.filter((i) => i !== team.id));
              } else {
                updateSelected([...selected, team.id]);
              }
            }}
          />
        ))}
      </div>
    </>
  );
};

export default TeamFiltersTab;
