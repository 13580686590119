import { format, subMonths } from "date-fns";
import { DateRange } from "@cubejs-client/core";

export const beginningOfMonthsAgo = (monthsAgo: number): Date => {
  const date = subMonths(new Date(), monthsAgo);
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const oneYearAgoToNowRange = (): DateRange => {
  const start = format(beginningOfMonthsAgo(12), "yyyy-MM-dd");
  const end = format(new Date(), "yyyy-MM-dd");
  return [start, end];
};
