import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";

import { ICompanyRelationshipValue } from "./company.interfaces";
import { useGetUserQuery } from "features/profile/profileApi";
import { propOr } from "ramda";

const useCompanyRelationships = ({
  companyId,
  limit,
  contactSearch,
  colleagueSearch,
  additionalFilters,
}: {
  companyId: string;
  limit?: number;
  contactSearch?: string;
  colleagueSearch?: string;
  additionalFilters?: Filter[];
}) => {
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const query = {
    dimensions: [
      "dim_contact.full_name",
      "dim_contact.uuid",
      "dim_contact.email",
      "dim_user.full_name",
      "dim_user.uuid",
      ...(user?.showContactSignatures ? ["dim_contact.phone_numbers"] : []),
    ],
    measures: [
      "fact_table.relationship_score_current",
      "fact_table.relationship_score_prev",
      "fact_table.inbound_current",
      "fact_table.outbound_current",
      "fact_table.interest_current",
      "fact_table.effort_current",
      "fact_table.last_interaction",
      "fact_table.activity",
      "fact_table.trend",
    ],
    order: {
      "fact_table.relationship_score_current": "desc",
    },
    filters: [
      {
        member: "dim_company.uuid",
        operator: "equals",
        values: [companyId],
      },
      {
        member: "fact_table.relationship_score_current",
        operator: "gt",
        values: ["0"],
      },
      ...(additionalFilters || []),
      ...(colleagueSearch
        ? [
            {
              member: "dim_user.full_name",
              operator: "contains",
              values: [colleagueSearch],
            },
          ]
        : []),
      ...(contactSearch
        ? [
            {
              member: "dim_contact.full_name",
              operator: "contains",
              values: [contactSearch],
            },
          ]
        : []),
    ],
    limit: limit || 20,
  };

  const { resultSet, isLoading, error } = useCubeQuery(query as Query);

  if (error) {
    console.error(error);
  }

  const rows = resultSet?.tablePivot() || [];
  const results: ICompanyRelationshipValue[] = rows.map((row) => ({
    colleagueUuid: row["dim_user.uuid"] as string,
    colleagueFullName: row["dim_user.full_name"] as string,
    contactUuid: row["dim_contact.uuid"] as string,
    contactFullName: row["dim_contact.full_name"] as string,
    contactEmail: row["dim_contact.email"] as string,
    contactPhoneNumbers: JSON.parse(
      propOr("[]", "dim_contact.phone_numbers", row)
    ),
    lastInteraction: row["fact_table.last_interaction"],
    relationshipScore: +row["fact_table.relationship_score_current"],
    relationshipScorePrev: +row["fact_table.relationship_score_prev"],
    relationshipScoreDelta:
      +row["fact_table.relationship_score_current"] -
      +row["fact_table.relationship_score_prev"],
    emailsIn: +row["fact_table.inbound_current"],
    emailsOut: +row["fact_table.outbound_current"],
    interest: +row["fact_table.interest_current"],
    effort: +row["fact_table.effort_current"],
    activity: +row["fact_table.activity"],
    trend: +row["fact_table.trend"],
  }));

  return {
    results,
    isLoading: isLoading || isUserLoading,
  };
};

export { useCompanyRelationships };
