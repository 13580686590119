import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import FilterButton from "components/FilterButton";
import SelectOption from "components/SelectOption";
import { RelationshipExplorerChart } from "charts";
import { useCompanyRelationships } from "features/company/useCompanyRelationships";
import { selectCubeFilters } from "app/filtersSlice";
import CubeLastRefresh from "components/CubeLastRefresh";
import TextInput from "components/TextInput";
import useDebounce from "utils/hooks/useDebounce";
import { Filter } from "@cubejs-client/core";
import { ActivityType } from "features/entity/entity.interfaces";

const ChartUnavailable = () => (
  <div className="my-[120px] flex items-center justify-center">
    <div className="flex flex-col text-center">
      <i className="icon-hidden text-3xl text-navy"></i>
      <div className="mt-2 text-xl font-bold text-navy">Chart unavailable</div>
      <div className="mt-2 text-metal">
        This chart is not available on small screens.
      </div>
    </div>
  </div>
);

const CompanyRelationshipExplorerView = () => {
  const params = useParams();
  const [relationshipFilter, setRelationshipFilter] = useState("relevant");
  const [contactSearchValue, setContactSearchValue] = useState("");
  const [colleagueSearchValue, setColleagueSearchValue] = useState("");

  const debouncedConact = useDebounce(contactSearchValue, 500);
  const debouncedColleague = useDebounce(colleagueSearchValue, 500);

  const cubeFilters = useSelector(selectCubeFilters);
  const filterOptions = [
    { value: "relevant", label: "Showing relevant relationships only" },
    { value: "all", label: "Showing all relationships" },
    { value: "active", label: "Showing active relationships only" },
    { value: "inactive", label: "Showing inactive relationships only" },
    { value: "dormant", label: "Showing dormant relationships only" },
  ];

  const getFilters = (): Filter[] => {
    switch (relationshipFilter) {
      case "relevant":
        return [
          {
            member: "fact_table.activity",
            operator: "equals",
            values: [
              String(ActivityType.Active),
              String(ActivityType.Inactive),
            ],
          },
          {
            member: "dim_contact.is_assistant",
            operator: "equals",
            values: ["false"],
          },
          {
            member: "dim_contact.is_leaver",
            operator: "equals",
            values: ["false"],
          },
        ];
      case "all":
        return [];
      case "active":
        return [
          {
            member: "fact_table.activity",
            operator: "equals",
            values: [String(ActivityType.Active)],
          },
        ];
      case "inactive":
        return [
          {
            member: "fact_table.activity",
            operator: "equals",
            values: [String(ActivityType.Inactive)],
          },
        ];
      case "dormant":
        return [
          {
            member: "fact_table.activity",
            operator: "equals",
            values: [String(ActivityType.Dormant)],
          },
        ];
      default:
        return [];
    }
  };

  const [limit, setLimit] = useState("24");
  const limitOptions = ["16", "24", "32", "48"].map((value) => ({
    value,
    label: `Strongest ${value} relationships`,
  }));

  const { results: relationships, isLoading } = useCompanyRelationships({
    companyId: params.id as string,
    additionalFilters: [...cubeFilters, ...getFilters()],
    contactSearch: debouncedConact,
    colleagueSearch: debouncedColleague,
    limit: +limit,
  });

  const sankeyMetricData = relationships.reduce(
    (result, relationship, index) => {
      result.push({
        fromId: relationship.colleagueUuid,
        from: relationship.colleagueFullName,
        toId: relationship.contactUuid,
        to: relationship.contactFullName,
        index,
        ...relationship,
      });
      return result;
    },
    [] as any[]
  );

  return (
    <div className="p-4">
      <div className="mb-8 flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <FilterButton warningOnActive />
          <div className="hidden flex-row gap-4 2xl:flex">
            <SelectOption
              options={filterOptions}
              value={relationshipFilter}
              onChange={setRelationshipFilter}
            />
            <SelectOption
              options={limitOptions}
              value={limit}
              onChange={setLimit}
            />
          </div>
          <CubeLastRefresh />
        </div>
        <div className="flex flex-row gap-4 2xl:hidden">
          <SelectOption
            options={filterOptions}
            value={relationshipFilter}
            onChange={setRelationshipFilter}
          />
          <SelectOption
            options={limitOptions}
            value={limit}
            onChange={setLimit}
          />
        </div>
      </div>
      <div className="invisible flex flex-row justify-between gap-4 md:visible">
        <div className="w-[400px]">
          <TextInput
            placeholder="Find a colleague..."
            onChangeText={setColleagueSearchValue}
            value={colleagueSearchValue}
            icon="icon-search"
            clearable
          />
        </div>
        <div className="flex gap-4">
          <div className="flex items-center gap-1">
            <div className="h-0.5 w-4 rounded-sm bg-mint-dark"></div>
            <div className="text-metal">Active</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="flex gap-0.5">
              <div className="h-0.5 w-2 rounded-sm bg-orange"></div>
              <div className="h-0.5 w-2 rounded-sm bg-orange"></div>
            </div>
            <div className="text-metal">Inactive</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="h-0.5 w-4 rounded-sm bg-red"></div>
            <div className="text-metal">Dormant</div>
          </div>
        </div>
        <div className="w-[400px]">
          <TextInput
            placeholder="Find a contact..."
            onChangeText={setContactSearchValue}
            value={contactSearchValue}
            icon="icon-search"
            clearable
          />
        </div>
      </div>
      <div className="md:hidden">
        <ChartUnavailable />
      </div>
      <div className="invisible md:visible">
        <RelationshipExplorerChart
          data={sankeyMetricData}
          isLoading={isLoading}
          contactQuery={debouncedConact}
          colleagueQuery={debouncedColleague}
        />
      </div>
    </div>
  );
};

export default CompanyRelationshipExplorerView;
