import { WatchListItemType, IWatchListItemKPI } from "../watchlist.interfaces";
import { GenericWatchListItemCell } from "./WatchListItemCells";
import Button from "components/Button";
import EntityIdentifier from "components/EntityIdentifier";
import { IPhoneNumber } from "features/entity/entity.interfaces";

const Row = ({ cells }: { cells: any[] }) => (
  <div className="flex flex-col gap-4 rounded-b-lg bg-white p-4">
    {cells.map((cell, i) => (
      <div key={i}>{cell}</div>
    ))}
  </div>
);

const WatchListItem = ({
  title,
  subtitle,
  id,
  type,
  kpi,
  removeItem,
  meta,
  logoUrl,
  logoColor,
}: {
  title: string;
  subtitle?: string;
  id?: string;
  type: WatchListItemType;
  kpi: IWatchListItemKPI[];
  removeItem?: () => void;
  meta?: {
    email?: string;
    phoneNumbers?: IPhoneNumber[];
  };
  logoUrl?: string;
  logoColor?: string;
}) => {
  const cells: any[] = [];

  kpi.forEach((option) => {
    cells.push(
      <GenericWatchListItemCell type={option.type} meta={option.meta} />
    );
  });

  return (
    <div className="min-w-[280px] overflow-hidden rounded-lg border border-dust-dark bg-white shadow">
      <div
        className="flex h-16 items-center justify-between p-4"
        style={{
          background:
            type === "company"
              ? `radial-gradient(100% 100% at 0.11% 0%, ${
                  logoColor || "#52738F"
                }26 0%, #ffffff 100%)`
              : "",
        }}
      >
        <EntityIdentifier
          type={type}
          title={title}
          description={subtitle}
          meta={meta}
          id={id}
          logoUrl={logoUrl}
        />
        <Button
          icon="icon-watchlist-remove"
          onClick={removeItem}
          tooltip="Remove from watchlist"
          color="transparent"
        />
      </div>
      <div className="mx-4 border-t border-dust-dark" />
      {cells.length > 0 && <Row cells={cells} />}
    </div>
  );
};

export default WatchListItem;
