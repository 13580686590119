import { useDispatch } from "react-redux";
import { useEffect } from "react";
import env from "config";
import PermissionGuard from "utils/permissions/PermissionGuard";
import { Permissions } from "features/profile";

import LapsingEngagementPreview from "./previews/LapsingEngagementPreview";
import FrequentRevisitsPreview from "./previews/FrequentRevisitsPreview";
import UnresponsiveContactsPreview from "pages/report/previews/UnresponsiveContactsPreview";
import ActivityInNumbersPreview from "./previews/ActivityInNumbersPreview";
import ClientServiceLevelPreview from "./previews/ClientServiceLevelPreview";
import TopicExplorerPreview from "pages/report/previews/TopicExplorerPreview";

import { changeBreadcrumb } from "app/breadcrumbSlice";

const REACT_APP_IS_TOPIC_ENABLED: boolean =
  env("REACT_APP_IS_TOPIC_ENABLED") === "true";

const REACT_APP_IS_CLIENT_SERVICE_REPORT_ENABLED: boolean =
  env("REACT_APP_IS_CLIENT_SERVICE_REPORT_ENABLED") === "true";

const ReportsListView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
      })
    );
  }, [dispatch]);

  return (
    <>
      <LapsingEngagementPreview />
      <FrequentRevisitsPreview />
      <UnresponsiveContactsPreview />
      {REACT_APP_IS_TOPIC_ENABLED && <TopicExplorerPreview />}
      {REACT_APP_IS_CLIENT_SERVICE_REPORT_ENABLED && (
        <PermissionGuard permission={Permissions.ACCESS_REPORT_EXTRA}>
          <ClientServiceLevelPreview />
        </PermissionGuard>
      )}
      <ActivityInNumbersPreview />
      <div className="flex flex-col items-center gap-2 text-center">
        <i className="icon-task text-3xl text-navy"></i>
        <div className="text-xl font-bold text-navy">You're up to date</div>
        <div className="w-[300px] text-metal">
          Check back soon for more reports on your network.
        </div>
      </div>
    </>
  );
};

export default ReportsListView;
