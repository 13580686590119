import classNames from "classnames";
import Button from "components/Button";
import Modal from "components/Modal";
import TextInput from "components/TextInput";
import { pathOr } from "ramda";
import { useState } from "react";
import { csrfInit } from "../../../../utils/requests";
import { useUpdateUserMutation } from "../../profileApi";
import { IUser } from "../../user.interface";
import usePasswordConstraints from "utils/hooks/usePasswordConstraints";

const ChangePasswordModal = ({
  user,
  visible,
  onClose,
  isPasswordChanged,
}: {
  user: IUser;
  visible: boolean;
  onClose: () => void;
  isPasswordChanged: (value: boolean) => void;
}) => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [requestError, setRequestError] = useState<string | undefined>();

  const userHasUsablePassword = user.hasUsablePassword || false;
  const { constraints, satisfied } = usePasswordConstraints(
    newPassword,
    confirmPassword,
    userHasUsablePassword ? oldPassword : undefined
  );

  const handleRequest = async () => {
    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await updateUser({
        password: newPassword,
        confirmation: confirmPassword,
        ...(userHasUsablePassword ? { old_password: oldPassword } : {}),
      }).unwrap();
      isPasswordChanged(true);
    } catch (error) {
      setRequestError(
        pathOr(
          "It was not possible to change your password. Try again in a few moments.",
          ["data", "old_password"],
          error
        )
      );
      console.error(error);
    }
  };
  const handleChange = (target: string, value: string) => {
    switch (target) {
      case "old-password":
        setOldPassword(value);
        break;
      case "new-password":
        setNewPassword(value);
        break;
      case "confirm-password":
        setConfirmPassword(value);
        break;
    }
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex h-full flex-col text-base">
        <div className="flex items-center justify-center text-center">
          <div className="flex-1 text-lg text-navy">
            <i className="icon-key mr-2"></i>
            {userHasUsablePassword ? "Change password" : "Set a password"}
          </div>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="mx-4 mt-4 flex h-full flex-col">
          <div>
            {userHasUsablePassword && (
              <>
                <div className="mt-6 flex-grow text-lg font-bold text-navy">
                  Current password
                </div>
                <div className="mb-4 mt-2 flex flex-row gap-2">
                  <TextInput
                    type={showOldPassword ? "text" : "password"}
                    onChangeText={(value) =>
                      handleChange("old-password", value)
                    }
                  />
                  <Button
                    color="dust"
                    text={showOldPassword ? "Hide" : "Show"}
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col gap-2 rounded bg-dust p-4">
            <div className="font-bold text-navy">
              The following criteria must be met:
            </div>
            {constraints.map(({ label, status }, index) => (
              <div
                key={index}
                className={classNames("flex gap-2", {
                  "text-metal": status,
                  "font-bold text-navy": !status,
                })}
              >
                <i
                  className={classNames("text-lg", {
                    "icon-check": status,
                    "icon-cross": !status,
                  })}
                ></i>
                {label}
              </div>
            ))}
          </div>
          <div>
            <div className="mt-6 text-lg font-bold text-navy">New password</div>
            <div className="mb-4 mt-2 flex flex-row gap-2">
              <TextInput
                type={showNewPassword ? "text" : "password"}
                onChangeText={(value) => handleChange("new-password", value)}
              />
              <Button
                color="dust"
                text={showNewPassword ? "Hide" : "Show"}
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </div>
          </div>
          <div>
            <div className="text-lg font-bold text-navy">Confirm password</div>
            <div className="my-2 flex flex-row gap-2">
              <TextInput
                type={showConfirmPassword ? "text" : "password"}
                onChangeText={(value) =>
                  handleChange("confirm-password", value)
                }
              />
              <Button
                color="dust"
                text={showConfirmPassword ? "Hide" : "Show"}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
          </div>
          {requestError && (
            <div className="mb-4 font-bold text-red-dark">{requestError}</div>
          )}
          <div className="mt-auto">
            <Button
              isLoading={isLoading}
              color="navy"
              iconRight
              block
              disabled={!satisfied}
              icon="icon-right"
              text={user.hasUsablePassword ? "Change password" : "Set password"}
              id="set-password"
              onClick={handleRequest}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
