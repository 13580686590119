import DustyBanana from "assets/DustyBanana.svg";
import Button from "components/Button";
import ReportBox from "./components/ReportBox";

export const ReportForbidden = () => (
  <div className="mb-5 mt-[120px] flex w-full flex-col items-center justify-center overflow-hidden">
    <div className="flex flex-row items-center gap-2 pb-6">
      <div className="flex h-12 w-12 items-center justify-center rounded bg-mint">
        <i className="icon-cursor text-2xl text-navy"></i>
      </div>
      <div className="flex h-16 w-16 items-center justify-center rounded bg-sky">
        <i className="icon-report text-4xl text-white"></i>
      </div>
      <div className="flex h-12 w-12 items-center justify-center rounded bg-navy">
        <i className="icon-search text-2xl text-white"></i>
      </div>
    </div>
    <div className="pb-2 text-xl font-bold text-navy">Welcome to Report</div>
    <div className="mt-2 max-w-[288px] text-lg text-metal sm:max-w-[409px]">
      <div className="mb-12 flex flex-col gap-4 text-center">
        <p>Track the pulse of every relationship with advanced reporting.</p>
        <p>
          With Relata Report, we answers specific questions about your network,
          and deliver the results directly to you.
        </p>
      </div>
      <p className="text-center font-bold">You currently have no reports.</p>
    </div>
    <div className="mt-8 sm:mt-10">
      <Button
        id="request-report"
        text="Request a report"
        color="dust"
        icon="icon-report"
      />
    </div>
    <div className="relative flex w-full flex-col items-center">
      <img
        src={DustyBanana}
        alt="dusty banana"
        className="absolute left-[50%] top-5 z-[-1] max-w-none -translate-x-[50%] sm:top-[100px]"
      />
      <div className="z-10 mt-[66px] sm:mt-[143px]">
        <ReportBox
          id="top-company"
          cadence="Client deep-dive"
          title="‘Your top company’"
          creationDate="Q2-Q3 2022"
        />
      </div>
      <div className="z-10 mt-4">
        <ReportBox
          id="team-a"
          cadence="Team network analysis"
          title="‘Team A’"
          creationDate="Q2-Q3 2022"
        />
      </div>
    </div>
  </div>
);

export default ReportForbidden;
