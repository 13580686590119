import { BinaryFilter, BinaryOperator, Filter } from "@cubejs-client/core";
import {
  TFilters,
  defaultContactFilters,
  defaultCompanyFilters,
} from "app/filtersSlice";
import same from "utils/same";

const getNoopFilter = (member: string): BinaryFilter => ({
  member,
  operator: "equals",
  values: ["9999"],
});

const transformToCubeFilters = (filtersState: TFilters): Filter[] => {
  const { contact, company, teams, custom } = filtersState;

  const result: Filter[] = [];

  if (contact) {
    if (contact.activity.length === 0) {
      result.push(getNoopFilter("dim_contact.activity_status"));
    } else if (
      contact.activity.length < defaultContactFilters.activity.length
    ) {
      result.push({
        member: "dim_contact.activity_status",
        operator: "equals",
        values: contact.activity.map(String),
      });
    }

    if (contact.rank.length === 0) {
      result.push(getNoopFilter("dim_contact.rank_status"));
    } else if (contact.rank.length < defaultContactFilters.rank.length) {
      result.push({
        member: "dim_contact.rank_status",
        operator: "equals",
        values: contact.rank.map(String),
      });
    }

    if (contact.trend.length === 0) {
      result.push(getNoopFilter("dim_contact.trend_status"));
    } else if (contact.trend.length < defaultContactFilters.trend.length) {
      result.push({
        member: "dim_contact.trend_status",
        operator: "equals",
        values: contact.trend.map(String),
      });
    }
    if (contact.segment.length === 0) {
      result.push(getNoopFilter("dim_contact.segment_status"));
    } else if (contact.segment.length < defaultContactFilters.segment.length) {
      result.push({
        member: "dim_contact.segment_status",
        operator: "equals",
        values: contact.segment.map(String),
      });
    }
    if (!contact.personalProvider) {
      result.push({
        member: "dim_contact.company_id",
        operator: "set",
      });
    }
    if (contact.relationshipScore.min > 0) {
      result.push({
        member: "fact_table.relationship_score_current",
        operator: "gte",
        values: [String(contact.relationshipScore.min)],
      });
    }
    if (contact.relationshipScore.max < 100) {
      result.push({
        member: "fact_table.relationship_score_current",
        operator: "lte",
        values: [String(contact.relationshipScore.max)],
      });
    }
    if (contact.colleaguesInteracting.value > 0) {
      result.push({
        member: "fact_table.colleague_count_current",
        operator: contact.colleaguesInteracting.operator as BinaryOperator,
        values: [String(contact.colleaguesInteracting.value)],
      });
    }

    // contact flags
    if (!contact.flags.showAssistants) {
      result.push({
        member: "dim_contact.is_assistant",
        operator: "equals",
        values: ["false"],
      });
    }
    if (!contact.flags.showLeavers) {
      result.push({
        member: "dim_contact.is_leaver",
        operator: "equals",
        values: ["false"],
      });
    }
  }

  if (company) {
    if (company.activity.length === 0) {
      result.push(getNoopFilter("dim_company.activity_status"));
    } else if (
      company.activity.length < defaultCompanyFilters.activity.length
    ) {
      result.push({
        member: "dim_company.activity_status",
        operator: "equals",
        values: company.activity.map(String),
      });
    }
    if (company.rank.length === 0) {
      result.push(getNoopFilter("dim_company.rank_status"));
    } else if (company.rank.length < defaultCompanyFilters.rank.length) {
      result.push({
        member: "dim_company.rank_status",
        operator: "equals",
        values: company.rank.map(String),
      });
    }
    if (company.trend.length === 0) {
      result.push(getNoopFilter("dim_company.trend_status"));
    } else if (company.trend.length < defaultCompanyFilters.trend.length) {
      result.push({
        member: "dim_company.trend_status",
        operator: "equals",
        values: company.trend.map(String),
      });
    }
    if (company.segment.length === 0) {
      result.push(getNoopFilter("dim_company.segment_status"));
    } else if (company.segment.length < defaultCompanyFilters.segment.length) {
      result.push({
        member: "dim_company.segment_status",
        operator: "equals",
        values: company.segment.map(String),
      });
    }
    if (company.relationshipScore.min > 0) {
      result.push({
        member: "fact_table.relationship_score_current",
        operator: "gte",
        values: [String(company.relationshipScore.min)],
      });
    }
    if (company.relationshipScore.max < 100) {
      result.push({
        member: "fact_table.relationship_score_current",
        operator: "lte",
        values: [String(company.relationshipScore.max)],
      });
    }
    if (company.colleaguesInteracting.value > 0) {
      result.push({
        member: "fact_table.colleague_count_current",
        operator: company.colleaguesInteracting.operator as BinaryOperator,
        values: [String(company.colleaguesInteracting.value)],
      });
    }
    if (company.contacts.value > 0) {
      result.push({
        member: "dim_contact.count",
        operator: company.contacts.operator as BinaryOperator,
        values: [String(company.contacts.value)],
      });
    }
    if (!company.flags.showServiceProviders) {
      result.push({
        member: "dim_company.is_service_provider",
        operator: "equals",
        values: ["false"],
      });
    }
  }

  if (
    teams &&
    teams.length > 0 &&
    teams.length < filtersState.defaultTeams.length
  ) {
    result.push({
      member: "dim_team_filters.teams",
      operator: "contains",
      values: teams.map((team) => `|${team}|`),
    });
  }

  if (
    Object.keys(custom).length > 0 &&
    !same(custom, filtersState.defaultCustom)
  ) {
    const lensesInGroup = Object.keys(custom).map((group_id: string) => ({
      member: "dim_lenses.lenses",
      operator: "contains",
      values: custom[group_id].map((lens) => `|${lens}|`),
    }));
    result.push(...(lensesInGroup as Filter[]));
  }

  return result;
};

export default transformToCubeFilters;
