import classNames from "classnames";

import Button from "components/Button";
import Identifier from "components/atoms/Identifier";

import { WatchListItemType } from "../watchlist.interfaces";
import { getInitials } from "utils/string";

const WatchListModalItem = ({
  id,
  title,
  subtitle,
  type,
  onRemove,
}: {
  id: string;
  title: string;
  subtitle?: string;
  type: WatchListItemType;
  onRemove?: () => void;
}) => (
  <div className="flex h-8 items-center justify-between">
    <div
      className={classNames(
        "transition-default group flex items-center gap-2 border border-transparent p-0.5 text-lg text-navy"
      )}
    >
      <Identifier type={type} text={getInitials(title)} />
      <div className="w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">
        <div className="flex flex-col">
          <div className="text-lg font-bold">{title}</div>
          <div className="text-sm text-metal">{subtitle}</div>
        </div>
      </div>
    </div>
    <Button text="Remove" color="dust" onClick={onRemove} />
  </div>
);

export default WatchListModalItem;
