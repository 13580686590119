import { faker } from "@faker-js/faker";
import { ICompanyDetail } from "features/entity/entity.interfaces";
import { mergeRight } from "ramda";

const fakeCompany = (
  override: Partial<ICompanyDetail>,
  withLogo?: boolean
): ICompanyDetail => {
  const probability = withLogo ? 1 : 0.5;
  const logo =
    faker.helpers.maybe(
      () => ({
        originalBrandColour: faker.helpers.maybe(() => faker.color.rgb(), {
          probability,
        }),
        logoUrl: faker.image.business(32, 32),
      }),
      { probability }
    ) || {};
  return mergeRight(
    {
      uuid: faker.datatype.uuid(),
      displayName: faker.company.name(),
      businessId: faker.datatype.uuid(),
      flags: [],
      ...logo,
    },
    override
  );
};

export default fakeCompany;
