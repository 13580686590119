import DustyBanana from "assets/DustyBanana.svg";
import ActivityEmailSegment from "components/ActivityEmailSegment";
import Button from "components/Button";
import {
  ContactEngagementType,
  IContactDetail,
} from "features/entity/entity.interfaces";

const TrackForbidden = () => {
  const recipients = [
    {
      engagement: "high",
      contact: { fullName: "Alfonso Schleifer" },
    },
    {
      engagement: "standard",
      contact: { fullName: "Jaxson Workman" },
    },
    {
      engagement: "standard",
      contact: { fullName: "Rayna George" },
    },
    {
      engagement: "none",
      contact: { fullName: "Madelyn Carder" },
    },
  ] as {
    engagement: ContactEngagementType;
    contact: IContactDetail;
  }[];

  return (
    <div className="mt-[120px] flex flex-col items-center text-center">
      <div className="flex flex-row items-center gap-2 pb-6">
        <div className="flex h-12 w-12 items-center justify-center rounded bg-navy">
          <i className="icon-search text-2xl text-white" />
        </div>
        <div className="flex h-16 w-16 items-center justify-center rounded bg-mint">
          <i className="icon-cursor text-3xl text-navy" />
        </div>
        <div className="flex h-12 w-12 items-center justify-center rounded bg-sky">
          <i className="icon-report text-2xl text-white" />
        </div>
      </div>
      <div className="pb-2 text-xl font-bold text-navy">Welcome to Track</div>
      <div className="mt-2 mb-12 max-w-[288px] text-lg text-metal sm:max-w-[409px]">
        Track gives you real time insights to see how contacts are interacting
        with the emails you send.
      </div>
      <div className="mb-8 text-lg font-bold text-metal">
        Your account is not currently tracked.
      </div>

      <Button
        text="Request access to Track"
        color="dust"
        id="request-access-track"
        icon="icon-cursor text-2xl"
      />
      <div className="relative flex w-full flex-col items-center">
        <img
          src={DustyBanana}
          alt="dusty banana"
          className="absolute top-5 left-[50%] z-[-1] max-w-none -translate-x-[50%] sm:top-[100px]"
        />
        <div className="mt-[180px] h-fit w-full max-w-[650px] shadow-lg">
          <ActivityEmailSegment
            subject="RE: Project Phoenix"
            date={new Date()}
            recipients={recipients}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackForbidden;
