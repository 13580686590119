import { TooltipItem } from "chart.js";
import { useGetPieInsightQuery } from "features/message/messageApi";
import { head } from "ramda";
import {
  classifyHours,
  classifyDevices,
  classifyCountries,
} from "utils/charts/classifiers";
import formatPieChartLabels, {
  formatPieChartCountryLabels,
} from "utils/charts/formatPieChartLabels";
import { nonZeroValues } from "utils/objects";
import { useTrackFilterOptions } from "utils/trackOptions";
import { pieOptions } from "charts";

export const usePieInsights = ({ id }: { id: string }) => {
  const [trackOptions] = useTrackFilterOptions();
  const queryOptions = { id, trackOptions };
  const {
    data: engagementBreakdown = {},
    refetch: refetchEngagementBreakdown,
  } = useGetPieInsightQuery({
    ...queryOptions,
    variant: "engagement-breakdown",
  });
  const {
    data: interactionsByHourBreakdown = [],
    refetch: refetchInteractionsByHourBreakdown,
  } = useGetPieInsightQuery({
    ...queryOptions,
    variant: "interaction-by-hour-breakdown",
  });

  const {
    data: interactionsByDeviceBreakdown = [],
    refetch: refetchInteractionsByDeviceBreakdown,
  } = useGetPieInsightQuery({
    ...queryOptions,
    variant: "interaction-by-device-breakdown",
  });

  const {
    data: interactionsPerContactBreakdown = {},
    refetch: refetchInteractionsPerContactBreakdown,
  } = useGetPieInsightQuery({
    ...queryOptions,
    variant: "interaction-per-contact-breakdown",
  });
  const {
    data: interactionsByLocationBreakdown = [],
    refetch: refetchInteractionsByLocationBreakdown,
  } = useGetPieInsightQuery({
    ...queryOptions,
    variant: "recipient-by-country-breakdown",
  });

  const refetchPieData = () => {
    refetchEngagementBreakdown();
    refetchInteractionsByHourBreakdown();
    refetchInteractionsByDeviceBreakdown();
    refetchInteractionsPerContactBreakdown();
    refetchInteractionsByLocationBreakdown();
  };

  const customPieOptions = {
    ...pieOptions,
    plugins: {
      ...pieOptions.plugins,
      tooltip: {
        ...pieOptions.plugins.tooltip,
        callbacks: {
          title: (tooltip: TooltipItem<any>[]) => {
            const selected = head(tooltip);
            return selected?.dataset.customTooltipLabels
              ? selected.dataset.customTooltipLabels[selected.dataIndex]
              : selected?.label;
          },
        },
      },
    },
  };

  const pieData = [
    {
      title: "Engagement breakdown",
      data: {
        labels: formatPieChartLabels(engagementBreakdown),
        datasets: [
          {
            data: nonZeroValues(engagementBreakdown),
            backgroundColor: ["#65D398", "#FFE871", "#F0F8FF"],
          },
        ],
      },
    },
    {
      title: "Interactions by time of day",
      data: {
        labels: formatPieChartLabels(
          classifyHours(interactionsByHourBreakdown)
        ),
        datasets: [
          {
            data: nonZeroValues(classifyHours(interactionsByHourBreakdown)),
            backgroundColor: ["#65D398", "#FF9271", "#8D7CF5"],
          },
        ],
      },
    },
    {
      title: "Interactions by device type",
      data: {
        labels: formatPieChartLabels(
          classifyDevices(interactionsByDeviceBreakdown)
        ),
        datasets: [
          {
            data: nonZeroValues(classifyDevices(interactionsByDeviceBreakdown)),
            backgroundColor: ["#8BE5C6", "#002258", "#378AD0"],
          },
        ],
      },
    },
    {
      title: "Interactions per contact",
      data: {
        labels: formatPieChartLabels(interactionsPerContactBreakdown, {
          zeroToFive: "0 - 5",
          sixToTen: "6 - 10",
          elevenOrMore: "11+",
        }),
        datasets: [
          {
            data: nonZeroValues(interactionsPerContactBreakdown),
            backgroundColor: ["#F2A5FF", "#F5505D", "#A3EAE1"],
          },
        ],
      },
    },
    {
      title: "Contact location",
      data: {
        labels: formatPieChartCountryLabels(
          classifyCountries(interactionsByLocationBreakdown),
          "countryCode"
        ),
        datasets: [
          {
            customTooltipLabels: formatPieChartCountryLabels(
              classifyCountries(interactionsByLocationBreakdown),
              "countryName"
            ),
            data: nonZeroValues(
              classifyCountries(interactionsByLocationBreakdown)
            ),
            backgroundColor: [
              "#65D398",
              "#A3EAE1",
              "#F0F8FF",
              "#D1F3E1",
              "#F2A5FF",
              "#8D7CF5",
              "#FFE871",
              "#52738F",
              "#002258",
              "#FF9271",
              "#8BE5C6",
              "#378AD0",
              "#F5505D",
            ],
          },
        ],
      },
    },
  ];
  return { pieData, pieOptions: customPieOptions, refetchPieData };
};
