import { evolve, mergeDeepRight, omit, propOr } from "ramda";
import { MigrationManifest, PersistedState } from "redux-persist";
import createMigrate from "redux-persist/lib/createMigrate";
import storage from "redux-persist/lib/storage";

import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";

const migrations = {
  0: (state: PersistedState) => {
    // migrating from string values to enums
    const filters: any = propOr({}, "filters", state);
    const defaultAggregateFilters = {
      activity: [
        ActivityType.Active,
        ActivityType.Inactive,
        ActivityType.Dormant,
      ],
      rank: [
        RankType.Gold,
        RankType.Silver,
        RankType.Bronze,
        RankType.Unranked,
      ],
      trend: [
        TrendType.Strengthening,
        TrendType.Consistent,
        TrendType.Weakening,
      ],
      segment: [
        SegmentType.New,
        SegmentType.Ongoing,
        SegmentType.Revived,
        SegmentType.Lapsing,
        SegmentType.Historic,
      ],
    };
    const filtersWithEnums = mergeDeepRight(filters, {
      contact: defaultAggregateFilters,
      company: defaultAggregateFilters,
    });

    // drop `hasRelationship` filters from contacts and companies
    return {
      ...state,
      filters: evolve(
        {
          contact: omit(["hasRelationship"]),
          company: omit(["hasRelationship"]),
        },
        filtersWithEnums
      ),
    };
  },
  1: (state: PersistedState) => {
    const filters: any = propOr({}, "filters", state);

    // adding flags to contact filters
    return {
      ...state,
      filters: evolve(
        {
          contact: (contactFilters) => ({
            ...contactFilters,
            flags: { showLeavers: false, showAssistants: true },
          }),
        },
        filters
      ),
    };
  },
  2: (state: PersistedState) => {
    const filters: any = propOr({}, "filters", state);

    // adding personalProvider to contact filters
    return {
      ...state,
      filters: evolve(
        {
          contact: (contactFilters) => ({
            ...contactFilters,
            personalProvider: false,
          }),
        },
        filters
      ),
    };
  },
  3: (state: PersistedState) => {
    const filters: any = propOr({}, "filters", state);
    // adding colleaguesInteracting to the company filters
    return {
      ...state,
      filters: evolve(
        {
          company: (companyFilters) => ({
            ...companyFilters,
            colleaguesInteracting: { value: 0, operator: "gte" },
          }),
        },
        filters
      ),
    };
  },
  4: (state: PersistedState) => {
    const filters: any = propOr({}, "filters", state);
    // adding flags to the company filters
    return {
      ...state,
      filters: evolve(
        {
          company: (companyFilters) => ({
            ...companyFilters,
            flags: { showServiceProviders: false },
          }),
        },
        filters
      ),
    };
  },
};

const persistConfig = {
  key: "root",
  whitelist: ["filters"],
  version: 4,
  storage,
  migrate: createMigrate(migrations as MigrationManifest, { debug: false }),
};

export default persistConfig;
