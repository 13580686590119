import { differenceInCalendarDays, isAfter } from "date-fns";

const lastColleagueLapsingActivity = (
  lastInteraction?: { date: string; contact: string; colleague: string },
  lastMeeting?: { date: string; contact: string; colleague: string }
): {
  lastActivityLapsed?: number;
  lastColleague?: string;
  lastActivity?: string;
} => {
  let lastActivityLapsed, lastColleague, lastActivity;
  if (lastMeeting?.date && lastInteraction?.date) {
    if (isAfter(new Date(lastMeeting.date), new Date(lastInteraction.date))) {
      lastActivityLapsed = differenceInCalendarDays(
        new Date(),
        new Date(lastMeeting.date)
      );
      lastColleague = lastMeeting.colleague;
      lastActivity = "met";
    } else {
      lastActivityLapsed = differenceInCalendarDays(
        new Date(),
        new Date(lastInteraction.date)
      );
      lastActivity = "interacted";
      lastColleague = lastInteraction.colleague;
    }
  } else if (lastMeeting?.date) {
    lastActivityLapsed = differenceInCalendarDays(
      new Date(),
      new Date(lastMeeting.date)
    );
    lastColleague = lastMeeting.colleague;
    lastActivity = "met";
  } else if (lastInteraction?.date) {
    lastActivityLapsed = differenceInCalendarDays(
      new Date(),
      new Date(lastInteraction.date)
    );
    lastActivity = "interacted";
    lastColleague = lastInteraction.colleague;
  }
  return { lastActivityLapsed, lastColleague, lastActivity };
};

export default lastColleagueLapsingActivity;
