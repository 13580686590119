import classNames from "classnames";
import { ContactEngagementType } from "features/entity/entity.interfaces";
import { InteractionEventType } from "features/interaction/interaction.interface";
import { IMessageInteraction } from "features/message/message.interface";
import enhancedInteractions from "features/interaction/enhancedInteractions.json";

const ContactInteractionIndicators = ({
  interactions,
  engagement,
}: {
  interactions: IMessageInteraction[];
  engagement: ContactEngagementType;
}) => {
  const filteredInteractions = enhancedInteractions.filter((eInteraction) => {
    const interactionSet = new Set(
      interactions.map(({ eventType }) => eventType)
    );
    if (interactionSet.has("privacyRead")) {
      interactionSet.delete("privacyRead");
    }
    if (interactions.some(({ isRevisit }) => isRevisit)) {
      interactionSet.add("revisit");
    }

    return (
      interactionSet.has(eInteraction.id as InteractionEventType) &&
      eInteraction.engagement === engagement
    );
  });

  return (
    <div className="flex flex-row gap-px">
      {filteredInteractions.map(({ bgColor }, i) => (
        <div
          key={i}
          className={classNames("h-1.5 w-1.5 rounded-full", `bg-${bgColor}`)}
        />
      ))}
    </div>
  );
};

export default ContactInteractionIndicators;
