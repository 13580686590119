import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { cubeTokenApi } from "features/cubeToken/cubeTokenApi";

export const refetchCubeToken = createAsyncThunk(
  "cubeToken/refetch",
  async (_, thunkAPI) => {
    const response: any = await thunkAPI.dispatch(
      cubeTokenApi.endpoints.getToken.initiate(undefined, {
        forceRefetch: true,
      })
    );

    if (response.data) {
      const timeout =
        response.data.expiration - Math.floor(new Date().getTime() / 1000);
      setTimeout(() => {
        thunkAPI.dispatch(refetchCubeToken());
      }, (timeout > 0 ? timeout : 5) * 1000);
    }

    return response;
  }
);

const initialState: {
  token?: string;
  expiration?: number;
  restApiUrl?: string;
} = {};

const cubeTokenSlice = createSlice({
  name: "cubeToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refetchCubeToken.fulfilled, (state, action) => {
      if (action.payload.data) {
        return { ...action.payload.data };
      }
    });
  },
});

export const selectCubeToken = (state: RootState) => state.cubeToken;

export default cubeTokenSlice.reducer;
