import { useEffect } from "react";

import Button from "components/Button";
import LogoShort from "assets/LogoShort.svg";

type ErrorType = 404 | 500;

const titles = {
  404: "Page not found (404)",
  500: "Relata is unavailable (500)",
};
const bodies = {
  404: (
    <>
      The page you are looking for cannot be found. <br />
      <br /> A URL may have changed, or you may not have permission to access
      this page.
    </>
  ),
  500: (
    <>
      You can try refreshing the page, or try to access this page again in a few
      minutes time.
    </>
  ),
};
const ErrorPage = ({ type }: { type: ErrorType }) => {
  useEffect(() => {
    document.title = `${titles[type]} | Relata`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen bg-gradient-to-b from-dust-light to-transparent">
      <div className="relative z-40 m-auto flex w-[350px] flex-col items-center justify-center rounded-lg border border-dust-dark bg-white shadow">
        <div className="h-16 w-full rounded-t-lg bg-dust"></div>
        <div className="absolute top-4 flex h-20 w-20 items-center justify-center rounded border border-dust-dark bg-white">
          <img src={LogoShort} className="h-8" alt="logo" />
        </div>
        <div className="mt-8 flex flex-col items-center p-4 align-middle">
          <div className="mt-2 text-xl font-bold text-navy">{titles[type]}</div>
          <div className="mb-12 mt-4 text-center text-lg text-metal">
            {bodies[type]}
          </div>
          <Button
            text="Return to home"
            color="dust"
            size="large"
            block
            onClick={() => (window.location.href = "/")}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
