import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDistanceToNow } from "date-fns";

import { changeBreadcrumb } from "app/breadcrumbSlice";
import { Line, timelineOptions } from "charts";

import { selectCubeLastRefresh } from "app/cubeLastRefreshSlice";
import MiniStatistic from "components/MiniStatistic";
import SkeletonReportContact from "pages/report/components/SkeletonReportContact";
import { formatSeconds } from "utils/dates";

import useActivityInNumbers from "pages/report/hooks/useActivityInNumbers";
import useEmailsBreakdown from "pages/report/hooks/useEmailsBreakdown";
import useMeetingsBreakdown from "pages/report/hooks/useMeetingsBreakdown";
import useEmailInteractionBreakdown from "pages/report/hooks/useEmailInteractionBreakdown";

const ActivityInNumbersReport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
        subsection: "Live Report",
        link: "/report/",
      })
    );
  }, [dispatch]);

  const lastRefreshed = useSelector(selectCubeLastRefresh);
  const { result, isLoading } = useActivityInNumbers();

  const { resultSet: emailsResultSet } = useEmailsBreakdown();
  const emailsBreakdownData = {
    datasets: [
      {
        label: "Emails sent",
        data: emailsResultSet.map((item) => ({
          x: item["dim_email.message_sent_date_time.day"],
          y: item["dim_email.count_sent"],
        })),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: "Emails recieved",
        data: emailsResultSet.map((item) => ({
          x: item["dim_email.message_sent_date_time.day"],
          y: item["dim_email.count_inbound"],
        })),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };
  const { resultSet: meetingsResultSet } = useMeetingsBreakdown();
  const meetingsBreakdownData = {
    datasets: [
      {
        label: "Meetings",
        data: meetingsResultSet.map((item) => ({
          x: item["dim_calendar.start.day"],
          y: item["dim_calendar.count"],
        })),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
    ],
  };

  const { resultSet: interactionsResultSet } = useEmailInteractionBreakdown();
  const interactionBreakdownData = {
    datasets: [
      {
        label: "Reads",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_reads"],
        })),
        borderColor: "#65D398",
        backgroundColor: "#65D398",
      },
      {
        label: "Clicks",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_clicks"],
        })),
        borderColor: "#A3EAE1",
        backgroundColor: "#A3EAE1",
      },
      {
        label: "Replies",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_replies"],
        })),
        borderColor: "#52738F",
        backgroundColor: "#52738F",
      },
      {
        label: "Potential forwards",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_forwards"],
        })),
        borderColor: "#8D7CF5",
        backgroundColor: "#8D7CF5",
      },
      {
        label: "Quick reads",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_quick_reads"],
        })),
        borderColor: "#D1F3E1",
        backgroundColor: "#D1F3E1",
      },
      {
        label: "Glances",
        data: interactionsResultSet.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_email.total_glances"],
        })),
        borderColor: "#FFE871",
        backgroundColor: "#FFE871",
      },
    ],
  };

  if (result === undefined) {
    return <></>;
  }

  const outbound = +result["fact_table.outbound_current"];
  const engaged = +result["fact_table.outbound_engaged_current"];

  const averageEngement =
    outbound > 0 ? ((engaged / outbound) * 100).toFixed(1) : 0;

  const averageMeetingDuration = formatSeconds(
    +result["fact_table.average_meetings_duration_current"]
  );

  return (
    <div className="flex w-full flex-col gap-4 lg:w-[1000px]">
      <div className="min-h-[680px] rounded-lg border border-dust-dark bg-white shadow">
        <div className="relative flex flex-col">
          <div className="sticky top-0 z-10 flex w-full flex-col gap-4 rounded-lg bg-white p-4">
            <div className="flex items-start gap-2">
              <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-br from-green to-sky text-white">
                <i className="icon-star-hollow text-xl" />
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="font-bold text-navy">
                  Your activity in numbers
                </div>
                <div className="flex max-w-full gap-2 text-metal">
                  {lastRefreshed.time
                    ? `Last updated ${formatDistanceToNow(
                        lastRefreshed.time
                      )} ago `
                    : "Live report "}
                  – Changes in your activity and engagement over the previous 90
                  days
                </div>
              </div>
            </div>
            <div className="w-full border-t border-t-dust"></div>
          </div>
          <div className="px-4 pb-4">
            <div className="flex flex-col gap-2" data-testid="emails-stats">
              {isLoading ? (
                <SkeletonReportContact />
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <MiniStatistic
                      icon="icon-email-out"
                      description="Emails sent"
                      value={result["fact_table.outbound_current"]}
                      previous={result["fact_table.outbound_prev"]}
                    />
                    <MiniStatistic
                      icon="icon-email-in"
                      description="Emails received"
                      value={result["fact_table.inbound_current"]}
                      previous={result["fact_table.inbound_prev"]}
                    />
                    <MiniStatistic
                      icon="icon-email-open"
                      description="Average Engagement"
                      value={`${averageEngement}%`}
                    />
                  </div>
                  <div className="w-full rounded-lg border border-dust-dark p-4">
                    <div className="pb-4 text-metal">Emails per day</div>
                    <Line
                      options={timelineOptions}
                      data={emailsBreakdownData}
                    />
                  </div>
                  <div className="w-full rounded-lg border border-dust-dark p-4">
                    <div className="pb-4 text-metal">Interactions per day</div>
                    <Line
                      options={timelineOptions}
                      data={interactionBreakdownData}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className="rounded-lg border border-dust-dark bg-white p-4 shadow">
          <div className="relative flex flex-col">
            <div className="flex flex-col gap-4" data-testid="meetings-stats">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                <MiniStatistic
                  icon="icon-calendar"
                  description="Meetings"
                  value={result["fact_table.meetings_current"]}
                  previous={result["fact_table.meetings_prev"]}
                />
                <MiniStatistic
                  icon="icon-alarm"
                  description="Average duration"
                  value={averageMeetingDuration}
                />
              </div>
              <div className="w-full rounded-lg border border-dust-dark p-4">
                <div className="pb-4 text-metal">Meetings per day</div>
                <Line options={timelineOptions} data={meetingsBreakdownData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityInNumbersReport;
