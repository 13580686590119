import { useEffect, useState } from "react";
import { propOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";

import { changeBreadcrumb } from "app/breadcrumbSlice";
import { showContactModal } from "app/contactModalsSlice";
import Button from "components/Button";
import EntityIdentifier from "components/EntityIdentifier";
import SelectOption from "components/SelectOption";

import useLapsingEngagement from "../hooks/useLapsingEngagement";
import lastColleagueLapsingActivity from "../utils/lastColleagueLapsingActivity";
import { selectCubeLastRefresh } from "app/cubeLastRefreshSlice";
import { formatDistanceToNow } from "date-fns";
import { useGetContactQuery } from "features/entity/entityApi";

const LapsingContact = ({
  id,
  name,
  company,
  email,
  phoneNumbers,
  daysLapsed,
  lastInteraction,
  lastMeeting,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  phoneNumbers: any[];
  daysLapsed: number;
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
}) => {
  const firstName = name.split(" ")[0];
  const dispatch = useDispatch();
  const { data: contact } = useGetContactQuery({
    id,
  });

  const onEmailClick = () =>
    dispatch(
      showContactModal({
        modal: "email",
        contact: { email, fullName: name, phoneNumbers },
      })
    );
  const onFlagClick = () =>
    dispatch(
      showContactModal({
        modal: "flags",
        contact,
      })
    );
  const { lastActivityLapsed, lastColleague, lastActivity } =
    lastColleagueLapsingActivity(lastInteraction, lastMeeting);

  return (
    <div className="flex w-full flex-col rounded border border-l-8 border-dust px-4 py-2 md:flex-row md:items-center">
      <div className="flex h-12 w-[260px] items-center justify-between">
        <EntityIdentifier
          type="contact"
          id={id}
          title={name}
          description={company}
          meta={{ email, phoneNumbers }}
        />
      </div>
      <div className="my-2 w-full border-t border-t-dust md:hidden"></div>
      <div className="flex flex-col gap-2">
        <div className="text-metal">
          You last interacted with {firstName}{" "}
          <span className="font-bold">
            {daysLapsed} {daysLapsed > 1 ? "days" : "day"} ago.
          </span>
        </div>
        {lastActivityLapsed && lastActivityLapsed < daysLapsed ? (
          <div className="text-metal">
            <span className="font-bold">{lastColleague}</span> {lastActivity}{" "}
            with {firstName}{" "}
            <span className="font-bold">
              {lastActivityLapsed} {lastActivityLapsed > 1 ? "days" : "day"}{" "}
              ago.
            </span>
          </div>
        ) : (
          <div className="text-metal">
            No colleagues have interacted with {firstName} since.
          </div>
        )}
      </div>
      <div className="flex gap-2 pb-2 pt-4 md:ml-auto md:flex-row-reverse md:p-0">
        <Button
          color="dust"
          text="Email now"
          icon="icon-email"
          onClick={onEmailClick}
        />
        <Button
          color="transparent"
          icon="icon-flag"
          onClick={onFlagClick}
          tooltip="Flag contact"
        />
      </div>
    </div>
  );
};

const SkeletonLapsingContact = () => (
  <div className="flex w-full flex-col rounded border border-l-8 border-dust px-4 py-2 md:flex-row md:items-center md:justify-between">
    <div className="flex h-12 gap-2 md:items-center md:justify-between">
      <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
      <div className="flex flex-col gap-1">
        <div className="h-4 w-[120px] animate-pulse rounded-sm bg-dust-light"></div>
        <div className="h-4 w-[120px] animate-pulse rounded-sm bg-dust"></div>
      </div>
    </div>
    <div className="flex flex-col gap-1 md:ml-16">
      <div className="h-4 w-[317px] animate-pulse rounded-sm bg-dust"></div>
      <div className="h-4 w-[290px] animate-pulse rounded-sm bg-dust-light"></div>
    </div>
    <div className="block pb-2 pt-4 md:ml-auto md:p-0">
      <div className="flex h-8 w-[120px] animate-pulse items-center justify-center rounded bg-dust"></div>
    </div>
  </div>
);

const LapsingEngagementReport = () => {
  const [relevantOption, setRelevantOption] = useState("relevant");
  const [timeOption, setTimeOption] = useState("90");
  const [orderOption, setOrderOption] = useState("most-relevant");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
        subsection: "Live Report",
        link: "/report/",
      })
    );
  }, [dispatch]);

  const { resultSet, isLoading } = useLapsingEngagement({
    relevantOption,
    timeOption,
    orderOption,
  });
  const lastRefreshed = useSelector(selectCubeLastRefresh);

  return (
    <div className="min-h-[680px] w-full overflow-scroll rounded-lg border border-dust-dark bg-white shadow lg:h-[680px] lg:w-[1000px]">
      <div className="relative flex flex-col">
        <div className="sticky top-0 z-10 flex w-full flex-col gap-4 rounded-lg bg-white p-4">
          <div className="flex items-start gap-2">
            <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-b from-sky to-sky-dark text-white">
              <i className="icon-contact text-xl" />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="font-bold text-navy">Lapsing engagement</div>
              <div className="flex max-w-full flex-col text-metal md:flex-row md:gap-2">
                {lastRefreshed.time
                  ? `Last updated ${formatDistanceToNow(
                      lastRefreshed.time
                    )} ago `
                  : "Live report "}
                – Strong contacts who you haven't interacted with recently
              </div>
            </div>
          </div>
          <div className="w-full border-t border-t-dust"></div>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
            <div className="col-span-1 lg:col-span-2">
              <SelectOption
                disabled={isLoading}
                options={[
                  {
                    value: "relevant",
                    label:
                      "Showing relevant contacts from your personal network",
                  },
                  {
                    value: "all",
                    label: "Showing all contacts from your personal network",
                  },
                ]}
                value={relevantOption}
                onChange={setRelevantOption}
              />
            </div>
            <SelectOption
              disabled={isLoading}
              options={[
                {
                  value: "3",
                  label: "Last contacted over 3 days ago",
                },
                {
                  value: "7",
                  label: "Last contacted over 7 days ago",
                },
                {
                  value: "30",
                  label: "Last contacted over 30 days ago",
                },
                {
                  value: "90",
                  label: "Last contacted over 90 days ago",
                },
                {
                  value: "180",
                  label: "Last contacted over 180 days ago",
                },
              ]}
              value={timeOption}
              onChange={setTimeOption}
            />
            <SelectOption
              disabled={isLoading}
              options={[
                {
                  value: "most-relevant",
                  label: "Most relevant first",
                },
                {
                  value: "least-lapsed",
                  label: "Least lapsed engagement first",
                },
                {
                  value: "most-lapsed",
                  label: "Most lapsed engagement first",
                },
              ]}
              value={orderOption}
              onChange={setOrderOption}
            />
          </div>
        </div>
        <div className="px-4 pb-4">
          <div className="flex flex-col gap-2">
            {isLoading ? (
              <SkeletonLapsingContact />
            ) : (
              resultSet.map((contact) => (
                <LapsingContact
                  key={contact["dim_contact.uuid"] as string}
                  id={contact["dim_contact.uuid"] as string}
                  name={contact["dim_contact.full_name"] as string}
                  company={contact["dim_company.display_name"] as string}
                  email={contact["dim_contact.email"] as string}
                  phoneNumbers={JSON.parse(
                    propOr("[]", "dim_contact.phone_numbers", contact)
                  )}
                  daysLapsed={
                    +contact["fact_table.days_since_last_interaction"]
                  }
                  lastInteraction={JSON.parse(
                    propOr(
                      "{}",
                      "fact_table.last_interaction_details",
                      contact.viewer
                    )
                  )}
                  lastMeeting={JSON.parse(
                    propOr(
                      "{}",
                      "fact_table.last_interaction_details",
                      contact.viewer
                    )
                  )}
                />
              ))
            )}
            {!isLoading && resultSet.length === 0 && (
              <div className="mt-40 flex flex-col items-center justify-center">
                <i className="icon-face-happy text-3xl text-navy"></i>
                <div className="text-xl font-bold text-navy">
                  No lapsing contacts
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LapsingEngagementReport;
