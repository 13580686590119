import { useState } from "react";
import { useParams } from "react-router-dom";
import { find, pluck, propEq, propOr } from "ramda";
import { useSelector } from "react-redux";

import { useGetUserQuery } from "features/profile/profileApi";
import { useCubeQuery } from "@cubejs-client/react";
import useEntityTimeSeries from "features/entity/useEntityTimeSeries";

import FilterButton from "components/FilterButton";

import { Line, Bar, PieChart } from "charts";
import {
  chartOptions,
  pieOptions,
  timeOnYOptions,
} from "charts/defaultOptions";
import { SelectionFrame } from "components/frames";
import RelationshipTables from "components/RelationshipTables";
import { selectCubeFilters } from "app/filtersSlice";
import CubeLastRefresh from "components/CubeLastRefresh";
import { beginningOfMonthsAgo } from "utils/dates";
import { format } from "date-fns";
import { TooltipItem } from "chart.js";
import { responseBreakdownColors } from "utils/colorMapping";

const contactRelationshipMonthRanges = [3, 6, 12];

const percentagePieOptions = {
  ...pieOptions,
  plugins: {
    ...pieOptions.plugins,
    tooltip: {
      ...pieOptions.plugins.tooltip,
      callbacks: {
        label: (tooltipItem: TooltipItem<any>) => {
          return `${tooltipItem.formattedValue}%`;
        },
      },
    },
  },
};

const relationshipMetricOptions = [
  {
    value: "fact_relationship_score.relationship_score",
    label: "Relationship score",
    legend: "relationship score",
  },
  { value: "fact_email.effort", label: "Effort", legend: "effort" },
  {
    value: "fact_email.interest",
    label: "Interest",
    legend: "interest",
  },
];

export const emailsMetricOptions = [
  {
    value: "fact_email.outbound",
    label: "Sent emails",
    legend: "Sent by",
  },
  {
    value: "fact_email.inbound",
    label: "Received emails",
    legend: "Received by",
  },
  {
    value: "fact_email.unsolicited_inbound",
    label: "Unprompted inbound",
    legend: "Received by",
  },
  {
    value: "dim_calendar.count",
    label: "Meetings",
    legend: "Attended by",
  },
];

export const responsetimeMetricOptions = [
  {
    value: "fact_email.avg_reply_out_response_time",
    label: "Average response time to them",
    legend: "response time",
  },
  {
    value: "fact_email.avg_reply_response_time",
    label: "Average response time from them",
    legend: "response time",
  },
];

export const responsebreakdownMetricOptions = [
  {
    value: "fact_email.response_out_breakdown",
    label: "Response time breakdown to them",
  },
  {
    value: "fact_email.response_breakdown",
    label: "Response time breakdown from them",
  },
];

const ContactRelationshipView = () => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);

  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { results, viewerResults, isLoading } = useEntityTimeSeries({
    contactId: params.id as string,
    additionalFilters: cubeFilters,
    user,
  });

  const getTimeLabels = (monthsAgo: number) => {
    return pluck("time.month", results || [])
      .filter(
        (date) => new Date(date as string) >= beginningOfMonthsAgo(monthsAgo)
      )
      .map((date) =>
        new Date(date as string).toLocaleString("default", { month: "long" })
      );
  };

  const [relationshipMonthRange, setRelationshipMonthRange] = useState(6);
  const [relationshipMetric, setRelationshipMetric] = useState(
    "fact_relationship_score.relationship_score"
  );

  const activeRelationshipLegend = propOr(
    "",
    "legend",
    find(propEq("value", relationshipMetric), relationshipMetricOptions)
  );

  const relationshipMetricsData = {
    labels: getTimeLabels(relationshipMonthRange),
    datasets: [
      {
        label: `Your ${activeRelationshipLegend}`,
        data: viewerResults
          ?.slice(-1 * (1 + relationshipMonthRange))
          .map((result) => propOr("", relationshipMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `All company ${activeRelationshipLegend}`,
        data: results
          ?.slice(-1 * (1 + relationshipMonthRange))
          .map((result) => propOr("", relationshipMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [emailsMonthRange, setEmailsMonthRange] = useState(6);
  const [emailsMetric, setEmailsMetric] = useState("fact_email.outbound");
  const activeEmailLegend = propOr(
    "",
    "legend",
    find(propEq("value", emailsMetric), emailsMetricOptions)
  );

  const emailsMetricData = {
    labels: getTimeLabels(emailsMonthRange),
    datasets: [
      {
        label: `${activeEmailLegend} you`,
        data: viewerResults
          ?.slice(-1 * (1 + emailsMonthRange))
          .map((result) => propOr("", emailsMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `${activeEmailLegend} colleagues`,
        data: results
          ?.slice(-1 * (1 + emailsMonthRange))
          .map((result) => propOr("", emailsMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [responsetimeMonthRange, setResponsetimeMonthRange] = useState(6);
  const [responsetimeMetric, setResponsetimeMetric] = useState(
    "fact_email.avg_reply_out_response_time"
  );
  const responsetimeLegend = propOr(
    "",
    "legend",
    find(propEq("value", responsetimeMetric), responsetimeMetricOptions)
  );

  const responsetimeMetricData = {
    labels: getTimeLabels(responsetimeMonthRange),
    datasets: [
      {
        label: `Your ${responsetimeLegend}`,
        data: viewerResults
          ?.slice(-1 * (1 + responsetimeMonthRange))
          .map((result) => propOr("", responsetimeMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `All colleague ${responsetimeLegend}`,
        data: results
          ?.slice(-1 * (1 + responsetimeMonthRange))
          .map((result) => propOr("", responsetimeMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [responsebreakdownMonthRange, setResponsebreakdownMonthRange] =
    useState(6);

  const { resultSet: responsebreakdownResultSet } = useCubeQuery({
    measures: [
      "fact_email.response_out_within_24h",
      "fact_email.response_out_gt_5",
      "fact_email.response_out_within_5d",
      "fact_email.response_within_24h",
      "fact_email.response_gt_5",
      "fact_email.response_within_5d",
    ],
    filters: [
      {
        member: "dim_contact.uuid",
        operator: "equals",
        values: [params.id as string],
      },
      {
        dimension: "dim_date.date_actual",
        operator: "inDateRange",
        values: [
          format(
            beginningOfMonthsAgo(responsebreakdownMonthRange || 6),
            "yyyy-MM-dd"
          ),
          format(new Date(), "yyyy-MM-dd"),
        ],
      },
      ...cubeFilters,
    ],
  });
  const responsebreakdownData = responsebreakdownResultSet?.tablePivot() || [];

  const defaultResponseBreakdownMetric = "fact_email.response_out_breakdown";
  const [responsebreakdownMetric, setResponsebreakdownMetric] = useState(
    defaultResponseBreakdownMetric
  );
  const responsebreakdownMetricLabels = ["< 24h", "1-5 days", "> 5 days"];
  const suffix =
    responsebreakdownMetric === defaultResponseBreakdownMetric ? "_out" : "";

  const mmm = responsebreakdownData.map((resultSet) => {
    const soon = +resultSet[`fact_email.response${suffix}_within_24h`] || 0;
    const mid = +resultSet[`fact_email.response${suffix}_within_5d`] || 0;
    const late = +resultSet[`fact_email.response${suffix}_gt_5`] || 0;
    const all = soon + mid + late;
    return all > 0
      ? [
          ((soon / all) * 100).toFixed(2),
          ((mid / all) * 100).toFixed(2),
          ((late / all) * 100).toFixed(2),
        ]
      : [];
  });
  const responsebreakdownMetricValues = mmm.length > 0 ? mmm[0] : [];

  const responsebreakdownMetricData = {
    labels: responsebreakdownMetricLabels,
    datasets: [
      {
        data: responsebreakdownMetricValues,
        backgroundColor: Object.keys(responsebreakdownMetricLabels).map((key) =>
          propOr("#65D398", key, responseBreakdownColors)
        ),
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <SelectionFrame
            options={relationshipMetricOptions}
            value={relationshipMetric}
            onChange={setRelationshipMetric}
            monthRanges={contactRelationshipMonthRanges}
            monthRangeValue={relationshipMonthRange}
            onMonthRangeChange={setRelationshipMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Line options={chartOptions} data={relationshipMetricsData} />
          </SelectionFrame>
          <SelectionFrame
            options={emailsMetricOptions}
            value={emailsMetric}
            onChange={setEmailsMetric}
            monthRanges={contactRelationshipMonthRanges}
            monthRangeValue={emailsMonthRange}
            onMonthRangeChange={setEmailsMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Bar options={chartOptions} data={emailsMetricData} />
          </SelectionFrame>
          <SelectionFrame
            options={responsetimeMetricOptions}
            value={responsetimeMetric}
            onChange={setResponsetimeMetric}
            monthRanges={contactRelationshipMonthRanges}
            monthRangeValue={responsetimeMonthRange}
            onMonthRangeChange={setResponsetimeMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Line options={timeOnYOptions} data={responsetimeMetricData} />
          </SelectionFrame>
          <SelectionFrame
            options={responsebreakdownMetricOptions}
            value={responsebreakdownMetric}
            onChange={setResponsebreakdownMetric}
            monthRanges={contactRelationshipMonthRanges}
            monthRangeValue={responsebreakdownMonthRange}
            onMonthRangeChange={setResponsebreakdownMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart
                options={percentagePieOptions}
                data={responsebreakdownMetricData}
              />
            </div>
          </SelectionFrame>
          <div className="col-span-1 xl:col-span-2">
            <RelationshipTables contactId={params.id as string} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactRelationshipView;
