import { Permissions } from "features/profile";
import { useGetUserQuery } from "features/profile/profileApi";

const PermissionGuard = ({
  permission,
  children,
  denied,
}: {
  permission: Permissions;
  children: JSX.Element;
  denied?: JSX.Element;
}): JSX.Element => {
  const { data: user } = useGetUserQuery();
  if (user?.permissions.includes(permission)) {
    return children;
  }
  return denied || <></>;
};
export default PermissionGuard;
