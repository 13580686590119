export const relationshipMetricOptions = [
  {
    value: "fact_relationship_score.relationship_score",
    label: "Relationship score",
    legend: "relationship score",
  },
  {
    value: "fact_email.effort",
    label: "Effort points",
    legend: "effort points",
  },
  {
    value: "fact_email.interest",
    label: "Interest points",
    legend: "interest points",
  },
];

export const emailsMetricOptions = [
  {
    value: "fact_email.outbound",
    label: "Sent emails",
    legend: "Sent by",
  },
  {
    value: "fact_email.inbound",
    label: "Received emails",
    legend: "Received by",
  },
  {
    value: "fact_email.unsolicited_inbound",
    label: "Unprompted inbound",
    legend: "Received by",
  },
  {
    value: "dim_calendar.count",
    label: "Meetings",
    legend: "Attended by",
  },
];

export const contactsMetricOptions = [
  {
    value: "fact_time_series.contact_count",
    label: "New contacts",
    legend: "New to",
  },
  {
    value: "dim_contact.count",
    label: "Interacting contacts",
    legend: "Interacting with",
  },
];

export const teamMetricOptions = [
  {
    value: "fact_email.outbound",
    label: "% emails sent by team",
  },
  {
    value: "fact_email.inbound",
    label: "% emails received by team",
  },
];

export const responsetimeMetricOptions = [
  {
    value: "fact_email.avg_reply_out_response_time",
    label: "Average response time to them",
    legend: "response time",
  },
  {
    value: "fact_email.avg_reply_response_time",
    label: "Average response time from them",
    legend: "response time",
  },
];

export const responsebreakdownMetricOptions = [
  {
    value: "fact_email.response_out_breakdown",
    label: "Response time breakdown to them",
  },
  {
    value: "fact_email.response_breakdown",
    label: "Response time breakdown from them",
  },
];
