import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";
import {
  IEnhancedEvent,
  IInteraction,
  IInteractionResponse,
  IInteractionStatsResponse,
  InteractionEventType,
} from "features/interaction/interaction.interface";
import enhancedInteractions from "./enhancedInteractions.json";
import { SearchTargetType } from "pages/track/email/EmailSearch";
import { trackOptionsType, trackSearchParams } from "utils/trackOptions";

const transformInteractions = (
  response: IInteractionResponse,
  linkId?: string
): IInteractionResponse => {
  const interactions = camelizeKeys(response.results) as IInteraction[];
  const linkClickInteraction = {
    ...(enhancedInteractions.find(
      (eInteraction) => eInteraction.id === "click"
    ) as IEnhancedEvent),
    verb: "clicked on the link in",
  };

  return {
    ...response,
    results: interactions.map((interaction: IInteraction) => ({
      ...interaction,
      eventType: enhancedInteractions.find(
        (eInteraction) => eInteraction.rawLabel === interaction.eventType
      )?.id,
      enhancedEvent: linkId
        ? linkClickInteraction
        : enhancedInteractions.find(
            (eInteraction) => eInteraction.rawLabel === interaction.eventType
          ),
    })) as IInteraction[],
  } as IInteractionResponse;
};

export const interactionApi = createApi({
  reducerPath: "interaction",
  baseQuery: prepareBaseQuery({
    baseUrl: "/track",
  }),
  endpoints: (builder) => ({
    getInteractionCount: builder.query<
      { count: number },
      {
        interactionsSinceTimestamp: string;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ interactionsSinceTimestamp, trackOptions }) => {
        const searchParams = new URLSearchParams();
        searchParams.append(
          "interactions_since_timestamp",
          interactionsSinceTimestamp
        );
        const searchParamsWithTrackOptions = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/interactions/count?" + searchParamsWithTrackOptions;
      },
    }),
    getInteractionsStats: builder.query<
      IInteractionStatsResponse,
      {
        search?: string;
        searchTarget?: SearchTargetType;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({ search, searchTarget, trackOptions }) => {
        const searchParams = new URLSearchParams();
        if (search) {
          searchParams.append("search_text", search);
        }
        if (search && searchTarget === "messageSubject") {
          searchParams.append("only_email_subject", "true");
        }
        if (search && searchTarget === "fullName") {
          searchParams.append("only_contact_name", "true");
        }
        const searchParamsWithTrackOptions = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/interactions/stats?" + searchParamsWithTrackOptions;
      },
      transformResponse: (response: any): IInteractionStatsResponse =>
        camelizeKeys(response) as IInteractionStatsResponse,
    }),
    getInteractions: builder.query<
      IInteractionResponse,
      {
        cursor?: string;
        search?: string;
        searchTarget?: SearchTargetType;
        eventType?: InteractionEventType;
        messageId?: string;
        linkId?: string;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({
        search,
        searchTarget,
        cursor,
        eventType,
        messageId,
        linkId,
        trackOptions,
      }) => {
        const searchParams = new URLSearchParams();
        if (cursor) return cursor;
        if (search) {
          searchParams.append("search_text", search);
        }
        if (messageId) {
          searchParams.append("message_id", messageId);
        }
        if (eventType) {
          const rawEvent = enhancedInteractions.find(
            (eInteraction) => eInteraction.id === eventType
          )?.rawLabel as string;
          searchParams.append("event_type", rawEvent);
        }
        if (search && searchTarget === "messageSubject") {
          searchParams.append("only_email_subject", "true");
        }
        if (search && searchTarget === "fullName") {
          searchParams.append("only_contact_name", "true");
        }
        if (linkId) {
          searchParams.append("link_id", linkId);
        }
        const searchParamsWithTrackOptions = trackSearchParams(
          searchParams,
          trackOptions
        );
        return "/interactions?" + searchParamsWithTrackOptions;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const {
          search,
          searchTarget,
          eventType,
          messageId,
          linkId,
          trackOptions,
        } = queryArgs;
        return {
          search,
          searchTarget,
          eventType,
          messageId,
          linkId,
          trackOptions,
        };
      },
      merge: (currentCache, newResponse, { arg }) => {
        if (!arg.cursor) {
          currentCache = newResponse;
        } else {
          currentCache.next = newResponse.next;
          currentCache.results.push(...newResponse.results);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        response: IInteractionResponse,
        meta,
        { linkId }
      ): IInteractionResponse => transformInteractions(response, linkId),
    }),
    getNewInteractions: builder.query<
      IInteractionResponse,
      {
        search?: string;
        searchTarget?: SearchTargetType;
        eventType?: InteractionEventType;
        messageId?: string;
        linkId?: string;
        happenedAfter?: string;
        round?: number;
        trackOptions?: trackOptionsType;
      }
    >({
      query: ({
        search,
        searchTarget,
        eventType,
        messageId,
        happenedAfter,
        trackOptions,
      }) => {
        const searchParams = new URLSearchParams();
        if (search) {
          searchParams.append("search_text", search);
        }
        if (messageId) {
          searchParams.append("message_id", messageId);
        }
        if (eventType) {
          const rawEvent = enhancedInteractions.find(
            (eInteraction) => eInteraction.id === eventType
          )?.rawLabel as string;
          searchParams.append("event_type", rawEvent);
        }
        if (search && searchTarget === "messageSubject") {
          searchParams.append("only_email_subject", "true");
        }
        if (search && searchTarget === "fullName") {
          searchParams.append("only_contact_name", "true");
        }
        if (happenedAfter) {
          searchParams.append("happened_after", happenedAfter);
        }
        const searchParamsWithTrackOptions = trackSearchParams(
          searchParams,
          trackOptions
        );

        return "/interactions?" + searchParamsWithTrackOptions;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const {
          search,
          searchTarget,
          eventType,
          messageId,
          round,
          trackOptions,
        } = queryArgs;
        return {
          search,
          searchTarget,
          eventType,
          messageId,
          round,
          trackOptions,
        };
      },
      merge: (currentCache, newResponse) => {
        if (!newResponse.next) {
          currentCache.results = [
            ...newResponse.results,
            ...currentCache.results,
          ];
        }
        currentCache.next = newResponse.next;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        response: IInteractionResponse,
        meta,
        { linkId }
      ): IInteractionResponse => transformInteractions(response, linkId),
    }),
  }),
});

export const {
  useGetInteractionsQuery,
  useGetNewInteractionsQuery,
  useGetInteractionsStatsQuery,
  useGetInteractionCountQuery,
} = interactionApi;
