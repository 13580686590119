import {
  ContactFlagType,
  ICompanyDetail,
  IContactDetail,
} from "features/entity/entity.interfaces";
import { find, propEq, propOr } from "ramda";

export const getFlagValue = (
  entity: IContactDetail | ICompanyDetail,
  flag: string
): boolean => {
  return propOr(false, "value", getFlag(entity, flag)) as boolean;
};

export const getFlagDateEffective = (
  entity: IContactDetail | ICompanyDetail,
  flag: ContactFlagType
): undefined | string => {
  return propOr(undefined, "dateEffective", getFlag(entity, flag));
};

export const getFlag = (
  entity: Partial<IContactDetail> | Partial<ICompanyDetail> | undefined,
  flag: string
) =>
  entity && entity.flags
    ? find(propEq("flag", flag), entity.flags as any)
    : undefined;
