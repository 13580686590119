import { ILensGroup } from "features/lens/lensApi";
import { CheckboxSection } from "pages/explore/modals/FiltersModal/FiltersModalSections";
import toggleListItem from "utils/toggleListItem";
import { CustomFiltersType } from "app/filtersSlice";
import { mergeDeepRight } from "ramda";

const CustomFiltersTab = ({
  lenses,
  selected,
  updateSelected,
}: {
  lenses: ILensGroup[];
  selected: CustomFiltersType;
  updateSelected: (updated: CustomFiltersType) => void;
}) => (
  <>
    <div className="mb-4 text-metal xl:w-[350px]">
      Custom Filters have been set up specifically for your organisation.
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-2">
      {lenses.map((lensGroup) => {
        const selectedGroup = selected[lensGroup.id] || [];
        return (
          <CheckboxSection
            title={lensGroup.name}
            key={lensGroup.id}
            onUpdate={(id: string) => {
              const selectedInGroup = toggleListItem(+id, selectedGroup);
              updateSelected(
                mergeDeepRight(selected, {
                  [lensGroup.id]: selectedInGroup,
                }) as CustomFiltersType
              );
            }}
            options={[
              ...lensGroup.lenses.map((lens) => ({
                label: `Show ${lens.name}`,
                checked: selectedGroup.includes(+lens.id),
                key: +lens.id,
              })),
              {
                label: `Show ${lensGroup.otherLabel}`,
                checked: selectedGroup.includes(-1 * +lensGroup.id),
                key: -1 * +lensGroup.id,
              },
            ]}
          />
        );
      })}
    </div>
  </>
);

export default CustomFiltersTab;
