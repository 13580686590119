import {
  find,
  filter,
  propEq,
  propOr,
  pluck,
  sortBy,
  prop,
  pathOr,
} from "ramda";
import { camelizeKeys } from "humps";

import { activityColors, trendColors } from "utils/colorMapping";
import { formatDifferenceFromNow, lenghtOfPeriodFromNow } from "utils/dates";
import trend from "utils/trend";

import { useGetUserQuery } from "features/profile/profileApi";
import { useGetWatchlistQuery } from "./watchlistApi";
import { IWatchListItem, IWatchListItemKPI } from "./watchlist.interfaces";
import useExtraCube from "utils/hooks/useExtraCube";
import {
  ActivityType,
  IPhoneNumber,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";

const cubeMeasures = [
  "fact_table.relationship_score_current",
  "fact_table.interest_current",
  "fact_table.interest_prev",
  "fact_table.effort_current",
  "fact_table.effort_prev",
  "fact_table.segment",
  "fact_table.trend",
  "fact_table.colleague_count_current",
  "fact_table.last_interaction_details",
  "fact_table.last_meeting_details",
  "fact_table.first_interaction",
  "fact_table.activity",
  "fact_table.inbound_current",
  "fact_table.outbound_current",
];

const secondaryMeasures = [
  "fact_table.relationship_score_current",
  "fact_table.trend",
  "fact_table.activity",
  "fact_table.inbound_current",
  "fact_table.outbound_current",
  "fact_table.last_interaction_details",
  "fact_table.last_meeting_details",
  "fact_table.interest_current",
  "fact_table.effort_current",
];

const parseMeasure = (name: string, measures: any) =>
  JSON.parse(propOr("{}", name, measures));

const formatKPI = (measures?: object): IWatchListItemKPI[] | undefined => {
  if (!measures) {
    return;
  }

  const viewerInterest = pathOr(
    0,
    ["viewer", "fact_table.interest_current"],
    measures
  );
  const currentInterest: number = propOr(
    0,
    "fact_table.interest_current",
    measures
  );
  const prevInterest: number = propOr(0, "fact_table.interest_prev", measures);

  const viewerEffort = pathOr(
    0,
    ["viewer", "fact_table.effort_current"],
    measures
  );
  const currentEffort: number = propOr(
    0,
    "fact_table.effort_current",
    measures
  );
  const prevEffort: number = propOr(0, "fact_table.effort_prev", measures);

  const firstInteraction = propOr(
    null,
    "fact_table.first_interaction",
    measures
  );

  const viewerLastInteraction = JSON.parse(
    pathOr("{}", ["viewer", "fact_table.last_interaction_details"], measures)
  );

  const lastInteraction = parseMeasure(
    "fact_table.last_interaction_details",
    measures
  );

  const viewerLastMeeting = JSON.parse(
    pathOr("{}", ["viewer", "fact_table.last_meeting_details"], measures)
  );

  const lastMeeting = parseMeasure("fact_table.last_meeting_details", measures);

  const viewerActivity: ActivityType = pathOr(
    ActivityType.Undefined,
    ["viewer", "fact_table.activity"],
    measures
  );

  const activity: ActivityType = propOr(
    ActivityType.Undefined,
    "fact_table.activity",
    measures
  );

  const segment: SegmentType = propOr(
    SegmentType.Historic,
    "fact_table.segment",
    measures
  );

  const relationshipTrend: string = propOr(
    TrendType.Consistent,
    "fact_table.trend",
    measures
  );

  const viewerRelationshipTrend = pathOr(
    String(TrendType.Consistent),
    ["viewer", "fact_table.trend"],
    measures
  );

  return [
    {
      type: "progress-bar",
      field: "viewerRelationshipScore",
      meta: {
        title: "Your relationship score",
        value: pathOr(
          0,
          ["viewer", "fact_table.relationship_score_current"],
          measures
        ),
        color: propOr("green", viewerRelationshipTrend, trendColors),
        trend: TrendType[+viewerRelationshipTrend],
      },
    },
    {
      type: "progress-bar",
      field: "relationshipScore",
      meta: {
        title: "All company relationship score",
        value: propOr(0, "fact_table.relationship_score_current", measures),
        color: propOr("green", relationshipTrend, trendColors),
        trend: TrendType[+relationshipTrend],
      },
    },
    {
      type: "interaction",
      field: "viewerLastInteraction",
      meta: {
        title: "Your last interaction",
        when: viewerLastInteraction.date
          ? formatDifferenceFromNow(new Date(viewerLastInteraction.date))
          : "-",
        tag: {
          color: propOr(2, String(viewerActivity), activityColors),
          label:
            viewerActivity === ActivityType.Undefined
              ? ""
              : ActivityType[viewerActivity],
        },
      },
    },
    {
      type: "text",
      field: "relationshipLength",
      meta: {
        title: "Length of relationship",
        label: firstInteraction
          ? `> ${lenghtOfPeriodFromNow(new Date(firstInteraction as string))}`
          : "-",
      },
    },
    {
      type: "interaction",
      field: "colleagueLastInteraction",
      meta: {
        title: "Recent colleague interaction",
        when:
          lastInteraction.date &&
          formatDifferenceFromNow(new Date(lastInteraction.date)),
        who: lastInteraction.colleague,
        tag: {
          color: propOr(
            null,
            propOr(null, "fact_table.activity", measures),
            activityColors
          ),
          label: ActivityType[activity],
        },
      },
    },
    {
      type: "trend",
      field: "interest",
      meta: {
        title: "Interest points",
        trend: trend(currentInterest, prevInterest),
        value: currentInterest,
        secondaryLabel: "You",
        secondaryValue: viewerInterest,
      },
    },
    {
      type: "trend",
      field: "effort",
      meta: {
        title: "Effort points",
        trend: trend(currentEffort, prevEffort),
        value: currentEffort,
        secondaryLabel: "You",
        secondaryValue: viewerEffort,
      },
    },
    {
      type: "text",
      field: "segment",
      meta: {
        title: "Segment",
        label: SegmentType[segment],
      },
    },
    {
      type: "text",
      field: "colleaguesInteracting",
      meta: {
        title: "# colleagues interacting",
        label: propOr(0, "fact_table.colleague_count_current", measures),
      },
    },
    {
      type: "email-breakdown",
      field: "viewerEmailBreakdown",
      meta: {
        title: "Your email breakdown",
        emailsIn: pathOr(0, ["viewer", "fact_table.inbound_current"], measures),
        emailsOut: pathOr(
          0,
          ["viewer", "fact_table.outbound_current"],
          measures
        ),
      },
    },
    {
      type: "email-breakdown",
      field: "allEmailBreakdown",
      meta: {
        title: "All email breakdown",
        emailsIn: propOr(0, "fact_table.inbound_current", measures),
        emailsOut: propOr(0, "fact_table.outbound_current", measures),
      },
    },
    {
      type: "text",
      field: "yourLastMeeting",
      meta: {
        title: "Your recent meeting",
        label: viewerLastMeeting.date
          ? formatDifferenceFromNow(new Date(viewerLastMeeting.date))
          : "-",
      },
    },
    {
      type: "interaction",
      field: "colleagueLastMeeting",
      meta: {
        title: "Recent colleague meeting",
        who: lastMeeting.colleague,
        when: lastMeeting.date
          ? formatDifferenceFromNow(new Date(lastMeeting.date))
          : "-",
      },
    },
  ];
};

const useWatchlistWithCube = (active: string[]) => {
  const { data, isLoading, isError, error } = useGetWatchlistQuery();
  const { data: user } = useGetUserQuery();

  const pluckEntityUUID = pluck("entityUuid");

  const contactIds = pluckEntityUUID(
    filter(propEq("entityType", "contact"), data || [])
  );
  const { resultSet: contactsKPI, isLoading: contactIsLoading } = useExtraCube(
    {
      dimensions: [
        "dim_contact.uuid",
        "dim_contact.email",
        ...(user?.showContactSignatures ? ["dim_contact.phone_numbers"] : []),
      ],
      measures: cubeMeasures,
      filters: [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: contactIds,
        },
      ],
    },
    {
      skip: contactIds.length === 0,
      secondary: {
        key: "dim_contact.uuid",
        measures: secondaryMeasures,
        filters: [
          {
            member: "dim_user.email",
            operator: "equals",
            values: [user?.email || ""],
          },
        ],
      },
    }
  );

  const companyIds = pluckEntityUUID(
    filter(propEq("entityType", "company"), data || [])
  );
  const { resultSet: companiesKPI, isLoading: companyIsLoading } = useExtraCube(
    {
      dimensions: ["dim_company.uuid"],
      measures: cubeMeasures,
      filters: [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: companyIds,
        },
      ],
    },
    {
      skip: companyIds.length === 0,
      secondary: {
        key: "dim_company.uuid",
        measures: secondaryMeasures,
        filters: [
          {
            member: "dim_user.email",
            operator: "equals",
            values: [user?.email || ""],
          },
        ],
      },
    }
  );

  const getMeasures = (uuid: string, type: string) => {
    if (type === "contact") {
      return find(propEq("dim_contact.uuid", uuid), (contactsKPI as any) || []);
    } else {
      return find(
        propEq("dim_company.uuid", uuid),
        (companiesKPI as any) || []
      );
    }
  };

  const watchlist = data?.map((item: IWatchListItem) => ({
    ...item,
    email: propOr(
      "",
      "dim_contact.email",
      getMeasures(item.entityUuid, item.entityType)
    ) as string,
    phoneNumbers: camelizeKeys(
      propOr(
        [],
        "dim_contact.phone_numbers",
        getMeasures(item.entityUuid, item.entityType)
      )
    ) as IPhoneNumber[],
    kpi: formatKPI(getMeasures(item.entityUuid, item.entityType))?.filter(
      ({ field }) => field && active.includes(field)
    ),
  }));

  return {
    watchlist: sortBy(prop("title"), watchlist || []),
    isLoading: isLoading || contactIsLoading || companyIsLoading,
    isError,
    error,
  };
};

export { useWatchlistWithCube };
