import classnames from "classnames";

export type DataTagType =
  | "increase"
  | "decrease"
  | "consistent"
  | "positive"
  | "at-risk";

type DataTagColor = "green" | "red" | "gray";

const DataTag = ({
  type,
  label,
  color,
}: {
  type: DataTagType;
  label: any;
  color?: DataTagColor;
}) => (
  <div
    className={classnames(
      "flex h-[18px] items-center gap-1 whitespace-nowrap rounded px-1",
      {
        "bg-green-lightest text-green-darkest":
          ((type === "increase" || type === "positive") && !color) ||
          color === "green",
        "bg-red-lightest text-red-darkest":
          ((type === "decrease" || type === "at-risk") && !color) ||
          color === "red",
        "bg-dust text-metal":
          (type === "consistent" && !color) || color === "gray",
      }
    )}
  >
    <i
      className={classnames({
        "icon-up": type === "increase",
        "icon-down": type === "decrease",
        "icon-maintain": type === "consistent",
        "icon-check": type === "positive",
        "icon-warning-hex": type === "at-risk",
      })}
    ></i>
    <div className="pr-1 text-sm font-bold">{label}</div>
  </div>
);

export default DataTag;
