import { useCubeQuery } from "@cubejs-client/react";
import { format, subDays } from "date-fns";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useFrequentRevisitsEmails = ({
  id,
  skip,
}: {
  id: string;
  skip: boolean;
}) => {
  const cubejsApi = useCubejsApi("frequent-revisits-emails");
  const afterDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: [],
      dimensions: [
        "dim_email.subject",
        "fact_email.revisits",
        "dim_email.message_sent_date_time",
      ],
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [id],
        },
        {
          member: "fact_email.revisits",
          operator: "gte",
          values: ["1"],
        },
        {
          member: "dim_email.message_sent_date_time",
          operator: "afterDate",
          values: [afterDate],
        },
        {
          member: "dim_contact.is_leaver",
          operator: "equals",
          values: ["false"],
        },
      ],
    },
    {
      cubejsApi,
      skip,
    }
  );
  const results = (resultSet?.tablePivot() || []) as any[];

  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useFrequentRevisitsEmails;
