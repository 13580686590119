import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";
import { IUser } from "features/profile/user.interface";
import { format } from "date-fns";
import { fromPairs } from "ramda";
import { beginningOfMonthsAgo } from "utils/dates";

export type EntityPieChartType = "engagement" | "duration" | "platform";

const useEntityPieMetrics = ({
  type,
  contactId,
  companyId,
  user,
  monthRange,
  additionalFilters,
}: {
  type: EntityPieChartType;
  contactId?: string;
  companyId?: string;
  user?: IUser;
  monthRange?: number;
  additionalFilters: Filter[];
}) => {
  // fetch twice for my vs all metrics
  const { results: globalPieMetrics, isLoading: isGlobalLoading } =
    useFetchPieData({
      type,
      contactId,
      companyId,
      monthRange,
      additionalFilters,
    });
  const { results: yourPieMetrics, isLoading: isYourLoading } = useFetchPieData(
    { type, contactId, companyId, user, monthRange, additionalFilters }
  );

  return {
    results: { [type]: globalPieMetrics, [`my_${type}`]: yourPieMetrics },
    isLoading: isGlobalLoading && isYourLoading,
  };
};

const useFetchPieData = ({
  type,
  contactId,
  companyId,
  user,
  monthRange,
  additionalFilters,
}: {
  type: EntityPieChartType;
  contactId?: string;
  companyId?: string;
  user?: IUser;
  monthRange?: number;
  additionalFilters: Filter[];
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };

  const dimensionsMap = {
    engagement: "fact_email.engagement_type",
    duration: "dim_calendar.duration_group",
    platform: "dim_calendar.venue",
  };
  const measuresMap = {
    engagement: "fact_email.count",
    duration: "dim_calendar.count",
    platform: "dim_calendar.count",
  };

  const dimension = dimensionsMap[type];
  const measure = measuresMap[type];

  const dateFilterRange = [
    format(beginningOfMonthsAgo(monthRange || 6), "yyyy-MM-dd"),
    format(new Date(), "yyyy-MM-dd"),
  ];

  const query = {
    measures: [measure],
    dimensions: [dimension],
    order: { [measure]: "desc" },
    filters: [
      ...entityFilters(),
      ...additionalFilters,
      {
        dimension: "dim_date.date_actual",
        operator: "inDateRange",
        values: dateFilterRange,
      },
    ],
  };

  if (user) {
    query.filters.push({
      member: "dim_user.email",
      operator: "equals",
      values: [user.email as string],
    });
  }

  const { resultSet, isLoading, error } = useCubeQuery(query as Query);

  if (error) {
    console.error(error);
  }

  const rows = resultSet?.tablePivot() || [];
  const results: [string, any][] = rows.map((row) => [
    row[dimension] as string,
    row[measure],
  ]);

  return {
    results: fromPairs(results),
    isLoading,
  };
};

export default useEntityPieMetrics;
