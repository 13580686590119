const AccessDeniedView = ({ name }: { name: string }) => {
  return (
    <div className="mt-[20%] flex flex-col items-center justify-center">
      <div className="flex items-center gap-2">
        <div className="flex h-12 w-12 items-center justify-center rounded bg-mint">
          <i className="icon-cursor text-2xl text-navy"></i>
        </div>
        <div className="relative">
          <div className="flex h-16 w-16 items-center justify-center rounded bg-navy">
            <i className="icon-search text-4xl text-white"></i>
          </div>
          <div className="absolute -bottom-2 -left-1">
            <div className="whitespace-nowrap rounded-sm bg-gold px-3 py-0.5 text-sm text-gold-darkest">
              Relata Pro
            </div>
          </div>
        </div>
        <div className="flex h-12 w-12 items-center justify-center rounded bg-sky">
          <i className="icon-report text-2xl text-white"></i>
        </div>
      </div>
      <div className="mt-7 text-xl text-navy">
        {name} isn’t included in your subscription
      </div>
      <div className="mt-2 mb-10 w-[350px] text-center text-lg text-metal">
        You can request access by contacting your organisation's admin.
      </div>
    </div>
  );
};

export default AccessDeniedView;
