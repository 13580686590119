import classNames from "classnames";

// generic component to render state with icon, title and paragraphs
const FeedbackMessage = ({
  icon,
  title,
  paragraps = [],
  hasGradientBackground = false,
}: {
  icon: string;
  title: string;
  paragraps?: string[];
  hasGradientBackground?: boolean;
}) => {
  return (
    <div
      className={classNames("flex h-full items-center justify-center", {
        "bg-gradient-to-b from-dust-light to-white": hasGradientBackground,
      })}
    >
      <div className="flex flex-col items-center text-center">
        <i className={`${icon} pb-6 text-2xl text-navy`}></i>
        <div className="flex flex-col pb-2 text-xl font-bold text-navy">
          {title}
        </div>
        <div className="flex flex-col gap-4 text-lg text-metal">
          {paragraps.map((paragraph, i) => (
            <div key={i} className="max-w-[309px] text-metal">
              {paragraph}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedbackMessage;
