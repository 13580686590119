import { useNavigate } from "react-router-dom";

import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewBadge from "../components/LiveReportPreviewBadge";
import LiveReportPreviewSkeleton from "../components/LiveReportPreviewSkeleton";

import ContactReportCard from "../components/ContactReportCard";
import useFrequentRevisits from "pages/report/hooks/useFrequentRevisits";

const RevisitingContact = ({
  id,
  name,
  company,
  email,
  emails,
  revisits,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  emails: number;
  revisits: number;
}) => (
  <ContactReportCard
    id={id}
    name={name}
    company={company}
    email={email}
    color="purple"
  >
    <div className="flex flex-col gap-2">
      <div className="text-metal">
        {name.split(" ")[0]} has{" "}
        <span className="font-bold">revisited {emails} of your emails</span> a
        total of <span className="font-bold">{revisits} times</span>.
      </div>
    </div>
  </ContactReportCard>
);

const FrequentRevisitsPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/frequent-revisits");
  const { resultSet, isLoading } = useFrequentRevisits();

  if (isLoading) {
    return <LiveReportPreviewSkeleton />;
  }
  if (resultSet.length === 0) {
    return <></>;
  }

  return (
    <LiveReportPreview
      color="purple"
      icon="icon-email-star"
      title="Frequent revisits"
      description="Contacts who have frequently revisited your emails in the previous 30 days"
      openTooltip="Explore frequent revisits"
      openReport={openReport}
    >
      {resultSet.length > 0 && (
        <div className="pt-4">
          <div className="flex h-[176px] gap-2 overflow-scroll">
            <LiveReportPreviewBadge
              color="purple"
              description={{
                singular: "revisiting contact",
                plural: "revisiting contacts",
              }}
              value={resultSet.length}
              openReport={openReport}
            />
            <div className="flex gap-4">
              {resultSet.slice(0, 2).map((contact) => (
                <RevisitingContact
                  key={contact["dim_contact.uuid"] as string}
                  id={contact["dim_contact.uuid"] as string}
                  name={contact["dim_contact.full_name"] as string}
                  company={contact["dim_company.display_name"] as string}
                  email={contact["dim_contact.email"] as string}
                  emails={contact["emails"]}
                  revisits={contact["revisits"]}
                />
              ))}
              <div className="visible sticky right-0 top-0 -ml-20 w-16 bg-gradient-to-r from-transparent to-white lg:invisible" />
            </div>
          </div>
        </div>
      )}
    </LiveReportPreview>
  );
};

export default FrequentRevisitsPreview;
