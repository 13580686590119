import classnames from "classnames";
import LoadingCircle from "../assets/LoadingCircle.svg";

export type ButtonColor =
  | "navy"
  | "white"
  | "dust"
  | "transparent"
  | "transparent-dark";
type ButtonSize = "small" | "large";

const Button = ({
  color,
  text,
  icon,
  size,
  iconRight,
  isLoading,
  disabled,
  block,
  square,
  tooltip,
  id,
  onClick,
}: {
  color: ButtonColor;
  text?: string;
  icon?: string;
  size?: ButtonSize;
  iconRight?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  block?: boolean;
  square?: boolean;
  tooltip?: string;
  id?: string;
  onClick?: (event?: any) => void;
}) => {
  const isDisabledOrLoading = disabled || isLoading;
  return (
    <button
      id={id}
      tabIndex={0}
      data-tooltip-id="default-tooltip"
      data-tooltip-content={tooltip}
      disabled={isDisabledOrLoading}
      className={classnames(
        `min-w-8 flex h-8 items-center justify-center text-lg bg-${color} outline-none focus:ring focus:ring-mint`,
        {
          "w-full": !!block,
          rounded: !square,
          "px-4 py-2": !!text,
          "w-8": text === "" || text === undefined,
          "h-10 text-xl": size === "large",
          [`hover:bg-${color}-dark`]: !isDisabledOrLoading,
          "cursor-pointer": !isDisabledOrLoading,
          "cursor-not-allowed": isDisabledOrLoading,

          // navy
          "text-white disabled:text-metal": color === "navy",

          // white
          "text-metal hover:bg-dust": color === "white" && !isDisabledOrLoading,
          "text-dust-dark hover:bg-white":
            color === "white" && isDisabledOrLoading,

          // dust
          "text-metal-dark disabled:text-dust-darker": color === "dust",

          // transparent
          "text-metal-dark hover:bg-[#00225814]":
            color === "transparent" && !isDisabledOrLoading,
          "text-dust-dark hover:bg-transparent":
            color === "transparent" && isDisabledOrLoading,

          // transparent dark
          "bg-[#00000073] text-white hover:bg-[#00000099]":
            color === "transparent-dark" && !isDisabledOrLoading,
          "text-white hover:bg-[#00000073]":
            color === "transparent-dark" && isDisabledOrLoading,
        }
      )}
      onClick={onClick}
    >
      {isLoading && (
        <img
          src={LoadingCircle}
          className="absolute mx-auto h-6 w-6 text-white"
          alt="loading"
        />
      )}
      <div
        className={classnames(
          "relative flex items-center justify-center gap-2 whitespace-nowrap",
          {
            invisible: isLoading,
          }
        )}
      >
        {!iconRight && icon && <i className={icon}></i>}
        {text}
        {iconRight && icon && <i className={icon}></i>}
      </div>
    </button>
  );
};

export default Button;
