import { useState, useEffect, useRef } from "react";
import useDebounce from "utils/hooks/useDebounce";

/**
 * useScrolling
 * to use this hook you have to link the ref to the element that will be scrolled
 * and sentryRef to the element that will trigger the callback
 * this hook will return the current scroll position from top and a function to scroll to top
 */
const useScrolling = ({
  callback,
  isFetching,
}: {
  callback: VoidFunction;
  isFetching: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const sentryRef = useRef<HTMLDivElement | null>(null);
  const [fromTop, setFromTop] = useState(0);
  const [intersection, setIntersection] = useState(false);
  const debouncedIntersection = useDebounce(intersection, 200); // prevents double triggering the intersection events

  useEffect(() => {
    const element = ref.current;
    const handleScroll = () => {
      if (element) {
        setFromTop(element.scrollTop);
      }
    };
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // control infinity scrolling by using sentry ref element
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersection(entry.isIntersecting);
      },
      { threshold: 1 }
    );
    const { current } = sentryRef;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callback, sentryRef]);

  useEffect(() => {
    if (debouncedIntersection && !isFetching) {
      callback();
    }
  }, [debouncedIntersection, callback, isFetching]);

  const scrollToTop = () => {
    ref.current &&
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return { ref, fromTop, sentryRef, scrollToTop };
};

export default useScrolling;
