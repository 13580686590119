import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import FilterButton from "components/FilterButton";
import MiniStatistic from "components/MiniStatistic";
import AverageTimeMiniStatistic from "components/AverageTimeMiniStatistic";

import ContactItem from "components/ContactItem";
import ActivityTimeline from "components/ActivityTimeline";

import { useGetCompanyQuery } from "features/entity/entityApi";
import useEntityRanking, {
  IEntityRankingItem,
} from "features/entity/useEntityRanking";
import useEntityQuickStats from "features/entity/useEntityQuickStats";
import useEntityMyQuickStats from "features/entity/useEntityMyQuickStats";
import { ICompanyDetail, RankType } from "features/entity/entity.interfaces";

import { selectCubeFilters } from "app/filtersSlice";
import { lenghtOfPeriodFromNow } from "utils/dates";
import CubeLastRefresh from "components/CubeLastRefresh";
import RelationshipSignals from "components/RelationshipSignals";
import useRelationshipSignals from "components/RelationshipSignals/useRelationshipSignals";
import { useGetUserQuery } from "features/profile/profileApi";

const SkeletonItem = () => (
  <div className="flex h-16 items-center gap-2 rounded bg-white p-4">
    <div className="h-4 w-4 animate-pulse bg-dust-dark"></div>
    <div className="mx-1 h-8 w-8 animate-pulse rounded-full bg-dust"></div>
    <div className="h-3 w-[150px] animate-pulse rounded bg-dust-dark"></div>
    <div className="ml-auto mr-2 h-4 w-4 animate-pulse bg-dust-dark"></div>
  </div>
);

const NoRelationshipsFound = () => (
  <div className="flex w-full flex-col gap-4 rounded-md bg-white">
    <div className="mb-8 mt-8 text-center">
      <i className="icon-warning-hex text-3xl text-navy"></i>
      <div className="mt-2 text-xl font-bold text-navy">
        No relationships found
      </div>
    </div>
  </div>
);

const StrongestContacts = ({
  company,
  contacts,
  isLoading,
}: {
  company?: ICompanyDetail;
  contacts: IEntityRankingItem[];
  isLoading: boolean;
}) => (
  <div className="flex flex-col items-center rounded-lg bg-metal bg-metal-banana bg-cover bg-center bg-no-repeat p-6">
    <div className="p-2 pb-6 text-lg font-bold text-white">
      {isLoading
        ? "Strongest contacts"
        : `${company?.displayName}'s strongest contacts`}
    </div>
    <div className="flex w-full flex-col gap-4">
      {isLoading
        ? [1, 2, 3].map((i) => <SkeletonItem key={i} />)
        : contacts
            .slice(0, 3)
            .map((contact: IEntityRankingItem) => (
              <ContactItem
                key={contact.id}
                id={contact.id}
                type="contact"
                description={contact.role}
                fullName={contact.fullName}
                position={contact.position}
                email={contact.email}
                phoneNumbers={contact.phoneNumbers}
              />
            ))}
      {!isLoading && contacts.length === 0 && <NoRelationshipsFound />}
    </div>
  </div>
);

const ColleagueRelationships = ({
  colleagues,
  isLoading,
}: {
  colleagues: IEntityRankingItem[];
  isLoading: boolean;
}) => (
  <div className="flex flex-col items-center rounded-lg bg-dust-dark p-6">
    <div className="flex flex-col items-center justify-center p-2 pb-6 text-center">
      <div className="text-lg font-bold text-navy">
        Colleagues with the strongest relationship
      </div>
      <div className="text-metal">
        See where you stack up against your colleagues
      </div>
    </div>
    <div className="flex w-full flex-col gap-4">
      {isLoading
        ? [1, 2, 3].map((i) => <SkeletonItem key={i} />)
        : colleagues
            .slice(0, 3)
            .map((contact: IEntityRankingItem) => (
              <ContactItem
                key={contact.id}
                id={contact.id}
                type="colleague"
                fullName={contact.fullName}
                position={contact.position}
                email={contact.email}
              />
            ))}
      {!isLoading && colleagues.length === 0 && <NoRelationshipsFound />}
    </div>
  </div>
);

const CompanyGlanceView = () => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);
  const { data: company, isLoading: isCompanyLoading } = useGetCompanyQuery({
    id: params.id as string,
  });
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();

  const { results: strongestContacts, isLoading: isStrongestContactsLoading } =
    useEntityRanking({
      companyId: params.id,
      source: "dim_contact",
      additionalFilters: cubeFilters,
    });

  const {
    results: strongestColleagues,
    isLoading: isStrongestColleaguesLoading,
  } = useEntityRanking({
    companyId: params.id,
    additionalFilters: cubeFilters,
  });

  const { results: allQuickStats, isLoading: isQuickStatsLoading } =
    useEntityQuickStats({
      companyId: params.id as string,
      additionalFilters: cubeFilters,
    });
  const { results: myQuickStats, isLoading: isMyQuickStatsLoading } =
    useEntityMyQuickStats({
      companyId: params.id as string,
      additionalFilters: cubeFilters,
      user,
    });

  const { results: signals, isLoading: isSignalsLoading } =
    useRelationshipSignals({
      company,
      additionalFilters: cubeFilters,
      user,
    });

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-3">
          <StrongestContacts
            company={company}
            contacts={strongestContacts}
            isLoading={isStrongestContactsLoading || isCompanyLoading}
          />
          <ColleagueRelationships
            colleagues={strongestColleagues}
            isLoading={isStrongestColleaguesLoading || isCompanyLoading}
          />
          <div className="col-span-1 md:col-span-2 2xl:col-span-1">
            <ActivityTimeline
              type="company"
              id={params.id as string}
              name={company?.displayName || ""}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-3">
          <div className="grid grid-cols-1 gap-4">
            <MiniStatistic
              icon="icon-team"
              isLoading={isQuickStatsLoading}
              value={allQuickStats.colleaguesCurrent}
              description="Active colleague relationships"
              previous={allQuickStats.colleaguesPrevious}
            />
            <MiniStatistic
              icon="icon-contact"
              isLoading={isQuickStatsLoading}
              value={allQuickStats.contactsCurrent}
              description="Active contact relationships "
              previous={allQuickStats.contactsPrevious}
            />
            <MiniStatistic
              icon="icon-trophy"
              isLoading={isQuickStatsLoading}
              value={
                allQuickStats.rank === RankType.Unranked
                  ? ""
                  : RankType[+(allQuickStats.rank as string)]
              }
              description="Company Rank"
            />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <MiniStatistic
              icon="icon-team"
              isLoading={isQuickStatsLoading}
              value={allQuickStats.colleaguesAll}
              description="All colleague relationships "
            />
            <MiniStatistic
              icon="icon-contact"
              isLoading={isQuickStatsLoading}
              value={allQuickStats.contactsAll}
              description="All contact relationships "
            />
            <MiniStatistic
              icon="icon-calendar"
              isLoading={isQuickStatsLoading}
              value={
                allQuickStats.firstInteraction
                  ? `> ${lenghtOfPeriodFromNow(
                      new Date(allQuickStats.firstInteraction as string)
                    )}`
                  : "-"
              }
              description="Length of longest relationship"
            />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <AverageTimeMiniStatistic
              myValue={myQuickStats.responseTimeCurrent}
              myPrevious={myQuickStats.responseTimePrev}
              allValue={allQuickStats.responseTimeCurrent}
              allPrevious={allQuickStats.responseTimePrev}
              myOutValue={myQuickStats.outResponseTimeCurrent}
              myOutPrevious={myQuickStats.outResponseTimePrev}
              allOutValue={allQuickStats.outResponseTimeCurrent}
              allOutPrevious={allQuickStats.outResponseTimePrev}
              isLoading={isQuickStatsLoading || isMyQuickStatsLoading}
            />
          </div>
        </div>
        <RelationshipSignals
          signals={signals}
          isLoading={isSignalsLoading || isCompanyLoading || isUserLoading}
        />
      </div>
    </div>
  );
};

export default CompanyGlanceView;
