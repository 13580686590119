import { useEffect, useState } from "react";
import { propOr } from "ramda";
import { formatDistanceToNow, parseISO } from "date-fns";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import InformationTag from "components/atoms/InformationTag";
import {
  ICompanyDetail,
  ICompanyFlag,
} from "features/entity/entity.interfaces";
import { getFlag } from "utils/flags";

const CompanySettingsModal = ({
  visible,
  company,
  onClose,
  onUpdate,
  onlyFlags,
}: {
  visible: boolean;
  company?: Partial<ICompanyDetail>;
  onClose: () => void;
  onUpdate: (updates: any) => void;
  onlyFlags?: boolean;
}) => {
  const serviceProviderFlag = getFlag(company, "service_provider");

  const [checkbox, toggleCheckbox] = useState({
    isServiceProvider: false,
  });

  useEffect(() => {
    toggleCheckbox({
      isServiceProvider: propOr(false, "value", serviceProviderFlag) as boolean,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const getTitle = () => {
    if (onlyFlags) {
      return (
        <>
          <i className="icon-flag" />
          Flag {company?.displayName}
        </>
      );
    } else {
      return (
        <>
          <i className="icon-settings" />
          Company settings
        </>
      );
    }
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            {getTitle()}
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 flex flex-col overflow-y-auto text-base">
          <div className="flex items-center gap-2 text-lg text-navy">
            <i className="icon-flag" />
            Flags
          </div>
          <div className="mx-5 mt-2 text-metal">
            Company flags help to add context to the relationship data in
            Relata, allowing us to filter out companies who are not relevant.
          </div>
          <div className="mt-4 flex flex-col gap-3" data-testid="contact-flags">
            <Checkbox
              checked={checkbox.isServiceProvider}
              onClick={() =>
                toggleCheckbox({
                  ...checkbox,
                  isServiceProvider: !checkbox.isServiceProvider,
                })
              }
              size="small"
              label={`${company?.displayName} is a service provider`}
            />
            {serviceProviderFlag &&
              propOr("", "lastModified", serviceProviderFlag) !== "" && (
                <div className="ml-8 text-sm text-metal">
                  {`Last changed ${formatDistanceToNow(
                    parseISO(
                      propOr("", "lastModified", serviceProviderFlag) as string
                    )
                  )} ago by ${propOr("", "editedBy", serviceProviderFlag)}`}
                </div>
              )}
          </div>
          <div className="mt-6 flex flex-col gap-3">
            <InformationTag
              color="teal"
              label="Changes you make here will be visible to all users."
              icon="icon-info-circle"
            />
            <InformationTag
              color="teal"
              label="Changes can take up to 24 hours to process."
              icon="icon-info-circle"
            />
          </div>
        </div>
        <div className="mt-auto">
          <Button
            id="button-save-contact-settings"
            color="navy"
            size="large"
            text="Save changes"
            onClick={() => {
              const flags: Partial<ICompanyFlag>[] = [];
              if (
                checkbox.isServiceProvider !==
                propOr(false, "value", serviceProviderFlag)
              ) {
                flags.push({
                  flag: "service_provider",
                  value: checkbox.isServiceProvider,
                });
              }
              onUpdate(flags);
            }}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default CompanySettingsModal;
