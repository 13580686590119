import { useEffect } from "react";

import { useUpdateUserMutation } from "features/profile/profileApi";
import { IUser } from "features/profile";
import { csrfInit } from "utils/requests";

const useSyncUserTimezone = (user?: IUser) => {
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const syncTimezone = async () => {
      if (
        user?.timezone &&
        user?.timezone !== timezone &&
        !user.impersonating
      ) {
        try {
          if (!(await csrfInit())) throw new Error("no CSRF set");
          await updateUser({
            timezone,
          }).unwrap();
        } catch (error) {
          console.error(error);
        }
      }
    };
    syncTimezone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};

export default useSyncUserTimezone;
