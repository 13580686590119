import { prop, propOr } from "ramda";
import { formatDifferenceFromNow } from "utils/dates";
import { trendColors } from "utils/colorMapping";
import flipSorting from "utils/flipSorting";
import { IdentifierCell } from "components/table/Cells";

import Header from "./Header";
import { ITableField, SortType } from "./Table.interfaces";
import { Row, GenericCell } from "./Table";
import { TrendType } from "features/entity/entity.interfaces";

const TeamRow = ({
  fields,
  name,
  color,
  relationshipScore,
  relationshipScoreTrend,
  responseTimeCurrent,
  responseTimePrev,
  responseTimeOutCurrent,
  responseTimeOutPrev,
  firstInteraction,
  lastInteraction,
  lastMeeting,
  emailInbound,
  emailOutbound,
}: {
  fields: any[];
  name: string;
  color?: string;
  relationshipScore: number;
  relationshipScoreTrend: string;
  responseTimeCurrent: string;
  responseTimePrev: string;
  responseTimeOutCurrent: string;
  responseTimeOutPrev: string;
  firstInteraction: string;
  lastInteraction: string;
  lastMeeting: string;
  emailInbound: number;
  emailOutbound: number;
}) => {
  const cells: any[] = [];
  cells.push(<IdentifierCell type="team" title={name} color={color} />);
  const metaFromField = {
    relationshipScore: {
      value: relationshipScore,
      description: TrendType[+relationshipScoreTrend],
      color: propOr("green", relationshipScoreTrend, trendColors),
    },
    averageResponseTime: {
      current: Number(responseTimeCurrent),
      prev: Number(responseTimePrev),
    },
    averageResponseTimeOut: {
      current: Number(responseTimeOutCurrent),
      prev: Number(responseTimeOutPrev),
    },
    firstInteraction: {
      text: firstInteraction
        ? formatDifferenceFromNow(new Date(firstInteraction))
        : "",
    },
    lastInteraction: {
      text: lastInteraction
        ? formatDifferenceFromNow(new Date(lastInteraction))
        : "",
    },
    lastMeeting: {
      text: lastMeeting ? formatDifferenceFromNow(new Date(lastMeeting)) : "",
    },
    allEmailBreakdown: { emailsIn: emailInbound, emailsOut: emailOutbound },
  };

  fields.forEach((option) => {
    cells.push(
      <GenericCell
        type={option.type}
        meta={prop(option.field, metaFromField)}
      />
    );
  });

  return <Row cells={cells} />;
};

const TeamSkeletonRow = () => {
  const cells: any[] = [];

  cells.push(
    <div className="flex items-center gap-2 pl-4 pr-12">
      <div className="h-8 w-8 rounded bg-dust"></div>
      <div className="h-3 w-[150px] rounded bg-dust-dark"></div>
    </div>
  );
  cells.push(
    <div className="flex w-[100px] items-center justify-center">
      <div className="h-3 w-[40px] rounded bg-dust"></div>
    </div>
  );
  cells.push(
    <div className="flex items-center justify-center">
      <div className="mx-4 h-3 w-[20px] rounded bg-dust"></div>
      <div className="flex flex-col gap-1 px-4">
        <div className="mt-2 h-2 w-[128px] rounded-full bg-dust"></div>
        <div className="h-3 w-[40px] rounded bg-dust-light"></div>
      </div>
    </div>
  );
  cells.push(
    <div className="flex w-[100px] items-center justify-center">
      <div className="h-3 w-[40px] rounded bg-dust"></div>
    </div>
  );
  cells.push(
    <div className="flex w-[100px] items-center justify-center">
      <div className="h-3 w-[40px] rounded bg-dust"></div>
    </div>
  );
  cells.push(
    <div className="flex w-[120px] items-center justify-center">
      <div className="h-3 w-[40px] rounded bg-dust"></div>
    </div>
  );
  return <Row cells={cells} />;
};

const TeamTable = ({
  dataSource = [],
  fields,
  isLoading,
  nameSource,
  sorting,
  setSorting,
}: {
  dataSource: any;
  fields: ITableField[];
  isLoading: boolean;
  nameSource: string;
  sorting: string;
  setSorting: (value: any) => void;
}) => {
  const sortingState = (field?: string): SortType | undefined => {
    if (field && sorting.endsWith(field)) {
      return sorting.startsWith("-") ? "desc" : "asc";
    }
  };
  return (
    <div className="overflow-x-auto whitespace-nowrap">
      <table className="mt-2 w-full border-separate">
        <thead>
          <tr>
            <Header
              text="Team name"
              sortable
              sticky
              sortState={sortingState(nameSource)}
              onSort={() => setSorting(flipSorting(nameSource, sorting))}
            />
            {fields.map((option) => (
              <Header
                text={option.label}
                sortable={!!option.source}
                sortState={sortingState(option.source)}
                key={"header-" + option.field}
                onSort={() =>
                  setSorting(flipSorting(option.source || "", sorting))
                }
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              {Array(10)
                .fill(null)
                .map((_, i) => (
                  <TeamSkeletonRow key={i} />
                ))}
            </>
          ) : (
            <>
              {dataSource.map((row: any) => {
                return (
                  <TeamRow
                    fields={fields}
                    name={row[nameSource]}
                    color={row["dim_team.color"]}
                    relationshipScoreTrend={row["fact_table.trend"]}
                    relationshipScore={
                      row["fact_table.relationship_score_current"]
                    }
                    responseTimeCurrent={
                      row["fact_table.reply_response_time_current"]
                    }
                    responseTimePrev={
                      row["fact_table.reply_response_time_prev"]
                    }
                    responseTimeOutCurrent={
                      row["fact_table.reply_out_response_time_current"]
                    }
                    responseTimeOutPrev={
                      row["fact_table.reply_out_response_time_prev"]
                    }
                    emailInbound={row["fact_table.inbound_current"]}
                    emailOutbound={row["fact_table.outbound_current"]}
                    firstInteraction={row["fact_table.first_interaction"]}
                    lastInteraction={row["fact_table.last_interaction"]}
                    lastMeeting={row["fact_table.last_meeting"]}
                    key={row["dim_team.name"]}
                  />
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TeamTable;
