import { RootState } from "app/store";
import { offlineToast } from "components/toast/library";
import { IToast } from "components/toast/toast.interfaces";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useNotifications from "./useNotifications";

/**
 * useNetworkDetector
 * notify when browser is offline
 */
export default function useNetworkDetector(): void {
  const notifications: IToast[] = useSelector(
    (state: RootState) => state.notifications
  );
  const { notify, removeNotification } = useNotifications();

  const onOffline = () => {
    if (!notifications.map((e) => e.id).includes(offlineToast.id)) {
      notify(offlineToast);
    }
  };

  const onOnline = () => {
    if (notifications.map((e) => e.id).includes(offlineToast.id))
      removeNotification(offlineToast.id as string);
  };

  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  });
}
