import DataTag, { DataTagType } from "components/atoms/DataTag";
import EntityIdentifier from "components/EntityIdentifier";
import { TooltipFrame } from "components/frames";
import { ICompanyRelationshipValue } from "features/company/company.interfaces";
import { propOr } from "ramda";

const RelationshipRow = ({
  contact,
  colleague,
  type,
  value,
}: {
  contact: { name: string; id: string; email: string };
  colleague: string;
  type: DataTagType;
  value: any;
}) => (
  <div className="flex flex-col items-start justify-between py-2 sm:flex-row sm:items-center">
    <div className="flex-grow basis-0">
      <EntityIdentifier
        type="contact"
        title={contact.name}
        id={contact.id}
        meta={{ email: contact.email }}
      />
    </div>
    <div className="self-end sm:self-center">
      <DataTag type={type} label={value} />
    </div>
    <div className="flex flex-grow basis-0 justify-end">
      <EntityIdentifier type="colleague" title={colleague} />
    </div>
  </div>
);

const RelationshipFrame = ({
  relationships,
  title,
  valueMetric,
  type,
}: {
  relationships: ICompanyRelationshipValue[];
  title: string;
  valueMetric: string;
  type: DataTagType;
}) => {
  return (
    <TooltipFrame title={title}>
      <div className="flex max-h-[273px] flex-col divide-y divide-dust overflow-x-auto">
        {relationships.length ? (
          relationships.map((item, index) => (
            <RelationshipRow
              contact={{
                name: item.contactFullName,
                id: item.contactUuid,
                email: item.contactEmail,
              }}
              colleague={item.colleagueFullName}
              type={type}
              value={Math.abs(propOr(0, valueMetric, item))}
              key={index}
            />
          ))
        ) : (
          <div className="my-2 text-center font-bold text-navy">
            No relationships found
          </div>
        )}
      </div>
    </TooltipFrame>
  );
};

export default RelationshipFrame;
