import classnames from "classnames";

export type FilterTileColor =
  | "pink"
  | "metal"
  | "purple"
  | "teal"
  | "green-lightest"
  | "green"
  | "yellow"
  | "red";

export const FilterTileSkeleton = () => (
  <div
    tabIndex={0}
    className="group flex h-[58px] min-w-[120px] cursor-pointer gap-2 rounded-lg border border-dust-dark bg-white p-2 pr-4 shadow outline-none hover:border-dust-darker focus:border-mint"
  >
    <div className="w-1 animate-pulse bg-dust"></div>
    <div className="flex animate-pulse flex-col justify-between p-0.5">
      <div className="h-4 w-5 rounded bg-dust"></div>
      <div className="h-3 w-[60px] rounded bg-dust"></div>
    </div>
  </div>
);

const FilterTile = ({
  value,
  label,
  color,
  selected,
  onToggle,
  filteredCount,
  size,
}: {
  value: any;
  label: string;
  color: FilterTileColor;
  selected?: boolean;
  filteredCount?: number;
  size?: "large" | "small";
  onToggle?: () => void;
}) => {
  if (value === 0 && !selected) {
    return null;
  }

  return (
    <div
      tabIndex={0}
      onClick={onToggle}
      data-testid={label}
      className={classnames(
        "group flex min-w-[120px] cursor-pointer gap-2 rounded-lg border border-dust-dark bg-white p-2 pr-4 shadow outline-none hover:border-dust-darker focus:border-mint",
        { "h-16 min-w-[148px]": size === "large" }
      )}
    >
      <div
        className={classnames("w-1 rounded", {
          //pink
          "bg-pink group-hover:bg-pink-dark": color === "pink" && selected,
          "bg-pink-lightest group-hover:bg-pink": color === "pink" && !selected,

          //metal
          "bg-metal group-hover:bg-metal-dark": color === "metal" && selected,
          "bg-dust group-hover:bg-dust-darker": color === "metal" && !selected,

          //purple
          "bg-purple group-hover:bg-purple-dark":
            color === "purple" && selected,
          "bg-purple-lightest group-hover:bg-purple":
            color === "purple" && !selected,

          //teal
          "bg-teal group-hover:bg-teal-dark": color === "teal" && selected,
          "bg-teal-lightest group-hover:bg-teal": color === "teal" && !selected,

          //green-lightest green
          "bg-green-lightest group-hover:bg-green":
            color === "green-lightest" || (color === "green" && !selected),
          "bg-green group-hover:bg-green-dark": color === "green" && selected,

          //yellow
          "bg-yellow group-hover:bg-yellow-dark":
            color === "yellow" && selected,
          "bg-yellow-lightest group-hover:bg-yellow":
            color === "yellow" && !selected,

          //red
          "bg-red group-hover:bg-red-dark": color === "red" && selected,
          "bg-red-lightest group-hover:bg-red": color === "red" && !selected,
        })}
      ></div>
      <div className="flex flex-col">
        {filteredCount !== undefined ? (
          <div className="text-xl font-bold text-navy">
            {filteredCount}
            <span className="font-normal text-metal"> of {value}</span>
          </div>
        ) : (
          <div className="text-xl font-bold text-navy">{value}</div>
        )}
        <div className="text-metal">{label}</div>
      </div>
    </div>
  );
};

export default FilterTile;
