import InteractionTag from "components/atoms/InteractionTag";
import env from "config";
import { format } from "date-fns";
import {
  ContactEngagementType,
  IContactDetail,
} from "features/entity/entity.interfaces";
import { highlightText } from "utils/highlightText";

export type ActivitySegmentType = "email-out" | "email-in" | "meeting";
const BLAST_EMAIL_THRESHOLD = +env("REACT_APP_BLAST_EMAIL_THRESHOLD") || 24;

export const ActivityEmailSegment = ({
  subject,
  date,
  recipients,
  searchValue = "",
  searchTarget = "default",
  totalRecipients,
  totalEngaged,
  onClick,
}: {
  subject: string;
  date: Date;
  recipients: {
    engagement: ContactEngagementType;
    contact: IContactDetail;
  }[];
  totalRecipients?: number;
  totalEngaged?: number;
  onClick?: () => void;
  searchValue?: string;
  searchTarget?: string;
}) => {
  const getColor = (engagement: ContactEngagementType) => {
    switch (engagement) {
      case "high":
        return "green";
      case "standard":
        return "yellow";
      default:
        return "dust";
    }
  };
  const onKeyDown = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && onClick) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className="group relative flex w-full cursor-pointer flex-row gap-4 border-b border-b-dust-dark bg-white p-4 outline-none hover:bg-dust-light"
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-md bg-dust text-lg text-metal">
        <i className="icon-email-out"></i>
      </div>
      <div className="flex flex-1 flex-col gap-2 overflow-hidden">
        <div className="w-[85%] overflow-hidden text-ellipsis whitespace-nowrap text-left text-lg font-bold text-navy">
          {searchTarget !== "fullName"
            ? highlightText(subject, searchValue)
            : subject}
        </div>
        <div className="flex flex-row gap-2">
          {recipients.map(({ engagement, contact }, i) => (
            <InteractionTag
              key={i}
              color={getColor(engagement)}
              searchValue={
                searchTarget !== "messageSubject" ? searchValue : undefined
              }
              label={contact.fullName}
            />
          ))}
        </div>
      </div>
      <div className="absolute right-0 top-0 h-full w-[100px] bg-gradient-to-r from-transparent to-white" />
      <div className="absolute right-4 top-[14px] flex flex-row items-center gap-8 text-metal">
        <div className="invisible group-hover:md:visible">
          <div className="flex flex-row">
            <div className="w-8 bg-gradient-to-r from-transparent to-dust-light" />
            {!!totalRecipients && totalRecipients > BLAST_EMAIL_THRESHOLD && (
              <div className="flex flex-row items-center gap-2 bg-dust-light">
                <i className="icon-contact text-xl" />
                <span>
                  <span className="font-bold">{totalRecipients}</span> contacts
                </span>
              </div>
            )}
            {!!totalEngaged &&
              !!totalRecipients &&
              totalRecipients > BLAST_EMAIL_THRESHOLD && (
                <div className="flex flex-row items-center gap-2 bg-dust-light pl-6">
                  <i className="icon-rocket text-xl" />
                  <span>
                    <span className="font-bold">
                      {Math.round((totalEngaged / totalRecipients) * 100)}%
                    </span>{" "}
                    engagement
                  </span>
                </div>
              )}
          </div>
        </div>
        {format(date, "HH:mm")}
      </div>
    </div>
  );
};

export default ActivityEmailSegment;
