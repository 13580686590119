import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ContactIntro from "components/ContactIntro";
import FilterButton from "components/FilterButton";
import MiniStatistic from "components/MiniStatistic";
import AverageTimeMiniStatistic from "components/AverageTimeMiniStatistic";
import ActivityTimeline from "components/ActivityTimeline";

import { selectCubeFilters } from "app/filtersSlice";
import useEntityQuickStats from "features/entity/useEntityQuickStats";
import useEntityMyQuickStats from "features/entity/useEntityMyQuickStats";

import useEntityRanking from "features/entity/useEntityRanking";
import { useGetUserQuery } from "features/profile/profileApi";
import { lenghtOfPeriodFromNow } from "utils/dates";
import { useGetContactQuery } from "features/entity/entityApi";
import CubeLastRefresh from "components/CubeLastRefresh";
import RelationshipSignals from "components/RelationshipSignals";
import useRelationshipSignals from "components/RelationshipSignals/useRelationshipSignals";

const ContactGlanceView = () => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { results: allQuickStats, isLoading: isQuickStatsLoading } =
    useEntityQuickStats({
      contactId: params.id,
      additionalFilters: cubeFilters,
    });
  const { results: myQuickStats, isLoading: isMyQuickStatsLoading } =
    useEntityMyQuickStats({
      contactId: params.id,
      additionalFilters: cubeFilters,
      user,
    });

  const { data: contact, isLoading: isContactLoading } = useGetContactQuery({
    id: params.id as string,
  });

  const { results: introColleagues, isLoading: isIntroLoading } =
    useEntityRanking({
      contactId: params.id,
      additionalFilters: cubeFilters,
    });

  const { results: signals, isLoading: isSignalsLoading } =
    useRelationshipSignals({
      contact,
      additionalFilters: cubeFilters,
      user,
    });

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="container">
            <ContactIntro
              user={user}
              contact={contact}
              isLoading={isIntroLoading || isContactLoading}
              colleagues={introColleagues}
            />
          </div>
          <div className="container">
            <ActivityTimeline
              type="contact"
              id={params.id as string}
              name={contact?.fullName || ""}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="grid grid-cols-1 gap-4">
            <MiniStatistic
              icon="icon-team"
              value={allQuickStats.colleaguesCurrent}
              isLoading={isQuickStatsLoading}
              description="Colleagues interacting recently"
              previous={allQuickStats.colleaguesPrevious}
            />
            <MiniStatistic
              icon="icon-team"
              value={allQuickStats.colleaguesAll}
              isLoading={isQuickStatsLoading}
              description="Total colleague relationships"
            />
            <MiniStatistic
              icon="icon-calendar"
              isLoading={isQuickStatsLoading}
              value={
                allQuickStats.firstInteraction
                  ? `> ${lenghtOfPeriodFromNow(
                      new Date(allQuickStats.firstInteraction as string)
                    )}`
                  : "-"
              }
              description="Length of longest relationship"
            />
          </div>
          <div>
            <AverageTimeMiniStatistic
              myValue={myQuickStats.responseTimeCurrent}
              myPrevious={myQuickStats.responseTimePrev}
              allValue={allQuickStats.responseTimeCurrent}
              allPrevious={allQuickStats.responseTimePrev}
              myOutValue={myQuickStats.outResponseTimeCurrent}
              myOutPrevious={myQuickStats.outResponseTimePrev}
              allOutValue={allQuickStats.outResponseTimeCurrent}
              allOutPrevious={allQuickStats.outResponseTimePrev}
              isLoading={isQuickStatsLoading || isMyQuickStatsLoading}
            />
          </div>
        </div>
        <RelationshipSignals
          signals={signals}
          isLoading={isSignalsLoading || isContactLoading || isUserLoading}
        />
      </div>
    </div>
  );
};

export default ContactGlanceView;
