import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { interactionApi } from "features/interaction/interactionApi";
import { SearchTargetType } from "pages/track/interaction/InteractionSearch";
import {
  IInteraction,
  InteractionEventType,
} from "../features/interaction/interaction.interface";
import { RootState } from "app/store";
import { prop, uniqBy } from "ramda";

export const fetchNewInteractions = createAsyncThunk(
  "newInteractions/refetch",
  async (
    params: {
      search?: string;
      searchTarget?: SearchTargetType;
      eventType?: InteractionEventType;
      mailboxes?: string[];
      messageId?: string;
      showInternals?: boolean;
      linkId?: string;
      happenedAfter?: string;
    },
    thunkAPI
  ) => {
    const newInteractionsCountResponse = (
      await thunkAPI.dispatch(
        interactionApi.endpoints.getInteractionCount.initiate(
          {
            ...params,
            interactionsSinceTimestamp:
              params.happenedAfter || new Date().toISOString(),
          },
          {
            forceRefetch: true,
          }
        )
      )
    ).data;

    if (
      !!newInteractionsCountResponse &&
      newInteractionsCountResponse.count > 0
    ) {
      const interactionsResponse = (
        await thunkAPI.dispatch(
          interactionApi.endpoints.getNewInteractions.initiate(params, {
            forceRefetch: true,
          })
        )
      ).data;

      return {
        results: interactionsResponse?.results || [],
        next: interactionsResponse?.next,
      };
    } else {
      return initialState;
    }
  }
);

interface NewInteractionsState {
  results: IInteraction[];
  next?: string;
}

const initialState = {
  results: [],
  next: undefined,
} as NewInteractionsState;

const newInteractionsSlice = createSlice({
  name: "newInteractions",
  initialState,
  reducers: {
    clearNewInteractions: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewInteractions.fulfilled, (state, action) => {
      const mergedList = [...action.payload.results, ...state.results];
      const uniqueList = uniqBy(prop("uuid"), mergedList);
      state.results = uniqueList;
      state.next = action.payload.next;
    });
  },
});

export const { clearNewInteractions } = newInteractionsSlice.actions;

export const selectNewInteractions = (state: RootState) =>
  state.newInteractions;

export default newInteractionsSlice.reducer;
