import Button from "./Button";

const CompanyDetails = ({
  contacts,
  domain,
  isLoading,
}: {
  contacts: any;
  domain: any;
  isLoading: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4 py-4 pl-6 pr-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="text-metal">Website</div>
          {isLoading ? (
            <div className="h-4 w-[150px] animate-pulse rounded bg-dust"></div>
          ) : (
            <div className="font-bold text-navy">{domain}</div>
          )}
        </div>
        <Button
          icon="icon-popup"
          id="open-company-website"
          tooltip="Open website in new tab"
          color="transparent"
          disabled={isLoading}
          onClick={() => window.open(`https://${domain}`, "_blank")}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-metal">Known contacts (All time)</div>
        {isLoading ? (
          <div className="h-4 w-[150px] animate-pulse rounded bg-dust"></div>
        ) : (
          <div className="font-bold text-navy">{contacts}</div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
