interface HotjarPluginConfig {
  appId?: string; // your hotjar app id
  appVersion?: number; // your hotjar api version
}

const config: HotjarPluginConfig = {
  appId: undefined,
  appVersion: 6,
};

export default function hotjarPlugin(pluginConfig: HotjarPluginConfig) {
  return {
    name: "hotjar",
    config: {
      ...config,
      ...pluginConfig,
    },
    initialize: ({ config }: { config: any }) => {
      if (!config.appId) return;
      window.hj =
        window.hj ||
        function () {
          (window.hj.q = window.hj.q || []).push(arguments);
        };
      window._hjSettings = { hjid: config.appId, hjsv: config.appVersion };
      const head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.async = true;
      script.src =
        "https://static.hotjar.com/c/hotjar-" +
        config.appId +
        ".js?sv=" +
        config.appVersion;
      head.appendChild(script);
    },
    page: ({ payload }: { payload: any }) => {
      // call provider specific page tracking
      return;
    },
    track: ({ payload }: { payload: any }) => {
      if (window.hj === undefined) return;
      window.hj("event", payload.event);
    },
    identify: ({ payload }: { payload: any }) => {
      const { userId, traits } = payload;
      if (window.hj === undefined) return;
      window.hj("identify", userId, traits);
    },
    loaded: () => {
      return !!window.hj;
    },
  };
}

declare global {
  interface Window {
    hj: any;
    _hjSettings: any;
  }
}
