const getZonedDate = (date: Date, timezone?: string): Date => {
  if (timezone) {
    return new Date(
      date.toLocaleString("en-US", {
        timeZone: timezone,
      })
    );
  }
  return date;
};
export default getZonedDate;
