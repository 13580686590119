import { format, isToday, isYesterday } from "date-fns";

const DayLine = ({ date }: { date: Date }) => {
  const variant = isToday(date)
    ? "Today"
    : isYesterday(date)
    ? "Yesterday"
    : undefined;
  return (
    <div className="sticky top-0 z-10 flex h-12 flex-row items-center justify-between bg-white-96 px-4 text-lg backdrop-blur-sm">
      {!!variant ? (
        <div>
          <div className="font-bold text-navy">{variant}</div>
          <div className="text-metal">{format(date, "MMMM do")}</div>
        </div>
      ) : (
        <div className="font-bold text-navy">
          {format(date, "EEEE, MMMM do")}
          <span className="ml-4 font-medium text-metal">
            {format(date, "yyyy")}
          </span>
        </div>
      )}
    </div>
  );
};

export default DayLine;
