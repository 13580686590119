import { Keccak } from "sha3";
import { IUser, Permissions } from "features/profile/user.interface";

const isRelataUser = (email: string): boolean =>
  email.includes("feedstock.co") ||
  email.endsWith("fdsk.co.uk") ||
  email.endsWith("relata.ai");

const transformUser = (user: IUser): IUser => {
  user.isProUser = user.permissions.some((permission) =>
    [
      Permissions.ACCESS_CONTACT_EXTRA,
      Permissions.ACCESS_COMPANY_EXTRA,
      Permissions.USE_FILTER_EXTRA,
    ].includes(permission)
  );
  user.isStaff = user.permissions.includes(Permissions.IS_STAFF_USER);
  user.showContactSignatures = user.permissions.includes(
    Permissions.USE_CONTACT_SIGNATURE
  );
  user.isDemoUser =
    user.permissions.includes(Permissions.IS_DEMO_USER) && !user.isStaff;

  if (user.realUser) {
    user.isRelataUser = isRelataUser(user.realUser);
    user.fullName = `${user?.name} ${user?.surname}`;

    // hash email for intercom
    const hash = new Keccak(224);
    hash.update(user.realUser);
    user.hashedEmail = hash.digest({ format: "hex" }).toString();
  }
  return user;
};
export default transformUser;
