import { find, map, propEq, propOr } from "ramda";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useGetUserQuery } from "features/profile/profileApi";
import useEntityWaffleCharts from "features/entity/useEntityWaffleCharts";
import useEntityPieMetrics from "features/entity/useEntityPieMetrics";
import { WaffleChart, PieChart, pieOptions } from "charts";
import FilterButton from "components/FilterButton";
import { SelectionFrame } from "components/frames";
import { capitalizeFirstLetter } from "utils/string";
import {
  meetingDurationMetricOptions,
  meetingPlatformMetricOptions,
  meetingsMetricOptions,
} from "./ActivityViewOptions";
import { selectCubeFilters } from "app/filtersSlice";
import { beginningOfMonthsAgo } from "utils/dates";

import ActivityTable from "./ActivityTable";
import CubeLastRefresh from "components/CubeLastRefresh";

const MeetingActivityView = ({ type }: { type: "contact" | "company" }) => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const activityMonthRanges = [3, 6, 12];

  const queryData = () => {
    switch (type) {
      case "contact":
        return { contactId: params.id as string };
      case "company":
        return { companyId: params.id as string };
    }
  };

  // waffles
  const { results: waffleSeries, isLoading: isWaffleLoading } =
    useEntityWaffleCharts({
      ...queryData(),
      user,
      additionalFilters: cubeFilters,
      type: "meetings",
    });

  const [meetingsMetric, setMeetingsMetric] = useState("meetings_count");
  const [meetingsMonthRange, setMeetingsMonthRange] = useState(6);

  const activeMeetingsLegendLabel = propOr(
    "",
    "legend",
    find(propEq("value", meetingsMetric), meetingsMetricOptions)
  );

  const meetingsMetricData = waffleSeries
    .filter(
      (record) =>
        new Date(record.timeLabel as string) >=
        beginningOfMonthsAgo(meetingsMonthRange)
    )
    .map((record) => ({
      day: record.timeLabel,
      value: propOr(0, meetingsMetric, record),
    }));

  // pies
  const [meetingDurationMetric, setMeetingDurationMetric] =
    useState("duration");
  const [meetingDurationMonthRange, setMeetingDurationMonthRange] = useState(6);

  const [meetingPlatformMetric, setMeetingPlatformMetric] =
    useState("platform");
  const [meetingPlatformMonthRange, setMeetingPlatformMonthRange] = useState(6);

  const { results: meetingDurations, isLoading: isMeetingDurationsLoading } =
    useEntityPieMetrics({
      user,
      type: "duration",
      additionalFilters: cubeFilters,
      monthRange: meetingDurationMonthRange,
      ...queryData(),
    });
  const { results: meetingPlatforms, isLoading: isMeetingPlatformsLoading } =
    useEntityPieMetrics({
      user,
      type: "platform",
      additionalFilters: cubeFilters,
      monthRange: meetingPlatformMonthRange,
      ...queryData(),
    });

  const labelize = (input: object) =>
    map(capitalizeFirstLetter, Object.keys(input));

  const durationdData: object = propOr(
    {},
    meetingDurationMetric,
    meetingDurations
  );
  const durationdChartData = {
    labels: labelize(durationdData),
    datasets: [
      {
        data: Object.values(durationdData),
        backgroundColor: ["#A3EAE1", "#FFE871", "#F2A5FF"],
      },
    ],
  };

  const platformData: object = propOr(
    {},
    meetingPlatformMetric,
    meetingPlatforms
  );

  const platformChartData = {
    labels: labelize(platformData),
    datasets: [
      {
        data: Object.values(platformData),
        backgroundColor: [
          "#8D7CF5",
          "#A3EAE1",
          "#ADADAD",
          "#65D398",
          "#F5505D",
          "#FFE871",
          "#FF9271",
          "#002258",
          "#8BE5C6",
          "#378AD0",
        ],
      },
    ],
  };

  // render
  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <SelectionFrame
          options={meetingsMetricOptions}
          value={meetingsMetric}
          onChange={setMeetingsMetric}
          monthRanges={activityMonthRanges}
          monthRangeValue={meetingsMonthRange}
          onMonthRangeChange={setMeetingsMonthRange}
        >
          <WaffleChart
            id="meetings"
            tooltip={(date, value) =>
              `${activeMeetingsLegendLabel}<br />${date}: ${value}`
            }
            data={meetingsMetricData}
            isLoading={isWaffleLoading}
          />
        </SelectionFrame>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
          <SelectionFrame
            options={meetingDurationMetricOptions}
            value={meetingDurationMetric}
            onChange={setMeetingDurationMetric}
            monthRanges={activityMonthRanges}
            monthRangeValue={meetingDurationMonthRange}
            onMonthRangeChange={setMeetingDurationMonthRange}
            isLoading={isUserLoading || isMeetingDurationsLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart data={durationdChartData} options={pieOptions} />
            </div>
          </SelectionFrame>
          <SelectionFrame
            options={meetingPlatformMetricOptions}
            value={meetingPlatformMetric}
            onChange={setMeetingPlatformMetric}
            monthRanges={activityMonthRanges}
            monthRangeValue={meetingPlatformMonthRange}
            onMonthRangeChange={setMeetingPlatformMonthRange}
            isLoading={isUserLoading || isMeetingPlatformsLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart data={platformChartData} options={pieOptions} />
            </div>
          </SelectionFrame>
        </div>
        <ActivityTable type={type} subtype="meetings" />
      </div>
    </div>
  );
};

export default MeetingActivityView;
