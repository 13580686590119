import classNames from "classnames";
import Button from "components/Button";
import DayLine from "components/DayLine";
import { format } from "date-fns";
import { IInteraction } from "features/interaction/interaction.interface";
import { groupBy, uniq } from "ramda";
import { useNavigate } from "react-router-dom";
import { getZonedDate } from "utils/dates";
import useScrolling from "utils/hooks/useScrolling";
import { SearchTargetType } from "./InteractionSearch";
import { InteractionSkeleton } from "./InteractionSkeletons";
import Interaction from "./Interaction";

const NoMore = () => (
  <div className="mt-16 flex flex-col items-center justify-center gap-2">
    <i className="icon-activity text-2xl font-bold text-navy" />
    <div className="text-lg font-bold text-navy">No more interactions</div>
    <div className="max-w-[309px] text-center text-lg text-metal">
      You've reached the end of your interaction timeline.
    </div>
  </div>
);

const InteractionTimeline = ({
  interactions,
  searchValue,
  searchTarget,
  newInteractions = [],
  fetchMore,
  hasNoMore,
  isFetching,
}: {
  interactions: IInteraction[];
  searchValue: string;
  searchTarget: SearchTargetType;
  newInteractions?: IInteraction[];
  fetchMore: () => void;
  hasNoMore: boolean;
  isFetching: boolean;
}) => {
  const navigate = useNavigate();
  const {
    ref: scrollingRef,
    scrollToTop,
    fromTop,
    sentryRef,
  } = useScrolling({ callback: fetchMore, isFetching });

  const byDay = groupBy((interaction: IInteraction) =>
    format(new Date(interaction.actionCreationTimestamp), "yyyy-MM-dd")
  );
  const groupedInteractions = byDay(interactions);
  const groupedNewInteractions = byDay(newInteractions);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const days = uniq([
    ...Object.keys(groupedNewInteractions),
    ...Object.keys(groupedInteractions),
  ]).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  return (
    <div ref={scrollingRef} className="h-full overflow-scroll">
      <div className="relative mx-auto flex max-w-5xl flex-col px-4 pb-64">
        <div className="fixed z-20 flex w-full max-w-5xl justify-end">
          <div className="flex flex-row p-2 pr-7">
            {fromTop > 200 && (
              <Button
                color="transparent"
                icon="icon-increase text-metal"
                text="Today"
                onClick={scrollToTop}
              />
            )}
          </div>
        </div>
        {days.map((day, i) => (
          <div
            key={i}
            className={classNames("mb-4 flex flex-col gap-4 gap-y-4 sm:mb-6", {
              "mb-0": days.length === i + 1,
            })}
          >
            <DayLine
              date={getZonedDate(new Date(`${day}T00:00:00`), timezone)}
            />
            <div className="flex flex-col gap-2">
              {groupedNewInteractions[day]?.map((interaction, i) => (
                <Interaction
                  searchTarget={searchTarget}
                  searchValue={searchValue}
                  key={i}
                  interaction={interaction}
                  onClick={() => navigate(`email/${interaction.messageId}`)}
                />
              ))}
              {!!newInteractions.length &&
                Object.keys(groupedNewInteractions).at(-1) === day && (
                  <div className="relative top-0 -z-10 my-2 h-0 w-full border-t border-mint-dark px-3">
                    <div className="absolute right-0 top-[-14px] -z-10 text-sm text-navy">
                      Since your last visit
                    </div>
                  </div>
                )}
              {groupedInteractions[day]?.map((interaction, i) => (
                <Interaction
                  searchTarget={searchTarget}
                  searchValue={searchValue}
                  key={i}
                  interaction={interaction}
                  onClick={() => navigate(`email/${interaction.messageId}`)}
                />
              ))}
            </div>
          </div>
        ))}
        {(isFetching || !hasNoMore) && (
          <div className="w-[333px] px-4" ref={sentryRef}>
            <InteractionSkeleton />
          </div>
        )}
        {hasNoMore && <NoMore />}
        <div className="absolute top-0 left-16 -z-10 h-full border-r border-dust-dark" />
      </div>
    </div>
  );
};

export default InteractionTimeline;
