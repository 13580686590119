import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: { time?: Date; lastUpdate?: Date } = {};

const cubeLastRefreshSlice = createSlice({
  name: "cubeLastRefresh",
  initialState,
  reducers: {
    changeCubeLastRefresh: (state, action: PayloadAction<{ time: Date }>) => {
      state.time = action.payload.time;
      state.lastUpdate = new Date();
    },
  },
});

export const selectCubeLastRefresh = (state: RootState) =>
  state.cubeLastRefresh;

export const { changeCubeLastRefresh } = cubeLastRefreshSlice.actions;

export default cubeLastRefreshSlice.reducer;
