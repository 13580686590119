import { ILink } from "features/link/link.interface";
import useScrolling from "utils/hooks/useScrolling";
import LinkTracking from "./LinkTracking";
import { LinkTrackingSkeleton } from "./LinkTrakingSkeletons";

const LinkTrackingNoMore = () => (
  <div className="mt-12 flex flex-col items-center justify-center gap-2 font-bold text-navy">
    <i className="icon-link text-2xl" />
    <div className="text-xl">No more links</div>
  </div>
);

const LinkTrackingList = ({
  links,
  searchValue,
  fetchMore,
  isFetching,
  hasNoMore,
  messageId,
}: {
  links: ILink[];
  searchValue?: string;
  fetchMore: VoidFunction;
  isFetching: boolean;
  hasNoMore: boolean;
  messageId?: string;
}) => {
  const { ref: scrollingRef, sentryRef } = useScrolling({
    callback: fetchMore,
    isFetching,
  });
  return (
    <div ref={scrollingRef} className="overflow-y-scroll">
      <div className="mx-auto mb-64 max-w-5xl px-4">
        {links.map((link) => (
          <LinkTracking
            messageId={messageId}
            key={link.uuid}
            link={link}
            searchValue={searchValue}
          />
        ))}
        {(isFetching || !hasNoMore) && (
          <div ref={sentryRef}>
            <LinkTrackingSkeleton />
          </div>
        )}
        {hasNoMore && !messageId && <LinkTrackingNoMore />}
      </div>
    </div>
  );
};
export default LinkTrackingList;
