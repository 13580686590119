import { SortType } from "components/table/Table.interfaces";

const IconSort = ({ state }: { state?: SortType }) => {
  switch (state) {
    case "asc":
      return (
        <div className="group relative h-[12px]  w-[12px] pr-[12px]">
          <i className="icon-sort-up absolute text-metal group-hover:text-dust-dark"></i>
          <i className="icon-sort-down absolute text-dust-dark group-hover:text-metal-light"></i>
        </div>
      );
    case "desc":
      return (
        <div className="group relative h-[12px]  w-[12px] pr-[12px]">
          <i className="icon-sort-up absolute text-dust-dark group-hover:text-metal-light"></i>
          <i className="icon-sort-down absolute text-metal group-hover:text-dust-dark"></i>
        </div>
      );
    default:
      return (
        <div className="group relative h-[12px] w-[12px] pr-[12px]">
          <i className="icon-sort-up absolute text-dust-dark"></i>
          <i className="icon-sort-down absolute text-dust-dark group-hover:text-metal-light"></i>
        </div>
      );
  }
};

export default IconSort;
