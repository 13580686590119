import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ReactNode, useState } from "react";
import env from "config";

import Button from "components/Button";
import RadioButton from "components/RadioButton";
import SelectOption from "components/SelectOption";
import TextInput from "components/TextInput";
import useLocalStorage from "utils/hooks/useLocalStorage";
import countries from "utils/countries.json";
import { ITeam, IUser } from "../user.interface";
import { csrfInit } from "../../../utils/requests";
import { useLogoutMutation } from "../profileApi";
import { ChangePasswordModal, PasswordChangedModal } from "./modals";

const Panel = ({ children, title }: { children: ReactNode; title: string }) => (
  <div className="mb-4 flex w-full min-w-[350px] break-inside-avoid flex-col rounded-lg border border-dust-dark md:max-w-[352px] xl:max-w-[379px]">
    <div className="rounded-t-lg bg-dust px-4 py-4 text-metal">{title}</div>
    <div className="rounded-b-lg">{children}</div>
  </div>
);

export const PersonalDetailPanel = ({
  user,
  updateUser,
  isLoading,
}: {
  user: IUser;
  updateUser: any;
  isLoading: boolean;
}) => {
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.surname);
  const [city, setCity] = useState(user.city);
  const [country, setCountry] = useState(user.country);

  const noChanges =
    name === user.name &&
    lastName === user.surname &&
    country === user.country &&
    user.city === city;

  const handleRequest = async () => {
    const changes = {
      name,
      surname: lastName,
      city,
      country: country ? country : "",
    };
    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await updateUser(changes).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Panel title="Personal details">
      <div className="divide-y divide-dust-dark">
        <div className="flex flex-col gap-4 p-4 pb-8">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold text-navy">First name</div>
            <TextInput value={name} onChangeText={setName} maxLength={40} />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold text-navy">Last name</div>
            <TextInput
              value={lastName}
              onChangeText={setLastName}
              maxLength={40}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 pt-6">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold text-navy">City</div>
            <TextInput value={city} onChangeText={setCity} maxLength={40} />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-bold text-navy">Country</div>
            <SelectOption
              options={countries}
              value={country}
              onChange={setCountry}
            />
          </div>
          <div>
            <Button
              isLoading={isLoading}
              color="dust"
              id="save-personal-details-button"
              text="Save changes"
              disabled={noChanges}
              onClick={handleRequest}
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export const AccessPanel = ({ user }: { user: IUser }) => {
  const ElementAccess = ({
    label,
    level,
  }: {
    label: string;
    level: number;
  }) => (
    <div className=" flex flex-row gap-20">
      <div className="w-28 text-lg font-bold text-navy">{label}</div>
      <div className="flex flex-row gap-1 text-metal">
        <i
          className={classNames("text-lg", {
            "icon-level1": level === 1,
            "icon-level2": level === 2,
            "icon-level3": level === 3,
          })}
        />
        {level === 1 ? "Basic" : "Full"}
      </div>
    </div>
  );

  return (
    <Panel title="Access rights">
      <div className="flex flex-col gap-4 p-4">
        <ElementAccess label="Relata Track" level={3} />
        <ElementAccess label="Relata Explore" level={user.isProUser ? 3 : 1} />
        <ElementAccess label="Relata Report" level={1} />
        <div className="text-base font-medium text-metal">
          Access rights are managed by your Relata admin.
        </div>
      </div>
    </Panel>
  );
};

export const TeamsPanel = ({ teams }: { teams: ITeam[] }) => {
  if (!teams.length)
    return (
      <Panel title="Teams">
        <div className="grid place-items-center gap-2 px-5 pb-12 pt-8 text-center">
          <i className="icon-team text-3xl text-navy" />
          <div className="text-xl font-bold text-navy">
            You aren't in any teams
          </div>
          <div className="text-lg text-metal">
            Your Relata admin is in charge of managing teams. Get in touch with
            them to join a team.
          </div>
        </div>
      </Panel>
    );
  return (
    <Panel title="Teams">
      <div className="flex flex-col gap-4 p-4">
        <div>
          <div className="text-lg font-bold text-navy">Team membership</div>
          <div className="text-metal">
            You have been included in the following teams.
          </div>
        </div>
        <div className="flex flex-col divide-y divide-dust-dark rounded border border-dust-dark text-navy">
          {teams.map(({ color, name, id }) => (
            <div className="flex flex-row gap-2 p-4" key={id}>
              <div
                className={`h-4 w-4 rounded-full`}
                style={{ backgroundColor: color }}
              />
              {name}
            </div>
          ))}
        </div>
        <div className="text-metal">
          Teams are assigned by your Relata admin.
        </div>
      </div>
    </Panel>
  );
};

export const LoginOptionsPanel = ({ user }: { user: IUser }) => {
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const navigate = useNavigate();

  const changePassword = async () => {
    setShowChangePassword(true);
  };

  const doLogout = async () => {
    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await logout().unwrap();
      window.localStorage.clear();
      window.location.replace("/");
    } catch (error) {
      console.error(error);
      navigate("/logout-error");
    }
  };

  return (
    <Panel title="Login options">
      <div className="flex flex-col gap-4 p-4">
        <div>
          <div className="text-lg font-bold text-navy">
            {user.hasUsablePassword ? "Change password" : "Set a password"}
          </div>
          <div className="mt-0.5 text-metal">
            {user.hasUsablePassword
              ? "By default, Relata uses email authentication to give you access to your account, however as you also have a password set, you can change it here."
              : "By default, Relata uses email authentication to give you access to your account, however you are also welcome to set a password."}
          </div>
        </div>
        <div>
          <Button
            color="dust"
            id="password-change-button"
            text={user.hasUsablePassword ? "Change password" : "Set a password"}
            onClick={changePassword}
          />
        </div>
        <div className="mt-1.5">
          <div className="text-lg font-bold text-navy">Log out</div>
          <div className="mt-0.5 text-metal">
            You can log out of your account on this device at any time.
          </div>
        </div>
        <div>
          <Button
            isLoading={isLogoutLoading}
            color="dust"
            text="Log out"
            id="logout-button"
            onClick={doLogout}
          />
        </div>
      </div>
      <ChangePasswordModal
        user={user}
        visible={showChangePassword}
        isPasswordChanged={setPasswordChanged}
        onClose={() => setShowChangePassword(false)}
      />
      <div className="z-100">
        <PasswordChangedModal user={user} visible={passwordChanged} />
      </div>
    </Panel>
  );
};

export const PersonalisationPanel = () => {
  const [storedSection, setStoredSection] = useLocalStorage(
    "landing-page",
    env("REACT_APP_DEFAULT_ROUTE") || "explore"
  );
  const [section, setSection] = useState(storedSection);

  const handleRequest = () => {
    setStoredSection(section);
  };

  return (
    <Panel title="Personalisation">
      <div className="flex flex-col gap-4 p-4">
        <div>
          <div className="text-lg font-bold text-navy">Home view</div>
          <div className="mt-0.5 text-metal">
            Select the section of Relata you see when you first log in.
          </div>
        </div>
        <RadioButton
          id="track"
          groupName="section"
          value={section}
          onChange={setSection}
          text="Track"
        />
        <RadioButton
          id="explore"
          groupName="section"
          value={section}
          onChange={setSection}
          text="Explore"
        />
        <RadioButton
          id="report"
          groupName="section"
          value={section}
          onChange={setSection}
          text="Report"
        />
        <div>
          <Button
            color="dust"
            text="Save changes"
            disabled={section === storedSection}
            onClick={handleRequest}
          />
        </div>
      </div>
    </Panel>
  );
};
