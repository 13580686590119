import FeedbackMessage from "components/FeedbackMessage";

const EmailError = () => (
  <FeedbackMessage
    icon="icon-warning-hex"
    title="We can't load Sent emails"
    paragraps={[
      "Try refreshing your browser. If this issue persists, get in contact with our support team.",
    ]}
  />
);
export default EmailError;
