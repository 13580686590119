import { ILink } from "features/link/link.interface";
import { useGetLinksQuery } from "features/link/linkApi";
import Button from "components/Button";
import { useState } from "react";
import TextInput from "components/TextInput";
import useDebounce from "utils/hooks/useDebounce";
import { useTrackFilterOptions } from "utils/trackOptions";
import { useGetMailboxesQuery } from "features/message/messageApi";
import TrackOptionsModal from "../modals/TrackOptionsModal";
import FeedbackMessage from "components/FeedbackMessage";
import LinkTrackingList from "./LinkTrackingList";
import { LinkTrackingListSkeleton } from "./LinkTrakingSkeletons";
import { LinkTrackingError } from "./LinkTrackingError";

const LinkTrackingEmptySearch = () => (
  <FeedbackMessage
    icon="icon-search"
    title="No links found"
    paragraps={["Try adjusting your spelling"]}
  />
);

const LinkTrackingEmpty = () => (
  <FeedbackMessage
    icon="icon-link"
    title="Welcome to Link tracking"
    paragraps={[
      "When you include URLs in the emails you send, we will aggregate them here so you can see how each individual link is performing across all of your emails.",
      "Links will only show here once a contact has clicked the link in an email.",
    ]}
    hasGradientBackground
  />
);

const LinkTrackingView = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [cursor, setCursor] = useState<string | undefined>();
  const [trackOptions, setTrackOptions] = useTrackFilterOptions();
  const { data: mailboxes } = useGetMailboxesQuery();
  const {
    data: links,
    isLoading: isLinksLoading,
    isFetching: isLinksFetching,
    isError: isLinksError,
  } = useGetLinksQuery({
    search: debouncedSearchValue.length > 1 ? debouncedSearchValue : "",
    cursor,
    trackOptions,
  });
  const isFetching = isLinksFetching || debouncedSearchValue !== searchValue;
  const [showOptions, setShowOptions] = useState(false);

  const fetchMore = () => {
    if (links?.next) {
      setCursor(links.next);
    }
  };

  const onChangeOptions = (value: any) => {
    setTrackOptions(value);
    setCursor(undefined);
  };

  const localSearch = (link: ILink) => {
    if (!searchValue) return true;
    return link.url.toLowerCase().includes(searchValue.toLowerCase());
  };

  if (isLinksError) return <LinkTrackingError />;
  if (isLinksLoading) return <LinkTrackingListSkeleton withSearch />;
  if (links?.results.length === 0 && !searchValue && !isFetching)
    return <LinkTrackingEmpty />;
  return (
    <div className="flex h-full w-full flex-col gap-4">
      <div className="mx-auto flex w-full max-w-5xl flex-row items-center gap-x-2 px-4 pt-4">
        <TextInput
          placeholder="Search for a link by URL..."
          onChangeText={setSearchValue}
          value={searchValue}
          icon="icon-search"
          size="large"
          clearable
        />
        <Button
          icon="icon-filter-settings"
          color="white"
          onClick={() => setShowOptions(true)}
          tooltip="Track options"
        />
      </div>
      {!!searchValue &&
      !isLinksFetching &&
      links?.results.filter(localSearch).length === 0 ? (
        <LinkTrackingEmptySearch />
      ) : (
        <LinkTrackingList
          fetchMore={fetchMore}
          isFetching={isFetching}
          hasNoMore={!links?.next && !isFetching}
          links={links?.results.filter(localSearch) || []}
          searchValue={searchValue}
        />
      )}
      {mailboxes && (
        <TrackOptionsModal
          mailboxes={mailboxes}
          visible={showOptions}
          onChange={onChangeOptions}
          onClose={() => setShowOptions(false)}
        />
      )}
    </div>
  );
};

export default LinkTrackingView;
