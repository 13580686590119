import { Outlet, useMatches, useNavigate } from "react-router-dom";
import NavItem from "components/NavItem";
import SearchBar from "features/search/components/SearchBar";
import { ISearchResult } from "features/search/search.interfaces";
import PermissionGuard from "utils/permissions/PermissionGuard";
import { Permissions } from "features/profile";
import { useGetUserQuery } from "features/profile/profileApi";
import { head } from "ramda";
import { useEffect } from "react";

const ExplorePage = () => {
  const navigate = useNavigate();
  const matches = useMatches();
  const { data: user } = useGetUserQuery();

  // effect to redirect user from root /explore url
  useEffect(() => {
    if (matches.length === 2 && user) {
      // determine where users should be redirected based on permissions
      const choices = [
        { permission: Permissions.USE_WATCHLIST, route: "/explore/watchlist" },
        {
          permission: Permissions.ACCESS_CONTACT_TABLE,
          route: "/explore/all-contacts",
        },
        {
          permission: Permissions.ACCESS_COMPANY_TABLE,
          route: "/explore/all-companies",
        },
      ];
      const choice = head(
        choices.filter((choice) => user.permissions.includes(choice.permission))
      );
      if (choice) {
        navigate(choice.route);
      } else {
        navigate("/track");
      }
    }
  }, [user, matches, navigate]);

  const openSearchResult = (result: ISearchResult) => {
    if (result.entityType === "company") {
      navigate(`/explore/company/${result.entityUuid}/at-a-glance`);
    } else if (result.entityType === "contact") {
      navigate(`/explore/contact/${result.entityUuid}/at-a-glance`);
    }
  };

  return (
    <>
      <div className="h-[176px] w-full bg-navy bg-explore-header bg-auto bg-center bg-no-repeat">
        <div className="mx-auto w-[350px] pt-24 sm:w-[500px] md:w-[600px]">
          <SearchBar
            placeholder="Search for a contact or a company..."
            onOpen={openSearchResult}
            sendAnalytics
          />
        </div>
      </div>
      <div className="sticky top-[63px] z-30 flex h-12 items-center justify-center gap-8 border border-dust-dark bg-white-96 shadow backdrop-blur">
        <PermissionGuard permission={Permissions.USE_WATCHLIST}>
          <NavItem text="Watchlist" to="/explore/watchlist" />
        </PermissionGuard>
        <PermissionGuard permission={Permissions.ACCESS_CONTACT_TABLE}>
          <NavItem text="All contacts" to="/explore/all-contacts" />
        </PermissionGuard>
        <PermissionGuard permission={Permissions.ACCESS_COMPANY_TABLE}>
          <NavItem text="All companies" to="/explore/all-companies" />
        </PermissionGuard>
      </div>

      <Outlet />
    </>
  );
};

export default ExplorePage;
