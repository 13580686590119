import { mergeRight, without } from "ramda";
import { IUser, Permissions } from "features/profile";
import { faker } from "@faker-js/faker";

export const staffPermissions = Object.values(Permissions);
export const proPermissions = without(
  [Permissions.IS_STAFF_USER, Permissions.IS_DEMO_USER],
  staffPermissions
);
export const basicPermissions = without(
  [
    Permissions.ACCESS_CONTACT_EXTRA,
    Permissions.ACCESS_COMPANY_EXTRA,
    Permissions.USE_FILTER_EXTRA,
    Permissions.USE_CONTACT_SIGNATURE,
  ],
  proPermissions
);
export const trackedPermissions = [Permissions.USE_TRACK];
export const demoPermissions = [Permissions.IS_DEMO_USER];

// strictly theoretical permission set
export const proWithoutWatchlistPermissions = without(
  [Permissions.USE_WATCHLIST],
  proPermissions
);

const fakeUser = (override: Partial<IUser>): IUser => {
  return mergeRight(
    {
      id: faker.datatype.number(),
      email: faker.internet.email(),
      name: faker.name.firstName(),
      surname: faker.name.lastName(),
      permissions: proPermissions,
      dateJoined: faker.date.past().toISOString(),
    },
    override
  );
};

export default fakeUser;
