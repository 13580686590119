import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import LoginContainer from "./LoginContainer";

const LoginRequestLinkDone = ({ email }: { email: string }) => {
  const navigate = useNavigate();
  return (
    <LoginContainer text="Welcome to Relata">
      <p className="mt-4 text-center text-metal">
        We've sent an email to
        <br />
        <span className="text-center font-bold text-navy">{email}</span>
        <br />
        which contains a secure link that will allow you to
        <br />
        access your account.
        <br />
        <br />
        Open this email, and click the link to continue.
      </p>
      <div className="mt-8 mb-4 flex flex-col px-4">
        <Button color="dust" text="Go back" onClick={() => navigate("/")} />
      </div>
    </LoginContainer>
  );
};
export default LoginRequestLinkDone;
