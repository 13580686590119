import classNames from "classnames";

import { csrfInit } from "utils/requests";
import {
  useGetUserQuery,
  useUnimpersonateMutation,
} from "features/profile/profileApi";
import useLocalStorage from "utils/hooks/useLocalStorage";
import Button from "../Button";

const ImpersonationBar = () => {
  const { data: user } = useGetUserQuery();
  const [unimpersonate] = useUnimpersonateMutation();
  const [demoImpersonator] = useLocalStorage("demo-impersonating", null);

  const onContactSales = async () => {
    window.location.href = "mailto:contact@relata.ai";
  };

  const getMode = () => {
    if (user && (user.isDemoUser || demoImpersonator)) {
      return "demo";
    } else {
      return "impersonation";
    }
  };

  const onUnimpersonate = async () => {
    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await unimpersonate().unwrap();
    } catch (error) {
      console.error(error);
    }
    window.localStorage.clear();
    window.location.href = "/";
  };

  if (!(user && (user.impersonating || user.isDemoUser))) return <></>;

  return (
    <div
      className={classNames(
        "transition-default fixed top-0 z-40 flex h-12 w-full items-center justify-between px-4 py-2",
        {
          "bg-navy text-white": getMode() === "demo",
          "bg-mint text-navy": getMode() === "impersonation",
        }
      )}
      data-testid="impersonation-bar"
    >
      {getMode() === "demo" ? (
        <>
          <div className="text-lg font-bold">
            Relata Demo {demoImpersonator !== null && `- ${demoImpersonator}`}
          </div>
          <div className="flex">
            <Button
              color="navy"
              icon="icon-email"
              tooltip="Contact sales"
              text="Contact sales team"
              onClick={onContactSales}
            />
            {demoImpersonator !== null && (
              <Button
                color="navy"
                icon="icon-cross"
                tooltip="Exit demo"
                onClick={onUnimpersonate}
                text="Exit demo"
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex-col">
            <div className="text-base font-bold">
              Impersonating {user.fullName}
            </div>
            <div className="text-sm">{user.email}</div>
          </div>
          <Button
            color="transparent"
            icon="icon-cross"
            tooltip="Exit impersonation"
            onClick={onUnimpersonate}
            id="unimpersonate-button"
          />
        </>
      )}
    </div>
  );
};

export default ImpersonationBar;
