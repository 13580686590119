import { head, propOr } from "ramda";
import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";

import { useGetUserQuery } from "features/profile/profileApi";

export interface IEntityRankingItem {
  id: string;
  email: string;
  phoneNumbers?: any[];
  fullName: string;
  position: number;
  role?: string;
  trend: any;
  relationshipScore: any;
}

const useEntityRanking = ({
  source = "dim_user",
  contactId,
  companyId,
  additionalFilters,
}: {
  source?: "dim_user" | "dim_contact";
  contactId?: string;
  companyId?: string;
  additionalFilters?: Filter[];
}) => {
  const { data: user } = useGetUserQuery();

  const dimensions = {
    dim_user: ["full_name", "email", "uuid"],
    dim_contact: [
      "full_name",
      "email",
      "uuid",
      ...(user?.showContactSignatures ? ["roles", "phone_numbers"] : []),
    ],
  };
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };
  const { resultSet, isLoading, error } = useCubeQuery({
    dimensions: dimensions[source].map((dim) => `${source}.${dim}`),
    measures: ["fact_table.relationship_score_current", "fact_table.trend"],
    order: {
      "fact_table.relationship_score_current": "desc",
      [`${source}.full_name`]: "asc",
    },
    limit: 5,
    filters: [...entityFilters(), ...(additionalFilters || [])],
  });

  if (error) {
    console.error(error);
  }

  const rows: { [key: string]: any } = resultSet?.tablePivot() || [];
  const results: IEntityRankingItem[] = rows.map((row: any, index: number) => ({
    id: row[`${source}.uuid`] as string,
    email: row[`${source}.email`] as string,
    phoneNumbers: JSON.parse(propOr("[]", `${source}.phone_numbers`, row)),
    fullName: row[`${source}.full_name`] as string,
    role:
      source === "dim_contact" && user?.showContactSignatures
        ? (head(JSON.parse(propOr("[]", "dim_contact.roles", row))) as string)
        : "",
    position: index + 1,
    trend: row["fact_table.trend"],
    relationshipScore: row["fact_table.relationship_score_current"],
  }));

  return {
    results,
    isLoading,
  };
};

export default useEntityRanking;
