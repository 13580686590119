import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import env from "config";

import intercomPlugin from "./intercom";
import heapPlugin from "./heap";
import hotjarPlugin from "./hotjar";

const analytics: any = Analytics({
  app: "relata",
  plugins: [
    googleAnalytics({
      measurementIds: [env("REACT_APP_GOOGLE_ANALYTICS_CODE")],
    }),
    heapPlugin({
      appId: env("REACT_APP_HEAP_ID"),
    }),
    hotjarPlugin({
      appId: env("REACT_APP_HOTJAR_ID"),
    }),
    intercomPlugin({
      appId: env("REACT_APP_INTERCOM_ID"),
    }),
  ],
});

export default analytics;
