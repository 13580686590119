import FeedbackMessage from "components/FeedbackMessage";

const EmailDetailError = () => (
  <FeedbackMessage
    icon="icon-warning-hex"
    title="We can't load this email"
    paragraps={[
      "Try refreshing your browser. If this issue persists, get in contact with our support team.",
    ]}
  />
);
export default EmailDetailError;
