import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import ErrorPage from "pages/ErrorPage";
import { Permissions } from "features/profile";

import ExplorePage from "pages/ExplorePage";
import WatchListView from "features/watchlist/components/WatchListView";

import {
  ContactGlanceView,
  ContactRelationshipView,
  ContactProfileView,
  ContactsView,
} from "pages/explore/contact/";
import {
  CompaniesView,
  CompanyContactsView,
  CompanyGlanceView,
  CompanyProfileView,
  CompanyRelationshipChangeView,
  CompanyRelationshipExplorerView,
} from "pages/explore/company";
import AccessDeniedView from "pages/explore/common/AccessDeniedView";

import TrackPage from "pages/TrackPage";
import TrackForbidden from "pages/track/TrackForbidden";
import InteractionTimelineView from "pages/track/interaction/InteractionTimelineView";
import SentEmailsView from "pages/track/email/SentEmailsView";
import LinkTrackingView from "pages/track/link/LinkTrackingView";

import ReportForbidden from "pages/report/ReportForbidden";
import ReportPage from "pages/ReportPage";

import Welcome from "features/profile/Login/Welcome";
import Login from "features/profile/Login";
import LoginPasswordReset from "features/profile/Login/LoginPasswordReset";
import LogoutError from "features/profile/Account/AccountLogoutError";
import Account from "features/profile/Account";
import DemoView from "features/demo/DemoView";

import AdminRedirect from "pages/AdminRedirect";
import SSORedirect from "pages/SSORedirect";
import PermissionGuard from "utils/permissions/PermissionGuard";

import ReportsListView from "pages/report/ReportsListView";
import LapsingEngagementReport from "pages/report/reports/LapsingEngagementReport";
import FrequentRevisitsReport from "pages/report/reports/FrequentRevisitsReport";
import UnresponsiveContactsReport from "pages/report/reports/UnresponsiveContactsReport";
import ActivityInNumbersReport from "pages/report/reports/ActivityInNumbersReport";
import TopicExplorerReport from "pages/report/reports/TopicExplorerReport";
import ClientServiceLevelReport from "pages/report/reports/ClientServiceLevelReport";
import {
  EmailActivityView,
  MeetingActivityView,
} from "pages/explore/common/ActivityView";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <LoginPasswordReset />,
  },
  { path: "/sso-login", element: <SSORedirect /> },
  { path: "/admin", element: <AdminRedirect /> },
  { path: "/welcome", element: <Welcome /> },
  {
    path: "/demo",
    element: (
      <PermissionGuard permission={Permissions.IS_DEMO_USER}>
        <DemoView />
      </PermissionGuard>
    ),
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage type={404} />,
    children: [
      {
        path: "explore",
        element: <ExplorePage />,
        handle: { crumb: "Explore" },
        children: [
          {
            path: "watchlist",
            element: (
              <PermissionGuard permission={Permissions.USE_WATCHLIST}>
                <WatchListView />
              </PermissionGuard>
            ),
            handle: { crumb: "Watchlist" },
          },
          {
            path: "all-contacts",
            element: (
              <PermissionGuard permission={Permissions.ACCESS_CONTACT_TABLE}>
                <ContactsView />
              </PermissionGuard>
            ),
            handle: { crumb: "All contacts" },
          },
          {
            path: "all-companies",
            element: (
              <PermissionGuard permission={Permissions.ACCESS_COMPANY_TABLE}>
                <CompaniesView />
              </PermissionGuard>
            ),
            handle: { crumb: "All companies" },
          },
        ],
      },
      {
        path: "explore/company/:id",
        handle: { crumb: "Explore" },
        element: <CompanyProfileView />,
        children: [
          { path: "", element: <Navigate to="at-a-glance" replace /> },
          {
            path: "at-a-glance",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_COMPANY_DETAILS}
                denied={<AccessDeniedView name="Company details" />}
              >
                <CompanyGlanceView />
              </PermissionGuard>
            ),
          },
          {
            path: "all-contacts",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_COMPANY_EXTRA}
                denied={<AccessDeniedView name="All contacts" />}
              >
                <CompanyContactsView />
              </PermissionGuard>
            ),
          },
          {
            path: "relationship-change",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_COMPANY_EXTRA}
                denied={<AccessDeniedView name="Relationship change" />}
              >
                <CompanyRelationshipChangeView />
              </PermissionGuard>
            ),
          },
          {
            path: "relationship-explorer",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_COMPANY_EXTRA}
                denied={<AccessDeniedView name="Relationship explorer" />}
              >
                <CompanyRelationshipExplorerView />
              </PermissionGuard>
            ),
          },
          {
            path: "emails",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_EXTRA}
                denied={<AccessDeniedView name="Emails" />}
              >
                <EmailActivityView type="company" />
              </PermissionGuard>
            ),
          },
          {
            path: "meetings",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_EXTRA}
                denied={<AccessDeniedView name="Meetings" />}
              >
                <MeetingActivityView type="company" />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: "explore/contact/:id",
        handle: { crumb: "Explore" },
        element: <ContactProfileView />,
        children: [
          { path: "", element: <Navigate to="at-a-glance" replace /> },
          {
            path: "at-a-glance",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_DETAILS}
                denied={<AccessDeniedView name="Contact details" />}
              >
                <ContactGlanceView />
              </PermissionGuard>
            ),
          },
          {
            path: "relationship-change",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_EXTRA}
                denied={<AccessDeniedView name="Relationship change" />}
              >
                <ContactRelationshipView />
              </PermissionGuard>
            ),
          },
          {
            path: "emails",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_EXTRA}
                denied={<AccessDeniedView name="Emails" />}
              >
                <EmailActivityView type="contact" />
              </PermissionGuard>
            ),
          },
          {
            path: "meetings",
            element: (
              <PermissionGuard
                permission={Permissions.ACCESS_CONTACT_EXTRA}
                denied={<AccessDeniedView name="Meetings" />}
              >
                <MeetingActivityView type="contact" />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: "track",
        element: (
          <PermissionGuard
            permission={Permissions.USE_TRACK}
            denied={<TrackForbidden />}
          >
            <TrackPage />
          </PermissionGuard>
        ),
        handle: { crumb: "Track" },
        children: [
          { path: "", element: <Navigate to="/track/sent-emails" replace /> },
          {
            path: "sent-emails",
            element: <SentEmailsView />,
            handle: { crumb: "Sent Emails" },
            children: [
              {
                path: "email/:id",
                element: <SentEmailsView />,
              },
            ],
          },
          {
            path: "interaction-timeline",
            element: <InteractionTimelineView />,
            handle: { crumb: "Interaction Timeline" },
            children: [
              {
                path: "email/:id",
                element: <InteractionTimelineView />,
              },
            ],
          },
          {
            path: "link-tracking",
            element: <LinkTrackingView />,
            handle: { crumb: "Link Tracking" },
            children: [
              {
                path: "email/:id",
                element: <LinkTrackingView />,
              },
            ],
          },
        ],
      },
      {
        path: "report",
        handle: { crumb: "Report" },
        element: (
          <PermissionGuard
            permission={Permissions.USE_REPORT}
            denied={<ReportForbidden />}
          >
            <ReportPage />
          </PermissionGuard>
        ),
        children: [
          { path: "", element: <Navigate to="/report/all-reports" replace /> },
          {
            path: "all-reports",
            element: <ReportsListView />,
          },
          {
            path: "lapsing-engagement",
            element: <LapsingEngagementReport />,
          },
          {
            path: "frequent-revisits",
            element: <FrequentRevisitsReport />,
          },
          {
            path: "unresponsive-contacts",
            element: <UnresponsiveContactsReport />,
          },
          {
            path: "activity-in-numbers",
            element: <ActivityInNumbersReport />,
          },
          {
            path: "client-service-level",
            element: <ClientServiceLevelReport />,
          },
          {
            path: "topic-explorer",
            element: <TopicExplorerReport />,
          },
        ],
      },
      { path: "account", handle: { crumb: "Account" }, element: <Account /> },
      {
        path: "logout-error",
        element: <LogoutError />,
      },
    ],
  },
]);

export default router;
