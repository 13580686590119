import classNames from "classnames";
import ColorTag, { ColorTagColor } from "components/atoms/ColorTag";
import { TrendContainer } from "components/table/Cells";
import { IWatchListItemKPI } from "features/watchlist/watchlist.interfaces";

export const TextWatchListItemCell = ({
  title,
  label,
}: {
  title: string;
  label: string;
}) => (
  <div>
    <div className="text-metal">{title}</div>
    <div className="font-bold text-navy">{label}</div>
  </div>
);

export const EmailBreakdownWatchListItemCell = ({
  title,
  emailsIn,
  emailsOut,
}: {
  title: string;
  emailsIn: number;
  emailsOut: number;
}) => (
  <div className="container">
    <div className="text-metal">{title}</div>
    <div className="flex gap-6 font-bold text-navy">
      <div className="flex items-center justify-center gap-2">
        <i className="icon-email-in text-xl"></i>
        {emailsIn}
      </div>
      <div className="flex items-center justify-center gap-2">
        <i className="icon-email-out text-xl"></i>
        {emailsOut}
      </div>
    </div>
  </div>
);

export const TrendWatchListItemCell = ({
  title,
  value,
  trend,
  secondaryValue,
  secondaryLabel,
}: {
  title: string;
  value: number;
  trend: "increase" | "decrease" | "maintain";
  secondaryValue?: number;
  secondaryLabel?: string;
}) => (
  <>
    <div className="text-metal">{title}</div>
    <TrendContainer
      trend={trend}
      value={value}
      secondaryLabel={secondaryLabel}
      secondaryValue={secondaryValue}
    />
  </>
);

export const ProgressBarWatchListItemCell = ({
  title,
  value,
  color,
  trend,
}: {
  title: string;
  value: number;
  color: ColorTagColor;
  trend: string;
}) => (
  <div className="container">
    <div className="text-metal">{title}</div>
    <div className="flex items-center justify-between">
      <div className="font-bold text-navy">{value}</div>
      <div className="text-sm text-metal-dark">{trend}</div>
    </div>

    <div className="mt-2 h-2 w-full rounded-full bg-dust-light">
      <div
        className={`h-2 rounded-full bg-${color}-dark`}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  </div>
);

export const InteractionWatchListItemCell = ({
  title,
  when,
  who,
  tag,
}: {
  title: string;
  when: string;
  who: string;
  tag?: { color: ColorTagColor; label: string };
}) => (
  <div className="container">
    <div className="text-metal">{title}</div>
    <div className="flex items-center justify-between">
      <div className="font-bold text-navy">{when}</div>
      {tag && tag.color && (
        <ColorTag color={tag.color || "green"} label={tag.label} />
      )}
    </div>
    {who && (
      <div className={classNames("text-sm text-metal-dark")}>with {who}</div>
    )}
  </div>
);

export const GenericWatchListItemCell = (data: IWatchListItemKPI) => {
  switch (data.type) {
    case "progress-bar":
      return <ProgressBarWatchListItemCell {...data.meta} />;
    case "text":
      return <TextWatchListItemCell {...data.meta} />;
    case "trend":
      return <TrendWatchListItemCell {...data.meta} />;
    case "email-breakdown":
      return <EmailBreakdownWatchListItemCell {...data.meta} />;
    case "interaction":
      return <InteractionWatchListItemCell {...data.meta} />;
  }
};
