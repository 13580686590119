const ContactReportCardSkeleton = () => (
  <div className="h-[176px] w-[397px] rounded border border-l-8 border-dust p-4">
    <div className="flex flex-col divide-y divide-dust">
      <div className="flex h-12 items-center justify-between pb-4">
        <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
      </div>
      <div className="flex flex-col gap-2 pt-2">
        <div className="h-4 w-[317px] animate-pulse rounded-sm bg-dust-light"></div>
        <div className="h-4 w-[290px] animate-pulse rounded-sm bg-dust-light"></div>
        <div className="block pt-4">
          <div className="flex h-8 w-[200px] animate-pulse items-center justify-center rounded bg-dust"></div>
        </div>
      </div>
    </div>
  </div>
);

const LiveReportBadgeSkeleton = () => (
  <div className="flex h-[176px] w-[146px] flex-shrink-0 flex-col items-center justify-center gap-2 rounded bg-dust"></div>
);

const LiveReportPreviewSkeleton = () => (
  <div className="w-full rounded-lg border border-dust-dark bg-white p-4 shadow lg:w-[1000px]">
    <div className="flex flex-col divide-y divide-dust">
      <div className="flex gap-2 pb-4">
        <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
        <div className="flex flex-col">
          <div className="h-4 w-[120px] animate-pulse rounded-sm bg-dust-light"></div>
          <div className="h-4 w-[300px] animate-pulse rounded-sm bg-dust"></div>
        </div>
        <div className="ml-auto">
          <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
        </div>
      </div>
      <div className="pt-4">
        <div className="relative flex gap-4 overflow-hidden">
          <LiveReportBadgeSkeleton />
          <div className="flex gap-4">
            <ContactReportCardSkeleton />
            <ContactReportCardSkeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LiveReportPreviewSkeleton;
