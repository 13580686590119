import { useEffect, useState } from "react";
import { head, propOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { formatDistanceToNow } from "date-fns";

import { changeBreadcrumb } from "app/breadcrumbSlice";
import Button from "components/Button";
import EntityIdentifier from "components/EntityIdentifier";
import SkeletonReportContact from "pages/report/components/SkeletonReportContact";

import { selectCubeLastRefresh } from "app/cubeLastRefreshSlice";
import useFrequentRevisits from "pages/report/hooks/useFrequentRevisits";
import ActivityItem, {
  ActivityItemSkeleton,
} from "pages/explore/common/ActivityView/ActivityItem";
import useFrequentRevisitsEmails from "pages/report/hooks/useFrequentRevisitsEmails";
import { useGetContactQuery } from "features/entity/entityApi";
import { showContactModal } from "app/contactModalsSlice";

const ContactCard = ({
  id,
  name,
  company,
  email,
  phoneNumbers,
  emails,
  revisits,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  phoneNumbers: any[];
  emails: number;
  revisits: number;
}) => {
  const [showEmails, setShowEmails] = useState(false);
  const { resultSet, isLoading } = useFrequentRevisitsEmails({
    id,
    skip: !showEmails,
  });
  const firstName = head(name.split(" "));
  const dispatch = useDispatch();
  const { data: contact } = useGetContactQuery({
    id,
  });

  const onFlagClick = () =>
    dispatch(
      showContactModal({
        modal: "flags",
        contact,
      })
    );

  return (
    <div className="rounded border border-l-8 border-dust border-l-purple-lightest px-4 py-2">
      <div className="flex w-full flex-col md:flex-row md:items-center">
        <div className="flex h-12 w-[260px] items-center justify-between">
          <EntityIdentifier
            type="contact"
            id={id}
            title={name}
            description={company}
            meta={{ email, phoneNumbers }}
          />
        </div>
        <div className="my-2 w-full border-t border-t-dust md:hidden"></div>
        <div className="flex flex-col gap-2">
          <div className="text-metal">
            {firstName} has{" "}
            <span className="font-bold">revisited {emails} of your emails</span>{" "}
            a total of <span className="font-bold">{revisits} times</span>.
          </div>
        </div>
        <div className="flex gap-2 pb-2 pt-4 md:ml-auto md:flex-row-reverse md:p-0">
          <Button
            color="dust"
            iconRight
            text={showEmails ? "Hide emails" : "Show emails"}
            icon={showEmails ? "icon-up" : "icon-down"}
            onClick={() => setShowEmails(!showEmails)}
          />
          <Button
            color="transparent"
            icon="icon-flag"
            onClick={onFlagClick}
            tooltip="Flag contact"
          />
        </div>
      </div>
      {showEmails && (
        <>
          <div className="flex max-h-60 flex-col overflow-scroll">
            <div className="my-4 w-full border-t border-t-dust"></div>
            <div className="text-metal">
              Emails that {firstName} has revisited
            </div>
            {isLoading && <ActivityItemSkeleton simple />}
            {!isLoading &&
              resultSet.map((email, index) => (
                <ActivityItem
                  key={index}
                  type="email-sent"
                  subject={email["dim_email.subject"]}
                  description={`${email["fact_email.revisits"]} revisits`}
                  date={new Date(email["dim_email.message_sent_date_time"])}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const FrequentRevisitsReport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
        subsection: "Live Report",
        link: "/report/",
      })
    );
  }, [dispatch]);

  const { resultSet, isLoading } = useFrequentRevisits();
  const lastRefreshed = useSelector(selectCubeLastRefresh);

  return (
    <div className="min-h-[680px] w-full overflow-scroll rounded-lg border border-dust-dark bg-white shadow lg:h-[680px] lg:w-[1000px]">
      <div className="relative flex flex-col">
        <div className="sticky top-0 z-10 flex w-full flex-col gap-4 rounded-lg bg-white p-4">
          <div className="flex items-start gap-2">
            <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-b from-purple to-purple-dark text-white">
              <i className="icon-email-star text-xl" />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="font-bold text-navy">Frequent revisits</div>
              <div className="flex max-w-full gap-2 text-metal">
                {lastRefreshed.time
                  ? `Last updated ${formatDistanceToNow(
                      lastRefreshed.time
                    )} ago `
                  : "Live report "}
                – Contacts who have frequently revisited your emails in the
                previous 30 days
              </div>
            </div>
          </div>
          <div className="w-full border-t border-t-dust"></div>
        </div>
        <div className="px-4 pb-4">
          <div
            className="flex flex-col gap-2"
            data-testid="frequent-revisitors"
          >
            {isLoading ? (
              <SkeletonReportContact />
            ) : (
              resultSet.map((contact) => (
                <ContactCard
                  key={contact["dim_contact.uuid"] as string}
                  id={contact["dim_contact.uuid"] as string}
                  name={contact["dim_contact.full_name"] as string}
                  company={contact["dim_company.display_name"] as string}
                  email={contact["dim_contact.email"] as string}
                  phoneNumbers={JSON.parse(
                    propOr("[]", "dim_contact.phone_numbers", contact)
                  )}
                  emails={contact["emails"]}
                  revisits={contact["revisits"]}
                />
              ))
            )}
            {!isLoading && resultSet.length === 0 && (
              <div className="mt-40 flex flex-col items-center justify-center">
                <i className="icon-face-sad text-3xl text-navy"></i>
                <div className="text-xl font-bold text-navy">
                  No frequent revisits
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentRevisitsReport;
