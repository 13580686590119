import Button from "components/Button";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { csrfInit } from "utils/requests";
import { isValidEmail } from "utils/emailValidation";
import {
  useGetUserQuery,
  useLoginOtpMutation,
  useRequestOtpMutation,
} from "../profileApi";
import LoginContainer from "./LoginContainer";
import LoginRequestLinkDone from "./LoginRequestLinkDone";
import RelataLoading from "assets/RelataLoading.svg";

const Welcome = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } = useGetUserQuery();

  const [queryParameters] = useSearchParams();
  const email = queryParameters.get("email");
  const secret = queryParameters.get("verification_code");

  const [otpLogin, { isError, isSuccess, isLoading }] = useLoginOtpMutation();
  const [requestLink, { isLoading: isLoadingRequest }] =
    useRequestOtpMutation();
  const [requestDone, setRequestDone] = useState(false);
  const [requestError, setRequestError] = useState(false);

  document.title = "Link expired | Relata";

  useEffect(() => {
    const checkCredentials = async () => {
      if (
        typeof email === "string" &&
        typeof secret === "string" &&
        !isLoading &&
        !isError &&
        !isSuccess
      ) {
        try {
          if (!(await csrfInit())) throw new Error("no CSRF set");
          await otpLogin({ email, secret }).unwrap();
          window.location.reload();
        } catch (error) {
          console.error(error);
          setRequestError(true);
        }
      }
    };
    checkCredentials();
  }, [email, isLoading, isError, isSuccess, secret, otpLogin]);

  const handleRequest = async () => {
    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await requestLink({ email: email as string }).unwrap();
    } catch (error) {
      console.error(error);
    }
    setRequestDone(true);
  };

  if (!isProfileLoading && profile) {
    const url = JSON.parse(
      localStorage.getItem("redirect-path-at-login") || '"/"'
    );
    return <Navigate to={url} replace />;
  }

  if (!email || !isValidEmail(email)) {
    return <Navigate to="/" replace />;
  }

  if (requestDone) return <LoginRequestLinkDone email={email as string} />;

  if (requestError)
    return (
      <LoginContainer
        icon="icon-warning-hex"
        text="Secure login link has expired"
      >
        <p className="mt-4 text-center text-metal">
          The secure login link you have used has expired.
          <br />
          <br />
          This can happen if you have already used this link,
          <br />
          or if you have requested multiple login links at the
          <br />
          same time.
        </p>

        <div className="mb-4 mt-8 flex flex-col gap-4 px-4">
          <Button
            isLoading={isLoadingRequest}
            color="dust"
            text="Request a new secure login link"
            onClick={handleRequest}
          />
          <Button
            color="dust"
            text="Go back to login"
            onClick={() => navigate("/login")}
          />
        </div>
      </LoginContainer>
    );

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      <img
        src={RelataLoading}
        className="h-[96px] w-[96px]"
        alt="loading spinner"
      />
      <div className="mt-14 text-2xl text-navy">Welcome to Relata</div>
      <div className="text-lg text-metal">
        The faster, simpler way to deepen client relationships
      </div>
    </div>
  );
};

export default Welcome;
