import classnames from "classnames";

type ProductItemType = "mint" | "navy" | "sky";

const ProductItem = ({
  type,
  text,
  icon,
  description,
  onClick,
}: {
  type: ProductItemType;
  text: string;
  icon: string;
  description: string;
  onClick: () => void;
}) => {
  const onKeyDown = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && onClick) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className="group flex h-20 cursor-pointer rounded-lg border border-dust-dark p-4 outline-none hover:shadow focus:ring focus:ring-mint sm:h-[102px] sm:w-full sm:flex-col lg:h-20 xl:max-w-[350px] xl:flex-row"
    >
      <div
        className={classnames(
          "mr-4 inline-flex h-12 w-12 items-center justify-center rounded sm:h-2 sm:w-6 xl:h-12 xl:w-12",
          {
            [`bg-${type}`]: true,
            "text-navy": type === "mint",
            "text-white": type !== "mint",
            "group-hover:bg-mint-dark": type === "mint",
            "group-hover:bg-navy-dark": type === "navy",
            "group-hover:bg-sky-dark": type === "sky",
          }
        )}
      >
        <i
          className={classnames(
            "transition-default text-2xl sm:hidden xl:block",
            { [icon]: true }
          )}
        ></i>
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-xl font-bold">{text}</div>
        <div className="text-metal">{description}</div>
      </div>
    </div>
  );
};

export default ProductItem;
