import {
  ActivityType,
  EngagementType,
  TrendType,
  ResponseBreakdownType,
} from "features/entity/entity.interfaces";

export const trendColors = {
  [TrendType.Strengthening]: "green",
  [TrendType.Consistent]: "green",
  [TrendType.Weakening]: "orange",
};

export const activityColors = {
  [ActivityType.Active]: "green",
  [ActivityType.Inactive]: "orange",
  [ActivityType.Dormant]: "red",
};

export const engagementColors = {
  [EngagementType.Unengaged]: "#F0F8FF",
  [EngagementType.Engaged]: "#FFE871",
  [EngagementType.HighlyEngaged]: "#65D398",
};

export const responseBreakdownColors = {
  [ResponseBreakdownType.Soon]: "#b2e8e1",
  [ResponseBreakdownType.Mid]: "#fbe983",
  [ResponseBreakdownType.Late]: "#e7a8fa",
};
