import { useState } from "react";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import SelectOption from "components/SelectOption";

const TableOptionsModal = ({
  visible,
  options,
  active,
  pages,
  onClose,
  onUpdate,
}: {
  visible: boolean;
  options: { label: string; field: string }[];
  active: string[];
  pages: number;
  onClose: () => void;
  onUpdate: (updates: any) => void;
}) => {
  const update = () => {
    onUpdate({
      pages: internalPages,
      active: internalActive,
    });
    onClose();
  };
  const [internalPages, setInternalPages] = useState(pages);
  const [internalActive, setInternalActive] = useState(active);

  const toggleCheckbox = (key: string) => {
    if (internalActive.includes(key)) {
      setInternalActive(internalActive.filter((value) => value !== key));
    } else {
      setInternalActive([...internalActive, key]);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} height="regular">
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-filter-settings"></i>
            Table options
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 flex flex-col overflow-y-auto text-base">
          <div className="mb-2 text-lg font-bold text-navy">
            Number of table rows
          </div>
          <div className="w-full">
            <SelectOption
              value={internalPages}
              options={[
                { value: "20", label: "20 rows" },
                { value: "40", label: "40 rows" },
                { value: "60", label: "60 rows" },
              ]}
              onChange={setInternalPages}
            />
          </div>
          <div className="mt-6 mb-4">
            <div className="text-lg font-bold text-navy">Data columns</div>
            <div className="text-metal">
              Select the columns shown on the table
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-2 sm:h-auto">
            {options.map((option) => (
              <Checkbox
                checked={internalActive.includes(option.field)}
                onClick={() => toggleCheckbox(option.field)}
                size="small"
                label={option.label}
                key={option.field}
              />
            ))}
          </div>
        </div>
        <div className="mt-auto">
          <Button
            color="navy"
            size="large"
            text="Apply changes"
            onClick={update}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default TableOptionsModal;
