import { useDispatch, useSelector } from "react-redux";
import {
  closeContactModals,
  selectContactModals,
} from "app/contactModalsSlice";
import ContactEmailModal from "pages/explore/modals/ContactEmailModal";
import ContactCallModal from "pages/explore/modals/ContactCallModal";
import { useGetUserQuery } from "features/profile/profileApi";
import ContactSettingsModal from "pages/explore/modals/ContactSettingsModal";
import { decamelizeKeys } from "humps";
import { useUpdateContactMutation } from "features/entity/entityApi";
import { IContactFlag } from "features/entity/entity.interfaces";

const ContactModalsContainer = () => {
  const { data: user } = useGetUserQuery();
  const { showCallModal, showEmailModal, showFlagsModal, contact } =
    useSelector(selectContactModals);
  const dispatch = useDispatch();
  const [updateContact] = useUpdateContactMutation();

  const updateContactSettings = async (flags: Partial<IContactFlag>[]) => {
    await updateContact({ id: contact?.uuid, flags: decamelizeKeys(flags) });
    dispatch(closeContactModals());
  };

  return (
    <>
      <ContactEmailModal
        visible={showEmailModal}
        onClose={() => dispatch(closeContactModals())}
        contact={contact}
        user={user}
      />
      <ContactCallModal
        visible={showCallModal}
        onClose={() => dispatch(closeContactModals())}
        contact={contact}
      />
      <ContactSettingsModal
        visible={showFlagsModal}
        onClose={() => dispatch(closeContactModals())}
        contact={contact}
        onUpdate={updateContactSettings}
        onlyFlags
      />
    </>
  );
};

export default ContactModalsContainer;
