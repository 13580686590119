import { useEffect } from "react";
import ErrorPage from "./ErrorPage";

const SSORedirect = () => {
  useEffect(() => {
    window.location.href = "/api/user/login";
  }, []);

  return <ErrorPage type={404} />;
};
export default SSORedirect;
