import Tooltip from "components/atoms/Tooltip";

const TooltipFrame = ({
  title,
  children,
  tooltip,
}: {
  title: string;
  children: any;
  tooltip?: string;
}) => (
  <div className="flex h-full flex-col">
    <div className="flex items-center rounded-t-lg border border-dust-dark bg-dust p-4">
      <div className="ml-2 flex-1 text-lg text-metal">{title}</div>
      {tooltip && <Tooltip content={tooltip} />}
    </div>
    <div className="flex flex-1 flex-col gap-4 rounded-b-lg border border-t-0 border-dust-dark bg-white p-4">
      {children}
    </div>
  </div>
);

export default TooltipFrame;
