import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";
import { IUser } from "features/profile/user.interface";
import { format } from "date-fns";
import { propEq, find, mergeDeepLeft } from "ramda";
import { oneYearAgoToNowRange } from "utils/dates";

const useEntityWaffleCharts = ({
  contactId,
  companyId,
  user,
  additionalFilters,
  type,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters: Filter[];
  type: "meetings" | "emails";
}) => {
  // fetch twice for my vs all metrics
  const { results: globalWaffleSeries, isLoading: isGlobalLoading } =
    useFetchWaffleData({ contactId, companyId, additionalFilters, type });

  const { results: yourWaffleSeries, isLoading: isYourLoading } =
    useFetchWaffleData({ contactId, companyId, user, additionalFilters, type });

  const results = globalWaffleSeries.map((ws) => {
    const yourKpis = find(propEq("timeLabel", ws.timeLabel))(yourWaffleSeries);
    if (yourKpis) {
      return mergeDeepLeft(yourKpis, ws);
    }
    return ws;
  });

  return {
    results,
    isLoading: isGlobalLoading || isYourLoading,
  };
};

const useFetchWaffleData = ({
  contactId,
  companyId,
  user,
  additionalFilters,
  type,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters: Filter[];
  type: "meetings" | "emails";
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };
  const query = {
    measures:
      type === "emails"
        ? [
            "dim_email.count",
            "dim_email.count_sent",
            "dim_email.count_inbound",
            "dim_email.count_unsolicited",
          ]
        : ["dim_calendar.count"],
    timeDimensions: [
      {
        dimension:
          type === "emails" ? "dim_date.date_actual" : "dim_calendar.start",
        granularity: "day",
        dateRange: oneYearAgoToNowRange(),
      },
    ],
    filters: [...entityFilters(), ...additionalFilters],
  };

  if (user) {
    query.filters.push({
      member: "dim_user.email",
      operator: "equals",
      values: [user.email as string],
    });
  }

  const { resultSet, isLoading, error } = useCubeQuery(query as Query);

  if (error) {
    console.error(error);
  }

  const rows = resultSet?.tablePivot() || [];
  const dateField =
    type === "emails" ? "dim_date.date_actual.day" : "dim_calendar.start.day";

  const results =
    type === "emails"
      ? rows.map((row) => {
          const date = row[dateField]
            ? new Date(row[dateField] as string)
            : null;
          return {
            timeLabel: date ? format(date, "yyyy-MM-dd") : "",
            [user ? "my_all_emails" : "all_emails"]: row["dim_email.count"],
            [user ? "my_emails_sent" : "emails_sent"]:
              row["dim_email.count_sent"],
            [user ? "my_emails_received" : "emails_received"]:
              row["dim_email.count_inbound"],
            [user ? "my_emails_unprompted" : "emails_unprompted"]:
              row["dim_email.count_unsolicited"],
          };
        })
      : rows.map((row) => {
          const date = row[dateField]
            ? new Date(row[dateField] as string)
            : null;
          return {
            timeLabel: date ? format(date, "yyyy-MM-dd") : "",
            [user ? "my_meetings_count" : "meetings_count"]:
              row["dim_calendar.count"],
          };
        });

  return {
    results,
    isLoading,
  };
};

export default useEntityWaffleCharts;
