import { IVersion, useGetBuildQuery } from "app/versionApi";
import { RootState } from "app/store";
import { newVersionToast } from "components/toast/library";
import { IToast } from "components/toast/toast.interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useNotifications from "./useNotifications";

const VERSION_INTERVAL = 120 * 1000;
/**
 * useNewVersionDetector
 * notify when there is a new version
 */
export default function useNewVersionDetector(): void {
  const notifications: IToast[] = useSelector(
    (state: RootState) => state.notifications
  );
  const { notify } = useNotifications();

  const { data: build } = useGetBuildQuery(undefined, {
    pollingInterval: VERSION_INTERVAL,
  });
  const [currentBuild, setCurrentBuild] = useState<IVersion>();

  useEffect(() => {
    if (build === currentBuild || !build) return;
    if (!currentBuild) {
      setCurrentBuild(build);
      return;
    }
    if (currentBuild.version !== build.version) {
      if (notifications.map((e) => e.id).includes(newVersionToast.id)) return;
      notify(newVersionToast);
    }
  }, [build, currentBuild, notify, notifications]);
}
