import Button from "components/Button";

const NewTrackRefetcher = ({
  type,
  onClick,
}: {
  type: "interactions" | "emails";
  onClick: VoidFunction;
}) => {
  const buttonLabel =
    type === "interactions"
      ? "Update Interaction timeline"
      : "Update Sent emails";
  const text = type === "interactions" ? "Interactions" : "Sent emails";
  return (
    <div className="mx-auto w-full max-w-5xl px-4">
      <div className="mb-6 flex items-center justify-between rounded border border-green p-1 pl-2">
        <div className="font-bold text-metal">New {text} are available.</div>
        <Button
          id={`update-${type}`}
          onClick={onClick}
          color="dust"
          icon="icon-refresh"
          text={buttonLabel}
        />
      </div>
    </div>
  );
};

export default NewTrackRefetcher;
