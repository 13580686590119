import MiniStatistic from "components/MiniStatistic";
import { Line, timelineOptions } from "charts";
import { useCubeQuery } from "@cubejs-client/react";
import { useGetUserQuery } from "features/profile/profileApi";
import { propOr } from "ramda";

const TopicOverview = ({ topic }: { topic: { id: any; name: any } }) => {
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isStatsLoading } = useCubeQuery({
    dimensions: ["dim_topic.topic"],
    measures: [
      "dim_contact.count",
      "dim_user.count",
      "dim_company.count",
      "fact_topic_scores.email_count_current",
      "fact_topic_scores.email_count_prev",
    ],
    limit: 1,
    filters: [
      { member: "dim_topic.id", operator: "equals", values: [topic.id] },
    ],
  });
  const results = (resultSet?.tablePivot() || []) as any[];
  const result = results.length ? results[0] : {};

  const { resultSet: timeResultSet } = useCubeQuery({
    measures: ["fact_topic.interest", "fact_topic.email_count"],
    timeDimensions: [
      {
        dimension: "dim_date.date_actual",
        granularity: "day",
        dateRange: "Last 180 days",
      },
    ],
    filters: [
      { member: "dim_topic.id", operator: "equals", values: [topic.id] },
    ],
  });
  const timeResults = (timeResultSet?.tablePivot() || []) as any[];
  const { resultSet: myTimeResultSet } = useCubeQuery({
    measures: ["fact_topic.interest", "fact_topic.email_count"],
    timeDimensions: [
      {
        dimension: "dim_date.date_actual",
        granularity: "day",
        dateRange: "Last 180 days",
      },
    ],
    filters: [
      { member: "dim_topic.id", operator: "equals", values: [topic.id] },
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ],
  });
  const myTimeResults = (myTimeResultSet?.tablePivot() || []) as any[];

  const emailsBreakdownData = {
    datasets: [
      {
        label: "All mentions",
        data: timeResults.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_topic.email_count"],
        })),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: "Mentions by you",
        data: myTimeResults.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_topic.email_count"],
        })),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const interestBreakdownData = {
    datasets: [
      {
        label: "All company interest",
        data: timeResults.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_topic.interest"],
        })),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: "Your interest",
        data: myTimeResults.map((item) => ({
          x: item["dim_date.date_actual.day"],
          y: item["fact_topic.interest"],
        })),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };
  const isLoading = isStatsLoading || isUserLoading;

  return (
    <div className="flex flex-col gap-4 p-4 text-base">
      <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-4">
        <MiniStatistic
          icon="icon-email"
          value={propOr(0, "fact_topic_scores.email_count_current", result)}
          isLoading={isLoading}
          description="Email mentions"
          previous={propOr(0, "fact_topic_scores.email_count_prev", result)}
        />
        <MiniStatistic
          icon="icon-team"
          value={propOr(0, "dim_user.count", result)}
          description="Colleagues mentioning"
          isLoading={isLoading}
        />
        <MiniStatistic
          icon="icon-contact"
          value={propOr(0, "dim_contact.count", result)}
          description="Related contacts"
          isLoading={isLoading}
        />
        <MiniStatistic
          icon="icon-company"
          value={propOr(0, "dim_company.count", result)}
          description="Related companies"
          isLoading={isLoading}
        />
      </div>
      <div className="w-full rounded-lg border border-dust-dark bg-white p-4">
        <div className="pb-4 text-metal">Email mentions over time</div>
        <Line options={timelineOptions} data={emailsBreakdownData} />
      </div>
      <div className="w-full rounded-lg border border-dust-dark bg-white p-4">
        <div className="pb-4 text-metal">Interest points over time</div>
        <Line options={timelineOptions} data={interestBreakdownData} />
      </div>
    </div>
  );
};

export default TopicOverview;
