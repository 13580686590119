import { createSlice } from "@reduxjs/toolkit";
import { IToast } from "components/toast/toast.interfaces";

const initialState: IToast[] = [];
const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addToast: (state, action) => {
      return [...state, { ...action.payload }];
    },
    removeToast(state, action) {
      const toasts = state.filter((toast) => toast.id !== action.payload);
      return [...toasts];
    },
  },
});

export const { addToast, removeToast } = notificationsSlice.actions;
export default notificationsSlice.reducer;
