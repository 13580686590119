/**
 * downloadCSV takes an array of objects and a filename, and downloads a CSV file
 * with the data from the array. The first row of the CSV file will be the keys
 * of the objects in the array, and the subsequent rows will be the values of
 * those keys.
 * @param { { [key: string]: string | number }[] } data - An array of objects
 */
const downloadCSV = (
  data: { [key: string]: string | number }[],
  filename: string
) => {
  if (data.length === 0) {
    return;
  }
  const headers = Object.keys(data[0]);
  const rows = data.map((obj) => headers.map((header) => `"${obj[header]}"`));
  const csv = [headers.join(","), ...rows.map((row) => row.join(","))].join(
    "\n"
  );
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("id", "download-link");
  downloadLink.setAttribute("hidden", "");
  downloadLink.setAttribute("href", url);
  downloadLink.setAttribute("download", filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};

export default downloadCSV;
