import classnames from "classnames";

const ReportBox = ({
  cadence,
  title,
  creationDate,
  onClick,
  id,
}: {
  cadence?: string;
  title: string;
  creationDate?: string;
  onClick?: () => void;
  id?: string;
}) => {
  const onKeyPressed = (e: any) => {
    if (e.key === "Enter" && onClick) onClick();
  };
  return (
    <div
      id={`report-box-${id}`}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyPressed}
      className={classnames(
        "flex w-[344px] flex-row rounded-lg border border-dust-dark bg-white p-4",
        {
          "cursor-pointer outline-none hover:bg-dust-light focus:bg-dust-light focus:ring focus:ring-mint":
            onClick,
        }
      )}
    >
      <div className="grid h-16 w-16 place-items-center rounded bg-sky text-4xl text-white">
        <i className="icon-report" />
      </div>
      <div className="ml-4 flex flex-col">
        <div className="text-metal">{cadence}</div>
        <div className="mt-px text-xl font-bold text-navy">{title}</div>
        <div className="mt-2 text-sm text-metal">{creationDate}</div>
      </div>
    </div>
  );
};

export default ReportBox;
