import { useCubeQuery } from "@cubejs-client/react";
import { propOr } from "ramda";
import { IUser } from "features/profile/user.interface";
import { Filter } from "@cubejs-client/core";

const useEntityMyQuickStats = ({
  companyId,
  contactId,
  user,
  additionalFilters,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters?: Filter[];
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };
  const { resultSet, isLoading, error } = useCubeQuery({
    measures: [
      "fact_table.colleague_count",
      "fact_table.colleague_count_current",
      "fact_table.colleague_count_prev",
      "fact_table.contact_count",
      "fact_table.contact_count_current",
      "fact_table.contact_count_prev",
      "fact_table.first_interaction",
      "fact_table.rank",
      "fact_table.reply_response_time_current",
      "fact_table.reply_response_time_prev",
      "fact_table.reply_out_response_time_current",
      "fact_table.reply_out_response_time_prev",
    ],
    filters: [
      ...entityFilters(),
      ...(additionalFilters || []),
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ],
  });

  if (error) {
    console.error(error);
  }

  const values = resultSet?.tablePivot();
  const kpi = values && values.length ? values[0] : undefined;

  const propOrNull = propOr(null);
  const results = {
    firstInteraction: propOrNull("fact_table.first_interaction", kpi),
    rank: propOrNull("fact_table.rank", kpi),
    colleaguesCurrent: propOrNull("fact_table.colleague_count_current", kpi),
    colleaguesPrevious: propOrNull("fact_table.colleague_count_prev", kpi),
    colleaguesAll: propOrNull("fact_table.colleague_count", kpi),
    contactsCurrent: propOrNull("fact_table.contact_count_current", kpi),
    contactsPrevious: propOrNull("fact_table.contact_count_prev", kpi),
    contactsAll: propOrNull("fact_table.contact_count", kpi),
    responseTimeCurrent: propOrNull(
      "fact_table.reply_response_time_current",
      kpi
    ),
    responseTimePrev: propOrNull("fact_table.reply_response_time_prev", kpi),
    outResponseTimeCurrent: propOrNull(
      "fact_table.reply_out_response_time_current",
      kpi
    ),
    outResponseTimePrev: propOrNull(
      "fact_table.reply_out_response_time_prev",
      kpi
    ),
  };

  return {
    results,
    isLoading,
  };
};

export default useEntityMyQuickStats;
