import {
  formatDuration,
  hoursToMilliseconds,
  intervalToDuration,
  millisecondsToHours,
  millisecondsToMinutes,
} from "date-fns";
import { filter, head, indexOf, propOr } from "ramda";

const formatKeys = [
  "years",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
];

const formatDistanceLocale = {
  xYears: "{{count}}y",
  xMonths: "{{count}}mo",
  xDays: "{{count}}d",
  xSeconds: "{{count}}s",
  xMinutes: "{{count}}m",
  xHours: "{{count}}h",
};

const partialFormatDuration = (duration: Duration, format = formatKeys) =>
  formatDuration(duration, {
    format,
    locale: {
      formatDistance: (
        token:
          | "xYears"
          | "xMonths"
          | "xDays"
          | "xSeconds"
          | "xMinutes"
          | "xHours",
        count: string
      ) => formatDistanceLocale[token].replace("{{count}}", count),
    },
  });

const formatSeconds = (seconds: number, forceshh?: boolean): string => {
  if (!seconds) return "-";

  const milliseconds = seconds * 1000;
  let hours = millisecondsToHours(milliseconds);
  let minutes =
    Math.round(
      millisecondsToMinutes(milliseconds - hoursToMilliseconds(hours)) / 10
    ) * 10;
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (!!forceshh && (hours > 0 || minutes > 50)) {
    if (hours > 20) return `~${hours}h`;
    else if (hours > 0 && minutes > 0) return `${hours}h ${minutes}m`;
    else if (hours > 0) return `${hours}h`;
    return `${minutes}m`;
  }

  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const fragments = filter(
    (key) => (propOr(0, key, duration) as number) > 0,
    formatKeys
  );
  const headIndex = indexOf(head(fragments), formatKeys);

  if (fragments.length === 1) {
    // only singular `days`, `minutes`, `hours` etc found
    return partialFormatDuration(duration);
  } else {
    // take into account the first value found and the adjacent one on format array
    return partialFormatDuration(
      duration,
      formatKeys.slice(headIndex, headIndex + 2)
    );
  }
};

export default formatSeconds;
