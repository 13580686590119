import { ITableField } from "components/table/Table.interfaces";

const contactTableFields: ITableField[] = [
  {
    field: "email",
    label: "Email",
    type: "text",
    source: "dim_contact.email",
  },
  {
    field: "phoneNumber",
    label: "Phone number",
    type: "text",
  },
  { field: "role", label: "Role", type: "text", source: "dim_contact.roles" },
  {
    field: "rank",
    label: "Rank",
    type: "rank-tag",
    source: "fact_table.relationship_score_current",
  },
  {
    field: "relationshipScore",
    label: "Relationship scores",
    type: "relationship-scores",
    source: "fact_table.relationship_score_current",
  },
  {
    field: "interest",
    label: "Interest points",
    type: "trend",
    source: "fact_table.interest_current",
  },
  {
    field: "effort",
    label: "Effort points",
    type: "trend",
    source: "fact_table.effort_current",
  },
  {
    field: "segment",
    label: "Segment",
    type: "text",
    source: "fact_table.segment",
  },
  {
    field: "viewerLastInteraction",
    label: "Your last interaction",
    type: "interaction",
  },
  {
    field: "colleagueLastInteraction",
    label: "Recent colleague interaction",
    type: "interaction",
    source: "fact_table.last_interaction_details",
  },
  {
    field: "colleaguesInteracting",
    label: "# colleagues interacting",
    type: "text",
  },
  {
    field: "viewerEmailBreakdown",
    label: "Your email breakdown",
    type: "email-breakdown",
  },
  {
    field: "allEmailBreakdown",
    label: "All email breakdown",
    type: "email-breakdown",
  },
  {
    field: "viewerLastMeeting",
    label: "Your last meeting",
    type: "interaction",
  },
  {
    field: "colleagueLastMeeting",
    label: "Recent colleague meeting",
    type: "interaction",
  },
];

export default contactTableFields;
