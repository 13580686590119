import { Outlet } from "react-router-dom";

const ReportPage = () => {
  return (
    <div className="mt-16 flex h-full flex-col items-center justify-center gap-8 bg-gradient-to-b from-dust-light to-white p-4">
      <Outlet />
    </div>
  );
};

export default ReportPage;
