const trend = (
  current: number,
  prev: number
): "increase" | "decrease" | "maintain" => {
  if (current === prev) return "maintain";
  if (current > prev) return "increase";
  return "decrease";
};

export default trend;
