import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import LoginContainer from "./LoginContainer";

const LoginSso = () => {
  const navigate = useNavigate();
  return (
    <LoginContainer text="Welcome to Relata">
      <p className="mt-4 text-center text-metal">
        Relata uses Single Sign-On (SSO) to make it easy to access
        <br />
        your account with your existing workplace credentials.
      </p>
      <div className="mt-8 mb-4 flex flex-col px-4">
        <Button
          color="navy"
          text="Go to Single Sign-On (SSO)"
          onClick={() => navigate("/sso-login/")}
        />
      </div>
    </LoginContainer>
  );
};

export default LoginSso;
