import NavItem from "components/NavItem";
import { useGetInteractionCountQuery } from "features/interaction/interactionApi";
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import useLocalStorage from "utils/hooks/useLocalStorage";
import { useTrackFilterOptions } from "utils/trackOptions";
import EmailDetailModal from "./track/email/detail/EmailDetailModal";

const TrackPage = () => {
  const [lastVisit, setLastVisit] = useLocalStorage(
    "last-interaction-timeline-visit",
    ""
  );
  const [trackOptions] = useTrackFilterOptions();
  const location = useLocation();
  const params = useParams();
  const messageId = params.id;

  const { data: interactions } = useGetInteractionCountQuery(
    {
      interactionsSinceTimestamp: lastVisit,
      trackOptions,
    },
    {
      pollingInterval: 30 * 1000,
    }
  );

  useEffect(() => {
    if (location.pathname.includes("interaction-timeline")) {
      setLastVisit(new Date().toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="h-screen overflow-hidden pt-16">
      <div className="flex h-12 items-center justify-center gap-8 border-b border-dust-dark bg-white-96 shadow backdrop-blur">
        <NavItem text="Sent emails" to="/track/sent-emails" />
        <NavItem
          text="Interaction timeline"
          notifications={
            location.pathname.includes("interaction-timeline")
              ? undefined
              : interactions?.count
          }
          to="/track/interaction-timeline"
        />
        <NavItem text="Link tracking" to="/track/link-tracking" />
      </div>
      <Outlet />
      {messageId && <EmailDetailModal messageId={messageId} />}
    </div>
  );
};

export default TrackPage;
