import { Pie } from "react-chartjs-2";
import GhostPieChart from "assets/GhostPieChart.svg";

type TPieChartData = { datasets: any[]; labels: string[] };

const PieChart = ({
  data,
  options,
}: {
  data: TPieChartData;
  options: object;
}) => {
  // check dataset exists and has at least one value
  if (
    data.datasets.length &&
    data.datasets[0].data.some((e: any) => e !== undefined && e !== 0)
  ) {
    return <Pie data={data} options={options} />;
  }
  return <img src={GhostPieChart} className="pb-14" alt="" />;
};

export default PieChart;
