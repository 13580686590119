import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useCubeQuery } from "@cubejs-client/react";
import { pathOr } from "ramda";

import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewBadge from "../components/LiveReportPreviewBadge";
import DataTag from "components/atoms/DataTag";
import useTopicsBreakdown from "pages/report/hooks/useTopicsBreakdown";
import LiveReportPreviewSkeleton from "pages/report/components/LiveReportPreviewSkeleton";
import { format, subDays } from "date-fns";

const TopicRow = ({
  name,
  type,
  interest,
  interestDelta,
}: {
  name: string;
  type: "company" | "sector" | "region" | "ticker" | "commodity";
  interest: number;
  interestDelta: number;
}) => (
  <div className="flex items-center gap-4">
    <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-dust text-metal">
      <i
        className={classNames("text-xl", {
          "icon-company": type === "company" || type === "ticker",
          "icon-cluster": type === "sector",
          "icon-globe": type === "region",
          "icon-node-hollow": type === "commodity",
        })}
      />
    </div>
    <div className="flex items-center gap-2">
      <div className="text-lg font-bold text-navy">{name}</div>
      {!(type === "ticker" || type === "company") && (
        <div className="text-sm capitalize text-metal">{type}</div>
      )}
    </div>
    <div className="ml-auto flex items-center gap-2">
      <div className="font-bold text-navy">{interest}</div>
      <DataTag
        label={Math.abs(interestDelta)}
        type={interestDelta > 0 ? "increase" : "decrease"}
      />
    </div>
  </div>
);

const TopicsSegment = ({
  description,
  topics,
}: {
  description: string;
  topics: any[];
}) => (
  <div className="w-[397px] rounded border border-l-8 border-dust border-l-dust">
    <div className="flex p-4 text-lg font-bold text-navy">{description}</div>
    <div className="flex flex-col gap-2 px-4">
      {topics.map((topic) => (
        <TopicRow
          key={topic["dim_topic.id"]}
          name={topic["dim_topic.topic"]}
          type={topic["dim_topic.topic_type"]}
          interest={topic["fact_topic_scores.interest_current"]}
          interestDelta={topic["fact_topic_scores.interest_delta"]}
        />
      ))}
    </div>
  </div>
);

const TopicExplorerPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/topic-explorer");
  const afterDate = format(subDays(new Date(), 180), "yyyy-MM-dd");

  const { resultSet: biggestGains, isLoading: isGainsLoading } =
    useTopicsBreakdown({
      orderOption: "largest-interest-gain",
      typeFilter: "all",
      limit: 3,
    });
  const { resultSet: biggestDrops, isLoading: isDropsLoading } =
    useTopicsBreakdown({
      orderOption: "largest-interest-drop",
      typeFilter: "all",
      limit: 3,
    });

  const { resultSet, isLoading: isCountLoading } = useCubeQuery({
    measures: ["dim_topic.count"],
    filters: [
      {
        member: "dim_date.date_actual",
        operator: "afterDate",
        values: [afterDate],
      },
      {
        member: "fact_topic.interest",
        operator: "gte",
        values: ["1"],
      },
    ],
  });
  const results = (resultSet?.tablePivot() || []) as any[];
  const count = pathOr(0, ["0", "dim_topic.count"], results);

  if (isDropsLoading || isGainsLoading || isCountLoading) {
    return <LiveReportPreviewSkeleton />;
  }

  return (
    <LiveReportPreview
      color="metal"
      icon="icon-network"
      title="Topic explorer"
      description="Popular topics mentioned in your organisation's email content"
      openTooltip="Explore topics"
      isBeta
      openReport={openReport}
    >
      <div className="pt-4">
        <div className="flex h-[176px] gap-2 overflow-scroll">
          <LiveReportPreviewBadge
            color="metal"
            description={{
              singular: "topic covered",
              plural: "topics covered",
            }}
            value={+count}
            openReport={openReport}
          />
          <div className="flex gap-4">
            <TopicsSegment
              description="Biggest interest gains"
              topics={biggestGains}
            />
            <TopicsSegment
              description="Biggest interest drops"
              topics={biggestDrops}
            />
            <div className="visible sticky right-0 top-0 -ml-20 w-16 bg-gradient-to-r from-transparent to-white lg:invisible" />
          </div>
        </div>
      </div>
    </LiveReportPreview>
  );
};

export default TopicExplorerPreview;
