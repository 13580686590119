import toggleListItem from "utils/toggleListItem";
import {
  CheckboxSection,
  RangeSection,
  SelectSection,
} from "pages/explore/modals/FiltersModal/FiltersModalSections";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import { propOr } from "ramda";

const ContactFiltersTab = ({
  activity,
  rank,
  trend,
  segment,
  relationshipScore,
  colleaguesInteracting,
  flags,
  personalProvider,
  onUpdate,
}: {
  activity: ActivityType[];
  rank: RankType[];
  trend: TrendType[];
  segment: SegmentType[];
  relationshipScore: { min: number; max: number };
  colleaguesInteracting: { value: number; operator: string };
  flags: { showLeavers: boolean; showAssistants: boolean };
  personalProvider: boolean;
  onUpdate: (data: any) => void;
}) => (
  <>
    <div className="mb-4 text-metal">
      These filters will hide contacts who do not meet the criteria, and also
      hide companies where no contacts meet the criteria.
    </div>
    <div className="flex h-full flex-row flex-wrap lg:h-[640px] lg:flex-col">
      <CheckboxSection
        title="Contact activity"
        onUpdate={(key: string) =>
          onUpdate({
            activity: toggleListItem(key, activity),
          })
        }
        options={[
          {
            label: "Show active contacts",
            checked: activity.includes(ActivityType.Active),
            key: ActivityType.Active,
          },
          {
            label: "Show inactive contacts",
            checked: activity.includes(ActivityType.Inactive),
            key: ActivityType.Inactive,
          },
          {
            label: "Show dormant contacts",
            checked: activity.includes(ActivityType.Dormant),
            key: ActivityType.Dormant,
          },
        ]}
      />
      <CheckboxSection
        title="Contact rank"
        onUpdate={(key: string) =>
          onUpdate({
            rank: toggleListItem(key, rank),
          })
        }
        options={Object.values(RankType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${RankType[key]} contacts`,
            checked: rank.includes(key),
            key,
          }))}
      />
      <CheckboxSection
        title="Contact trend"
        onUpdate={(key: string) =>
          onUpdate({
            trend: toggleListItem(key, trend),
          })
        }
        options={Object.values(TrendType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${TrendType[key]} contacts`,
            checked: trend.includes(key),
            key,
          }))}
      />
      <CheckboxSection
        title="Contact segment"
        onUpdate={(key: string) =>
          onUpdate({
            segment: toggleListItem(key, segment),
          })
        }
        options={Object.values(SegmentType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${SegmentType[key]} contacts`,
            checked: segment.includes(key),
            key,
          }))}
      />
      <RangeSection
        title="Relationship score"
        {...relationshipScore}
        onUpdate={(min, max) =>
          onUpdate({
            relationshipScore: { min, max },
          })
        }
      />
      <SelectSection
        title="# Colleagues interacting"
        {...colleaguesInteracting}
        onUpdate={({ value, operator }) =>
          onUpdate({
            colleaguesInteracting: { value, operator },
          })
        }
      />
      <CheckboxSection
        title="Contact flags"
        onUpdate={(key: string) =>
          onUpdate({
            flags: { ...flags, [key]: !propOr(false, key, flags) },
          })
        }
        options={[
          {
            label: "Show contacts who have left",
            checked: flags.showLeavers,
            key: "showLeavers",
          },
          {
            label: "Show Executive Assistants",
            checked: flags.showAssistants,
            key: "showAssistants",
          },
        ]}
      />
      <CheckboxSection
        title="Personal email providers"
        onUpdate={() =>
          onUpdate({
            personalProvider: !personalProvider,
          })
        }
        options={[
          {
            label:
              "Show contacts who use a personal email provider such as Gmail",
            checked: personalProvider,
          },
        ]}
      />
    </div>
  </>
);

export default ContactFiltersTab;
