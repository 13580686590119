import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDistanceToNow } from "date-fns";
import classNames from "classnames";

import { selectCubeLastRefresh } from "app/cubeLastRefreshSlice";
import { changeBreadcrumb } from "app/breadcrumbSlice";
import useTopicsBreakdown from "pages/report/hooks/useTopicsBreakdown";
import useDebounce from "utils/hooks/useDebounce";
import { highlightText } from "utils/highlightText";

import Button from "components/Button";
import SelectOption from "components/SelectOption";
import TextInput from "components/TextInput";
import DataTag from "components/atoms/DataTag";
import InteractionTag from "components/atoms/InteractionTag";
import TopicExplorerModal from "pages/report/modals/TopicExplorerModal";

const TopicCard = ({
  name,
  searchValue,
  type,
  interest,
  interestDelta,
  emails,
  emailsDelta,
  onClick,
}: {
  name: string;
  searchValue: string;
  type: "company" | "sector" | "region" | "ticker" | "commodity";
  interest: number;
  interestDelta: number;
  emails: number;
  emailsDelta: number;
  onClick: VoidFunction;
}) => (
  <div className="flex w-full flex-col gap-2 rounded border border-l-8 border-dust px-2 py-2 md:flex-row md:items-center md:px-4">
    <div className="flex h-8 justify-between gap-4 md:h-12">
      <div className="flex items-center gap-4">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-dust text-metal">
          <i
            className={classNames("text-xl", {
              "icon-company": type === "company" || type === "ticker",
              "icon-cluster": type === "sector",
              "icon-globe": type === "region",
              "icon-node-hollow": type === "commodity",
            })}
          />
        </div>
        <div className="flex w-40 flex-col">
          <div className="text-lg font-bold text-navy">
            {highlightText(name, searchValue)}
          </div>
          {!(type === "ticker" || type === "company") && (
            <div className="text-sm capitalize text-metal">{type}</div>
          )}
        </div>
      </div>
      <div className="block md:ml-auto md:hidden">
        <Button color="dust" iconRight icon="icon-right" onClick={onClick} />
      </div>
    </div>
    <div className="flex w-full items-center justify-around">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <div className="font-bold text-navy">{emails}</div>
          <DataTag
            label={Math.abs(emailsDelta)}
            type={emailsDelta > 0 ? "increase" : "decrease"}
          />
        </div>
        <div className="text-sm text-metal-dark">Email mentions</div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <div className="font-bold text-navy">{interest}</div>
          <DataTag
            label={Math.abs(interestDelta)}
            type={interestDelta > 0 ? "increase" : "decrease"}
          />
        </div>
        <div className="text-sm text-metal-dark">Interest points</div>
      </div>
    </div>
    <div className="hidden md:ml-auto md:block">
      <Button
        color="dust"
        text="See more"
        iconRight
        icon="icon-right"
        onClick={onClick}
      />
    </div>
  </div>
);

const SkeletonTopicCard = () => (
  <div className="flex w-full rounded border border-l-8 border-dust p-2 md:items-center md:justify-between">
    <div className="flex h-12 gap-2 md:items-center md:justify-between">
      <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust-dark"></div>
      <div className="flex flex-col gap-1">
        <div className="h-4 w-[120px] animate-pulse rounded-sm bg-dust-light"></div>
        <div className="h-2 w-[80px] animate-pulse rounded-sm bg-dust"></div>
      </div>
    </div>
    <div className="hidden pb-2 pt-4 md:ml-auto md:block md:p-0">
      <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust md:w-[120px]"></div>
    </div>
    <div className="ml-auto block md:hidden">
      <div className="flex h-8 w-8 animate-pulse items-center justify-center rounded bg-dust"></div>
    </div>
  </div>
);

const EmptyContainer = ({
  message,
  description,
  icon,
}: {
  message: string;
  description?: string;
  icon: string;
}) => (
  <div className="mt-20 flex flex-col items-center gap-2 text-navy">
    <i className={classNames("text-3xl", { [icon]: true })} />
    <div className="text-lg font-bold">{message}</div>
    {description && (
      <div className="text-base text-metal-dark">{description}</div>
    )}
  </div>
);

const TopicExplorerReport = () => {
  const [typeOption, setTypeOption] = useState("all");
  const [orderOption, setOrderOption] = useState("most-interest");
  const [searchValue, setSearchValue] = useState("");
  const [currentTopic, setCurrentTopic] = useState<any>(undefined);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const dispatch = useDispatch();

  const { resultSet, isLoading } = useTopicsBreakdown({
    orderOption,
    typeFilter: typeOption,
    search: debouncedSearchValue,
  });

  useEffect(() => {
    dispatch(
      changeBreadcrumb({
        main: "Report",
        subsection: "Live Report",
        link: "/report/",
      })
    );
  }, [dispatch]);

  const lastRefreshed = useSelector(selectCubeLastRefresh);

  return (
    <div className="min-h-[680px] w-full overflow-scroll rounded-lg border border-dust-dark bg-white shadow lg:h-[680px] lg:w-[1000px]">
      <div className="relative flex flex-col">
        <div className="sticky top-0 z-10 flex w-full flex-col gap-4 rounded-lg bg-white p-4">
          <div className="flex items-start gap-2">
            <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-b from-metal to-metal-dark text-white">
              <i className="icon-network text-xl" />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="flex h-4 items-center gap-2">
                <div className="font-bold text-navy">Topic explorer</div>
                <InteractionTag color="pink" label="BETA" />
              </div>
              <div className="flex max-w-full flex-col text-metal md:flex-row md:gap-2">
                {lastRefreshed.time
                  ? `Last updated ${formatDistanceToNow(
                      lastRefreshed.time
                    )} ago `
                  : "Live report "}
                – Popular topics mentioned in your organisation's email content
              </div>
            </div>
          </div>
          <div className="w-full border-t border-t-dust"></div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <SelectOption
              options={[
                {
                  value: "all",
                  label: "Show everything",
                },
                {
                  value: "company",
                  label: "Show companies only",
                },
                {
                  value: "sector",
                  label: "Show sectors only",
                },
                {
                  value: "region",
                  label: "Show regions only",
                },
                {
                  value: "commodity",
                  label: "Show commodities only",
                },
              ]}
              value={typeOption}
              onChange={setTypeOption}
            />
            <SelectOption
              options={[
                {
                  value: "most-interest",
                  label: "Most interest points first",
                },
                {
                  value: "most-email",
                  label: "Most email mentions first",
                },
                {
                  value: "largest-interest-gain",
                  label: "Biggest interest gains first",
                },
                {
                  value: "largest-interest-drop",
                  label: "Biggest interest drops first",
                },
              ]}
              value={orderOption}
              onChange={setOrderOption}
            />
          </div>
          <TextInput
            placeholder="Search for a company, stock symbol, sector or commodity"
            onChangeText={setSearchValue}
            value={searchValue}
            icon="icon-search"
            size="large"
            id="topic-search"
            clearable
          />
        </div>
        <div className="px-4 pb-4">
          <div
            className="flex h-[470px] flex-col gap-2 overflow-scroll"
            data-testid="topics"
          >
            {isLoading ? (
              <SkeletonTopicCard />
            ) : (
              resultSet.map((topic) => (
                <TopicCard
                  key={topic["dim_topic.id"]}
                  searchValue={debouncedSearchValue}
                  name={topic["dim_topic.topic"]}
                  type={topic["dim_topic.topic_type"]}
                  interest={topic["fact_topic_scores.interest_current"]}
                  interestDelta={topic["fact_topic_scores.interest_delta"]}
                  emails={topic["fact_topic_scores.email_count_current"]}
                  emailsDelta={topic["fact_topic_scores.email_count_delta"]}
                  onClick={() =>
                    setCurrentTopic({
                      id: topic["dim_topic.id"],
                      name: topic["dim_topic.topic"],
                    })
                  }
                />
              ))
            )}
            {resultSet.length === 0 &&
              !isLoading &&
              debouncedSearchValue !== "" && (
                <EmptyContainer
                  message="No topics found"
                  description="Try adjusting your spelling"
                  icon="icon-search"
                />
              )}
            {resultSet.length === 0 &&
              !isLoading &&
              debouncedSearchValue === "" && (
                <EmptyContainer
                  message="No topics have been found"
                  icon="icon-cluster"
                />
              )}
          </div>
        </div>
      </div>
      <TopicExplorerModal
        topic={currentTopic}
        visible={currentTopic !== undefined}
        onClose={() => setCurrentTopic(undefined)}
      />
    </div>
  );
};

export default TopicExplorerReport;
