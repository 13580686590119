import Checkbox from "components/Checkbox";
import NumberInput from "components/NumberInput";
import SelectOption from "components/SelectOption";

export const RangeSection = ({
  title,
  min,
  max,
  onUpdate,
}: {
  title: string;
  min: number;
  max: number;
  onUpdate?: (min: number, max: number) => void;
}) => (
  <div className="flex w-full flex-col gap-2 pb-6 lg:w-1/2">
    <div className="text-lg font-bold text-navy">{title}</div>
    <div className="flex items-center gap-2 text-metal">
      <span className="w-24">Minimum</span>
      <span className="w-24">Maximum</span>
    </div>
    <div className="flex items-center gap-2 text-metal">
      <NumberInput
        value={min}
        onChange={(val) => onUpdate && onUpdate(+val, max)}
      />
      -
      <NumberInput
        value={max}
        onChange={(val) => onUpdate && onUpdate(min, +val)}
      />
    </div>
  </div>
);

export const SelectSection = ({
  title,
  value,
  operator,
  onUpdate,
}: {
  title: string;
  operator: string;
  value: number;
  onUpdate?: (data: any) => void;
}) => (
  <div className="flex w-full flex-col gap-2 pb-6 lg:w-1/2">
    <div className="text-lg font-bold text-navy">{title}</div>
    <div className="flex items-center gap-2 text-metal"></div>
    <div className="flex gap-4">
      <div className="w-[150px]">
        <SelectOption
          options={[
            { label: "is greater than", value: "gte" },
            { label: "is less than", value: "lte" },
          ]}
          value={operator}
          onChange={(val) => onUpdate && onUpdate({ operator: val, value })}
        />
      </div>
      <NumberInput
        value={value}
        onChange={(val) => onUpdate && onUpdate({ value: +val, operator })}
      />
    </div>
  </div>
);

export const CheckboxSection = ({
  title,
  options,
  onUpdate,
}: {
  title: string;
  options: any[];
  onUpdate?: (data: any) => void;
}) => (
  <div className="flex w-full flex-col gap-2 pb-6 lg:w-1/2">
    <div className="text-lg font-bold text-navy">{title}</div>
    {options.map((option, index) => (
      <Checkbox
        checked={option.checked}
        onClick={() => onUpdate && onUpdate(option.key)}
        key={index}
        size="small"
        label={option.label}
      />
    ))}
  </div>
);
