import DataTag from "components/atoms/DataTag";
import Tooltip from "./atoms/Tooltip";

const MiniStatistic = ({
  icon,
  value,
  previous,
  isLoading,
  description,
  tooltip,
}: {
  icon: string;
  value: any;
  previous?: any;
  isLoading?: boolean;
  description: string;
  tooltip?: string;
}) => {
  if (isLoading) {
    return (
      <div className="relative flex w-full items-center gap-4 rounded-lg border border-dust-dark bg-white px-3 py-4">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dust">
          <i className={`${icon} text-2xl text-metal`}></i>
        </div>
        <div className="flex flex-col">
          <div className="h-3 w-[100px] rounded bg-dust-dark"></div>
        </div>
      </div>
    );
  }

  const valueNumber = Number(value) || 0;
  const previousNumber = Number(previous) || 0;
  const delta = valueNumber - previousNumber;

  return (
    <div className="relative flex w-full items-center gap-4 rounded-lg border border-dust-dark bg-white px-3 py-4">
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dust">
        <i className={`${icon} text-2xl text-metal`}></i>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <div className="text-xl font-bold text-navy">{value || "-"}</div>
          {previous ? (
            <DataTag
              label={Math.abs(delta)} // this should show delta
              type={
                previousNumber < valueNumber
                  ? "increase"
                  : previousNumber > valueNumber
                  ? "decrease"
                  : "consistent"
              }
            />
          ) : (
            <></>
          )}
        </div>
        <div className="text-metal">{description}</div>
      </div>
      {!!tooltip && (
        <div className="absolute right-4 top-4">
          <Tooltip content={tooltip} />
        </div>
      )}
    </div>
  );
};

export default MiniStatistic;
