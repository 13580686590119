import classNames from "classnames";
import { format } from "date-fns";
import { IMessageInteraction } from "features/message/message.interface";
import InteractionTimelineTag from "pages/track/interaction/InteractionTimelineTag";
import { deviceToTypeIcon, deviceToPlatformIcon } from "utils/devices";

const EmailDetailInteractions = ({
  interactions,
}: {
  interactions: IMessageInteraction[];
}) => {
  return (
    <div className="relative flex w-full flex-col gap-2 pt-4 text-metal">
      <div className="absolute left-4 top-0 h-full border-r border-dust" />
      {interactions.map((interaction, i) => (
        <div key={i} className="flex flex-row justify-between pl-[42px] pr-4">
          <div className="flex flex-row items-center gap-1">
            <InteractionTimelineTag interaction={interaction} size="small" />
            <i
              className={classNames(
                "text-lg",
                deviceToTypeIcon(interaction.deviceType)
              )}
            />
            <i
              className={classNames(
                "text-lg",
                deviceToPlatformIcon(interaction.deviceType)
              )}
            />
            <span
              className="invisible max-w-[200px] cursor-pointer truncate pl-2 sm:visible"
              onClick={() => window.open(interaction.url, "_blank")}
            >
              {interaction.url}
            </span>
          </div>

          <div className="flex flex-row gap-2">
            <span>
              {format(
                new Date(interaction.actionCreationTimestamp),
                "EEE do MMM"
              )}
            </span>
            <span>
              {format(new Date(interaction.actionCreationTimestamp), "HH:mm")}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmailDetailInteractions;
