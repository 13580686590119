import { useGetUserQuery } from "features/profile/profileApi";
import ErrorPage from "pages/ErrorPage";

const AdminRedirect = () => {
  const { data: user } = useGetUserQuery();
  if (user?.isStaff) {
    window.location.href = "/api/admin";
  }
  return <ErrorPage type={404} />;
};
export default AdminRedirect;
