import { ReactNode } from "react";
import LogoShort from "assets/LogoShort.svg";

const LoginHeader = ({ text, icon }: { text: string; icon?: string }) => {
  return (
    <div className="mt-6 grid place-items-center gap-4">
      <div className="grid h-20 w-20 place-items-center rounded-md border border-dust-dark bg-white shadow">
        {icon ? (
          <i className={`${icon} text-5xl text-navy`} />
        ) : (
          <img src={LogoShort} className="h-8" alt="logo" />
        )}
      </div>
      <div className="text-center text-xl font-bold text-navy">{text}</div>
    </div>
  );
};

const LoginContainer = ({
  children,
  text,
  icon,
}: {
  text: string;
  icon?: string;
  children: ReactNode;
}) => {
  return (
    <div className="grid h-screen place-items-center bg-gradient-to-b from-dust-light text-base">
      <div className="flex w-[334px] flex-col  rounded-lg border border-dust-dark bg-white  shadow md:w-96">
        <LoginHeader icon={icon} text={text} />
        {children}
      </div>
    </div>
  );
};

export default LoginContainer;
