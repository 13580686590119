import { useCubeQuery } from "@cubejs-client/react";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useEmailsBreakdown = () => {
  const cubejsApi = useCubejsApi("emails-breakdown");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: ["dim_email.count_sent", "dim_email.count_inbound"],
      order: [],
      dimensions: [],
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
      ],
      timeDimensions: [
        {
          dimension: "dim_email.message_sent_date_time",
          granularity: "day",
          dateRange: "Last 90 days",
        },
      ],
    },
    {
      cubejsApi,
    }
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useEmailsBreakdown;
