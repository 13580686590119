import LogoFull from "assets/LogoFull.svg";
import LogoShort from "assets/LogoShort.svg";

const BareNavigation = () => (
  <nav className="fixed top-12 z-40 h-16 w-full border border-dust-dark bg-white-96 shadow backdrop-blur">
    <a className="absolute left-[50%] top-[21px] -translate-x-[50%]" href="/">
      <img src={LogoShort} className="h-6 sm:hidden" alt="logo" />
      <img src={LogoFull} className="hidden h-6 sm:block" alt="logo" />
    </a>
  </nav>
);

export default BareNavigation;
