import { useEffect, useState } from "react";

import Button from "components/Button";
import Modal from "components/Modal";
import NavTab from "components/NavTab";

import TopicOverview from "pages/report/components/TopicOverview";
import TopicRelatedEmails from "pages/report/components/TopicRelatedEmails";
import TopicInterest from "pages/report/components/TopicInterest";

const TopicExplorerModal = ({
  topic,
  visible,
  onClose,
}: {
  topic?: { id: string; name: string };
  visible: boolean;
  onClose: VoidFunction;
}) => {
  const [tab, setTab] = useState<string>("overview");

  // reset the tab after changing topics
  useEffect(() => {
    setTab("overview");
  }, [topic]);

  const body = () => {
    switch (tab) {
      case "overview":
        return <TopicOverview topic={topic!} />;
      case "interest":
        return <TopicInterest topic={topic!} />;
      case "related-emails":
        return <TopicRelatedEmails topic={topic!} />;
    }
  };
  if (topic === undefined) {
    return <></>;
  }

  return (
    <Modal
      width="xl"
      height="xl"
      usePadding={false}
      visible={visible}
      onClose={onClose}
    >
      <div className="flex h-full flex-col">
        <div>
          <div className="relative flex items-center justify-center pb-2 pt-6 text-center">
            <div className="flex-1 truncate px-16 text-lg text-navy">
              {topic.name}
            </div>
            <div className="absolute right-4 top-4">
              <div className="flex gap-2">
                <Button icon="icon-cross" color="dust" onClick={onClose} />
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-center gap-8 overflow-x-scroll border-b border-dust-dark bg-white-96">
            <NavTab
              text="Overview"
              active={tab === "overview"}
              onClick={() => setTab("overview")}
            />
            <NavTab
              text="Interest"
              active={tab === "interest"}
              onClick={() => setTab("interest")}
            />
            <NavTab
              text="Related emails"
              active={tab === "related-emails"}
              onClick={() => setTab("related-emails")}
            />
          </div>
        </div>
        <div className="h-full w-full overflow-scroll bg-gradient-to-b from-dust-light via-white to-white sm:rounded-b-md">
          {body()}
        </div>
      </div>
    </Modal>
  );
};

export default TopicExplorerModal;
