import Button from "components/Button";
import TextInput from "components/TextInput";

export const EmailSkeleton = () => (
  <div className="flex w-full max-w-5xl flex-row justify-between p-4 px-8">
    <div className="flex flex-row gap-2">
      <div className="h-8 w-8 rounded bg-dust"></div>
      <div className="flex flex-col gap-2">
        <div className="h-4 w-40 rounded-full bg-dust-light"></div>
        <div className="flex flex-row gap-2">
          <div className="h-4 w-[100px] rounded bg-dust-dark"></div>
          <div className="h-4 w-[91px] rounded bg-dust"></div>
          <div className="h-4 w-[84px] rounded bg-dust-dark"></div>
          <div className="h-4 w-[102px] rounded bg-dust-light"></div>
        </div>
      </div>
    </div>
    <div className="h-4 w-14 rounded-full bg-dust"></div>
  </div>
);

export const EmailListSkeleton = () => (
  <div className="mx-auto flex h-full max-w-5xl animate-pulse flex-col pt-4">
    <div className="flex flex-row items-center gap-x-2 px-4 pb-6">
      <TextInput
        placeholder="Search for an interaction by subject or contact..."
        icon="icon-search"
        size="large"
        disabled
      />
      <Button icon="icon-filter-settings" color="white" disabled />
    </div>
    <div className="flex flex-row justify-between px-8 py-2">
      <div className="flex flex-col gap-0.5">
        <div className="h-4 w-40 rounded-full bg-dust"></div>
        <div className="h-4 w-20 rounded-full bg-dust-light"></div>
      </div>
    </div>
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
    <EmailSkeleton />
  </div>
);
