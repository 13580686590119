// @ts-nocheck
import { decamelizeKeys } from "humps";
interface IntercomPluginConfig {
  appId?: string; // your intercom app id
  disableAnonymousTraffic?: boolean; // disable loading intercom for anonymous visitors
  syncAnonymousId?: boolean; // enable syncing anonymous users
  alignment?: string; // customize left or right position of messenger
  horizontalPadding?: string; // customize horizontal padding
  verticalPadding?: string; // customize vertical padding
  customLauncherSelector?: string; // css selector of the custom launcher see https://www.intercom.com/help/en/articles/2894-customize-the-intercom-messenger-technical for additional info
  userHash?: string; // user hash used for identity verification
}

const config: IntercomPluginConfig = {
  appId: undefined,
  disableAnonymousTraffic: false,
  syncAnonymousId: false,
  alignment: undefined,
  horizontalPadding: undefined,
  verticalPadding: undefined,
  customLauncherSelector: undefined,
  userHash: undefined,
};

/**
 * intercom analytics plugin
 * @link https://getanalytics.io/plugins/intercom/
 * @link https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 * @param {IntercomPluginConfig}  pluginConfig - Plugin settings
 * @return {Object} Analytics plugin
 * @example
 *
 * intercomPlugin({
 *   appId: '123-xyz'
 * })
 */
function intercomPlugin(pluginConfig: IntercomPluginConfig = {}) {
  return {
    name: "intercom",
    config: {
      ...config,
      ...pluginConfig,
    },
    /* Custom methods TODO: shutdown? hide show */
    methods: {
      startTour(tourId: any) {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("startTour", tourId);
      },
      shutdown() {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("shutdown");
      },
      hide() {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("hide");
      },
      show() {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("show");
      },
      showMessages() {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("showMessages");
      },
      showNewMessage() {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("showNewMessage");
      },
      onShow(callback: any) {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("onShow", callback);
      },
      onUnreadCountChange(callback: any) {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("onUnreadCountChange", callback);
      },
      update(config: any) {
        const intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("update", config);
      },
    },
    bootstrap: ({
      config,
      instance,
    }: {
      config: IntercomPluginConfig;
      instance: any;
    }) => {
      /* Load intercom script after userId exists */
      if (config.disableAnonymousTraffic && !instance.user("userId")) {
        instance.once("identifyStart", ({ plugins }: { plugins: any }) => {
          const self = plugins["intercom"];
          if (!self.loaded()) {
            instance.loadPlugin("intercom");
          }
        });
      }
    },
    /* Load intercom widget on page */
    initialize: ({
      config,
      instance,
      payload,
    }: {
      config: IntercomPluginConfig;
      instance: any;
      payload: any;
    }) => {
      const {
        disableAnonymousTraffic,
        appId,
        alignment,
        horizontalPadding,
        verticalPadding,
        customLauncherSelector,
        userHash,
      } = config;
      if (!appId) {
        return false;
      }
      /* Disable intercom.com if user is not yet identified. Save on Monthly MTU bill $$$ */
      const userID = instance.user("userId");
      if (!userID && disableAnonymousTraffic) {
        return false;
      }
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + appId;
            const head = document.getElementsByTagName("head")[0];
            head.appendChild(s);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
      /* eslint-enable */
      window.intercomSettings = {
        app_id: appId,
        alignment,
        horizontal_padding: horizontalPadding,
        vertical_padding: verticalPadding,
        custom_launcher_selector: customLauncherSelector,
        user_hash: userHash,
      };
    },
    /* Trigger intercom page view */
    page: ({
      payload,
      config,
    }: {
      payload: any;
      config: IntercomPluginConfig;
    }) => {
      const intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("update");
    },
    /* Track intercom event */
    track: ({
      payload,
      config,
    }: {
      payload: any;
      config: IntercomPluginConfig;
    }) => {
      const intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("trackEvent", payload.event, payload.properties);
    },

    /* Identify Segment user */
    identify: ({
      payload,
      config,
    }: {
      payload: any;
      config: IntercomPluginConfig;
    }) => {
      const intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      const { userId, traits } = payload;
      const decamelized = decamelizeKeys(traits);

      if (typeof userId === "string") {
        window.Intercom("boot", {
          app_id: config.appId,
          user_id: userId,
          ...decamelized,
        });
      }
    },
    /* Remove intercom cookies on analytics.reset */
    reset: () => {
      const intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("shutdown");
    },
    /* Sync id when ready */
    ready: ({
      instance,
      config,
    }: {
      config: IntercomPluginConfig;
      instance: any;
    }) => {
      const intercom = window.Intercom;
      if (!config.syncAnonymousId || typeof intercom === "undefined") return;
      const intercomUser = intercom("getVisitorId");
      if (intercomUser) {
        const intercomAnonId = intercom("getVisitorId");
        const analyticsAnonId = instance.user("anonymousId");
        // If has intercom anonymous ID && doesnt match analytics anon id, update
        if (intercomAnonId && intercomAnonId !== analyticsAnonId) {
          instance.setAnonymousId(intercomAnonId);
        }
      }
    },
    /* Check if intercom loaded */
    loaded: () => {
      return window.Intercom && window.Intercom.booted;
    },
  };
}

declare global {
  interface Window {
    Intercom: any;
  }
}

export default intercomPlugin;
