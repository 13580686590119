interface HeapPluginConfig {
  appId?: string;
}

const config: HeapPluginConfig = {
  appId: undefined,
};

function bootstrapHeap(
  appId: string,
  config: any = undefined,
  done: () => void
) {
  if (!appId) return;
  window.heap = window.heap || [];
  window.heap.appid = appId;
  window.heap.config = config || {};
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.onload = done;
  script.src = "https://cdn.heapanalytics.com/js/heap-" + appId + ".js";
  const firstScript = document.getElementsByTagName("script")[0];
  // @ts-ignore
  firstScript.parentNode.insertBefore(script, firstScript);
  for (
    var n = function (e: any) {
        return function () {
          window.heap.push(
            [e].concat(Array.prototype.slice.call(arguments, 0))
          );
        };
      },
      p = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty",
      ],
      o = 0;
    o < p.length;
    o++
  )
    window.heap[p[o]] = n(p[o]);
}

export default function heapPlugin(pluginConfig: HeapPluginConfig = {}) {
  // return object for analytics to use
  return {
    name: "heap",
    config: {
      ...config,
      ...pluginConfig,
    },
    initialize: ({ config }: { config: any }) => {
      if (!window || !document) return;

      const load = () =>
        bootstrapHeap(config.appId, {}, () => {
          window.heap.booted = true;
        });

      if (document.readyState === "complete")
        bootstrapHeap(config.appId, {}, () => {
          window.heap.booted = true;
        });
      // @ts-ignore
      else if (window.attachEvent) window.attachEvent("onload", load);
      else window.addEventListener("load", load, false);
    },
    page: ({ payload }: { payload: any }) => {
      // call provider specific page tracking
    },
    track: ({ payload }: { payload: any }) => {
      return window.heap.track(payload.event, payload.properties);
    },
    identify: ({ payload }: { payload: any }) => {
      window.heap.addUserProperties({
        id: payload.userId,
        isRelataUser: payload.isRelataUser,
        name: payload.name,
        email: payload.email,
        roleType: payload.roleType,
      });
      window.heap.identify(payload.userId);
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return window.heap && window.heap.booted;
    },
  };
}

declare global {
  interface Window {
    heap: any;
  }
}
