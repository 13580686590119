import classNames from "classnames";

const RadioButton = ({
  id,
  groupName,
  text,
  value,
  onChange,
}: {
  id: string;
  groupName: string;
  text: string;
  value: string;
  onChange?: (value: string) => void;
}) => {
  const handleChange = (e: any) => {
    if (onChange) onChange(e.target.id);
  };

  const onKeyDown = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && onChange) {
      e.preventDefault();
      onChange(e.target.htmlFor);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <input
        className="peer hidden"
        type="radio"
        id={id}
        name={groupName}
        value={value}
        onChange={handleChange}
        checked={id === value}
      />
      <label
        onKeyDown={onKeyDown}
        tabIndex={0}
        htmlFor={id}
        className="flex cursor-pointer items-center gap-2 text-lg text-navy"
      >
        <div
          className={classNames("h-6 w-6 rounded-full border border-dust", {
            "border-6 bg-navy": id === value,
            "bg-white": id !== value,
          })}
        />
        {text}
      </label>
    </div>
  );
};

export default RadioButton;
