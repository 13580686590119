import {
  differenceInCalendarDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { DatesDeltaTypes, PeriodDifferenceType } from "utils/dates/dates.types";

const periodDifference = (dateA: Date, dateB: Date): PeriodDifferenceType => {
  let value: number = differenceInCalendarDays(dateA, dateB);
  const isPrevious = value > 0;
  let unity = "day";
  if (Math.abs(value) >= 32) {
    unity = "month";
    value = differenceInMonths(dateA, dateB);
    if (Math.abs(value) > 12) {
      unity = "year";
      value = differenceInYears(dateA, dateB);
    }
  }
  return {
    value: Math.abs(value),
    unity: unity as DatesDeltaTypes,
    isPrevious,
  };
};
export default periodDifference;
