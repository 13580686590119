import { useDispatch } from "react-redux";
import { useClipboard } from "use-clipboard-copy";
import { head } from "ramda";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";

import {
  IContactDetail,
  IContactLocation,
  IPhoneNumber,
} from "features/entity/entity.interfaces";
import useNotifications from "utils/hooks/useNotifications";
import useClock from "utils/hooks/useClock";

import InformationTag from "components/atoms/InformationTag";
import Identifier from "components/atoms/Identifier";
import Button from "components/Button";
import { showContactModal } from "app/contactModalsSlice";
import { getInitials } from "utils/string";
import { getFlagDateEffective, getFlagValue } from "utils/flags";
import classNames from "classnames";

const ContactDetails = ({ contact }: { contact: IContactDetail }) => {
  const { notify } = useNotifications();
  const dispatch = useDispatch();

  const clipboard = useClipboard({
    onSuccess() {
      notify({ text: "Email was copied to your clipboard" }, 1000);
    },
    onError() {
      notify({ text: "Failed to copy text" }, 1000);
    },
  });
  const copyEmail = (email: string) => {
    clipboard.copy(email);
  };

  const role = contact.roles ? head(contact.roles) : undefined;
  const { timezone, location } =
    contact.locations && contact.locations.length > 0
      ? (head(contact.locations) as IContactLocation)
      : { timezone: undefined, location: undefined };
  const { phoneNumber } =
    contact.phoneNumbers && contact.phoneNumbers.length > 0
      ? (head(contact.phoneNumbers) as IPhoneNumber)
      : { phoneNumber: undefined };
  const currentDate = useClock({ pattern: "HH:mm", timezone }) as string;
  const firstName = contact.fullName.split(" ")[0];

  const phoneNumbersCount =
    contact && contact.phoneNumbers ? contact.phoneNumbers.length : 0;

  const isLeaver = contact && getFlagValue(contact, "leaver");
  const getLeaverDescription = () => {
    if (isLeaver) {
      const result = `${firstName} left ${contact?.company?.displayName}`;
      if (getFlagDateEffective(contact, "leaver")) {
        return (
          result +
          " around " +
          format(
            parseISO(getFlagDateEffective(contact, "leaver") as string),
            "LLLL yyyy"
          )
        );
      }
      return result;
    }
    return "";
  };
  const contactFlagValue = getFlagValue(contact, "assistant");

  return (
    <>
      <div
        style={{
          background: !!contact.company?.originalBrandColour
            ? `radial-gradient(100% 70px at 0.11% 0%, ${contact.company.originalBrandColour}26 0%, #ffffff 100%)`
            : "",
        }}
        className={classNames(
          "flex flex-col overflow-hidden border-dust-dark px-4",
          {
            "divide-y":
              !!contact.company || !!contactFlagValue || !!role || !!location,
          }
        )}
        data-testid="contact-details"
      >
        <div
          className={classNames("flex flex-col gap-4 px-2 py-4", {
            hidden: !(contact.company || location || contactFlagValue || role),
          })}
        >
          {contact.company && (
            <div className="flex flex-col">
              <div className="text-metal">Company</div>
              <div className="flex items-center">
                <div className="flex min-w-0 items-center gap-2 overflow-hidden rounded-sm border border-transparent p-px pr-2 hover:border hover:border-dust hover:bg-white hover:shadow">
                  <div className="whitespace-nowrap">
                    <Identifier
                      type="company"
                      size="small"
                      text={getInitials(contact.company.displayName)}
                      logoUrl={contact.company.logoUrl}
                    />
                  </div>
                  <div className="truncate font-bold text-navy">
                    <Link
                      to={`/explore/company/${contact.company.uuid}/at-a-glance`}
                    >
                      {contact.company.displayName}
                    </Link>
                  </div>
                </div>
              </div>
              {isLeaver && (
                <div className="mt-2">
                  <InformationTag
                    label={getLeaverDescription()}
                    color="pink"
                    icon="icon-leaver"
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={classNames("flex flex-col gap-2", {
              hidden: !(role || contactFlagValue),
            })}
          >
            {role && (
              <>
                <div className="text-metal">Role</div>
                <div className="font-bold text-navy">{role}</div>
              </>
            )}
            {contactFlagValue && (
              <InformationTag
                label="Executive Assistant"
                color="dust"
                icon="icon-assistant"
              />
            )}
          </div>
          {location && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <div className="text-metal">Location</div>
                <div className="font-bold text-navy">{location}</div>
              </div>
              {timezone && (
                <div
                  className="text-metal"
                  data-tooltip-id="default-tooltip"
                  data-tooltip-content="Local time"
                >
                  {currentDate}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 py-4">
          <div className="flex items-center justify-between rounded-lg bg-dust-light px-4 py-2">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              <div className="text-metal">Email</div>
              <span className="font-bold text-navy">{contact.email}</span>
            </div>
            <div className="flex gap-1">
              {clipboard.isSupported() && (
                <Button
                  color="transparent"
                  icon="icon-copy"
                  tooltip="Copy email"
                  onClick={() => copyEmail(contact.email)}
                />
              )}
              <Button
                color="transparent"
                id="contact-email-button"
                icon="icon-send"
                tooltip="Email contact"
                onClick={() =>
                  dispatch(showContactModal({ modal: "email", contact }))
                }
              />
            </div>
          </div>
          {phoneNumber && (
            <div className="flex items-center justify-between rounded bg-dust-light px-4 py-2">
              <div className="flex flex-col">
                <div className="text-metal">Phone</div>
                <div className="font-bold text-navy">{phoneNumber}</div>
              </div>
              {phoneNumbersCount > 1 && (
                <div className="mt-4 text-metal">
                  {phoneNumbersCount === 2
                    ? "and 1 other"
                    : `and ${phoneNumbersCount - 1} others`}
                </div>
              )}
              {!!phoneNumber && (
                <Button
                  color="transparent"
                  id="contact-call-button"
                  tooltip="Call contact"
                  icon="icon-mobile"
                  onClick={() =>
                    dispatch(showContactModal({ modal: "call", contact }))
                  }
                ></Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
