import Button from "components/Button";
import TextInput from "components/TextInput";

const InteractionSkeleton = () => {
  return (
    <div className="mt-2 flex w-full flex-row items-center gap-x-4 rounded-md border border-dust bg-white p-2">
      <div className="h-6 w-12 animate-pulse rounded-sm bg-dust" />
      <div className="h-4 w-full animate-pulse rounded-full bg-dust-light" />
    </div>
  );
};

const FilterTileSkeleton = () => (
  <div className="flex h-16 w-[148px] min-w-[148px] animate-pulse flex-row gap-2 rounded-lg border border-dust-dark bg-white p-2 shadow">
    <div className="rounded-full bg-dust-darker px-0.5" />
    <div className="flex flex-col gap-px py-1">
      <div className="h-6 w-8 rounded bg-dust" />
      <div className="h-4 w-[102px] rounded-full bg-dust-light" />
    </div>
  </div>
);
const FilterTilesSkeleton = () => (
  <div className="pb-6">
    <div className="flex items-center pb-2 text-base text-metal">
      <i className="icon-filter pr-1" />
      Quick insights
    </div>
    <div className="flex flex-row gap-x-2 overflow-hidden">
      {Array(10)
        .fill(null)
        .map((_, i) => (
          <FilterTileSkeleton key={i} />
        ))}
    </div>
  </div>
);

const InteractionTimelineSkeleton = () => (
  <div className="relative h-full">
    <div className="absolute top-0 -z-10 h-60 w-screen bg-gradient-to-b from-dust-light to-white" />
    <div className="mx-auto flex h-full w-full max-w-5xl flex-col px-4">
      <div className="flex flex-row items-center gap-x-2 pt-4 pb-6">
        <TextInput
          placeholder="Search for an interaction by subject or contact..."
          icon="icon-search"
          size="large"
          disabled
        />
        <Button icon="icon-filter-settings" color="white" disabled />
      </div>
      <FilterTilesSkeleton />
      <div className="flex h-12 flex-row items-center bg-white pl-4">
        <div className="flex flex-col gap-0.5">
          <div className="h-4 w-[155px] rounded-full bg-dust" />
          <div className="h-4 w-[93px] rounded-full bg-dust-light" />
        </div>
      </div>
      <div className="relative pl-4 pt-2">
        <div className="absolute top-0 left-12 -z-10 h-full border-r border-dust-dark" />
        <div className={`w-[350px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[331px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[333px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[357px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[340px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[364px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[344px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[333px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[330px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[342px]`}>
          <InteractionSkeleton />
        </div>
        <div className={`w-[350px]`}>
          <InteractionSkeleton />
        </div>
      </div>
    </div>
  </div>
);

export { InteractionTimelineSkeleton, InteractionSkeleton };
