import { useEffect, useRef, useState } from "react";
import { pluck, uniq, equals } from "ramda";
import { useAnalytics } from "use-analytics";

import FilterToggle from "components/FilterToggle";
import TextInput from "components/TextInput";
import { useGetSearchQuery } from "features/search/searchApi";

import useDebounce from "utils/hooks/useDebounce";
import useKeyPressed from "utils/hooks/useKeyPressed";
import useOutsideClick from "utils/hooks/useOutsideClick";

import SearchBarResult from "./SearchBarResult";

// filters
const filterOptions = [
  {
    name: "All results",
    key: "default",
    entityTypes: ["contact", "company"],
  },
  {
    name: "Contacts only",
    key: "contacts",
    entityTypes: ["contact"],
  },
  {
    name: "Companies only",
    key: "companies",
    entityTypes: ["company"],
  },
];

const SearchBar = ({
  placeholder,
  onOpen,
  disabled = false,
  sendAnalytics = false,
}: {
  placeholder: string;
  onOpen?: (props: any) => void;
  disabled?: boolean;
  sendAnalytics?: boolean;
}) => {
  const { track } = useAnalytics();
  const [query, setQuery] = useState("");
  const [opened, setOpened] = useState(false);
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);
  const debouncedQuery = useDebounce(query, 500);

  const close = () => setOpened(false);
  useKeyPressed("Escape", close);
  const searchResultsRef = useOutsideClick(close);
  const searchInputRef = useRef(null);
  const { data, isUninitialized, isSuccess } = useGetSearchQuery(
    {
      text: debouncedQuery,
      limit: 10,
      offset: 0,
      entityTypes: filterOptions[activeFilterIndex].entityTypes,
    },
    { skip: debouncedQuery.length <= 2 }
  );

  useEffect(() => {
    if (isSuccess && !opened) setOpened(true);
    if (sendAnalytics && data?.length === 0) {
      track("Empty search", { category: "Search", label: debouncedQuery });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  const availableFilterOptions = () => {
    const available = uniq(pluck("entityType", data || []));
    if (available.length === 1 && activeFilterIndex === 0) {
      return filterOptions.filter((option) =>
        equals(option.entityTypes, available)
      );
    }
    return filterOptions;
  };

  const openResult = (props: any) => {
    close();

    if (sendAnalytics) {
      track(`Searched for ${props.entityType}`, {
        category: "Search",
        label: debouncedQuery,
      });
    }

    onOpen && onOpen(props);
  };

  const onClick = (e: any) => {
    if (
      searchInputRef.current &&
      (searchInputRef.current as HTMLElement).contains(e.target) &&
      query &&
      !disabled
    ) {
      setOpened(true);
    }
  };

  return (
    <div
      onClick={onClick}
      tabIndex={0}
      className="relative text-left text-base"
    >
      <TextInput
        id="search-bar-input"
        placeholder={placeholder}
        icon="icon-search"
        size="large"
        clearable
        value={query}
        onChangeText={setQuery}
        ref={searchInputRef}
      />
      {opened && !isUninitialized && (
        <div
          ref={searchResultsRef}
          className="absolute z-40 mt-2 max-h-[512px] w-full overflow-auto rounded border border-dust-dark bg-white shadow"
        >
          {data?.length && isSuccess ? (
            <>
              <div className="p-4">
                <FilterToggle
                  options={availableFilterOptions()}
                  activeIndex={activeFilterIndex}
                  onChange={setActiveFilterIndex}
                />
              </div>
              <div className="flex flex-col">
                {data?.map((result) => (
                  <SearchBarResult
                    {...result}
                    key={result.entityUuid}
                    onClick={openResult}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="p-10 text-center text-navy">
              <i className="icon-search text-4xl"></i>
              <div className="text-xl font-bold">No result found</div>
              <div className="text-metal">Try adjusting your spelling</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
