import { createApi } from "@reduxjs/toolkit/query/react";
import { ISearchResponse, ISearchResult } from "./search.interfaces";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";

export const searchApi = createApi({
  reducerPath: "search",
  baseQuery: prepareBaseQuery({
    baseUrl: "/pbi",
  }),
  endpoints: (builder) => ({
    getSearch: builder.query<
      ISearchResult[],
      { text: string; limit: number; offset: number; entityTypes?: string[] }
    >({
      query: ({ text, limit, offset, entityTypes }) => {
        const searchParams = new URLSearchParams({
          text: text,
          limit: limit.toString(),
          offset: offset.toString(),
        });
        entityTypes?.forEach((entity) =>
          searchParams.append("entity_type", entity)
        );
        return "/search?" + searchParams;
      },
      transformResponse: (response: any): ISearchResult[] => {
        const camelized = camelizeKeys(response) as ISearchResponse;
        return camelized.results;
      },
    }),
  }),
});

export const { useGetSearchQuery } = searchApi;
