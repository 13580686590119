import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";
import { useGetUserQuery } from "features/profile/profileApi";
import { head } from "ramda";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useClientServiceLevelQuery = ({
  query,
  skip = false,
}: {
  query: Query;
  skip?: boolean;
}) => {
  const { resultSet, isLoading, error } = useCubeQuery(query as Query, {
    skip,
    cubejsApi: useCubejsApi("client-service-level"),
  });

  if (error) {
    console.error(error);
  }

  const results = resultSet?.tablePivot() || [];
  const result = head(results);
  return {
    result,
    isLoading,
  };
};

const useClientServiceLevel = ({
  timeOption,
  teamOption,
  lensOption,
}: {
  timeOption: string;
  teamOption: string;
  lensOption: string;
}) => {
  const { data: user } = useGetUserQuery();
  const entityFilters = (): Filter[] => {
    return [
      {
        member: "dim_company.uuid",
        operator: "set",
      },
      {
        member: "dim_company.is_service_provider",
        operator: "equals",
        values: ["false"],
      },
      {
        member: "dim_contact.is_leaver",
        operator: "equals",
        values: ["false"],
      },
    ];
  };

  const additionalFilters = (): Filter[] => {
    const ret = [];
    if (teamOption !== "all-teams") {
      ret.push({
        member: "dim_team.uuid",
        operator: "equals",
        values: [teamOption],
      } as Filter);
    }
    if (lensOption !== "top-companies") {
      ret.push({
        member: "dim_lenses.lenses",
        operator: "contains",
        values: [`|${lensOption}|`],
      } as Filter);
    }
    return ret;
  };

  const dateRange = timeOption || "Last 180 days";
  const baseQuery: Query = {
    dimensions: [],
    measures: [],
    filters: [...entityFilters(), ...additionalFilters()],
    timeDimensions: [
      {
        dimension: "dim_date.date_actual",
        dateRange,
      },
    ],
  };

  const {
    result: meetingServiceLevel,
    isLoading: isMeetingServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: ["dim_calendar.count", "dim_calendar.duration"],
    },
    skip: !user,
  });

  const {
    result: responseTimeServiceLevel,
    isLoading: isResponseTimeServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: [
        "fact_email.avg_reply_response_time",
        "fact_email.avg_reply_out_response_time",
        "fact_email.outbound",
        "fact_email.outbound_engaged",
        "fact_email.inbound",
      ],
    },
    skip: !user,
  });

  const {
    result: contactsServiceLevel,
    isLoading: isContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
    },
    skip: !user,
  });

  const {
    result: newContactsServiceLevel,
    isLoading: isNewContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      filters: [
        ...(baseQuery.filters as Filter[]),
        {
          member: "dim_contact.segment_status",
          operator: "equals",
          values: ["0"],
        },
      ],
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
    },
    skip: !user,
  });

  const {
    result: activeContactsServiceLevel,
    isLoading: isActiveContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      filters: [
        ...(baseQuery.filters as Filter[]),
        {
          member: "dim_contact.activity_status",
          operator: "equals",
          values: ["0"],
        },
      ],
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
    },
    skip: !user,
  });

  const result = {
    meetings:
      (!!meetingServiceLevel && meetingServiceLevel["dim_calendar.count"]) || 0,
    meetingsDuration:
      (!!meetingServiceLevel && meetingServiceLevel["dim_calendar.duration"]) ||
      0,
    inboundMessages:
      (!!responseTimeServiceLevel &&
        responseTimeServiceLevel["fact_email.inbound"]) ||
      0,
    outboundMessages:
      (!!responseTimeServiceLevel &&
        Number(responseTimeServiceLevel["fact_email.outbound"])) ||
      0,
    outboundEngagedMessages:
      (!!responseTimeServiceLevel &&
        Number(responseTimeServiceLevel["fact_email.outbound_engaged"])) ||
      0,
    responseTime:
      (!!responseTimeServiceLevel &&
        responseTimeServiceLevel["fact_email.avg_reply_response_time"]) ||
      0,
    responseTimeOut:
      (!!responseTimeServiceLevel &&
        responseTimeServiceLevel["fact_email.avg_reply_out_response_time"]) ||
      0,
    contacts:
      (!!contactsServiceLevel && contactsServiceLevel["dim_contact.count"]) ||
      0,
    activeContacts:
      (!!activeContactsServiceLevel &&
        activeContactsServiceLevel["dim_contact.count"]) ||
      0,
    newContacts:
      (!!newContactsServiceLevel &&
        newContactsServiceLevel["dim_contact.count"]) ||
      0,
  };

  return {
    result,
    isLoading:
      isMeetingServiceLevelLoading ||
      isResponseTimeServiceLevelLoading ||
      isContactsServiceLevelLoading ||
      isNewContactsServiceLevelLoading ||
      isActiveContactsServiceLevelLoading,
  };
};

export default useClientServiceLevel;
