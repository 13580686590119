import { useNavigate } from "react-router-dom";

import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewSkeleton from "../components/LiveReportPreviewSkeleton";
import useActivityInNumbers from "pages/report/hooks/useActivityInNumbers";

const ActivityPreviewBadge = ({
  value,
  description,
}: {
  value: any;
  description: { singular: string; plural: string };
}) => (
  <div className="flex h-40 w-[146px] flex-shrink-0 flex-col items-center justify-center rounded bg-gradient-to-br from-green to-sky p-6 text-center text-white">
    <div className="text-3xl">{value}</div>
    <div className="text-xl">
      {value > 1 || value === 0 ? description.plural : description.singular}
    </div>
  </div>
);

const ActivityInNumbersPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/activity-in-numbers");
  const { result, isLoading } = useActivityInNumbers();

  if (isLoading) {
    return <LiveReportPreviewSkeleton />;
  }
  if (result === undefined) {
    return <></>;
  }

  const outbound = +result["fact_table.outbound_current"];
  const engaged = +result["fact_table.outbound_engaged_current"];

  const averageEngement =
    outbound > 0 ? ((engaged / outbound) * 100).toFixed(1) : 0;

  return (
    <LiveReportPreview
      color="aquamarine"
      icon="icon-star-hollow"
      title="Your activity in numbers"
      description="Changes in your activity and engagement over the previous 90 days"
      openTooltip="Explore your activity in numbers"
      openReport={openReport}
    >
      <div className="pt-4">
        <div className="flex h-[176px] gap-2 overflow-scroll">
          <ActivityPreviewBadge
            description={{
              singular: "email sent",
              plural: "emails sent",
            }}
            value={+result["fact_table.outbound_current"]}
          />
          <ActivityPreviewBadge
            description={{
              singular: "email received",
              plural: "emails received",
            }}
            value={+result["fact_table.inbound_current"]}
          />
          <ActivityPreviewBadge
            description={{
              singular: "average engagement",
              plural: "average engagement",
            }}
            value={`${averageEngement}%`}
          />
          <ActivityPreviewBadge
            description={{
              singular: "meeting",
              plural: "meetings",
            }}
            value={+result["fact_table.meetings_current"]}
          />
        </div>
      </div>
    </LiveReportPreview>
  );
};

export default ActivityInNumbersPreview;
