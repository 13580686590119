import { useContext, useEffect, useRef, useState } from "react";
import { find, propEq } from "ramda";

import { CubejsApi, Filter, Query, isQueryPresent } from "@cubejs-client/core";
import { CubeContext } from "@cubejs-client/react";

import useIsMounted from "./useIsMounted";
import useDeepCompareMemoize from "./useDeepCompareMemoize";

const mergeSources = (
  allSource: Array<object>,
  viewerSource: Array<object>,
  key: string
) =>
  allSource.map((item: any) => {
    const pred = propEq(key, item[key]);
    return { ...item, viewer: find(pred, viewerSource as any[]) };
  });

type UseExtraCubeQueryOptions = {
  /**
   * A `CubejsApi` instance to use. Taken from the context if the param is not passed
   */
  cubejsApi?: CubejsApi;
  /**
   * When `true` the resultSet will be reset to `null` first
   */
  resetResultSetOnChange?: boolean;

  /**
   * Query execution will be skipped when `skip` is set to `true`.
   * You can use this flag to avoid sending incomplete queries.
   */
  skip?: boolean;

  /**
   * Secondary query that will be executed and merged with the results of primary query.
   * Takes following params:
   *  * `measures` - a list of fields to be fetched,
   *  * `filters` - additional set of filters for second query,
   *  * `key` - both a primary dimention and join field for both queries.
   */
  secondary?: {
    measures: string[];
    filters: Filter[];
    key: string;
  };
};

const useExtraCube = (
  primaryQuery: Query,
  options: UseExtraCubeQueryOptions
): {
  resultSet: Array<object> | null;
  isLoading: boolean;
  total: number;
  error: any;
} => {
  const isMounted = useIsMounted();
  const mutexRef = useRef({});
  const context = useContext(CubeContext);

  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [resultSet, setResultSet] = useState(null);
  const [error, setError] = useState(null);

  async function fetchPrimary(cubejsApi: CubejsApi) {
    try {
      const allResponse = await cubejsApi.load(primaryQuery, {
        mutexObj: mutexRef.current,
        mutexKey: "query",
      });

      if (isMounted()) {
        const allSource = allResponse.tablePivot();
        // @ts-ignore
        setTotal(allResponse?.loadResponse.results[0].total);
        return allSource;
      }
    } catch (error: any) {
      if (isMounted()) {
        setError(error);
        setLoading(false);
        setResultSet(null);
      }
    }
  }

  useEffect(() => {
    const { skip = false, resetResultSetOnChange } = options;
    const cubejsApi = options.cubejsApi || context?.cubejsApi;

    if (!cubejsApi) {
      throw new Error("Cube.js API client is not provided");
    }
    if (resetResultSetOnChange) {
      setResultSet(null);
    }

    async function loadQuery() {
      if (!skip && isQueryPresent(primaryQuery)) {
        setError(null);
        setLoading(true);

        try {
          const primarySource = await fetchPrimary(cubejsApi);

          if (!primarySource) {
            return;
          }
          if (options.secondary) {
            const { measures, key, filters } = options.secondary;
            const entities = primarySource.map(
              (item: any) => item[key] as string
            );
            if (entities.length > 0) {
              const viewersResponse = await cubejsApi.load(
                {
                  dimensions: [key],
                  measures,
                  filters: [
                    {
                      member: key,
                      operator: "equals",
                      values: entities,
                    },
                    ...filters,
                  ],
                },
                {
                  mutexObj: mutexRef.current,
                  mutexKey: "query",
                }
              );
              const viewerSource = viewersResponse.tablePivot();
              setResultSet(
                mergeSources(primarySource, viewerSource, key) as any
              );
            } else {
              setResultSet(primarySource as any);
            }
          } else {
            setResultSet(primarySource as any);
          }
        } catch (e) {
          if (isMounted()) {
            setError(e as any);
            setResultSet(null);
            setLoading(false);
          }
        }
        if (isMounted()) {
          setLoading(false);
        }
      }
    }
    loadQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([primaryQuery, Object.keys((primaryQuery && primaryQuery.order) || {}), options, context]));

  return { resultSet, isLoading, total, error };
};

export default useExtraCube;
