import classnames from "classnames";
import { faker } from "@faker-js/faker";
import { capitalizeFirstLetter } from "utils/string";

import { ICell } from "./Table.interfaces";
import {
  ColorTagCell,
  ContactCell,
  EmailBreakdownCell,
  IdentifierCell,
  InteractionCell,
  AverageTimeCell,
  ProgressBarCell,
  RankTagCell,
  TextCell,
  TrendCell,
  RelationshipScoresCell,
} from "./Cells";

export const Row = ({ cells }: { cells: any[] }) => (
  <tr>
    {cells.map((cell, i) => (
      <td
        className={classnames("h-12 border-b border-r border-dust lg:h-16", {
          "sticky left-0 w-[200px] bg-white": i === 0,
        })}
        key={i}
      >
        {cell}
      </td>
    ))}
  </tr>
);

export const GenericRow = ({ cells }: { cells: ICell[] }) => {
  return (
    <Row
      cells={cells.map((cell, index) => (
        <GenericCell {...cell} key={index} />
      ))}
    />
  );
};

export const GenericCell = (data: ICell) => {
  switch (data.type) {
    case "identifier":
      return <IdentifierCell {...data.meta} />;
    case "color-tag":
      return <ColorTagCell {...data.meta} />;
    case "rank-tag":
      return <RankTagCell {...data.meta} />;
    case "progress-bar":
      return <ProgressBarCell {...data.meta} />;
    case "relationship-scores":
      return <RelationshipScoresCell {...data.meta} />;
    case "trend":
      return <TrendCell {...data.meta} />;
    case "interaction":
      return <InteractionCell {...data.meta} padding />;
    case "average-time":
      return <AverageTimeCell {...data.meta} />;
    case "text":
      return <TextCell {...data.meta} />;
    case "email-breakdown":
      return <EmailBreakdownCell {...data.meta} />;
    case "contact":
      return <ContactCell {...data.meta} />;
  }
};

export const RandomRow = () => {
  const cells: any[] = [];

  cells.push(<IdentifierCell type="company" title={faker.company.name()} />);
  const rank = faker.datatype.number({ min: 10, max: 100 });
  const color = rank >= 35 ? "gold" : rank > 15 ? "silver" : "bronze";
  cells.push(
    <RankTagCell color={color} label={capitalizeFirstLetter(color)} />
  );
  const good = faker.datatype.boolean();
  cells.push(
    <ProgressBarCell
      value={rank}
      description={good ? "Strengthening" : "Weakening"}
      color={good ? "green" : "orange"}
    />
  );
  cells.push(
    <TrendCell
      trend={faker.helpers.arrayElement(["increase", "decrease"])}
      value={faker.datatype.number({ min: 10, max: 400 })}
    />
  );
  cells.push(
    <TrendCell
      trend={faker.helpers.arrayElement(["increase", "decrease"])}
      value={faker.datatype.number({ min: 10, max: 400 })}
    />
  );
  const active = faker.datatype.boolean();
  cells.push(
    <ColorTagCell
      color={active ? "green" : "orange"}
      label={active ? "Active" : "Inactive"}
    />
  );
  cells.push(
    <InteractionCell
      when={faker.helpers.arrayElement([
        "Today",
        "Yesterday",
        "Last week",
        "Last month",
      ])}
      who={faker.name.fullName()}
      tagColor="green"
      tagLabel="Active"
    />
  );
  cells.push(
    <AverageTimeCell
      current={faker.datatype.number({ min: 100, max: 10000 })}
      prev={faker.datatype.number({ min: 100, max: 10000 })}
    />
  );
  cells.push(
    <TextCell
      text={faker.helpers.arrayElement(["Today", "In 5 days", "Next week"])}
    />
  );
  cells.push(
    <EmailBreakdownCell
      emailsIn={faker.datatype.number({ min: 1, max: 400 })}
      emailsOut={faker.datatype.number({ min: 1, max: 400 })}
    />
  );
  cells.push(<ContactCell fullName={faker.name.fullName()} />);
  return <Row cells={cells} />;
};
