import classNames from "classnames";
import { format } from "date-fns";
import { ReactNode } from "react";

import Button from "components/Button";
import InteractionTag from "components/atoms/InteractionTag";

const LiveReportPreview = ({
  title,
  description,
  date,
  color,
  icon,
  openReport,
  openTooltip,
  isBeta,
  children,
}: {
  title: string;
  description: string;
  date?: Date;
  color: "sky" | "purple" | "red" | "aquamarine" | "metal" | "salmon";
  icon: string;
  openReport: VoidFunction;
  openTooltip: string;
  isBeta?: boolean;
  children: ReactNode;
}) => (
  <div className="w-full rounded-lg border border-dust-dark bg-white p-4 shadow lg:w-[1000px]">
    <div className="flex flex-col divide-y divide-dust">
      <div
        className={classNames("flex items-start gap-2", {
          "pb-4": !!children,
        })}
      >
        <div
          className={classNames(
            "flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gradient-to-b text-white",
            {
              "from-sky to-sky-dark": color === "sky",
              "from-metal to-metal-dark": color === "metal",
              "from-purple to-purple-dark": color === "purple",
              "from-red to-red-dark": color === "red",
              "bg-gradient-to-br from-green to-sky": color === "aquamarine",
              "bg-gradient-to-br from-orange to-orange-dark":
                color === "salmon",
            }
          )}
        >
          <i className={classNames("text-xl", { [icon]: true })} />
        </div>
        <div className="flex flex-col gap-0.5">
          <div className="flex h-4 items-center gap-2">
            <div className="font-bold text-navy">{title}</div>
            {isBeta && <InteractionTag color="pink" label="BETA" />}
          </div>

          <div className="flex gap-2 text-metal">
            {date ? format(date, "mm-yy-dd") : "Live report"} – {description}
          </div>
        </div>
        <div className="ml-auto">
          <Button
            color="transparent"
            icon="icon-right"
            tooltip={openTooltip}
            iconRight
            onClick={openReport}
          />
        </div>
      </div>
      {children}
    </div>
  </div>
);

export default LiveReportPreview;
