import classnames from "classnames";

const FilterToggleItem = ({
  name,
  active,
  onClick,
}: {
  name: string;
  active?: boolean;
  onClick: any;
}) => {
  const onKeyPressed = (e: any) => {
    if (e.key === "Enter") onClick();
  };
  return (
    <div
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyPressed}
      data-testid={name}
      className={classnames(
        "cursor-pointer select-none rounded-xl py-1 px-2 text-sm outline-none focus:ring focus:ring-mint",
        {
          "bg-navy text-white hover:bg-navy-dark focus:bg-navy-dark": active,
          "bg-dust-light text-metal hover:bg-dust-dark hover:text-navy focus:bg-dust-dark focus:text-navy":
            !active,
        }
      )}
    >
      {name}
    </div>
  );
};

export default FilterToggleItem;
