import Button from "components/Button";
import Modal from "components/Modal";
import { IContactDetail } from "features/entity/entity.interfaces";
import { IUser } from "features/profile/user.interface";
import templates from "./emailTemplates.json";

const EmailTemplateItem = ({
  title,
  description,
  icon,
  onClick,
}: {
  title: string;
  description: string;
  icon: string;
  onClick: () => void;
}) => {
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      className="group flex cursor-pointer items-center justify-between text-metal outline-none hover:bg-dust-light hover:text-metal-dark focus:bg-dust-light focus:text-metal-dark focus:ring focus:ring-mint"
    >
      <i className={`${icon} m-4 text-xl`} />
      <div className="flex flex-1 flex-col">
        <div className="text-lg">{title}</div>
        <div className="">{description}</div>
      </div>
      <i className="icon-right mx-2 text-xl text-dust-darker" />
    </div>
  );
};

const ContactEmailModal = ({
  visible,
  contact,
  user,
  onClose,
}: {
  visible: boolean;
  contact?: Partial<IContactDetail>;
  user?: IUser;
  onClose: () => void;
}) => {
  const openEmail = ({
    subject,
    body,
  }: {
    subject?: string;
    body?: string;
  }) => {
    const fullBody = `Hi ${contact?.fullName},${body}`;

    if (subject && body) {
      window.open(
        `mailto:${contact?.email}?subject=${subject}&body=${fullBody}${
          user?.name || user?.email
        }`,
        "_blank"
      );
    } else {
      window.open(`mailto:${contact?.email}`, "_blank");
    }
  };
  return (
    <Modal
      level="mid"
      visible={visible}
      onClose={onClose}
      id="contact-email-modal-container"
    >
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-email-send"></i>
            Email {contact?.fullName}
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 w-full text-base">
          <EmailTemplateItem
            title="Open a blank email"
            description="This will open your default email client"
            icon="icon-email-send"
            onClick={() => openEmail({})}
          />
          <div className="m-4 text-center text-navy">
            Or start with a pre-written template
          </div>
          {templates.map(
            ({ title, description, icon, subject, body }, index) => (
              <EmailTemplateItem
                title={title}
                description={description}
                icon={icon}
                key={index}
                onClick={() => openEmail({ subject, body })}
              />
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ContactEmailModal;
