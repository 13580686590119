import { useEffect, useState } from "react";

const passwordConstraints = (
  newPassword: string,
  confirmPassword: string,
  oldPassword?: string
) => {
  const constraints = [
    {
      label: "Contains more than 8 characters",
      status: newPassword.length > 7,
    },
    {
      label: "Contains at least 1 number",
      status: /[0-9]/.test(newPassword),
    },
    {
      label: "Contains at least 1 special character",
      status: /[!@#$%&()\-_[\]{};:"./<>?]+/.test(newPassword),
    },
    {
      label: "Contains at least 1 upper case character",
      status: /[A-Z]+/.test(newPassword),
    },
    {
      label: "Contains at least 1 lower case character",
      status: /[a-z]+/.test(newPassword),
    },
    {
      label: "The passwords must match",
      status: newPassword === confirmPassword,
    },
  ];
  if (oldPassword !== undefined)
    return [
      ...constraints,
      {
        label: "The password must be different from your previous password",
        status: newPassword !== oldPassword && oldPassword.length > 0,
      },
    ];

  return constraints;
};

const passwordConstraintsSatisfied = (
  constraints: { label: string; status: boolean }[]
) => constraints.filter(({ status }) => status).length === constraints.length;

const usePasswordConstraints = (
  newPassword: string,
  confirmPassword: string,
  oldPassword?: string
) => {
  const initial = passwordConstraints(
    newPassword,
    confirmPassword,
    oldPassword
  );
  const [constraints, setConstraints] = useState(initial);

  useEffect(() => {
    setConstraints(
      passwordConstraints(newPassword, confirmPassword, oldPassword)
    );
  }, [newPassword, confirmPassword, oldPassword]);

  return { constraints, satisfied: passwordConstraintsSatisfied(constraints) };
};

export default usePasswordConstraints;
