import Button from "components/Button";
import FeedbackMessage from "components/FeedbackMessage";
import FilterToggle from "components/FilterToggle";
import TextInput from "components/TextInput";
import { useState } from "react";

export const EmailEmptySearch = () => (
  <FeedbackMessage
    icon="icon-search"
    title="No emails found"
    paragraps={["Try adjusting your spelling or changing the filters"]}
  />
);

export type SearchTargetType = "default" | "messageSubject" | "fullName";
const EmailSearch = ({
  searchValue,
  setSearchValue,
  setSearchTarget,
  setShowOptions,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  setSearchTarget: (value: SearchTargetType) => void;
  setShowOptions: () => void;
}) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);

  const filterOptions: { name: string; key: SearchTargetType }[] = [
    {
      name: "All results",
      key: "default",
    },
    {
      name: "Email subject only",
      key: "messageSubject",
    },
    {
      name: "Contact name only",
      key: "fullName",
    },
  ];

  return (
    <div className="mx-auto flex w-full max-w-5xl flex-col gap-2 px-4 pb-6 pt-4">
      <div className="flex flex-row items-center gap-x-2">
        <TextInput
          placeholder="Search for a sent email by subject or contact..."
          onChangeText={setSearchValue}
          value={searchValue}
          icon="icon-search"
          size="large"
          clearable
        />
        <Button
          icon="icon-filter-settings"
          color="white"
          onClick={setShowOptions}
          tooltip="Track options"
        />
      </div>

      {!!searchValue.length && (
        <FilterToggle
          options={filterOptions}
          activeIndex={activeFilterIndex}
          onChange={(index: number) => {
            setSearchTarget(filterOptions[index].key);
            setActiveFilterIndex(index);
          }}
        />
      )}
    </div>
  );
};

export default EmailSearch;
