import FeedbackMessage from "components/FeedbackMessage";

export const LinkTrackingError = () => (
  <FeedbackMessage
    icon="icon-warning-hex"
    title="We can't load Link tracking"
    paragraps={[
      "Try refreshing your browser. If this issue persists, get in contact with our support team.",
    ]}
  />
);
