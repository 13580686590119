import { useCubeQuery } from "@cubejs-client/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { responseBreakdownColors } from "utils/colorMapping";
import { TooltipItem } from "chart.js";

import {
  add,
  filter,
  find,
  descend,
  pluck,
  prop,
  propEq,
  propOr,
  propSatisfies,
  reduce,
  sort,
  ascend,
  zip,
} from "ramda";

import { Line, Bar, PieChart } from "charts";
import {
  chartOptions,
  pieOptions,
  timeOnYOptions,
} from "charts/defaultOptions";
import { useGetUserQuery } from "features/profile/profileApi";
import useEntityTimeSeries from "features/entity/useEntityTimeSeries";

import FilterButton from "components/FilterButton";
import { RelationshipFrame, SelectionFrame } from "components/frames";
import {
  contactsMetricOptions,
  emailsMetricOptions,
  relationshipMetricOptions,
  teamMetricOptions,
  responsetimeMetricOptions,
  responsebreakdownMetricOptions,
} from "./RelationshipChangeOptions";
import { selectCubeFilters } from "app/filtersSlice";
import { useCompanyRelationships } from "features/company/useCompanyRelationships";
import RelationshipTables from "components/RelationshipTables";
import { ICompanyRelationshipValue } from "features/company/company.interfaces";
import { TrendType } from "features/entity/entity.interfaces";
import CubeLastRefresh from "components/CubeLastRefresh";
import { beginningOfMonthsAgo } from "utils/dates";
import { format } from "date-fns";

const CompanyRelationshipChangeView = () => {
  const params = useParams();
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const cubeFilters = useSelector(selectCubeFilters);
  const companyRelationshipMonthRanges = [3, 6, 12];

  const percentagePieOptions = {
    ...pieOptions,
    plugins: {
      ...pieOptions.plugins,
      tooltip: {
        ...pieOptions.plugins.tooltip,
        callbacks: {
          label: (tooltipItem: TooltipItem<any>) => {
            return `${tooltipItem.formattedValue}%`;
          },
        },
      },
    },
  };

  const { results, viewerResults, isLoading } = useEntityTimeSeries({
    companyId: params.id as string,
    additionalFilters: cubeFilters,
    user,
  });

  const [teamMonthRange, setTeamMonthRange] = useState(6);
  const [teamMetric, setTeamMetric] = useState("fact_email.outbound");
  const { resultSet: teamsResultSet } = useCubeQuery({
    measures: ["fact_email.outbound", "fact_email.inbound"],
    dimensions: ["dim_team.name", "dim_team.color"],
    filters: [
      {
        member: "dim_company.uuid",
        operator: "equals",
        values: [params.id as string],
      },
      {
        dimension: "dim_date.date_actual",
        operator: "inDateRange",
        values: [
          format(beginningOfMonthsAgo(teamMonthRange || 6), "yyyy-MM-dd"),
          format(new Date(), "yyyy-MM-dd"),
        ],
      },
      ...cubeFilters,
    ],
  });
  const teamsData = teamsResultSet?.tablePivot() || [];

  const { results: strengtheningResults } = useCompanyRelationships({
    companyId: params.id as string,
    additionalFilters: [
      ...cubeFilters,
      {
        member: "fact_table.trend",
        operator: "equals",
        values: [String(TrendType.Strengthening)],
      },
    ],
  });

  const strengtheningRelationships = sort(
    descend<ICompanyRelationshipValue>(prop("relationshipScoreDelta")),
    filter(
      propSatisfies((x) => x > 2, "relationshipScoreDelta"),
      strengtheningResults
    )
  );

  const { results: weakeningResults } = useCompanyRelationships({
    companyId: params.id as string,
    additionalFilters: [
      ...cubeFilters,
      {
        member: "fact_table.trend",
        operator: "equals",
        values: [String(TrendType.Weakening)],
      },
    ],
  });

  const weakeningRelationships = sort(
    ascend<ICompanyRelationshipValue>(prop("relationshipScoreDelta")),
    filter(
      propSatisfies((x) => x < -2, "relationshipScoreDelta"),
      weakeningResults
    )
  );

  const getTimeLabels = (monthsAgo: number) => {
    return pluck("time.month", results || [])
      .filter(
        (date) => new Date(date as string) >= beginningOfMonthsAgo(monthsAgo)
      )
      .map((date) =>
        new Date(date as string).toLocaleString("default", { month: "long" })
      );
  };

  const [relationshipMonthRange, setRelationshipMonthRange] = useState(6);
  const [relationshipMetric, setRelationshipMetric] = useState(
    "fact_relationship_score.relationship_score"
  );

  const activeRelationshipLegend = propOr(
    "",
    "legend",
    find(propEq("value", relationshipMetric), relationshipMetricOptions)
  );

  const relationshipMetricsData = {
    labels: getTimeLabels(relationshipMonthRange),
    datasets: [
      {
        label: `Your ${activeRelationshipLegend}`,
        data: viewerResults
          ?.slice(-1 * (1 + relationshipMonthRange))
          .map((result) => propOr("", relationshipMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `All company ${activeRelationshipLegend}`,
        data: results
          ?.slice(-1 * (1 + relationshipMonthRange))
          .map((result) => propOr("", relationshipMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [emailsMonthRange, setEmailsMonthRange] = useState(6);
  const [emailsMetric, setEmailsMetric] = useState("fact_email.outbound");
  const activeEmailLegend = propOr(
    "",
    "legend",
    find(propEq("value", emailsMetric), emailsMetricOptions)
  );

  const emailsMetricData = {
    labels: getTimeLabels(emailsMonthRange),
    datasets: [
      {
        label: `${activeEmailLegend} you`,
        data: viewerResults
          ?.slice(-1 * (1 + emailsMonthRange))
          .map((result) => propOr("", emailsMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `${activeEmailLegend} colleagues`,
        data: results
          ?.slice(-1 * (1 + emailsMonthRange))
          .map((result) => propOr("", emailsMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [contactsMonthRange, setContactsMonthRange] = useState(6);
  const [contactsMetric, setContactsMetric] = useState(
    "fact_time_series.contact_count"
  );
  const activeContactsLegend = propOr(
    "",
    "legend",
    find(propEq("value", contactsMetric), contactsMetricOptions)
  );

  const contactsMetricData = {
    labels: getTimeLabels(contactsMonthRange),
    datasets: [
      {
        label: `${activeContactsLegend} you`,
        data: viewerResults
          ?.slice(-1 * (1 + contactsMonthRange))
          .map((result) => propOr("", contactsMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `${activeContactsLegend} all company`,
        data: results
          ?.slice(-1 * (1 + contactsMonthRange))
          .map((result) => propOr("", contactsMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [responsetimeMonthRange, setResponsetimeMonthRange] = useState(6);
  const [responsetimeMetric, setResponsetimeMetric] = useState(
    "fact_email.avg_reply_out_response_time"
  );
  const responsetimeLegend = propOr(
    "",
    "legend",
    find(propEq("value", responsetimeMetric), responsetimeMetricOptions)
  );

  const responsetimeMetricData = {
    labels: getTimeLabels(responsetimeMonthRange),
    datasets: [
      {
        label: `Your ${responsetimeLegend}`,
        data: viewerResults
          ?.slice(-1 * (1 + responsetimeMonthRange))
          .map((result) => propOr("", responsetimeMetric, result)),
        borderColor: "#378AD0",
        backgroundColor: "#378AD0",
      },
      {
        label: `All colleague ${responsetimeLegend}`,
        data: results
          ?.slice(-1 * (1 + responsetimeMonthRange))
          .map((result) => propOr("", responsetimeMetric, result)),
        borderColor: "#D3DFEA",
        backgroundColor: "#D3DFEA",
      },
    ],
  };

  const [responsebreakdownMonthRange, setResponsebreakdownMonthRange] =
    useState(6);

  const { resultSet: responsebreakdownResultSet } = useCubeQuery({
    measures: [
      "fact_email.response_out_within_24h",
      "fact_email.response_out_gt_5",
      "fact_email.response_out_within_5d",
      "fact_email.response_within_24h",
      "fact_email.response_gt_5",
      "fact_email.response_within_5d",
    ],
    filters: [
      {
        member: "dim_company.uuid",
        operator: "equals",
        values: [params.id as string],
      },
      {
        dimension: "dim_date.date_actual",
        operator: "inDateRange",
        values: [
          format(
            beginningOfMonthsAgo(responsebreakdownMonthRange || 6),
            "yyyy-MM-dd"
          ),
          format(new Date(), "yyyy-MM-dd"),
        ],
      },
      ...cubeFilters,
    ],
  });
  const responsebreakdownData = responsebreakdownResultSet?.tablePivot() || [];

  const defaultResponseBreakdownMetric = "fact_email.response_out_breakdown";
  const [responsebreakdownMetric, setResponsebreakdownMetric] = useState(
    defaultResponseBreakdownMetric
  );
  const responsebreakdownMetricLabels = ["< 24h", "1-5 days", "> 5 days"];
  const suffix =
    responsebreakdownMetric === defaultResponseBreakdownMetric ? "_out" : "";

  const mmm = responsebreakdownData.map((resultSet) => {
    const soon = +resultSet[`fact_email.response${suffix}_within_24h`] || 0 + 0;
    const mid = +resultSet[`fact_email.response${suffix}_within_5d`] || 0 + 0;
    const late = +resultSet[`fact_email.response${suffix}_gt_5`] || 0 + 0;
    const all = soon + mid + late;
    return all > 0
      ? [
          ((soon / all) * 100).toFixed(2),
          ((mid / all) * 100).toFixed(2),
          ((late / all) * 100).toFixed(2),
        ]
      : [];
  });
  const responsebreakdownMetricValues = mmm.length > 0 ? mmm[0] : [];

  const responsebreakdownMetricData = {
    labels: responsebreakdownMetricLabels,
    datasets: [
      {
        data: responsebreakdownMetricValues,
        backgroundColor: Object.keys(responsebreakdownMetricLabels).map((key) =>
          propOr("#65D398", key, responseBreakdownColors)
        ),
      },
    ],
  };

  // calculate percentages
  const teamValues = teamsData.map(
    (team) => +(propOr(0, teamMetric, team) as string)
  );
  const teamTotal = reduce(add, 0, teamValues);

  const teamMetricLabels = zip(
    pluck("dim_team.name", teamsData as any[]),
    teamValues
  )
    .filter((values) => values[1] > 0)
    .map((values) => values[0]);

  const teamMetricData = {
    labels: teamMetricLabels,
    datasets: [
      {
        data: teamValues.map((n: number) =>
          teamTotal > 0 ? Math.floor((n / teamTotal) * 100) : 0
        ),
        backgroundColor: pluck("dim_team.color", teamsData as any[]),
      },
    ],
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex flex-row items-center justify-between">
        <FilterButton warningOnActive />
        <CubeLastRefresh />
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <SelectionFrame
            options={relationshipMetricOptions}
            value={relationshipMetric}
            onChange={setRelationshipMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={relationshipMonthRange}
            onMonthRangeChange={setRelationshipMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Line options={chartOptions} data={relationshipMetricsData} />
          </SelectionFrame>
          <SelectionFrame
            options={emailsMetricOptions}
            value={emailsMetric}
            onChange={setEmailsMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={emailsMonthRange}
            onMonthRangeChange={setEmailsMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Bar options={chartOptions} data={emailsMetricData} />
          </SelectionFrame>
          <SelectionFrame
            options={contactsMetricOptions}
            value={contactsMetric}
            onChange={setContactsMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={contactsMonthRange}
            onMonthRangeChange={setContactsMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Bar options={chartOptions} data={contactsMetricData} />
          </SelectionFrame>
          <SelectionFrame
            options={teamMetricOptions}
            value={teamMetric}
            onChange={setTeamMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={teamMonthRange}
            onMonthRangeChange={setTeamMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart options={percentagePieOptions} data={teamMetricData} />
            </div>
          </SelectionFrame>
          <SelectionFrame
            options={responsetimeMetricOptions}
            value={responsetimeMetric}
            onChange={setResponsetimeMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={responsetimeMonthRange}
            onMonthRangeChange={setResponsetimeMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <Line options={timeOnYOptions} data={responsetimeMetricData} />
          </SelectionFrame>
          <SelectionFrame
            options={responsebreakdownMetricOptions}
            value={responsebreakdownMetric}
            onChange={setResponsebreakdownMetric}
            monthRanges={companyRelationshipMonthRanges}
            monthRangeValue={responsebreakdownMonthRange}
            onMonthRangeChange={setResponsebreakdownMonthRange}
            isLoading={isUserLoading || isLoading}
          >
            <div className="flex max-h-[240px] justify-center">
              <PieChart
                options={percentagePieOptions}
                data={responsebreakdownMetricData}
              />
            </div>
          </SelectionFrame>
          <div className="col-span-1 xl:col-span-2 2xl:col-span-1">
            <RelationshipFrame
              title="Strengthening relationships"
              valueMetric="relationshipScoreDelta"
              relationships={strengtheningRelationships}
              type="increase"
            />
          </div>
          <div className="col-span-1 xl:col-span-2 2xl:col-span-1">
            <RelationshipFrame
              title="Weakening relationships"
              valueMetric="relationshipScoreDelta"
              relationships={weakeningRelationships}
              type="decrease"
            />
          </div>
          <div className="col-span-1 xl:col-span-2">
            <RelationshipTables companyId={params.id as string} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRelationshipChangeView;
