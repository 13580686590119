import { useCubeQuery } from "@cubejs-client/react";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useEmailInteractionBreakdown = () => {
  const cubejsApi = useCubejsApi("email-interaction-breakdown");

  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      order: [],
      measures: [
        "fact_email.total_reads",
        "fact_email.total_clicks",
        "fact_email.total_replies",
        "fact_email.total_forwards",
        "fact_email.total_quick_reads",
        "fact_email.total_glances",
      ],
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
      ],
      timeDimensions: [
        {
          dimension: "dim_date.date_actual",
          granularity: "day",
          dateRange: "Last 90 days",
        },
      ],
    },
    {
      cubejsApi,
    }
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useEmailInteractionBreakdown;
