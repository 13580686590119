import { faker } from "@faker-js/faker";
import { ILink, ILinksResponse } from "features/link/link.interface";
import { mergeRight } from "ramda";

const fakeLink = (override: Partial<ILink>): ILink => {
  return mergeRight(
    {
      uuid: faker.datatype.uuid(),
      url:
        faker.helpers.maybe(() => faker.internet.avatar(), {
          probability: 0.2,
        }) || faker.internet.url(),
      totalClicks: faker.datatype.number(),
      totalEmails: faker.datatype.number(),
      lastInteractionTimestamp: faker.date.recent(10).toISOString(),
    },
    override
  );
};

const fakeLinks = (items: any[] = [], amount: number = 20): ILinksResponse => {
  return {
    next: undefined,
    prev: undefined,
    results: [
      ...items,
      ...[...Array(amount - items.length)].map(() => fakeLink({})),
    ].sort((a, b) => {
      const aDate = new Date(a.lastInteractionTimestamp);
      const bDate = new Date(b.lastInteractionTimestamp);
      return bDate.getTime() - aDate.getTime();
    }),
  };
};

export { fakeLink, fakeLinks };
