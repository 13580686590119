import classNames from "classnames";

import ColorTag, { ColorTagColor } from "components/atoms/ColorTag";
import RankTag, { RankTagColor } from "components/atoms/RankTag";
import { IdentifierType } from "components/atoms/Identifier";

import { IPhoneNumber } from "features/entity/entity.interfaces";
import { formatSeconds } from "utils/dates";
import DataTag from "components/atoms/DataTag";
import EntityIdentifier from "components/EntityIdentifier";
import InformationTag from "components/atoms/InformationTag";

import TrendArrowUp from "assets/TrendArrowUp.svg";
import TrendArrowDown from "assets/TrendArrowDown.svg";
import TrendMaintain from "assets/TrendMaintain.svg";

export const IdentifierCell = ({
  type,
  title,
  color,
  description,
  id,
  flags,
  meta,
  logoUrl,
}: {
  type: IdentifierType;
  title: string;
  color?: string;
  description?: string;
  id?: string;
  flags?: {
    isAssistant?: boolean;
    isLeaver?: boolean;
    isServiceProvider?: boolean;
  };
  meta?: {
    email?: string;
    phoneNumbers?: IPhoneNumber[];
    onlyIdentifier?: boolean;
  };
  logoUrl?: string;
}) => (
  <div
    className={classNames("relative flex w-full items-center gap-2 px-4", {
      "mr-10": flags?.isAssistant && flags?.isLeaver,
    })}
  >
    <EntityIdentifier
      type={type}
      title={title}
      color={color}
      description={description}
      meta={meta}
      id={id}
      logoUrl={logoUrl}
    />
    <div className="absolute right-2 flex gap-2">
      {flags?.isAssistant && (
        <InformationTag
          color="dust"
          icon="icon-assistant"
          tooltip="Executive Assistant"
        />
      )}
      {flags?.isLeaver && (
        <InformationTag
          color="pink"
          icon="icon-leaver"
          tooltip="Has left this company"
        />
      )}
      {flags?.isServiceProvider && (
        <InformationTag
          color="metal"
          icon="icon-plug"
          tooltip="Service provider"
        />
      )}
    </div>
  </div>
);

export const ColorTagCell = ({
  color,
  label,
}: {
  color: ColorTagColor;
  label: string;
}) => (
  <div className="flex w-[120px] items-center justify-center">
    <ColorTag color={color} label={label} />
  </div>
);

export const RankTagCell = ({
  color,
  label,
}: {
  color: RankTagColor;
  label: string;
}) => (
  <div className="flex w-[120px] items-center justify-center">
    <RankTag color={color} label={label} />
  </div>
);

export const ProgressBarCell = ({
  value,
  color,
  description,
}: {
  value: number;
  color: "green" | "orange";
  description: string;
}) => (
  <div className="flex items-center justify-between">
    <div className="w-10 pl-6 text-center text-lg font-bold text-navy">
      {value}
    </div>
    <div className="flex flex-col px-6">
      <div className="mt-2 h-2 w-[128px] rounded-full bg-dust-light">
        <div
          className={`h-2 rounded-full bg-${color}-dark`}
          style={{ width: `${value}%` }}
        ></div>
      </div>
      <div className="capitalize text-metal-dark">{description}</div>
    </div>
  </div>
);

const RelationshipScoreRow = ({
  value,
  color,
  trend,
  tooltip,
  description,
}: {
  value: number;
  color: "green" | "orange";
  trend: string;
  tooltip: string;
  description?: string;
}) => (
  <div className="flex items-center gap-2 px-4">
    <div className="flex w-14 items-center gap-2">
      <div className="text-lg font-bold text-navy"> {value}</div>
      {!!description && (
        <div className="text-sm font-normal text-metal-dark">
          ({description})
        </div>
      )}
    </div>
    <div
      className="h-2 w-[112px] rounded-full bg-dust-light"
      data-tooltip-id="default-tooltip"
      data-tooltip-content={tooltip}
    >
      <div
        className={`h-2 rounded-full bg-${color}-dark`}
        style={{ width: `${value}%` }}
      ></div>
    </div>
    <div className="text-sm capitalize text-metal-dark">{trend}</div>
  </div>
);

type RelationshipProgressType = {
  value: number;
  color: "green" | "orange";
  description: string;
};

export const RelationshipScoresCell = ({
  global,
  viewer,
}: {
  global?: RelationshipProgressType;
  viewer?: RelationshipProgressType;
}) => (
  <div className="flex flex-col gap-1">
    {global && (
      <RelationshipScoreRow
        value={global.value}
        color={global.color}
        trend={global.description}
        tooltip="All company score"
      />
    )}
    {viewer && viewer.value > 0 && (
      <RelationshipScoreRow
        value={viewer.value}
        color={viewer.color}
        trend={viewer.description}
        tooltip="Your score"
        description="You"
      />
    )}
  </div>
);

export const TrendCell = ({
  trend,
  value,
  secondaryValue,
  secondaryLabel,
}: {
  trend: "increase" | "decrease" | "maintain";
  value: number;
  secondaryValue?: number;
  secondaryLabel?: string;
}) => (
  <div className="px-4">
    <TrendContainer
      trend={trend}
      value={value}
      secondaryValue={secondaryValue}
      secondaryLabel={secondaryLabel}
    />
  </div>
);

export const TrendContainer = ({
  trend,
  value,
  secondaryValue,
  secondaryLabel,
}: {
  trend: "increase" | "decrease" | "maintain";
  value: number;
  secondaryValue?: number;
  secondaryLabel?: string;
}) => {
  const trendImageMap = {
    increase: TrendArrowUp,
    decrease: TrendArrowDown,
    maintain: TrendMaintain,
  };
  return (
    <div className="flex items-baseline gap-2">
      <img src={trendImageMap[trend]} className="" alt="arrow-up" />
      <div className="font-bold text-navy">{value}</div>
      {!!secondaryValue && secondaryValue > 0 && (
        <div className="text-sm text-metal-dark">
          {secondaryValue} ({secondaryLabel})
        </div>
      )}
    </div>
  );
};

export const InteractionCell = ({
  when,
  who,
  tagLabel,
  tagColor,
  padding,
}: {
  when: string;
  who: string;
  tagLabel: string;
  tagColor: ColorTagColor;
  padding?: boolean;
}) => (
  <div
    className={classNames("flex items-center justify-between gap-4", {
      "px-6": padding,
    })}
  >
    <div className="flex flex-col">
      <div className="font-bold text-navy">{when || "-"}</div>
      {!!who && <div className="text-sm text-metal-dark">with {who}</div>}
    </div>
    <ColorTag color={tagColor} label={tagLabel} />
  </div>
);

export const TextCell = ({ text }: { text: string }) => (
  <div className="flex px-6">
    <div className="text-metal">{text}</div>
  </div>
);

export const AverageTimeCell = ({
  current,
  prev,
}: {
  current: number;
  prev: number;
}) => {
  // if !current then show as negative
  const delta = current - prev;
  const positiveTrend = current > 0 && delta < 0;
  return (
    <div className="flex items-center justify-evenly px-6">
      <div className="text-metal">{formatSeconds(current)}</div>
      {!!prev && (
        <DataTag
          type={positiveTrend ? "decrease" : "increase"}
          color={positiveTrend ? "green" : "red"}
          label={prev && delta ? formatSeconds(delta) : ""}
        />
      )}
    </div>
  );
};

export const EmailBreakdownCell = ({
  emailsIn,
  emailsOut,
  meetings,
}: {
  emailsIn: number;
  emailsOut: number;
  meetings?: number;
}) => (
  <div className="flex justify-around gap-6 px-4">
    <div className="flex items-center justify-center gap-2 text-metal">
      <i className="icon-email-in text-xl"></i>
      {emailsIn}
    </div>
    <div className="flex items-center justify-center gap-2 text-metal">
      <i className="icon-email-out text-xl"></i>
      {emailsOut}
    </div>
    {meetings && (
      <div className="flex items-center justify-center gap-2 text-metal">
        <i className="icon-calendar text-xl"></i>
        {meetings}
      </div>
    )}
  </div>
);

export const ContactCell = ({ fullName }: { fullName: string }) => (
  <div className="flex items-center gap-2 px-6">
    <div className="h-4 w-4 rounded-full bg-metal-dark"></div>
    <div className="font-bold text-metal-dark">{fullName}</div>
  </div>
);
