import classnames from "classnames";

const Checkbox = ({
  checked,
  size,
  label,
  onClick,
}: {
  checked?: boolean;
  size: "small" | "standard" | "large";
  label?: string;
  onClick?: () => void;
}) => {
  const onKeyDown = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && onClick) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      className="group flex cursor-pointer items-center gap-2 outline-none"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        className={classnames(
          "flex flex-shrink-0 items-center justify-center rounded border border-dust group-hover:border-dust-dark group-focus:border-mint",
          {
            "h-6 w-6": size === "small",
            "h-8 w-8": size === "standard",
            "h-10 w-10": size === "large",
            "bg-dust": !!checked,
          }
        )}
      >
        <i
          className={classnames("icon-check text-navy", {
            "text-xl": size === "small",
            "text-2xl": size === "standard",
            "text-3xl": size === "large",
            "text-navy": !!checked,
            "text-white group-hover:text-dust group-focus:text-dust": !checked,
          })}
        ></i>
      </div>
      <div className="text-lg text-navy">{label}</div>
    </div>
  );
};

export default Checkbox;
