import classnames from "classnames";

export type SelectOptionType = {
  value: any;
  label: string;
};

const SelectOption = ({
  value,
  size,
  onChange,
  disabled,
  options,
}: {
  value: any;
  size?: "standard" | "large";
  onChange: (value: any) => void;
  disabled?: boolean;
  options: SelectOptionType[];
}) => (
  <select
    value={value}
    disabled={disabled}
    onChange={(event) => onChange(event.target.value)}
    className={classnames("w-full rounded border border-dust focus:ring-0", {
      "px-4 py-2 pr-8 text-base": !size || size === "standard",
      "px-4 py-4 pr-8 text-lg": size === "large",
      "cursor-not-allowed text-dust-dark hover:bg-white focus:border-dust":
        disabled,
      "cursor-pointer text-metal hover:border-dust-dark focus:border-mint focus:text-navy":
        !disabled,
    })}
  >
    {options.map((option) => (
      <option
        key={option.value}
        value={option.value}
        disabled={option.label === "---"}
      >
        {option.label}
      </option>
    ))}
  </select>
);

export default SelectOption;
