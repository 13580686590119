import { useCubeQuery } from "@cubejs-client/react";
import { useGetUserQuery } from "features/profile/profileApi";
import { head } from "ramda";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useActivityInNumbers = () => {
  const cubejsApi = useCubejsApi("activity-in-numbers");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: [
        "fact_table.outbound_engaged_current",
        "fact_table.outbound_current",
        "fact_table.outbound_prev",
        "fact_table.inbound_current",
        "fact_table.inbound_prev",
        "fact_table.meetings_current",
        "fact_table.meetings_prev",
        "fact_table.average_meetings_duration_current",
      ],
      order: [],
      dimensions: [],
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
      ],
    },
    {
      cubejsApi,
    }
  );
  const results = resultSet?.tablePivot() || [];
  const result = head(results);
  return {
    result,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useActivityInNumbers;
