import { useNavigate } from "react-router-dom";
import { formatSeconds } from "utils/dates";
import classnames from "classnames";

import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewSkeleton from "../components/LiveReportPreviewSkeleton";
import useClientServiceLevel from "pages/report/hooks/useClientServiceLevel";

export type BadgeSize = "small" | "large";

export const ActivityPreviewBadge = ({
  value,
  description,
  size,
}: {
  value: any;
  description: { singular: string; plural: string };
  size?: BadgeSize;
}) => (
  <div
    className={classnames(
      `flex h-40 flex-shrink-0 flex-col items-center justify-center rounded bg-gradient-to-br from-orange to-orange-dark p-6 text-center text-white`,
      {
        "w-[186px]": size === "large",
        "w-[146px]": size === "small" || !size,
      }
    )}
  >
    <div className="text-3xl">{value}</div>
    <div className="text-xl">
      {value > 1 || value === 0 ? description.plural : description.singular}
    </div>
  </div>
);

const ClientServiceLevelPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/client-service-level");
  const { result, isLoading } = useClientServiceLevel({
    timeOption: "Last 180 days",
    teamOption: "all-teams",
    lensOption: "top-companies",
  });

  if (isLoading) {
    return <LiveReportPreviewSkeleton />;
  }
  if (result === undefined) {
    return <></>;
  }

  const outbound = result["outboundMessages"];
  const engaged = result["outboundEngagedMessages"];
  const averageEngement =
    outbound > 0 ? ((engaged / outbound) * 100).toFixed(1) : 0;

  return (
    <LiveReportPreview
      color="salmon"
      icon="icon-relationships"
      title="Client service levels"
      description="Monitor your service levels to key clients"
      openTooltip="Explore client service levels"
      isBeta
      openReport={openReport}
    >
      <div className="pt-4">
        <div className="flex h-[176px] gap-2 overflow-scroll">
          <ActivityPreviewBadge
            description={{
              singular: "email sent",
              plural: "emails sent",
            }}
            value={result["outboundMessages"]}
          />
          <ActivityPreviewBadge
            description={{
              singular: "email received",
              plural: "emails received",
            }}
            value={result["inboundMessages"]}
          />
          <ActivityPreviewBadge
            description={{
              singular: "average engagement",
              plural: "average engagement",
            }}
            value={`${averageEngement}%`}
          />
          <ActivityPreviewBadge
            description={{
              singular: "average response time to them",
              plural: "average response time to them",
            }}
            value={formatSeconds(Number(result["responseTimeOut"]))}
            size="large"
          />
          <ActivityPreviewBadge
            description={{
              singular: "meeting",
              plural: "meetings",
            }}
            value={result["meetings"]}
          />
        </div>
      </div>
    </LiveReportPreview>
  );
};

export default ClientServiceLevelPreview;
