import { formatSeconds } from "utils/dates";

const plugins = {
  tooltip: {
    backgroundColor: "#002258",
    titleMarginBottom: 8,
    boxPadding: 4,
  },
  legend: {
    position: "bottom" as const,
    labels: {
      boxWidth: 16,
      boxHeight: 16,
    },
  },
};

export const chartOptions = {
  scales: {
    x: { grid: { display: false } },
    y: {
      grid: { display: false },
      ticks: {
        stepSize: 50,
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  plugins,
};

export const timeOnYOptions = {
  scales: {
    x: { grid: { display: false } },
    y: {
      grid: { display: false },
      ticks: {
        stepSize: 3600,
        callback: (value: string | number) => {
          return typeof value !== "number" ? value : formatSeconds(value);
        },
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  plugins: {
    tooltip: {
      backgroundColor: "#002258",
      titleMarginBottom: 8,
      boxPadding: 4,
      callbacks: {
        label: (item: any) => {
          const value = !!item && !!item.raw ? item.raw : undefined;
          if (!!value && typeof value === "number") {
            return formatSeconds(value);
          } else if (!!value && !isNaN(Number(value))) {
            return formatSeconds(Number(value));
          }
          return "-";
        },
      },
    },
    legend: {
      position: "bottom" as const,
      labels: {
        boxWidth: 16,
        boxHeight: 16,
      },
    },
  },
};

export const timelineOptions = {
  scales: {
    x: {
      grid: { display: false },
      type: "time" as any,
      time: {
        tooltipFormat: "yyyy-MM-dd",
        unit: "month",
        displayFormats: {
          month: "MMMM",
        },
      },
    },
    y: {
      grid: { display: false },
      ticks: {
        stepSize: 10,
      },
    },
  },
  elements: { point: { radius: 0 } },
  responsive: true,
  aspectRatio: 4,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  plugins,
};

export const pieOptions = {
  responsive: true,
  plugins,
};

export const stackedBarOptions = {
  scales: {
    x: {
      stacked: true,
      ticks: {
        maxRotation: 0,
        minRotation: 0,
      },
      border: {
        display: false,
      },
      grid: { display: false },
    },
    y: {
      stacked: true,
      grid: { display: false },
      ticks: {
        stepSize: 25,
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  plugins,
};
