export const DESKTOP_AGENTS = [
  "windows_outlook",
  "windows_chrome",
  "windows_ie",
  "windows_firefox",
  "windows_edge",
  "macos_browser",
  "linux_browser",
];
export const MOBILE_AGENTS = [
  "iphone_email_client",
  "ipad_email_client",
  "iphone_outlook",
  "android_email_client",
  "android_browser",
  "iphone_browser",
];

export const TABLET_AGENTS = ["ipad_browser"];

export const WINDOWS_AGENTS = [
  "windows_outlook",
  "windows_chrome",
  "windows_ie",
  "windows_firefox",
  "windows_edge",
];

export const IOS_AGENTS = [
  "macos_browser",
  "iphone_email_client",
  "ipad_email_client",
  "iphone_outlook",
  "ipad_browser",
  "iphone_browser",
];

export const LINUX_AGENTS = ["linux_browser"];

export const ANDROID_AGENTS = ["android_email_client", "android_browser"];

export const deviceToPlatformIcon = (value: any) => {
  if (WINDOWS_AGENTS.includes(value)) {
    return "icon-microsoft";
  } else if (IOS_AGENTS.includes(value)) {
    return "icon-apple";
  } else if (LINUX_AGENTS.includes(value)) {
    return "icon-linux";
  } else if (ANDROID_AGENTS.includes(value)) {
    return "icon-android";
  } else {
    return "";
  }
};

export const deviceToTypeIcon = (value: any) => {
  if (MOBILE_AGENTS.includes(value)) {
    return "icon-mobile";
  } else if (DESKTOP_AGENTS.includes(value)) {
    return "icon-laptop";
  } else if (value === "bot") {
    return "icon-robot";
  } else {
    return "";
  }
};
