import Identifier, { IdentifierType } from "components/atoms/Identifier";
import { getInitials } from "utils/string";

const SearchBarResult = ({
  title,
  description,
  entityType,
  entityUuid,
  onClick,
  logoUrl,
}: {
  title: string;
  description: string;
  entityType: string;
  entityUuid: string;
  onClick?: (props: any) => void;
  logoUrl?: string;
}) => (
  <div
    onClick={() =>
      onClick && onClick({ title, description, entityType, entityUuid })
    }
    className="group m-2 flex cursor-pointer items-center rounded p-2 hover:bg-dust-light"
  >
    <Identifier
      type={entityType as IdentifierType}
      text={getInitials(title)}
      logoUrl={logoUrl}
    />
    <div className="ml-4 flex flex-1 flex-col">
      <div className="font-bold text-navy">{title}</div>
      <div className="text-sm text-metal">{description}</div>
    </div>
    <div className="flex-0">
      <i className="icon-right text-xl text-dust-darker group-hover:text-navy"></i>
    </div>
  </div>
);
export default SearchBarResult;
