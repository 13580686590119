import { useRef, useEffect } from "react";

/**
 * useDebounce
 * @param {function} callback - value to be debounced after delay
 */
export default function useOutsideClick(callback: () => void) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        ref.current &&
        !(ref.current as HTMLElement).contains(event.target as any)
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref;
}
