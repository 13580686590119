import Button from "components/Button";
import TextInput from "components/TextInput";

export const LinkTrackingSkeleton = () => (
  <div className="flex w-full animate-pulse flex-row items-start justify-between gap-4 p-4 md:items-center">
    <div className="flex flex-grow flex-col justify-between gap-y-4 md:flex-row">
      <div className="flex flex-row items-center gap-4">
        <div className="pulse h-8 w-8 rounded bg-dust" />
        <div className="pulse h-4 w-64 rounded-full bg-dust-light lg:w-96" />
      </div>
      <div className="flex flex-col items-start gap-y-2 gap-x-4 pl-12 md:flex-row md:items-center md:pl-0">
        <div className="pulse h-4 w-36 rounded-full bg-dust" />
        <div className="pulse h-4 w-28 rounded-full bg-dust-light" />
        <div className="pulse h-4 w-20 rounded-full bg-dust" />
      </div>
    </div>
    <div className="flex items-center">
      <Button icon="icon-down" color="transparent" disabled />
    </div>
  </div>
);

export const LinkTrackingListSkeleton = ({
  withSearch = false,
}: {
  withSearch?: boolean;
}) => (
  <div className="mx-auto flex w-full max-w-5xl flex-col">
    {withSearch && (
      <div className="flex w-full flex-row items-center gap-x-2 px-4 py-4">
        <TextInput
          placeholder="Search for a link by URL..."
          icon="icon-search"
          size="large"
          clearable
          disabled
        />
        <Button
          icon="icon-filter-settings"
          color="white"
          tooltip="Track options"
          disabled
        />
      </div>
    )}
    {Array.from({ length: 7 }).map((_, i) => (
      <div key={i} className="flex items-center border-b border-dust">
        <LinkTrackingSkeleton />
      </div>
    ))}
  </div>
);
