import { addToast, removeToast } from "app/notificationsSlice";
import { useDispatch } from "react-redux";
import { IToast } from "../../components/toast/toast.interfaces";
import { v4 as uuidv4 } from "uuid";

export default function useNotifications() {
  const dispatch = useDispatch();

  const notify = (toast: IToast, timeout?: number): void => {
    toast.id = toast.id || uuidv4();
    dispatch(addToast(toast));
    if (timeout) {
      setTimeout(() => {
        dispatch(removeToast(toast.id));
      }, timeout);
    }
  };

  const removeNotification = (id: string): void => {
    dispatch(removeToast(id));
  };

  return { notify, removeNotification };
}
