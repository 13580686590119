import { SelectionFrame } from "components/frames";
import { useState } from "react";
import { PieChart, Bar } from "charts";
import { usePieInsights } from "pages/track/email/detail/usePieInsights";
import { useBarInsights } from "./useBarInsights";

const EmailDetailInsight = ({ id }: { id: string }) => {
  const [barMetric, setBarMetric] = useState({ day: "all", hour: "all" });

  const { barData, barOptions } = useBarInsights({
    id,
    barMetric,
  });
  const { pieData, pieOptions } = usePieInsights({ id });
  const barMetricOptions = (variant: "hour" | "day") => [
    {
      value: "all",
      label: `All interactions in first ${
        variant === "hour" ? "24 hours" : "7 days"
      }`,
    },
    {
      value: "high",
      label: `Highly engaged interactions in first ${
        variant === "hour" ? "24 hours" : "7 days"
      }`,
    },
    {
      value: "standard",
      label: `Engaged interactions in first ${
        variant === "hour" ? "24 hours" : "7 days"
      }`,
    },
  ];

  return (
    <div className="flex h-full flex-col overflow-y-scroll p-4 text-base text-metal">
      <div className="grid grid-cols-1 gap-4 text-base text-metal lg:grid-cols-2">
        <SelectionFrame
          options={barMetricOptions("hour")}
          value={barMetric.hour}
          onChange={(value: string) =>
            setBarMetric({ ...barMetric, hour: value })
          }
        >
          <Bar options={barOptions("hour")} data={barData("hour")} />
        </SelectionFrame>
        <SelectionFrame
          options={barMetricOptions("day")}
          value={barMetric.day}
          onChange={(value: string) =>
            setBarMetric({ ...barMetric, day: value })
          }
        >
          <Bar options={barOptions("day")} data={barData("day")} />
        </SelectionFrame>
        {pieData.map(({ title, data }, i) => (
          <div key={i} className="rounded-lg border border-dust-dark">
            <div className="flex flex-col ">
              <div className="p-4 pb-7">{title}</div>
              <div className="flex max-h-64 w-full justify-center">
                <PieChart data={data} options={pieOptions} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailDetailInsight;
