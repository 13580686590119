import { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { selectFiltersActive } from "app/filtersSlice";
import FiltersModal from "pages/explore/modals/FiltersModal";
import PermissionGuard from "utils/permissions/PermissionGuard";
import { Permissions } from "features/profile";

const FilterButton = ({
  disabled,
  warningOnActive,
  id,
}: {
  disabled?: boolean;
  warningOnActive?: boolean;
  id?: string;
}) => {
  const active = useSelector(selectFiltersActive);
  const [showModal, setShowModal] = useState(false);

  return (
    <PermissionGuard permission={Permissions.USE_FILTER_EXTRA}>
      <div className="flex gap-4">
        <button
          id={id}
          tabIndex={0}
          disabled={disabled}
          className={classnames(
            `min-w-8 flex h-8 items-center gap-2 rounded-full px-4 text-lg outline-none focus:ring focus:ring-mint sm:w-auto`,
            {
              [`hover:bg-${active ? "mint" : "dust"}-dark`]: !disabled,
              "bg-dust text-metal-dark hover:text-navy": !active,
              "bg-mint text-navy hover:text-navy-dark": active,
              "cursor-pointer": !disabled,
              "cursor-not-allowed": disabled,
            }
          )}
          onClick={() => setShowModal(true)}
        >
          <i className="icon-filter" />
          {active ? "Filters are applied to this page" : "Apply a filter"}
        </button>
        {warningOnActive && active && (
          <div className="hidden text-metal sm:block">
            <div>Some relationship data may be hidden below.</div>
            <div>Change the filters to view all data.</div>
          </div>
        )}

        <FiltersModal visible={showModal} onClose={() => setShowModal(false)} />
      </div>
    </PermissionGuard>
  );
};

export default FilterButton;
