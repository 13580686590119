import Toast from "./Toast";
import { IToast } from "./toast.interfaces";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import useNetworkDetector from "utils/hooks/useNetworkDetector";
import useNewVersionDetector from "utils/hooks/useNewVersionDetector";

const ToastContainer = () => {
  const notifications: IToast[] = useSelector(
    (state: RootState) => state.notifications
  );
  useNetworkDetector();
  useNewVersionDetector();

  return (
    <div className="relative">
      <div className="sm:px-auto fixed left-0 right-0 top-[74px] z-50 m-auto flex  w-full flex-col items-center px-2 text-lg sm:w-fit sm:min-w-[335px] md:w-fit md:min-w-[335px] lg:w-fit lg:min-w-[335px]">
        {notifications.map((toast: IToast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </div>
    </div>
  );
};

export default ToastContainer;
