import periodDifference from "./periodDifference";

const datesDeltaTypesPlurals = {
  day: "days",
  month: "months",
  year: "years",
};

export const formatDifference = (dateA: Date, dateB: Date) => {
  const { value, unity, isPrevious } = periodDifference(dateA, dateB);
  if (value <= 1 && unity === "day") {
    if (value === 0) return "Same day";
    else if (isPrevious) return "Previous day";
    return "Next day";
  }

  if (isPrevious)
    return `${value} ${
      value === 1 ? unity : datesDeltaTypesPlurals[unity]
    } before`;
  else
    return `After ${value} ${
      value === 1 ? unity : datesDeltaTypesPlurals[unity]
    }`;
};

export const formatDifferenceFromNow = (date: Date) => {
  const { value, unity, isPrevious } = periodDifference(new Date(), date);
  if (value <= 1 && unity === "day") {
    if (value === 0) return "Today";
    else if (isPrevious) return "Yesterday";
    return "Tomorrow";
  }

  if (isPrevious)
    return `${value} ${
      value === 1 ? unity : datesDeltaTypesPlurals[unity]
    } ago`;
  else
    return `In ${value} ${value === 1 ? unity : datesDeltaTypesPlurals[unity]}`;
};

export const lenghtOfPeriodFromNow = (date: Date) => {
  // lenght of period in absolute terms
  const { value, unity } = periodDifference(new Date(), date);
  return `${value} ${value === 1 ? unity : datesDeltaTypesPlurals[unity]}`;
};
