import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

const initialState: {
  main: string;
  subsection?: string;
  link?: string;
} = { main: "Explore" };

const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    changeBreadcrumb(
      state,
      action: PayloadAction<{
        main: string;
        subsection?: string;
        link?: string;
      }>
    ) {
      state.main = action.payload.main;
      state.subsection = action.payload.subsection
        ? action.payload.subsection
        : undefined;
      state.link = action.payload.link ? action.payload.link : undefined;
    },
  },
});

export const selectBreadcrumbs = (state: RootState) => state.breadcrumbs;

export const { changeBreadcrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
