import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";

const useCompanyQuery = ({
  companyId,
  measures,
  dimensions,
  additionalFilters,
}: {
  companyId: string;
  measures?: string[];
  dimensions?: string[];
  additionalFilters?: Filter[];
}) => {
  const { resultSet, isLoading } = useCubeQuery({
    measures,
    dimensions,
    filters: [
      {
        member: "dim_company.uuid",
        operator: "equals",
        values: [companyId],
      },
      ...(additionalFilters || []),
    ],
    limit: 1,
  });

  const results = resultSet?.tablePivot() || [];
  const row = results.length ? results[0] : {};

  return {
    result: row,
    isLoading,
  };
};

export default useCompanyQuery;
