import { useEffect, useState } from "react";
import { pathOr, prop, propOr } from "ramda";
import {
  eachMonthOfInterval,
  format,
  formatDistanceToNow,
  getMonth,
  getYear,
  parseISO,
} from "date-fns";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import SelectOption from "components/SelectOption";
import InformationTag from "components/atoms/InformationTag";
import {
  IContactDetail,
  IContactFlag,
} from "features/entity/entity.interfaces";
import { getFlag } from "utils/flags";

const ContactSettingsModal = ({
  visible,
  contact,
  onClose,
  onUpdate,
  onlyFlags,
}: {
  visible: boolean;
  contact?: Partial<IContactDetail>;
  onClose: () => void;
  onUpdate: (updates: any) => void;
  onlyFlags?: boolean;
}) => {
  const assistantFlag = getFlag(contact, "assistant") as IContactFlag;
  const personalContactFlag = getFlag(
    contact,
    "personal_contact"
  ) as IContactFlag;
  const leaverFlag = getFlag(contact, "leaver") as IContactFlag;

  const dateLeft: undefined | string = propOr(
    undefined,
    "dateEffective",
    leaverFlag
  );

  const [checkbox, toggleCheckbox] = useState({
    isAssistant: false,
    isPersonalContact: false,
    isLeaver: false,
  });

  useEffect(() => {
    toggleCheckbox({
      isAssistant: propOr(false, "value", assistantFlag) as boolean,
      isPersonalContact: propOr(false, "value", personalContactFlag) as boolean,
      isLeaver: propOr(false, "value", leaverFlag) as boolean,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const firstName =
    contact && contact.fullName ? contact.fullName.split(" ")[0] : "";
  const company = pathOr("", ["company", "displayName"], contact);

  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate);

  const [month, setMonth] = useState(
    dateLeft ? getMonth(parseISO(dateLeft)) : currentMonth
  );
  const [year, setYear] = useState(
    dateLeft ? getYear(parseISO(dateLeft)) : currentYear
  );

  const months = eachMonthOfInterval({
    start: new Date(2023, 0, 1),
    end: new Date(2023, 11, 30),
  }).map((date) => format(date, "LLLL")); // months from Jan to Dec

  const yearOptions = Array(5)
    .fill(currentYear)
    .map((value, i) => ({ value: value - i, label: String(value - i) }));

  const getTitle = () => {
    if (onlyFlags) {
      return (
        <>
          <i className="icon-flag" />
          Flag {firstName}
        </>
      );
    } else {
      return (
        <>
          <i className="icon-settings" />
          Contact settings
        </>
      );
    }
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            {getTitle()}
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 flex flex-col overflow-y-auto text-base">
          <div className="flex items-center gap-2 text-lg text-navy">
            <i className="icon-flag" />
            Flags
          </div>
          <div className="mx-5 mt-2 text-metal">
            Contact flags help to add context to the relationship data in
            Relata, allowing us to filter out contacts who are not relevant.
          </div>
          <div className="mt-4 flex flex-col gap-3" data-testid="contact-flags">
            <Checkbox
              checked={checkbox.isLeaver}
              onClick={() =>
                toggleCheckbox({ ...checkbox, isLeaver: !checkbox.isLeaver })
              }
              size="small"
              label={`${firstName} has left ${company}`}
            />
            {checkbox.isLeaver && (
              <div className="-mt-3 ml-3 flex flex-col gap-2 border-l border-l-dust-dark p-2 pl-5">
                <div className="text-metal">When did they leave?</div>
                <div className="flex gap-2">
                  <SelectOption
                    options={(year === currentYear
                      ? months.splice(0, currentMonth + 1)
                      : months
                    ).map((month, i) => ({
                      value: i,
                      label: month,
                    }))}
                    value={month}
                    onChange={setMonth}
                  />
                  <SelectOption
                    options={yearOptions}
                    value={year}
                    onChange={(value) => setYear(+value)}
                  />
                </div>
              </div>
            )}
            {leaverFlag && prop("lastModified", leaverFlag) && (
              <div className="ml-8 text-sm text-metal">
                {`Last changed ${formatDistanceToNow(
                  parseISO(prop("lastModified", leaverFlag) as string)
                )} ago by ${prop("editedBy", leaverFlag)}`}
              </div>
            )}
            <Checkbox
              checked={checkbox.isAssistant}
              onClick={() =>
                toggleCheckbox({
                  ...checkbox,
                  isAssistant: !checkbox.isAssistant,
                })
              }
              size="small"
              label={`${firstName} is an Executive Assistant`}
            />
            {assistantFlag && prop("lastModified", assistantFlag) && (
              <div className="ml-8 flex flex-col text-sm text-metal">
                {`Last changed ${formatDistanceToNow(
                  parseISO(prop("lastModified", assistantFlag) as string)
                )} ago by ${prop("editedBy", assistantFlag)}`}
              </div>
            )}
            <Checkbox
              checked={checkbox.isPersonalContact}
              onClick={() =>
                toggleCheckbox({
                  ...checkbox,
                  isPersonalContact: !checkbox.isPersonalContact,
                })
              }
              size="small"
              label={`${firstName} is a personal contact`}
            />
            {personalContactFlag &&
              prop("lastModified", personalContactFlag) && (
                <div className="ml-8 flex flex-col text-sm text-metal">
                  {`Last changed ${formatDistanceToNow(
                    parseISO(
                      prop("lastModified", personalContactFlag) as string
                    )
                  )} ago by ${prop("editedBy", personalContactFlag)}`}
                </div>
              )}
          </div>
          <div className="mt-6 flex flex-col gap-3">
            <InformationTag
              color="teal"
              label="Changes you make here will be visible to all users."
              icon="icon-info-circle"
            />
            <InformationTag
              color="teal"
              label="Changes can take up to 24 hours to process."
              icon="icon-info-circle"
            />
          </div>
        </div>
        <div className="mt-auto">
          <Button
            id="button-save-contact-settings"
            color="navy"
            size="large"
            text="Save changes"
            onClick={() => {
              const flags: Partial<IContactFlag>[] = [];
              if (
                checkbox.isLeaver !== propOr(false, "value", leaverFlag) ||
                (checkbox.isLeaver &&
                  format(new Date(year, month, 1), "yyyy-MM-dd") !==
                    format(
                      dateLeft ? parseISO(dateLeft) : new Date(),
                      "yyyy-MM-dd"
                    ))
              ) {
                flags.push({
                  flag: "leaver",
                  value: checkbox.isLeaver,
                  dateEffective: format(new Date(year, month, 1), "yyyy-MM-dd"),
                });
              }
              if (
                checkbox.isAssistant !== propOr(false, "value", assistantFlag)
              ) {
                flags.push({ flag: "assistant", value: checkbox.isAssistant });
              }
              if (
                checkbox.isPersonalContact !==
                propOr(false, "value", personalContactFlag)
              ) {
                flags.push({
                  flag: "personal_contact",
                  value: checkbox.isPersonalContact,
                });
              }
              onUpdate(flags);
            }}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactSettingsModal;
