import classnames from "classnames";

export type RankTagColor = "gold" | "silver" | "bronze";
export type RankTagSize = "small" | "large";

const RankTag = ({
  color,
  label,
  size,
}: {
  color: RankTagColor;
  label: string;
  size?: RankTagSize;
}) => (
  <div
    className={classnames(
      `bg-${color} flex items-center gap-1 rounded-full px-1`,
      {
        "h-[18px]": size === "large",
        "h-4": size === "small" || !size,
      }
    )}
  >
    <div
      className={classnames(`bg-${color}-dark rounded-full`, {
        "h-[10px] w-[10px]": size === "large",
        "h-2 w-2": size === "small" || !size,
      })}
    ></div>
    <div
      className={classnames(`text-${color}-darkest pr-1 font-bold`, {
        "text-sm": size === "small" || !size,
      })}
    >
      {label}
    </div>
  </div>
);

export default RankTag;
