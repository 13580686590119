import { useEffect, useState } from "react";

/**
 * useDebounce
 * @param {any} value - value to be debounced after delay
 * @param {number} delay - delay in miliseconds
 */
export default function useDebounce(value: any, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
