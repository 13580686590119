import { getHours } from "date-fns";
import { useEffect, useState } from "react";

/**
 * useGreetings
 */
export default function useGreetings(): string {
    const currentGreet = (() => {
        const hour = getHours(new Date())
        if (hour > 17) {
            return 'Good evening'
        } else if (hour > 11) {
            return 'Good afternoon'
        } else {
            return 'Good morning'
        }
    })
    const [greetings, setGreetings] = useState(currentGreet());

    useEffect(() => {
        const interval = setInterval(() => {
            setGreetings(currentGreet())
        }, 60 * 1000);
        return () => clearInterval(interval);
    },);

    return greetings;
}
