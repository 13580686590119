import { faker } from "@faker-js/faker";
import { mergeRight } from "ramda";

interface ICubeToken {
  token: string;
  expiration: number;
  rest_api_url: string;
}

const fakeCubeToken = (override: Partial<ICubeToken>): ICubeToken =>
  mergeRight(
    {
      token: faker.datatype.string(),
      expiration: Math.floor(faker.date.soon().getTime() / 1000),
      rest_api_url: "/api/fake-cubeapi/dev",
    },
    override
  );

export default fakeCubeToken;
