import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import classnames from "classnames";
import { prop, propOr } from "ramda";
import { useDispatch } from "react-redux";
import { decamelizeKeys } from "humps";

import Identifier from "components/atoms/Identifier";
import NavItem from "components/NavItem";
import Button from "components/Button";
import CompanyDetails from "components/CompanyDetails";

import { getInitials } from "utils/string";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "features/entity/entityApi";
import {
  useGetWatchlistQuery,
  useUpdateWatchlistMutation,
} from "features/watchlist/watchlistApi";
import { IWatchListItem } from "features/watchlist/watchlist.interfaces";
import { changeBreadcrumb } from "app/breadcrumbSlice";
import useCompanyQuery from "features/company/useCompanyQuery";
import PermissionGuard from "utils/permissions/PermissionGuard";
import { Permissions } from "features/profile";
import ErrorPage from "pages/ErrorPage";
import { useAnalytics } from "use-analytics";
import CompanySettingsModal from "pages/explore/modals/CompanySettingsModal";
import { ICompanyFlag } from "features/entity/entity.interfaces";
import { getFlag } from "utils/flags";
import InformationTag from "components/atoms/InformationTag";

const extractDomain = (email: string) => email.split("@")[1];

const CompanyProfileView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [updateCompany] = useUpdateCompanyMutation();
  const [showSettings, setShowSettings] = useState(false);
  const { track } = useAnalytics();

  const { result: knownContactsResult, isLoading: isKnownContactsLoading } =
    useCompanyQuery({
      companyId: params.id as string,
      measures: ["fact_table.contact_count"],
    });
  const knownContacts = prop("fact_table.contact_count", knownContactsResult);

  // TODO: move to the backend
  const { result: companyContactResult, isLoading: isDomainLoading } =
    useCompanyQuery({
      companyId: params.id as string,
      dimensions: ["dim_contact.email"],
    });
  const domain = extractDomain(
    propOr("@unknown.com", "dim_contact.email", companyContactResult) as string
  );

  const { data: watchlist } = useGetWatchlistQuery();
  const { data: company, error } = useGetCompanyQuery({
    id: params.id as string,
  });

  const serviceProviderFlag = company && getFlag(company, "service_provider");
  const isServiceProvider = propOr(
    false,
    "value",
    serviceProviderFlag
  ) as boolean;

  const [showDetails, setShowDetails] = useState(false);
  const title = company?.displayName || "";

  const [updateWatchlist] = useUpdateWatchlistMutation();
  const toggleOnWatchlist = async () => {
    if (onWatchlist) {
      await updateWatchlist(
        watchlist?.filter((item) => item.entityUuid !== params.id) || []
      );
      track(`Removed Company to watchlist`, {
        category: "Watchlist",
        label: "Company",
      });
    } else {
      await updateWatchlist([
        ...(watchlist || []),
        {
          entityType: "company",
          entityUuid: params.id as string,
        } as IWatchListItem,
      ]);
      track("Added Company to watchlist", {
        category: "Watchlist",
        label: "Company",
      });
    }
  };

  const updateSettings = async (flags: Partial<ICompanyFlag>[]) => {
    await updateCompany({ id: company?.uuid, flags: decamelizeKeys(flags) });
    setShowSettings(false);
  };

  const onWatchlist =
    (watchlist?.filter((item) => item.entityUuid === params.id) || []).length >
    0;

  useEffect(() => {
    document.title = `${title} | Explore | Relata`;
    dispatch(
      changeBreadcrumb({
        main: "Explore",
        subsection: "Company profile",
        link: "/explore/",
      })
    );
  }, [dispatch, title]);

  if (error && (error as any).status !== 404) {
    console.error(error);
  } else if (error && (error as any).status === 404) {
    return <ErrorPage type={404} />;
  }

  return (
    <div className="flex flex-col lg:flex-row">
      <div
        style={{
          background: `radial-gradient(100% 240px at 0% 64px, ${
            company?.originalBrandColour || "#52738F"
          }26 0%, rgba(248, 252, 255, 1) 100%)`,
        }}
        className="flex flex-col border border-dust-dark bg-dust-light px-4 pb-4 pt-16 lg:sticky lg:top-0 lg:h-screen lg:w-[350px]"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="h-20 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-navy">
            <div className="flex items-center p-4">
              <div className="mr-4">
                <Identifier
                  type="company"
                  size="large"
                  logoUrl={company?.logoUrl}
                  text={getInitials(title)}
                />
              </div>
              <div className="flex flex-col">
                {title}
                {isServiceProvider && (
                  <InformationTag
                    label="Service provider"
                    color="metal"
                    icon="icon-plug"
                  />
                )}
              </div>
            </div>
          </div>
          {!isServiceProvider && (
            <Button
              icon="icon-edit"
              tooltip="Suggest a name improvement"
              color="transparent"
              id="suggest-a-company-name"
            />
          )}
        </div>
        <div className="hidden rounded-lg border border-dust-dark bg-white shadow lg:visible lg:block">
          {!isServiceProvider && (
            <CompanyDetails
              contacts={knownContacts}
              domain={domain}
              isLoading={isKnownContactsLoading || isDomainLoading}
            />
          )}
        </div>
        <div className="mt-4 flex justify-between lg:mt-auto lg:justify-center">
          <div className="flex gap-1">
            <Button
              color="transparent"
              tooltip="Company settings"
              icon="icon-settings text-xl"
              id="contact-settings"
              onClick={() => setShowSettings(!showSettings)}
            />
            {!isServiceProvider && (
              <>
                <Button
                  color="transparent"
                  tooltip="Remove company"
                  icon="icon-delete text-xl"
                  id="remove-company"
                />
                <PermissionGuard permission={Permissions.USE_WATCHLIST}>
                  <Button
                    color="transparent"
                    onClick={toggleOnWatchlist}
                    tooltip={
                      onWatchlist ? "Remove from watchlist" : "Add to watchlist"
                    }
                    icon={classnames("text-xl", {
                      "icon-watchlist-remove": onWatchlist,
                      "icon-watchlist-add": !onWatchlist,
                    })}
                  />
                </PermissionGuard>
              </>
            )}
          </div>
          {!isServiceProvider && (
            <div className="visible lg:hidden">
              <Button
                text={
                  showDetails ? "Hide company details" : "Show company details"
                }
                color="transparent"
                iconRight
                onClick={() => setShowDetails(!showDetails)}
                icon={showDetails ? "icon-up" : "icon-down"}
              />
            </div>
          )}
        </div>

        <div
          className={classnames(
            "mt-4 rounded-lg border border-dust-dark bg-white shadow lg:hidden",
            { visible: showDetails, hidden: !showDetails }
          )}
        >
          <CompanyDetails
            contacts={knownContacts}
            domain={domain}
            isLoading={isKnownContactsLoading || isDomainLoading}
          />
        </div>
      </div>
      <div className="lg:w-[calc(100%-350px)] lg:pt-16">
        {!isServiceProvider ? (
          <>
            <div
              className={classnames("sticky top-[63px] z-30 h-12 gap-8 px-8", {
                "border-b border-dust-dark bg-white-96 shadow backdrop-blur":
                  true,
                "flex items-center md:justify-center lg:justify-start": true,
                "overflow-x-auto whitespace-nowrap": true,
              })}
            >
              <NavItem
                text="At a glance"
                to={`/explore/company/${params.id}/at-a-glance`}
              />
              <NavItem
                text="All contacts"
                to={`/explore/company/${params.id}/all-contacts`}
              />
              <NavItem
                text="Relationship change"
                to={`/explore/company/${params.id}/relationship-change`}
              />
              <NavItem
                text="Relationship explorer"
                to={`/explore/company/${params.id}/relationship-explorer`}
              />
              <NavItem
                text="Emails"
                to={`/explore/company/${params.id}/emails`}
              />
              <NavItem
                text="Meetings"
                to={`/explore/company/${params.id}/meetings`}
              />
            </div>
            <Outlet />
          </>
        ) : (
          <div className="flex h-full flex-col items-center gap-3 bg-gradient-to-b from-dust-light to-white pt-[20%]">
            <i className="icon-hidden text-2xl text-navy" />
            <div className="text-xl font-bold text-navy">
              {company?.displayName} has been flagged as a service provider
            </div>
            <div className="w-[300px] text-center text-metal">
              Relata does not display relationship data for service providers.
            </div>
            <div className="text-center text-metal">
              Service providers are still discoverable via search.
            </div>
          </div>
        )}
      </div>
      {company && (
        <CompanySettingsModal
          visible={showSettings}
          company={company}
          onClose={() => setShowSettings(false)}
          onUpdate={updateSettings}
        />
      )}
    </div>
  );
};

export default CompanyProfileView;
