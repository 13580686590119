import classNames from "classnames";
import InteractionTag from "components/atoms/InteractionTag";

const FilterTag = ({
  name,
  icon,
  isDefault,
  active,
  onClick,
}: {
  name: string;
  icon: string;
  isDefault?: boolean;
  active?: boolean;
  onClick?: () => void;
}) => {
  const onKeyDown = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && onClick) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={classNames(
        "flex h-30 cursor-pointer flex-col items-center gap-2 rounded-lg border bg-white p-2 text-center outline-none",
        {
          "border-metal hover:border-metal-dark hover:shadow-lg": active,
          "border-dust hover:border-dust-darker hover:shadow focus:ring focus:ring-mint":
            !active,
        }
      )}
    >
      <i className={classNames(`${icon} text-3xl text-navy`, {})}></i>
      <div className="h-8 text-base font-bold text-navy">{name}</div>
      <InteractionTag
        color={isDefault ? "dust" : "green"}
        label={isDefault ? "Default filters" : "Filters applied"}
      />
    </div>
  );
};

export default FilterTag;
