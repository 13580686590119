import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import RelationshipExplorerChart from "charts/RelationshipExplorerChart";
import WaffleChart from "./WaffleChart";
import OneDimensionChart from "./OneDimensionChart";
import PieChart from "./PieChart";
import {
  chartOptions,
  pieOptions,
  timelineOptions,
} from "charts/defaultOptions";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  TimeScale
);

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.color = "#52738F"; // metal

export {
  PieChart,
  Line,
  Bar,
  WaffleChart,
  OneDimensionChart,
  RelationshipExplorerChart,
  chartOptions,
  pieOptions,
  timelineOptions,
};
