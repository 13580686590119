import Button from "components/Button";
import Modal from "components/Modal";
import { IContactDetail } from "features/entity/entity.interfaces";

const CallItem = ({
  phoneNumber,
  isMobile,
  onClick,
}: {
  phoneNumber: string;
  isMobile: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      tabIndex={0}
      onClick={onClick}
      className="group flex cursor-pointer items-center justify-between text-metal outline-none hover:bg-dust-light hover:text-metal-dark focus:bg-dust-light focus:text-metal-dark focus:ring focus:ring-mint"
    >
      <i className={`${isMobile ? "icon-mobile" : "icon-phone"} m-4 text-xl`} />
      <div className="flex flex-1 flex-col">
        <div className="text-lg">Call {isMobile ? "mobile" : "landline"}</div>
        <div className="">{phoneNumber}</div>
      </div>
      <i className="icon-right mx-2 text-xl text-dust-darker" />
    </div>
  );
};

const ContactCallModal = ({
  visible,
  onClose,
  contact,
}: {
  visible: boolean;
  onClose: () => void;
  contact?: Partial<IContactDetail>;
}) => {
  const openCall = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Modal
      level="mid"
      visible={visible}
      onClose={onClose}
      id="contact-call-modal-container"
    >
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-mobile"></i>
            Call {contact?.fullName}
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 w-full text-base">
          {contact?.phoneNumbers?.map(({ phoneNumber, type }, index) => (
            <CallItem
              phoneNumber={phoneNumber as string}
              isMobile={type === "mobile"}
              key={index}
              onClick={() => openCall(phoneNumber as string)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ContactCallModal;
