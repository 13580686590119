import { propOr } from "ramda";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";
import { showContactModal } from "app/contactModalsSlice";

import useLapsingEngagement from "../hooks/useLapsingEngagement";
import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewBadge from "../components/LiveReportPreviewBadge";
import lastColleagueLapsingActivity from "../utils/lastColleagueLapsingActivity";
import ContactReportCard from "../components/ContactReportCard";
import LiveReportPreviewSkeleton from "../components/LiveReportPreviewSkeleton";
import { useGetContactQuery } from "features/entity/entityApi";

const LapsingContact = ({
  id,
  name,
  company,
  email,
  daysLapsed,
  lastInteraction,
  lastMeeting,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  daysLapsed: number;
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
}) => {
  const firstName = name.split(" ")[0];
  const dispatch = useDispatch();
  const { data: contact } = useGetContactQuery({
    id,
  });

  const onEmailClick = () =>
    dispatch(
      showContactModal({
        modal: "email",
        contact: { email, fullName: name },
      })
    );

  const onFlagClick = () =>
    dispatch(
      showContactModal({
        modal: "flags",
        contact,
      })
    );
  const { lastActivityLapsed, lastColleague, lastActivity } =
    lastColleagueLapsingActivity(lastInteraction, lastMeeting);

  return (
    <ContactReportCard
      id={id}
      name={name}
      company={company}
      email={email}
      color="dust"
    >
      <div className="flex flex-col gap-2">
        <div className="text-metal">
          You last interacted with {firstName}{" "}
          <span className="font-bold">
            {daysLapsed} {daysLapsed > 1 ? "days" : "day"} ago.
          </span>
        </div>
        {lastActivityLapsed && lastActivityLapsed < daysLapsed ? (
          <div className="text-metal">
            <span className="font-bold">{lastColleague}</span> {lastActivity}{" "}
            with {firstName}{" "}
            <span className="font-bold">
              {lastActivityLapsed} {lastActivityLapsed > 1 ? "days" : "day"}{" "}
              ago.
            </span>
          </div>
        ) : (
          <div className="text-metal">
            No colleagues have interacted with {firstName} since.
          </div>
        )}
        <div className="flex gap-2 pt-2">
          <Button
            color="dust"
            text={`Email ${firstName} now`}
            icon="icon-email"
            onClick={onEmailClick}
          />
          <Button
            color="transparent"
            icon="icon-flag"
            onClick={onFlagClick}
            tooltip="Flag contact"
          />
        </div>
      </div>
    </ContactReportCard>
  );
};

const LapsingEngagementPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/lapsing-engagement");
  const { resultSet, isLoading } = useLapsingEngagement({
    relevantOption: "relevant",
    timeOption: "90",
    orderOption: "most-relevant",
  });
  if (isLoading) {
    return <LiveReportPreviewSkeleton />;
  }
  return (
    <LiveReportPreview
      color="sky"
      icon="icon-contact"
      title="Lapsing engagement"
      description="Strong contacts who you haven't interacted with recently"
      openTooltip="Explore lapsing engagement"
      openReport={openReport}
    >
      {resultSet.length > 0 && (
        <div className="pt-4">
          <div className="flex gap-2 overflow-scroll">
            <LiveReportPreviewBadge
              color="sky"
              description={{
                singular: "lapsing contact",
                plural: "lapsing contacts",
              }}
              value={resultSet.length}
              openReport={openReport}
            />
            <div className="flex gap-4">
              {resultSet.slice(0, 2).map((contact) => (
                <LapsingContact
                  key={contact["dim_contact.uuid"] as string}
                  id={contact["dim_contact.uuid"] as string}
                  name={contact["dim_contact.full_name"] as string}
                  company={contact["dim_company.display_name"] as string}
                  email={contact["dim_contact.email"] as string}
                  daysLapsed={
                    +contact["fact_table.days_since_last_interaction"]
                  }
                  lastInteraction={JSON.parse(
                    propOr(
                      "{}",
                      "fact_table.last_interaction_details",
                      contact.viewer
                    )
                  )}
                  lastMeeting={JSON.parse(
                    propOr(
                      "{}",
                      "fact_table.last_interaction_details",
                      contact.viewer
                    )
                  )}
                />
              ))}
              <div className="visible sticky right-0 top-0 -ml-20 w-16 bg-gradient-to-r from-transparent to-white lg:invisible" />
            </div>
          </div>
        </div>
      )}
    </LiveReportPreview>
  );
};

export default LapsingEngagementPreview;
