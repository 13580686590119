import { useEffect, useState } from "react";
import { head } from "ramda";
import { addDays } from "date-fns";

import { IMessage } from "features/message/message.interface";
import {
  useGetMailboxesQuery,
  useGetMessagesQuery,
  useGetNewMessagesQuery,
} from "features/message/messageApi";
import useDebounce from "utils/hooks/useDebounce";
import { useTrackFilterOptions } from "utils/trackOptions";
import TrackOptionsModal from "../modals/TrackOptionsModal";
import EmailError from "./EmailError";
import EmailList from "./EmailList";
import EmailSearch, { EmailEmptySearch, SearchTargetType } from "./EmailSearch";
import { EmailListSkeleton } from "./EmailSkeletons";
import NewTrackRefetcher from "../NewTrackRefetcher";
import SlowQueryWarningModal from "pages/explore/common/SlowQueryWarningModal";

const SentEmailsView = () => {
  const [cursor, setCursor] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState("");
  const [searchTarget, setSearchTarget] = useState<SearchTargetType>("default");
  const [showOptions, setShowOptions] = useState(false);
  const [round, setRound] = useState(0);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [happenedAfter, setHappenedAfter] = useState("");
  const [nextHappenedAfter, setNextHappenedAfter] = useState("");
  const [trackOptions, setTrackOptions] = useTrackFilterOptions();
  const { data: mailboxes } = useGetMailboxesQuery();
  const {
    data: messages,
    isLoading: isMessagesLoading,
    isFetching: isMessagesFetching,
    isError: isMessagesError,
    refetch: refetchMessages,
  } = useGetMessagesQuery({
    search: debouncedSearchValue.length > 1 ? debouncedSearchValue : "",
    searchTarget,
    cursor,
    trackOptions,
  });
  const {
    data: newMessages,
    isLoading: isNewMessagesLoading,
    isFetching: isNewMessagesFetching,
    refetch: refetchNewMessages,
  } = useGetNewMessagesQuery(
    {
      happenedAfter,
      search: debouncedSearchValue.length > 1 ? debouncedSearchValue : "",
      searchTarget,
      round,
      trackOptions,
    },
    { skip: !happenedAfter }
  );
  const isFetching = isMessagesFetching || debouncedSearchValue !== searchValue;

  const fetchMore = () => {
    if (messages?.next) {
      setCursor(messages.next);
    }
  };

  const onChangeOptions = (value: any) => {
    setTrackOptions(value);
    setCursor(undefined);
  };
  const localSearch = (message: IMessage) => {
    if (!searchValue) return message.recipients.length > 0;
    switch (searchTarget) {
      case "default":
        return (
          (message.messageSubject
            .toLowerCase()
            .includes(searchValue.toLowerCase()) &&
            message.recipients.length > 0) ||
          message.recipients.filter(({ contact }) =>
            contact.fullName.toLowerCase().includes(searchValue.toLowerCase())
          ).length > 0
        );
      case "fullName":
        return (
          message.recipients.filter(({ contact }) =>
            contact.fullName.toLowerCase().includes(searchValue.toLowerCase())
          ).length > 0
        );
      case "messageSubject":
        return (
          message.messageSubject
            .toLowerCase()
            .includes(searchValue.toLowerCase()) &&
          message.recipients.length > 0
        );
      default:
        return message.recipients.length > 0;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!newMessages?.next && !isMessagesLoading && !isMessagesFetching) {
        if (nextHappenedAfter !== happenedAfter) {
          setHappenedAfter(nextHappenedAfter);
        } else {
          refetchNewMessages();
        }
      }
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (messages?.results && messages.results.length > 0) {
      const latest = (head(messages.results) as IMessage).sendTimestamp;
      setNextHappenedAfter(latest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (newMessages?.results && newMessages.results.length > 0) {
      const latest = (head(newMessages.results) as IMessage).sendTimestamp;
      setNextHappenedAfter(latest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages]);

  const updateSentEmails = () => {
    setCursor(undefined);
    setRound(round + 1);
    setHappenedAfter(addDays(new Date(), 3).toISOString());
    refetchMessages();
  };

  if (isMessagesError) return <EmailError />;
  if (isMessagesLoading) return <EmailListSkeleton />;
  return (
    <div className="flex h-full flex-col">
      <EmailSearch
        searchValue={searchValue}
        setShowOptions={() => setShowOptions(true)}
        setSearchTarget={(value) => {
          setSearchTarget(value);
          setCursor(undefined);
          setHappenedAfter(new Date().toISOString());
        }}
        setSearchValue={(value) => {
          setSearchValue(value);
          setCursor(undefined);
          setHappenedAfter(new Date().toISOString());
        }}
      />
      {!isNewMessagesLoading &&
        !isNewMessagesFetching &&
        !!newMessages?.next && (
          <NewTrackRefetcher type="emails" onClick={updateSentEmails} />
        )}
      {!!searchValue &&
      !isFetching &&
      messages?.results.filter(localSearch).length === 0 ? (
        <EmailEmptySearch />
      ) : (
        <EmailList
          fetchMore={fetchMore}
          isFetching={isFetching}
          searchValue={searchValue}
          searchTarget={searchTarget}
          messages={[
            ...(newMessages?.results.filter(localSearch) || []),
            ...(messages?.results.filter(localSearch) || []),
          ]}
          hasNoMore={!messages?.next}
        />
      )}
      <SlowQueryWarningModal isLoading={isFetching} />

      {mailboxes && (
        <TrackOptionsModal
          mailboxes={mailboxes}
          visible={showOptions}
          onChange={onChangeOptions}
          onClose={() => setShowOptions(false)}
        />
      )}
    </div>
  );
};
export default SentEmailsView;
