import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export type IVersion = { version?: string; commit?: string };
export const versionApi = createApi({
  reducerPath: "version",
  baseQuery: fetchBaseQuery({ baseUrl: window.location.origin }),
  endpoints: (builder) => ({
    getBuild: builder.query<IVersion, void>({
      query: () => "/relata.version.json",
    }),
  }),
});

export const { useGetBuildQuery } = versionApi;
