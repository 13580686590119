import Button from "components/Button";
import TextInput from "components/TextInput";
import { useState } from "react";
import { csrfInit } from "utils/requests";
import { useRequestOtpMutation } from "../profileApi";
import LoginContainer from "./LoginContainer";
import { LoginComponentType } from ".";
import LoginRequestLinkDone from "./LoginRequestLinkDone";
import { isValidEmail } from "utils/emailValidation";

const LoginOtp = ({
  switchComponent,
}: {
  switchComponent: (component: LoginComponentType) => void;
}) => {
  const [requestLink, { isLoading }] = useRequestOtpMutation();
  const [requestDone, setRequestDone] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>();

  const handleRequest = async () => {
    if (email.length === 0) {
      setError("Enter an email address.");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Enter a valid email address.");
      return;
    }

    try {
      if (!(await csrfInit())) throw new Error("no CSRF set");
      await requestLink({ email }).unwrap();
    } catch (error) {
      console.error(error);
    }
    setRequestDone(true);
  };

  const handleChange = (value: string) => {
    setEmail(value);
    setError(undefined);
  };

  if (requestDone) return <LoginRequestLinkDone email={email} />;
  return (
    <LoginContainer text="Welcome to Relata">
      <div className="ml-4 mt-6 flex-grow text-lg font-bold text-navy">
        Email address
      </div>
      <div className="mb-4 mt-2 flex flex-col gap-4 px-4">
        <TextInput
          error={error}
          type="email"
          placeholder="name@company.com"
          onChangeText={handleChange}
        />
        <Button
          isLoading={isLoading}
          color="navy"
          iconRight
          id="login-link-button"
          icon="icon-right"
          text="Request a secure login link"
          onClick={handleRequest}
        />
        <Button
          color="dust"
          id="login-password-button"
          text="Log in with a password"
          onClick={() => switchComponent("standard")}
        />
      </div>
    </LoginContainer>
  );
};

export default LoginOtp;
