import { faker } from "@faker-js/faker";
import { RelationshipSignalType } from "components/RelationshipSignals/useRelationshipSignals";
import fakeCubeResponse from "./cubeResponse";

const fakeRelationshipSignal = (
  type: RelationshipSignalType,
  override?: {
    [key: string]: string | number | boolean;
  }[]
) => {
  switch (type) {
    case "relationship-score":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.relationship_score_prev",
            "fact_table.relationship_score_current",
          ],
        },
        data: override || [
          {
            "fact_table.relationship_score_prev": faker.datatype.number(100),
            "fact_table.relationship_score_current": faker.datatype.number(100),
          },
        ],
      });
    case "personal-relationship-score":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.relationship_score_prev",
            "fact_table.relationship_score_current",
          ],
        },
        data: override || [
          {
            "fact_table.relationship_score_prev": faker.datatype.number(100),
            "fact_table.relationship_score_current": faker.datatype.number(100),
          },
        ],
      });
    case "interactive-colleagues":
      return fakeCubeResponse({
        query: { measures: ["fact_relationship_score.colleague_count"] },
        data: override || [
          {
            "fact_relationship_score.colleague_count": faker.datatype.number(3),
          },
        ],
      });
    case "unsolicited-inbound":
      return fakeCubeResponse({
        query: { measures: ["fact_table.unsolicited_inbound_current"] },
        data: override || [
          {
            "fact_table.unsolicited_inbound_current": faker.datatype.number(15),
          },
        ],
      });
    case "engagement-score":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.all_interactions_current",
            "fact_table.all_interactions_prev",
          ],
        },
        data: override || [
          {
            "fact_table.all_interactions_current": faker.datatype.number(10),
            "fact_table.all_interactions_prev": faker.datatype.number(10),
          },
        ],
      });
    case "teams-engagement-score":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.all_interactions_current",
            "fact_table.all_interactions_prev",
          ],
          dimensions: ["dim_team.name", "dim_team.uuid"],
        },
        data:
          override ||
          Array(5)
            .fill(null)
            .map(() => ({
              "fact_table.all_interactions_current": faker.datatype.number(10),
              "fact_table.all_interactions_prev": faker.datatype.number(10),
              "dim_team.name": `Team ${faker.animal.type()}`,
              "dim_team.uuid": faker.datatype.uuid(),
            })),
      });
    case "response-time":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.reply_response_time_current",
            "fact_table.reply_response_time_prev",
          ],
        },
        data: override || [
          {
            "fact_table.reply_response_time_current": faker.datatype.number(10),
            "fact_table.reply_response_time_prev": faker.datatype.number(10),
          },
        ],
      });
    case "link-clicks":
      return fakeCubeResponse({
        query: {
          measures: [
            "fact_table.link_clicks_current",
            "fact_table.link_clicks_prev",
          ],
        },
        data: override || [
          {
            "fact_table.link_clicks_current": faker.datatype.number(10),
            "fact_table.link_clicks_prev": faker.datatype.number(10),
          },
        ],
      });
    case "revisits":
      return fakeCubeResponse({
        query: {
          measures: ["fact_table.revisits_current", "fact_table.revisits_prev"],
        },
        data: override || [
          {
            "fact_table.revisits_current": faker.datatype.number(100),
            "fact_table.revisits_prev": faker.datatype.number(100),
          },
        ],
      });
    case "active-contacts":
      return fakeCubeResponse({
        query: {
          dimensions: ["dim_contact.activity_status"],
          measures: ["dim_contact.count"],
        },
        data: override || [
          {
            "dim_contact.activity_status": 0,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_status": 1,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_status": 2,
            "dim_contact.count": faker.datatype.number(10),
          },
        ],
      });
    case "active-contacts-change":
      return fakeCubeResponse({
        query: {
          dimensions: [
            "dim_contact.activity_prev_status",
            "dim_contact.activity_status",
          ],
          measures: ["dim_contact.count"],
        },
        data: override || [
          {
            "dim_contact.activity_status": 0,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_status": 1,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_status": 2,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_prev_status": 0,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_prev_status": 1,
            "dim_contact.count": faker.datatype.number(10),
          },
          {
            "dim_contact.activity_prev_status": 2,
            "dim_contact.count": faker.datatype.number(10),
          },
        ],
      });
    case "new-contacts":
      return fakeCubeResponse({
        query: { measures: ["dim_contact.count"] },
        data: override || [
          {
            "dim_contact.count": faker.datatype.number(3),
          },
        ],
      });
  }
};

export default fakeRelationshipSignal;
