import classNames from "classnames";
import { useState, useRef, useEffect } from "react";

export interface IDataPoint {
  label: string;
  value?: number;
  color: string;
  tooltip: string;
}

/**
 * OneDimensionChart Component
 *
 * This component displays data points within a specified interval (default is [-1, 1]) by positioning them along a horizontal axis.
 * The data points are represented as rectangles with custom colors. The chart also includes a zero-axis in the center.
 * When hovering over a data point, a tooltip appears showing all points with the hovered value.
 *
 * @param {Array<IDataPoint>} data - An array of data points to be displayed on the chart.
 * @param {number} min - The minimum value of the data range. Default is -1.
 * @param {number} max - The maximum value of the data range. Default is 1.
 *
 */
const OneDimensionChart = ({
  data,
  min = -1,
  max = 1,
  title,
  noDataTitle = "No data",
  noDataDescription = "We cannot find any data",
}: {
  data: IDataPoint[];
  min?: number;
  max?: number;
  title: string;
  noDataTitle?: string;
  noDataDescription?: string;
}) => {
  const [containerWidth, setContainerWidth] = useState(10);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [containerRef]);

  const mapDataPointToScale = (input: number): string => {
    const scaleWidth = 100 - (5 / containerWidth) * 100; // using the width of datapoint (5)
    const normalizedInput = (input - min) / (max - min);
    const xPosition = normalizedInput * scaleWidth;
    return Math.min(scaleWidth, Math.max(0, xPosition)) + "%";
  };

  const pointsWithSameValue = (value: number) =>
    data.filter(
      (point) =>
        point.value !== undefined && point.value.toFixed(2) === value.toFixed(2)
    );
  return (
    <div
      className="overflow-hidden"
      ref={containerRef}
      data-testid="one-dimension-chart"
    >
      <>
        <div className="relative my-12 flex h-36 items-center">
          {data.filter(({ value }) => value !== undefined).length === 0 && (
            <div className="absolute left-1/2 top-1/2 z-20 flex w-[350px] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center gap-2 rounded-md border border-dust bg-white px-8 py-4 text-center shadow-lg backdrop-blur">
              <div className="text-lg font-bold text-navy">{noDataTitle}</div>
              <div className="text-base text-metal">{noDataDescription}</div>
            </div>
          )}
          {/* background */}
          <div className="absolute h-16 w-full rounded-lg bg-dust-light" />

          {/* zero axis */}
          <div className="absolute left-1/2 h-32 w-px -translate-x-[0.5px] transform bg-dust-darker" />

          <div className="absolute right-0 top-0 text-metal">Positive</div>
          <div className="absolute left-0 top-0 text-metal">Negative</div>

          {/* data points */}
          {data.map(
            ({ value, color, label }, index) =>
              value !== undefined && (
                <div
                  key={index}
                  data-testid={`data-point-${label}`}
                  className={`group absolute flex h-24 w-[5px] items-center justify-center rounded bg-${color}`}
                  style={{
                    left: mapDataPointToScale(value),
                  }}
                >
                  {/* datapoint tooltip */}
                  <div className="relative">
                    <div
                      id={`datapoint-tooltip-${label}`}
                      data-testid={`datapoint-tooltip-${label}`}
                      className={classNames(
                        "invisible absolute z-10 rounded bg-navy p-1 text-white group-hover:visible",
                        {
                          "left-2": value < min / 2,
                          "right-2": value > max / 2,
                          "left-1/2 top-1/2 -translate-x-1/2 transform":
                            value < max / 2 && value > min / 2,
                        }
                      )}
                    >
                      <div className="mb-1 whitespace-nowrap font-bold">
                        {title}
                      </div>
                      {pointsWithSameValue(value).map(
                        ({ color, tooltip, value }, index) =>
                          value !== undefined && (
                            <div
                              key={index}
                              className="flex items-center gap-1 whitespace-nowrap"
                            >
                              <div
                                className={`h-2 w-2 rounded-sm bg-${color}`}
                              />
                              <div>
                                {tooltip}: {value.toFixed(2)}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        <div className="flex flex-col items-center justify-center gap-4 text-gray-500">
          {data.map(({ color, label }, index) => (
            <div key={index} className="flex items-center gap-1">
              <div className={`h-4 w-4 rounded-sm bg-${color}`} />
              <div>{label}</div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default OneDimensionChart;
