import { DESKTOP_AGENTS, MOBILE_AGENTS, TABLET_AGENTS } from "../devices";

/**
 * classifyHours
 * @param {list} list - list of object with hour in UTC and count, classify hours to a
 * part of the day, returns an object with part of the day as key and count as value
 */
export const classifyHours = (list: { hour: number; count: number }[]) => {
  const offset = new Date().getTimezoneOffset() / -60;

  let morning = 0;
  let afternoon = 0;
  let night = 0;

  for (const { hour: utcHour, count } of list) {
    // Convert UTC hour to local hour
    const hour = utcHour + offset;

    if (hour > 6 && hour < 12) {
      morning += count;
    } else if (hour >= 12 && hour < 20) {
      afternoon += count;
    } else {
      night += count;
    }
  }

  return { morning, afternoon, night };
};

/**
 * classifyDevices
 * @param {list} list - list of object with deviceType and count as props,
 * classify device type to desktop, mobile or tablet.
 * returns an object with device type as key and count as value
 */
export const classifyDevices = (
  list: { deviceType: string; count: number }[]
) => {
  let mobile = 0;
  let desktop = 0;
  let tablet = 0;
  for (const { deviceType, count } of list) {
    if (MOBILE_AGENTS.includes(deviceType)) {
      mobile += count;
    } else if (DESKTOP_AGENTS.includes(deviceType)) {
      desktop += count;
    } else if (TABLET_AGENTS.includes(deviceType)) {
      tablet += count;
    }
  }

  return { mobile, desktop, tablet };
};

/**
 * classifyDevices
 * @param {list} classifyCountries - list of object with countryCode and count as props,
 * classify null countryCode as unknown and return an object with countryCode as key and count as value
 */
export const classifyCountries = (
  list: { countryCode: string | null; count: number }[]
) => {
  let countriesCount: { [key: string]: number } = {};
  for (const { countryCode, count } of list) {
    if (countryCode === null) {
      countriesCount.Unknown = count;
    } else {
      countriesCount[countryCode] = count;
    }
  }

  return countriesCount;
};
