import { ReactNode } from "react";
import SelectOption, { SelectOptionType } from "components/SelectOption";
import BaseFrame from "./BaseFrame";
import RelataLoading from "assets/RelataLoading.svg";

const SelectionFrame = ({
  children,
  options,
  isLoading,
  value,
  onChange,
  monthRanges,
  monthRangeValue,
  onMonthRangeChange,
}: {
  children: ReactNode;
  options: SelectOptionType[];
  isLoading?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  monthRanges?: number[];
  monthRangeValue?: number;
  onMonthRangeChange?: (range: number) => void;
}) => (
  <BaseFrame>
    <div className="flex flex-col gap-4 sm:flex-row">
      {options !== undefined && value !== undefined && (
        <SelectOption
          options={options}
          value={value}
          onChange={(value) => {
            onChange && onChange(value);
          }}
        />
      )}
      {monthRanges && monthRanges.length > 0 && (
        <SelectOption
          options={monthRanges.map((months) => ({
            value: months,
            label: `Over previous ${months} months`,
          }))}
          value={monthRangeValue || monthRanges[0]}
          onChange={(value) => {
            if (!!value && typeof value === "number") {
              onMonthRangeChange && onMonthRangeChange(value);
            } else if (!!value && !isNaN(Number(value))) {
              onMonthRangeChange && onMonthRangeChange(Number(value));
            }
          }}
        />
      )}
    </div>
    {isLoading ? (
      <div className="my-[80px] flex items-center justify-center">
        <div className="flex flex-col items-center">
          <img
            src={RelataLoading}
            className="h-[96px] w-[96px]"
            alt="loading spinner"
          />
        </div>
      </div>
    ) : (
      children
    )}
  </BaseFrame>
);

export default SelectionFrame;
