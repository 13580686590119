const WatchListSkeleton = () => (
  <div
    style={{
      background:
        "radial-gradient(100.00% 64px at 0.11% -0.00%, rgba(82, 115, 143, 0.15) 0%, #ffffff 100%)",
    }}
    className="min-w-[280px] rounded-md border border-dust-dark shadow"
  >
    <div className="flex items-center p-4">
      <div className="h-8 w-8 animate-pulse rounded-full bg-dust-darker"></div>
      <div className="ml-4 flex flex-1 animate-pulse flex-col gap-1">
        <div className="h-3 w-[115px] rounded bg-dust-dark"></div>
        <div className="h-3 w-14 rounded bg-dust"></div>
      </div>
      <div className="h-6 w-6 animate-pulse rounded bg-dust"></div>
    </div>
    <div className="pl-4 pr-8">
      <div className="w-full border-b border-dust" />
    </div>
    <div className="flex animate-pulse flex-col gap-5 rounded-b-lg bg-white p-4">
      <div className="flex flex-col gap-1">
        <div className="h-3 w-[115px] rounded bg-dust"></div>
        <div className="h-3 w-6 rounded bg-dust"></div>
        <div className="h-3 w-full rounded bg-dust-light"></div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="h-3 w-[115px] rounded bg-dust"></div>
        <div className="flex w-full justify-between">
          <div className="h-3 w-10 rounded bg-dust-light"></div>
          <div className="h-3 w-14 rounded bg-dust"></div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="h-3 w-[130px] rounded bg-dust"></div>
        <div className="h-3 w-14 rounded bg-dust-light"></div>
      </div>
    </div>
  </div>
);

export default WatchListSkeleton;
