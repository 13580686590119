import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useAnalytics } from "use-analytics";

import Identifier from "components/atoms/Identifier";
import NavItem from "components/NavItem";
import Button from "components/Button";
import ContactDetails from "./ContactDetails";

import { getInitials } from "utils/string";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "features/entity/entityApi";
import {
  useGetWatchlistQuery,
  useUpdateWatchlistMutation,
} from "features/watchlist/watchlistApi";
import { IWatchListItem } from "features/watchlist/watchlist.interfaces";
import { changeBreadcrumb } from "app/breadcrumbSlice";
import PermissionGuard from "utils/permissions/PermissionGuard";
import { Permissions } from "features/profile";
import ErrorPage from "pages/ErrorPage";
import ContactSettingsModal from "pages/explore/modals/ContactSettingsModal";
import InformationTag from "components/atoms/InformationTag";
import { find, propEq, propOr } from "ramda";
import { IContactFlag } from "features/entity/entity.interfaces";
import { decamelizeKeys } from "humps";

const ContactProfileView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [updateContact] = useUpdateContactMutation();
  const { track } = useAnalytics();
  const { data: watchlist } = useGetWatchlistQuery();
  const {
    data: contact,
    error,
    isLoading,
  } = useGetContactQuery({
    id: params.id as string,
  });
  const [showDetails, setShowDetails] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const title = contact?.fullName || "";
  const firstName = contact?.fullName.split(" ")[0] || "";

  const [updateWatchlist] = useUpdateWatchlistMutation();

  useEffect(() => {
    document.title = `${title} | Explore | Relata`;
    dispatch(
      changeBreadcrumb({
        main: "Explore",
        subsection: "Contact profile",
        link: "/explore/",
      })
    );
  }, [dispatch, title]);

  const toggleOnWatchlist = async () => {
    if (onWatchlist) {
      await updateWatchlist(
        watchlist?.filter((item) => item.entityUuid !== params.id) || []
      );
      track(`Removed Contact to watchlist`, {
        category: "Watchlist",
        label: "Contact",
      });
    } else {
      await updateWatchlist([
        ...(watchlist || []),
        {
          entityType: "contact",
          entityUuid: params.id as string,
        } as IWatchListItem,
      ]);
      track(`Added Contact to watchlist`, {
        category: "Watchlist",
        label: "Contact",
      });
    }
  };

  const personalContactFlag =
    contact && find(propEq("flag", "personal_contact"), contact.flags);
  const isPersonalContact =
    !isLoading &&
    contact &&
    (propOr(false, "value", personalContactFlag) as boolean);
  const hasPersonalProvider = !isLoading && contact && !contact.company;

  const updateContactSettings = async (flags: Partial<IContactFlag>[]) => {
    await updateContact({ id: contact?.uuid, flags: decamelizeKeys(flags) });
    setShowSettings(false);
  };
  const onWatchlist =
    (watchlist?.filter((item) => item.entityUuid === params.id) || []).length >
    0;

  if (error && (error as any).status !== 404) {
    console.error(error);
  } else if (error && (error as any).status === 404) {
    return <ErrorPage type={404} />;
  }

  return (
    <div className="flex flex-col lg:flex-row">
      <div
        style={{
          background:
            "radial-gradient(100% 240px at 0% 64px, rgba(82, 115, 143, 0.15) 0%, rgba(248, 252, 255, 1) 100%)",
        }}
        className="flex flex-col border border-dust-dark bg-dust-light px-4 pt-16 lg:sticky lg:top-0 lg:h-screen lg:w-[350px]"
      >
        <div className="flex h-20 items-center text-xl font-bold text-navy">
          <div className="m-4 inline-block">
            <Identifier type="contact" size="large" text={getInitials(title)} />
          </div>
          <div className="inline-flex flex-col overflow-hidden text-ellipsis whitespace-nowrap pr-4">
            {title}
            {isPersonalContact && (
              <InformationTag
                label="Personal contact"
                color="dust"
                icon="icon-hidden"
              />
            )}
            {hasPersonalProvider && (
              <InformationTag
                label="Personal email provider"
                color="metal"
                icon="icon-email-symbol"
              />
            )}
          </div>
        </div>
        {!isPersonalContact && contact && (
          <div className="hidden flex-col gap-4 overflow-hidden lg:visible lg:flex">
            <div className="overflow-hidden rounded-lg border border-dust-dark bg-white">
              <ContactDetails contact={contact} />
            </div>
            <div className="px-4 text-sm text-metal">
              Relata automatically captures these details from {firstName}'s
              email signature.
              <br />
              We check for updates regularly.
            </div>
          </div>
        )}
        <div className="my-4 flex justify-between lg:mt-auto lg:justify-center">
          <div className="flex gap-1">
            <Button
              color="transparent"
              tooltip="Contact settings"
              icon="icon-settings text-xl"
              id="contact-settings"
              onClick={() => setShowSettings(!showSettings)}
            />
            {!isPersonalContact && (
              <>
                <Button
                  color="transparent"
                  tooltip="Remove contact"
                  icon="icon-delete text-xl"
                  id="remove-contact"
                />
                <PermissionGuard permission={Permissions.USE_WATCHLIST}>
                  <Button
                    color="transparent"
                    tooltip={
                      onWatchlist ? "Remove from watchlist" : "Add to watchlist"
                    }
                    onClick={toggleOnWatchlist}
                    icon={classnames("text-xl", {
                      "icon-watchlist-remove": onWatchlist,
                      "icon-watchlist-add": !onWatchlist,
                    })}
                  />
                </PermissionGuard>
              </>
            )}
          </div>
          {!isPersonalContact && (
            <div className="visible lg:hidden">
              <Button
                text={
                  showDetails ? "Hide contact details" : "Show contact details"
                }
                id="contact-details-toggle-button"
                color="transparent"
                iconRight
                onClick={() => setShowDetails(!showDetails)}
                icon={showDetails ? "icon-up" : "icon-down"}
              />
            </div>
          )}
        </div>

        {contact && !isPersonalContact && (
          <div
            className={classnames(
              "mb-4 overflow-hidden rounded-lg border border-dust-dark bg-white lg:hidden",
              { visible: showDetails, hidden: !showDetails }
            )}
          >
            <ContactDetails contact={contact} />
          </div>
        )}
      </div>
      <div className="lg:w-[calc(100%-350px)] lg:pt-16">
        {!isPersonalContact ? (
          <>
            <div
              className={classnames("sticky top-[63px] z-30 h-12 gap-8 px-8", {
                "border-b border-dust-dark bg-white-96 shadow backdrop-blur":
                  true,
                "flex items-center sm:justify-center lg:justify-start": true,
                "overflow-x-auto whitespace-nowrap": true,
              })}
            >
              <NavItem
                text="At a glance"
                to={`/explore/contact/${params.id}/at-a-glance`}
              />
              <NavItem
                text="Relationship change"
                to={`/explore/contact/${params.id}/relationship-change`}
              />
              <NavItem
                text="Emails"
                to={`/explore/contact/${params.id}/emails`}
              />
              <NavItem
                text="Meetings"
                to={`/explore/contact/${params.id}/meetings`}
              />
            </div>
            <Outlet />
          </>
        ) : (
          <div className="flex h-full flex-col items-center gap-3 bg-gradient-to-b from-dust-light to-white pt-[20%]">
            <i className="icon-hidden text-2xl text-navy" />
            <div className="text-xl font-bold text-navy">
              {firstName} has been flagged as a personal contact
            </div>
            <div className="w-[300px] text-center text-metal">
              When a contact is flagged as personal, Relata will not display any
              associated relationship data.
            </div>
            <div className="text-center text-metal">
              Personal contacts are still discoverable via search.
            </div>
          </div>
        )}
        {contact && (
          <ContactSettingsModal
            visible={showSettings}
            contact={contact}
            onClose={() => setShowSettings(false)}
            onUpdate={updateContactSettings}
          />
        )}
      </div>
    </div>
  );
};

export default ContactProfileView;
