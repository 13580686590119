import { ReactNode } from "react";
import classNames from "classnames";

import EntityIdentifier from "components/EntityIdentifier";

const ContactReportCard = ({
  id,
  name,
  company,
  email,
  color,
  children,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  color: "dust" | "purple" | "red";
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "w-[397px] rounded border border-l-8 border-dust p-4",
        {
          "border-l-dust": color === "dust",
          "border-l-purple-lightest": color === "purple",
          "border-l-red-lightest": color === "red",
        }
      )}
    >
      <div className="flex flex-col divide-y divide-dust">
        <div className="flex h-12 items-center justify-between pb-4">
          <EntityIdentifier
            type="contact"
            id={id}
            title={name}
            description={company}
            meta={{ email }}
          />
        </div>
        <div className="pt-2">{children}</div>
      </div>
    </div>
  );
};
export default ContactReportCard;
