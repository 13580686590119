import {
  IInteraction,
  InteractionEventType,
} from "features/interaction/interaction.interface";
import {
  useGetInteractionCountQuery,
  useGetInteractionsQuery,
  useGetInteractionsStatsQuery,
} from "features/interaction/interactionApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InteractionFilters from "./InteractionFilters";
import InteractionTimeline from "./InteractionTimeline";
import InteractionError from "./InteractionError";
import { InteractionTimelineSkeleton } from "./InteractionSkeletons";
import InteractionSearch, {
  InteractionEmptySearch,
  SearchTargetType,
} from "./InteractionSearch";
import useDebounce from "utils/hooks/useDebounce";
import useLocalStorage from "utils/hooks/useLocalStorage";
import { useGetMailboxesQuery } from "features/message/messageApi";
import TrackOptionsModal from "../modals/TrackOptionsModal";
import { useTrackFilterOptions } from "utils/trackOptions";
import NewTrackRefetcher from "../NewTrackRefetcher";
import { head } from "ramda";
import {
  clearNewInteractions,
  fetchNewInteractions,
  selectNewInteractions,
} from "app/newInteractionsSlice";
import { useAppDispatch } from "app/store";

const InteractionTimelineView = () => {
  const newInteractions = useSelector(selectNewInteractions);
  const dispatch = useAppDispatch();

  const [cursor, setCursor] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState("");
  const [searchTarget, setSearchTarget] = useState<SearchTargetType>("default");
  const [interactionFilter, setInteractionFilter] =
    useState<InteractionEventType>();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [showOptions, setShowOptions] = useState(false);
  const [lastVisit, setLastVisit] = useLocalStorage(
    "last-interaction-timeline-visit",
    ""
  );
  const [showNewInteractionsLine, setShowNewInteractionsLine] = useState(true);
  const [trackOptions, setTrackOptions] = useTrackFilterOptions();

  const { data: newInteractionsSinceLastVisit = { count: 0 } } =
    useGetInteractionCountQuery({
      interactionsSinceTimestamp: lastVisit,
      ...trackOptions,
    });

  const { data: mailboxes } = useGetMailboxesQuery();
  const queryOptions = {
    search: debouncedSearchValue.length > 1 ? debouncedSearchValue : "",
    searchTarget,
    trackOptions,
  };
  const {
    data: interactionsResponse,
    isLoading: isInteractionsLoading,
    isFetching: isInteractionsFetching,
    isError: isInteractionsError,
    refetch: refetchInteractions,
  } = useGetInteractionsQuery({
    ...queryOptions,
    cursor,
    eventType: interactionFilter,
  });

  const isFetching =
    isInteractionsFetching || debouncedSearchValue !== searchValue;

  const {
    data: interactionStats,
    isLoading: isStatsLoading,
    isError: isStatsError,
    refetch: refetchInteractionStats,
  } = useGetInteractionsStatsQuery({
    ...queryOptions,
  });

  const onChangeOptions = (value: any) => {
    setTrackOptions(value);
    setCursor(undefined);
  };

  const fetchMore = () => {
    if (interactionsResponse?.next) {
      setCursor(interactionsResponse.next);
    }
  };

  const localSearch = (interaction: IInteraction) => {
    if (
      interactionFilter &&
      ((interaction.eventType !== interactionFilter &&
        interactionFilter !== "revisit") ||
        (interactionFilter === "revisit" && !interaction.isRevisit))
    )
      return false;
    if (!searchValue) return true;
    switch (searchTarget) {
      case "default":
        return (
          interaction.messageSubject
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          interaction.contact.fullName
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      case "fullName":
        return interaction.contact.fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "messageSubject":
        return interaction.messageSubject
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      default:
        return true;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLastVisit(new Date().toISOString());
      setShowNewInteractionsLine(false);
    }, 6000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !newInteractions.next &&
        !isFetching &&
        !!interactionsResponse &&
        interactionsResponse?.results.length > 0
      ) {
        const latest = (
          head([
            ...newInteractions.results,
            ...interactionsResponse.results,
          ]) as IInteraction
        ).actionCreationTimestamp;
        const params = {
          happenedAfter: latest,
          ...queryOptions,
          eventType: interactionFilter,
        };
        dispatch(fetchNewInteractions(params));
      }
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    // if first page
    if (!!interactionsResponse && !interactionsResponse.prev) {
      dispatch(clearNewInteractions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionsResponse]);

  const updateInteractionTimeline = () => {
    dispatch(clearNewInteractions());
    refetchInteractionStats();
    if (!!cursor) {
      setCursor(undefined);
    } else {
      refetchInteractions();
    }
  };

  const isFiltered = !!searchValue || !!interactionFilter;
  if (isInteractionsError || isStatsError) return <InteractionError />;
  if (isInteractionsLoading || isStatsLoading)
    return <InteractionTimelineSkeleton />;
  return (
    <div className="relative h-full">
      <div className="absolute top-0 -z-10 h-60 w-screen bg-gradient-to-b from-dust-light to-white" />
      <div className="flex h-full flex-col">
        <div className="mx-auto flex w-full max-w-5xl flex-col px-4 pt-4">
          <InteractionSearch
            setShowOptions={() => setShowOptions(true)}
            searchValue={searchValue}
            setSearchTarget={(value) => {
              setSearchTarget(value);
              setCursor(undefined);
            }}
            setSearchValue={(value) => {
              setSearchValue(value);
              setCursor(undefined);
            }}
          />
          <InteractionFilters
            filteredStats={
              !!searchValue ? interactionStats?.searched : undefined
            }
            stats={interactionStats?.total}
            active={interactionFilter}
            onChange={(value) => {
              setInteractionFilter(value);
              setCursor(undefined);
            }}
          />
        </div>
        {!!newInteractions?.next && (
          <NewTrackRefetcher
            type="interactions"
            onClick={updateInteractionTimeline}
          />
        )}
        {!!searchValue &&
        !isFetching &&
        (interactionsResponse?.results.filter(localSearch) || []).length ===
          0 ? (
          <InteractionEmptySearch />
        ) : (
          <InteractionTimeline
            hasNoMore={!interactionsResponse?.next && !isFetching}
            isFetching={isFetching}
            newInteractions={
              showNewInteractionsLine &&
              !isFiltered &&
              newInteractionsSinceLastVisit.count > 0
                ? interactionsResponse?.results.slice(
                    0,
                    newInteractionsSinceLastVisit.count
                  )
                : undefined
            }
            searchValue={searchValue}
            searchTarget={searchTarget}
            interactions={[
              ...(newInteractions?.results.filter(localSearch) || []),
              ...(interactionsResponse?.results.filter(localSearch) || []),
            ].slice(
              showNewInteractionsLine ? newInteractionsSinceLastVisit.count : 0
            )}
            fetchMore={fetchMore}
          />
        )}
        {mailboxes && (
          <TrackOptionsModal
            mailboxes={mailboxes}
            visible={showOptions}
            onChange={onChangeOptions}
            onClose={() => setShowOptions(false)}
          />
        )}
      </div>
    </div>
  );
};

export default InteractionTimelineView;
