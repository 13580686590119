import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";

export interface ILensGroup {
  id: string;
  name: string;
  otherLabel: string;
  otherSelectedInUi: boolean;
  lenses: ILens[];
}

interface ILens {
  id: string;
  name: string;
  selectedInUi: boolean;
}

export const lensApi = createApi({
  reducerPath: "lens",
  baseQuery: prepareBaseQuery({
    baseUrl: "/pbi/lens-group",
  }),
  endpoints: (builder) => ({
    getLenses: builder.query<ILensGroup[], void>({
      query: () => "",
      transformResponse: (response: any): ILensGroup[] => {
        const lensGroups = camelizeKeys(response).results as ILensGroup[];
        return lensGroups;
      },
    }),
  }),
});

export const { useGetLensesQuery } = lensApi;
