import classNames from "classnames";
import InteractionTag from "components/atoms/InteractionTag";
import { format, isToday, isYesterday } from "date-fns";

import { EngagementType } from "features/entity/entity.interfaces";
import { ascend, descend, prop, sortWith } from "ramda";
import { highlightText } from "utils/highlightText";

const formatDate = (date: Date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  }
  return format(date, "eee do MMM");
};

type Participant = {
  "fact_activity.engagement_type": EngagementType;
  "fact_activity.uuid": string;
  "dim_contact.full_name"?: string;
  "dim_user.full_name"?: string;
};

const sortByEngagementAndName = sortWith([
  descend<Participant>(prop("fact_activity.engagement_type")),
  ascend<Participant>(
    (part) => part["dim_contact.full_name"] || part["dim_user.full_name"] || ""
  ),
]);

const engagementColor = (engagement: EngagementType) => {
  switch (engagement) {
    case EngagementType.HighlyEngaged:
      return "green";
    case EngagementType.Engaged:
      return "yellow";
    default:
      return "dust";
  }
};

const descriptionByType = {
  "email-received": "Received from",
  "email-sent": "Sent by",
  calendar: "Organised by",
};

export const ActivityItemSkeleton = ({ simple }: { simple?: boolean }) => (
  <div className="flex w-full animate-pulse flex-row justify-between p-4">
    <div className="flex flex-row gap-2">
      <div className="h-8 w-8 rounded bg-dust"></div>
      <div className="flex flex-col justify-center gap-1">
        <div className="h-4 w-40 rounded bg-dust-dark"></div>
        {!simple && (
          <>
            <div className="h-4 w-36 rounded bg-dust"></div>
            <div className="flex flex-row gap-2">
              <div className="h-4 w-[75px] rounded bg-dust-dark"></div>
              <div className="h-4 w-[91px] rounded bg-dust"></div>
              <div className="h-4 w-[87px] rounded bg-dust-dark"></div>
              <div className="h-4 w-[101px] rounded bg-dust"></div>
            </div>
          </>
        )}
      </div>
    </div>
    <div className="h-4 w-24 rounded-full bg-dust"></div>
  </div>
);

const ActivityItem = ({
  type,
  subject,
  date,
  user,
  description,
  participants,
  searchValue = "",
}: {
  type: "email-received" | "email-sent" | "calendar";
  subject: string;
  date: Date;
  user?: string;
  description?: string;
  participants?: Participant[];
  searchValue?: string;
}) => (
  <div className="group relative flex w-full flex-row gap-4 border-b border-b-dust-dark bg-white p-4 outline-none">
    <div
      className={classNames(
        "flex h-8 w-8 items-center justify-center rounded-md text-lg",
        {
          "bg-dust": type === "email-sent",
          "bg-dust-dark": type === "email-received",
          "bg-metal": type === "calendar",
        }
      )}
    >
      <i
        className={classNames({
          "icon-email-out text-metal": type === "email-sent",
          "icon-email-in text-metal": type === "email-received",
          "icon-calendar text-white": type === "calendar",
        })}
      ></i>
    </div>
    <div className="flex flex-1 flex-col justify-center gap-1 overflow-hidden">
      <div className="overflow-hidden text-ellipsis whitespace-nowrap text-left text-lg font-bold text-navy">
        {highlightText(subject, searchValue)}
      </div>
      {user && (
        <div className="text-metal">
          {descriptionByType[type]} {user}
        </div>
      )}
      <div className="flex flex-row gap-2">
        {description !== undefined && participants === undefined && (
          <div className="font-bold text-metal">{description}</div>
        )}
        {participants !== undefined && participants.length === 0 && (
          <div className="h-4 w-24 animate-pulse rounded-sm bg-dust" />
        )}
        {sortByEngagementAndName(participants || []).map(
          (part: any, i: number) => (
            <InteractionTag
              key={i}
              color={engagementColor(part["fact_activity.engagement_type"])}
              label={
                part["dim_contact.full_name"] || part["dim_user.full_name"]
              }
            />
          )
        )}
      </div>
    </div>
    <div className="absolute right-0 top-0 h-full w-40 bg-gradient-to-r from-transparent via-white to-white md:w-60"></div>
    <div className="absolute right-4 flex flex-row gap-8 text-metal">
      <div className="flex flex-col items-end gap-1 md:flex-row">
        <span>{formatDate(date)},</span>
        <span>{format(date, "HH:mm")}</span>
      </div>
    </div>
  </div>
);

export default ActivityItem;
