import { Filter } from "@cubejs-client/core";
import { IRelationshipSignal } from ".";
import { IUser } from "features/profile";
import {
  ICompanyDetail,
  IContactDetail,
} from "features/entity/entity.interfaces";
import useRelationshipSignalQuery from "./useRelationshipSignalQuery";

export type RelationshipSignalType =
  | "relationship-score"
  | "personal-relationship-score"
  | "interactive-colleagues"
  | "unsolicited-inbound"
  | "engagement-score"
  | "teams-engagement-score"
  | "response-time"
  | "link-clicks"
  | "revisits"
  | "active-contacts"
  | "active-contacts-change"
  | "new-contacts";

const useRelationshipSignals = ({
  company,
  contact,
  additionalFilters,
  user,
}: {
  company?: ICompanyDetail;
  contact?: IContactDetail;
  additionalFilters: Filter[];
  user?: IUser;
}) => {
  const entityFilters = (): Filter[] => {
    if (company) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [company.uuid],
        },
      ];
    } else if (contact) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contact.uuid],
        },
      ];
    }
    return [];
  };

  const { result: relationshipScore, isLoading: isRelationshipScoreLoading } =
    useRelationshipSignalQuery({
      type: "relationship-score",
      query: {
        measures: [
          "fact_table.relationship_score_current",
          "fact_table.relationship_score_prev",
        ],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const {
    result: personalRelationshipScore,
    isLoading: isPersonalRelationshipScoreLoading,
  } = useRelationshipSignalQuery({
    type: "personal-relationship-score",
    query: {
      measures: [
        "fact_table.relationship_score_current",
        "fact_table.relationship_score_prev",
      ],
      filters: [
        ...entityFilters(),
        ...additionalFilters,
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user?.email || ""],
        },
      ],
    },
    contact,
    company,
  });
  const {
    result: interactiveColleagues,
    isLoading: isInteractiveColleaguesLoading,
  } = useRelationshipSignalQuery({
    type: "interactive-colleagues",
    query: {
      measures: ["fact_relationship_score.colleague_count"],
      filters: [...entityFilters(), ...additionalFilters],
    },
    contact,
    company,
  });
  const { result: unsolicitedInbound, isLoading: isUnsolicitedInboundLoading } =
    useRelationshipSignalQuery({
      type: "unsolicited-inbound",
      query: {
        measures: ["fact_table.unsolicited_inbound_current"],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const { result: engagementScore, isLoading: isEngagementScoreLoading } =
    useRelationshipSignalQuery({
      type: "engagement-score",
      query: {
        measures: [
          "fact_table.all_interactions_current",
          "fact_table.all_interactions_prev",
        ],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const {
    result: teamsEngagementScore,
    isLoading: isTeamsEngagementScoreLoading,
  } = useRelationshipSignalQuery({
    type: "teams-engagement-score",
    query: {
      measures: [
        "fact_table.all_interactions_current",
        "fact_table.all_interactions_prev",
      ],
      dimensions: ["dim_team.name", "dim_team.uuid"],
      filters: [
        ...entityFilters(),
        ...additionalFilters,
        {
          member: "dim_team.uuid",
          operator: "notEquals",
          values: ["-1"],
        },
      ],
    },
    contact,
    company,
  });

  const { result: responseTime, isLoading: isResponseTimeLoading } =
    useRelationshipSignalQuery({
      type: "response-time",
      query: {
        measures: [
          "fact_table.reply_response_time_current",
          "fact_table.reply_response_time_prev",
        ],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const { result: linkClicks, isLoading: isLinkClicksLoading } =
    useRelationshipSignalQuery({
      type: "link-clicks",
      query: {
        measures: [
          "fact_table.link_clicks_current",
          "fact_table.link_clicks_prev",
        ],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const { result: revisits, isLoading: isRevisitsLoading } =
    useRelationshipSignalQuery({
      type: "revisits",
      query: {
        measures: ["fact_table.revisits_current", "fact_table.revisits_prev"],
        filters: [...entityFilters(), ...additionalFilters],
      },
      contact,
      company,
    });
  const {
    result: activeContacts,
    rawResult: activeContactsCurrent,
    isLoading: isActiveContactsLoading,
  } = useRelationshipSignalQuery({
    type: "active-contacts",
    query: {
      dimensions: ["dim_contact.activity_status"],
      measures: ["dim_contact.count"],
      filters: [...entityFilters(), ...additionalFilters],
    },
    company,
  });
  const { result: activeContactsPrev, isLoading: isActiveContactsPrevLoading } =
    useRelationshipSignalQuery({
      type: "active-contacts-change",
      query: {
        dimensions: ["dim_contact.activity_prev_status"],
        measures: ["dim_contact.count"],
        filters: [...entityFilters(), ...additionalFilters],
      },
      company,
      moreResults: activeContactsCurrent,
    });
  const { result: newContacts, isLoading: isNewContactsLoading } =
    useRelationshipSignalQuery({
      type: "new-contacts",
      query: {
        measures: ["dim_contact.count"],
        filters: [
          ...entityFilters(),
          ...additionalFilters,
          {
            member: "fact_table.segment",
            operator: "equals",
            values: ["0"],
          },
        ],
      },
      company,
    });

  const signals: IRelationshipSignal[] = [
    ...(relationshipScore ? relationshipScore : []),
    ...(personalRelationshipScore ? personalRelationshipScore : []),
    ...(interactiveColleagues ? interactiveColleagues : []),
    ...(unsolicitedInbound ? unsolicitedInbound : []),
    ...(engagementScore ? engagementScore : []),
    ...(teamsEngagementScore ? teamsEngagementScore : []),
    ...(responseTime ? responseTime : []),
    ...(linkClicks ? linkClicks : []),
    ...(revisits ? revisits : []),
    ...(activeContacts ? activeContacts : []),
    ...(activeContactsPrev ? activeContactsPrev : []),
    ...(newContacts ? newContacts : []),
  ];

  return {
    results: signals,
    isLoading:
      isRelationshipScoreLoading ||
      isPersonalRelationshipScoreLoading ||
      isInteractiveColleaguesLoading ||
      isUnsolicitedInboundLoading ||
      isEngagementScoreLoading ||
      isResponseTimeLoading ||
      isLinkClicksLoading ||
      isRevisitsLoading ||
      isActiveContactsLoading ||
      isActiveContactsPrevLoading ||
      isNewContactsLoading ||
      isTeamsEngagementScoreLoading,
  };
};
export default useRelationshipSignals;
