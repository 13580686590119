import { useNavigate } from "react-router-dom";

import LiveReportPreview from "../components/LiveReportPreview";
import LiveReportPreviewBadge from "../components/LiveReportPreviewBadge";
import LiveReportPreviewSkeleton from "../components/LiveReportPreviewSkeleton";

import ContactReportCard from "../components/ContactReportCard";
import useUnresponsiveContacts from "pages/report/hooks/useUnresponsiveContacts";

const UnresponsiveContact = ({
  id,
  name,
  company,
  email,
  emails,
}: {
  id: string;
  name: string;
  company: string;
  email: string;
  emails: number;
}) => (
  <ContactReportCard
    id={id}
    name={name}
    company={company}
    email={email}
    color="red"
  >
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 text-metal">
        <div className="w-80">
          {name.split(" ")[0]} has{" "}
          <span className="font-bold">
            not interacted with any of your emails
          </span>{" "}
          in the previous 90 days.
        </div>
        <div>
          You have{" "}
          <span className="font-bold">
            sent {emails} {emails > 1 ? "emails" : "email"}
          </span>
          .
        </div>
      </div>
    </div>
  </ContactReportCard>
);

const UnresponsiveContactsPreview = () => {
  const navigate = useNavigate();
  const openReport = () => navigate("/report/unresponsive-contacts");
  const { resultSet, isLoading } = useUnresponsiveContacts();

  if (isLoading) {
    return <LiveReportPreviewSkeleton />;
  }
  if (resultSet.length === 0) {
    return <></>;
  }

  return (
    <LiveReportPreview
      color="red"
      icon="icon-face-dizzy"
      title="Unresponsive contacts"
      description="Contacts who are not interacting with your recent emails "
      openTooltip="Explore unresponsive contacts"
      openReport={openReport}
    >
      {resultSet.length > 0 && (
        <div className="pt-4">
          <div className="flex h-[176px] gap-2 overflow-scroll">
            <LiveReportPreviewBadge
              color="red"
              description={{
                singular: "unresponsive contact",
                plural: "unresponsive contacts",
              }}
              value={resultSet.length}
              openReport={openReport}
            />
            <div className="flex gap-4">
              {resultSet.slice(0, 2).map((contact) => (
                <UnresponsiveContact
                  key={contact["dim_contact.uuid"] as string}
                  id={contact["dim_contact.uuid"] as string}
                  name={contact["dim_contact.full_name"] as string}
                  company={contact["dim_company.display_name"] as string}
                  email={contact["dim_contact.email"] as string}
                  emails={contact["fact_table.outbound_current"]}
                />
              ))}
              <div className="visible sticky right-0 top-0 -ml-20 w-16 bg-gradient-to-r from-transparent to-white lg:invisible" />
            </div>
          </div>
        </div>
      )}
    </LiveReportPreview>
  );
};

export default UnresponsiveContactsPreview;
