import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";
import { IUser } from "features/profile/user.interface";
import { prop, propOr } from "ramda";

const useEntityActivity = ({
  contactId,
  companyId,
  user,
  additionalFilters,
}: {
  contactId?: string;
  companyId?: string;
  user?: IUser;
  additionalFilters: Filter[];
}) => {
  const entityFilters = (): Filter[] => {
    if (companyId) {
      return [
        {
          member: "dim_company.uuid",
          operator: "equals",
          values: [companyId],
        },
      ];
    } else if (contactId) {
      return [
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [contactId],
        },
      ];
    }
    return [];
  };

  /* Global data */
  const {
    isLoading: isGlobalLoading,
    interaction: globalInteraction,
    meeting: globalMeeting,
  } = useLastActivityData([
    ...entityFilters(),
    ...additionalFilters,
    {
      member: "dim_user.email",
      operator: "notEquals",
      values: [user ? (user.email as string) : ""],
    },
  ]);

  /* Viewers data */
  const { isLoading: isViewerLoading, meeting: viewerMeeting } =
    useLastActivityData([
      ...entityFilters(),
      ...additionalFilters,
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ]);

  const { date: lastInbound, isLoading: isInboundLoading } =
    useViewersLastEmail({
      user,
      inbound: true,
      filters: [...entityFilters(), ...additionalFilters],
    });

  const { date: lastOutbound, isLoading: isOutboundLoading } =
    useViewersLastEmail({
      user,
      inbound: false,
      filters: [...entityFilters(), ...additionalFilters],
    });

  return {
    viewer: {
      lastInbound,
      lastOutbound,
      lastMeeting: prop("date", viewerMeeting),
    },
    globalInteraction,
    globalMeeting,
    isLoading:
      isGlobalLoading ||
      isOutboundLoading ||
      isInboundLoading ||
      isViewerLoading,
  };
};

const useViewersLastEmail = ({
  user,
  filters,
  inbound,
}: {
  user?: IUser;
  filters: Filter[];
  inbound: boolean;
}) => {
  const { resultSet, isLoading } = useCubeQuery({
    dimensions: ["fact_email.last_interaction_date_time"],
    order: [["fact_email.last_interaction_date_time", "desc"]],
    filters: [
      ...filters,
      {
        member: "fact_email.is_inbound",
        operator: "equals",
        values: [inbound ? "true" : "false"],
      },
      {
        member: "dim_user.email",
        operator: "equals",
        values: [user ? (user.email as string) : ""],
      },
    ],
    limit: 1,
  });

  const result = resultSet?.tablePivot() || [];
  const row = result.length ? result[0] : {};

  const date = prop("fact_email.last_interaction_date_time", row);

  return { date, isLoading };
};

const useLastActivityData = (filters: Filter[]) => {
  const { resultSet, isLoading } = useCubeQuery({
    measures: [
      "fact_table.last_interaction_details",
      "fact_table.last_meeting_details",
    ],
    filters,
  });

  const result = resultSet?.tablePivot() || [];
  const row = result.length ? result[0] : {};

  const interaction = JSON.parse(
    propOr("{}", "fact_table.last_interaction_details", row)
  );
  const meeting = JSON.parse(
    propOr("{}", "fact_table.last_meeting_details", row)
  );

  return { isLoading, interaction, meeting };
};

export default useEntityActivity;
