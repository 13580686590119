import classNames from "classnames";
import { Link } from "react-router-dom";

import Identifier, { IdentifierType } from "components/atoms/Identifier";
import { getInitials } from "utils/string";
import ContactIdentifier from "components/ContactIdentifier";
import { propOr } from "ramda";
import { IPhoneNumber } from "features/entity/entity.interfaces";
import { highlightText } from "utils/highlightText";

const EntityIdentifier = ({
  type,
  title,
  description,
  color,
  id,
  meta,
  searchValue,
  logoUrl,
}: {
  type: IdentifierType;
  title: string;
  description?: string;
  color?: string;
  id?: string;
  meta?: {
    email?: string;
    phoneNumbers?: IPhoneNumber[];
    onlyIdentifier?: boolean;
  };
  searchValue?: string;
  logoUrl?: string;
}) => {
  const onlyIdentifier = propOr(false, "onlyIdentifier", meta);
  if (onlyIdentifier) {
    return (
      <Link to={`/explore/${type}/${id}/at-a-glance`}>
        <Identifier
          type={type}
          text={getInitials(title)}
          logoUrl={logoUrl}
          color={color}
        />
      </Link>
    );
  }

  if (type === "contact") {
    return (
      <ContactIdentifier
        id={id}
        meta={meta}
        name={title}
        description={description}
        searchValue={searchValue}
      />
    );
  }
  const highlightedTitle = highlightText(title, searchValue);
  const highlightedDescription = highlightText(description, searchValue);

  return (
    <div className="relative">
      <div
        className={classNames(
          "group flex items-center gap-2 border border-transparent text-lg text-navy",
          {
            "overflow-hidden hover:absolute hover:-top-[17px] hover:z-10 hover:rounded-md hover:border-dust-dark hover:bg-white hover:shadow":
              type === "company",
          }
        )}
      >
        <Identifier
          type={type}
          text={getInitials(title)}
          color={color}
          logoUrl={logoUrl}
        />
        <div
          className={classNames(
            "w-[180px] overflow-hidden text-ellipsis whitespace-nowrap pr-2",
            { "group-hover:w-fit": type === "company" }
          )}
        >
          <div className="flex flex-col">
            <div className="max-w-[60vw] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold">
              {!!id && type === "company" ? (
                <Link to={`/explore/${type}/${id}/at-a-glance`}>
                  {highlightedTitle}
                </Link>
              ) : (
                highlightedTitle
              )}
            </div>
            <div className="text-sm text-metal">{highlightedDescription}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityIdentifier;
