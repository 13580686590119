import { find, propEq } from "ramda";

import ContactItem from "components/ContactItem";

import { IUser } from "features/profile";
import { IEntityRankingItem } from "features/entity/useEntityRanking";
import { IContactDetail, TrendType } from "features/entity/entity.interfaces";

const SkeletonContactIntroItem = () => (
  <div className="flex h-16 items-center gap-2 rounded bg-white p-4">
    <div className="h-4 w-4 animate-pulse bg-dust-dark"></div>
    <div className="mx-1 h-8 w-8 animate-pulse rounded-full bg-dust"></div>
    <div className="h-3 w-[150px] animate-pulse rounded bg-dust-dark"></div>
    <div className="ml-auto mr-2 h-4 w-4 animate-pulse bg-dust-dark"></div>
  </div>
);

const ContactIntroBox = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => (
  <div className="flex h-full flex-col items-center rounded-lg bg-metal bg-metal-banana bg-cover bg-center bg-no-repeat py-8 px-6">
    <div className="mb-1 text-lg text-white">{title}</div>
    {children}
  </div>
);

const ContactIntro = ({
  user,
  contact,
  isLoading,
  colleagues,
}: {
  user?: IUser;
  contact?: IContactDetail;
  isLoading: boolean;
  colleagues?: IEntityRankingItem[];
}) => {
  const userRelationship = find(
    propEq("email", user ? user.email : ""),
    colleagues || []
  ) as IEntityRankingItem;

  if (isLoading) {
    // loading state
    return (
      <ContactIntroBox title="Strongest relationships">
        <div className="mt-6 flex w-full flex-col gap-4">
          {[1, 2, 3].map((i) => (
            <SkeletonContactIntroItem key={i} />
          ))}
        </div>
      </ContactIntroBox>
    );
  } else if (!colleagues || colleagues.length === 0) {
    // state if there are no colleagues interacting with this contact
    return (
      <ContactIntroBox title="Get an introduction">
        <div className="mb-6 text-dust">
          You don't have colleagues who can introduce you to {contact?.fullName}
        </div>
        <div className="flex w-full flex-col gap-4 rounded-md bg-white">
          <div className="mt-8 mb-8 text-center">
            <i className="icon-warning-hex text-3xl text-navy"></i>
            <div className="mt-2 text-xl font-bold text-navy">
              No relationships found
            </div>
          </div>
        </div>
      </ContactIntroBox>
    );
  } else if (
    !userRelationship ||
    (userRelationship && !userRelationship.position)
  ) {
    // vewer has no relationship score
    return (
      <ContactIntroBox title="Get an introduction">
        <div className="mb-6 text-dust">
          You have colleagues who can introduce you to {contact?.fullName}
        </div>
        <div className="flex w-full flex-col gap-4">
          {colleagues.slice(0, 3).map((contact: IEntityRankingItem) => (
            <ContactItem
              id={contact.id}
              key={contact.id}
              type="colleague"
              fullName={contact.fullName}
              position={contact.position}
              email={contact.email}
            />
          ))}
        </div>
      </ContactIntroBox>
    );
  } else if (userRelationship.position === 1) {
    // viewer is the strongest relationship
    return (
      <div className="flex h-full flex-col gap-6 rounded-lg bg-sky bg-metal-banana bg-cover bg-center bg-no-repeat py-8 px-6">
        <div className="text-2xl text-white">
          You have the strongest relationship with {contact?.fullName}
        </div>
        <div className="flex flex-1 gap-1 text-dust">
          <span>And it has been</span>
          <span className="text-mint">
            {TrendType[userRelationship.trend].toLocaleLowerCase()}
          </span>
          <span>over time.</span>
        </div>
        <div className="flex w-full flex-col gap-4">
          {colleagues.slice(1, 2).map((contact: IEntityRankingItem) => (
            <ContactItem
              id={contact.id}
              key={contact.id}
              type="colleague"
              fullName={contact.fullName}
              position={contact.position}
              email={contact.email}
            />
          ))}
        </div>
      </div>
    );
  } else if (userRelationship.position <= 3) {
    // viewer is second or third strongest relationship
    return (
      <ContactIntroBox title={`${contact?.fullName}'s strongest relationships`}>
        <div className="mb-6 text-dust">
          See where you stack up against your colleagues
        </div>
        <div className="flex w-full flex-col gap-4">
          {colleagues.slice(0, 3).map((contact: IEntityRankingItem) => (
            <ContactItem
              id={contact.id}
              key={contact.id}
              type="colleague"
              fullName={contact.fullName}
              position={contact.position}
              email={contact.email}
            />
          ))}
        </div>
      </ContactIntroBox>
    );
  } else {
    // viewer is far behind i.e. rank > 3
    return (
      <ContactIntroBox title={`${contact?.fullName}'s strongest relationships`}>
        <div className="mb-6 text-dust">
          See where you stack up against your colleagues
        </div>
        <div className="flex w-full flex-col gap-4">
          {colleagues.slice(0, 2).map((contact: IEntityRankingItem) => (
            <ContactItem
              id={contact.id}
              key={contact.id}
              type="colleague"
              fullName={contact.fullName}
              position={contact.position}
              email={contact.email}
            />
          ))}
          <ContactItem
            id={userRelationship.id}
            type="colleague"
            fullName={userRelationship.fullName}
            position={userRelationship.position}
          />
        </div>
      </ContactIntroBox>
    );
  }
};

export default ContactIntro;
