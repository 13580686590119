import {
  CheckboxSection,
  RangeSection,
  SelectSection,
} from "pages/explore/modals/FiltersModal/FiltersModalSections";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";
import toggleListItem from "utils/toggleListItem";
import { propOr } from "ramda";

const CompanyFiltersTab = ({
  activity,
  rank,
  trend,
  segment,
  flags,
  relationshipScore,
  colleaguesInteracting,
  contacts,
  onUpdate,
}: {
  activity: ActivityType[];
  rank: RankType[];
  trend: TrendType[];
  segment: SegmentType[];
  flags: { showServiceProviders: boolean };
  relationshipScore: { min: number; max: number };
  colleaguesInteracting: { value: number; operator: string };
  contacts: { value: number; operator: string };
  onUpdate: (data: any) => void;
}) => (
  <>
    <div className="mb-4 text-metal">
      These filters will hide companies that do not meet the criteria, and also
      hide the contacts who belong to those companies.
    </div>
    <div className="flex h-full flex-row flex-wrap lg:h-[545px] lg:flex-col">
      <CheckboxSection
        title="Company activity"
        onUpdate={(key: string) =>
          onUpdate({
            activity: toggleListItem(key, activity),
          })
        }
        options={[
          {
            label: "Show active companies",
            checked: activity.includes(ActivityType.Active),
            key: ActivityType.Active,
          },
          {
            label: "Show inactive companies",
            checked: activity.includes(ActivityType.Inactive),
            key: ActivityType.Inactive,
          },
          {
            label: "Show dormant companies",
            checked: activity.includes(ActivityType.Dormant),
            key: ActivityType.Dormant,
          },
        ]}
      />
      <CheckboxSection
        title="Company rank"
        onUpdate={(key: string) =>
          onUpdate({
            rank: toggleListItem(key, rank),
          })
        }
        options={Object.values(RankType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${RankType[key]} companies`,
            checked: rank.includes(key),
            key,
          }))}
      />
      <CheckboxSection
        title="Company trend"
        onUpdate={(key: string) =>
          onUpdate({
            trend: toggleListItem(key, trend),
          })
        }
        options={Object.values(TrendType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${TrendType[key]} companies`,
            checked: trend.includes(key),
            key,
          }))}
      />
      <RangeSection
        title="Relationship score"
        {...relationshipScore}
        onUpdate={(min, max) =>
          onUpdate({
            relationshipScore: { min, max },
          })
        }
      />
      <CheckboxSection
        title="Company segment"
        onUpdate={(key: string) =>
          onUpdate({
            segment: toggleListItem(key, segment),
          })
        }
        options={Object.values(SegmentType)
          .filter((v) => !isNaN(Number(v)))
          .map((key: any) => ({
            label: `Show ${SegmentType[key]} companies`,
            checked: segment.includes(key),
            key,
          }))}
      />
      <SelectSection
        title="# Colleagues interacting"
        {...colleaguesInteracting}
        onUpdate={({ value, operator }) =>
          onUpdate({
            colleaguesInteracting: { value, operator },
          })
        }
      />
      <SelectSection
        title="Contacts"
        {...contacts}
        onUpdate={({ value, operator }) =>
          onUpdate({
            contacts: { value, operator },
          })
        }
      />
      <CheckboxSection
        title="Company flags"
        onUpdate={(key: string) =>
          onUpdate({
            flags: { ...flags, [key]: !propOr(false, key, flags) },
          })
        }
        options={[
          {
            label: "Show service providers",
            checked: flags.showServiceProviders,
            key: "showServiceProviders",
          },
        ]}
      />
    </div>
  </>
);

export default CompanyFiltersTab;
