import classnames from "classnames";
import { forwardRef, useState } from "react";

type TextInputSize = "small" | "large";
type TextInputType = "text" | "password" | "email";

const TextInput = forwardRef(
  (
    {
      placeholder,
      icon,
      size = "small",
      disabled,
      onChangeText,
      type = "text",
      error,
      value,
      maxLength,
      clearable,
      id,
    }: {
      placeholder?: string;
      icon?: string;
      size?: TextInputSize;
      disabled?: boolean;
      onChangeText?: (value: string) => void;
      type?: TextInputType;
      error?: string;
      value?: string;
      maxLength?: number;
      clearable?: boolean;
      id?: string;
    },
    ref: any
  ) => {
    const [internalValue, setInternalValue] = useState(value);
    const hasError = !!error;

    const handleChange = (event: any) => {
      updateValue(event.target.value);
    };
    const updateValue = (updated: string) => {
      setInternalValue(updated);
      if (onChangeText) onChangeText(updated);
    };

    const onIconClick = () => {
      if (clearable) {
        updateValue("");
      }
    };
    return (
      <div className="w-full">
        <div
          className={classnames(
            "group relative flex h-8 w-full min-w-[200px] items-center justify-center rounded bg-white",
            {
              "h-10 text-lg": size === "large",
            }
          )}
          ref={ref}
        >
          <input
            type={type}
            id={id}
            onChange={handleChange}
            className={classnames(
              "w-full rounded border-dust py-2 pl-4 pr-10 focus:border focus:border-mint focus:placeholder-transparent focus:ring-0",
              {
                "h-8 text-base": size === "small",
                "h-10 text-lg": size === "large",
                "text-metal placeholder-metal-light hover:border-dust-dark hover:text-metal-dark  hover:placeholder-metal focus:text-navy focus:placeholder-navy":
                  !disabled,
                "cursor-not-allowed text-dust-dark placeholder-dust-dark":
                  disabled,
                "border border-red-dark text-red-dark placeholder-red-dark hover:border-red-dark  hover:text-red-dark hover:placeholder-red-dark focus:border focus:border-red-dark focus:placeholder-red-dark":
                  hasError,
              }
            )}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
          />

          {(icon || clearable) && (
            <i
              className={classnames(
                "absolute right-4 text-metal-light group-hover:text-metal group-focus:text-navy",
                {
                  "icon-menu-open cursor-pointer":
                    clearable && internalValue?.length,
                  [icon as string]: !(clearable && internalValue?.length),
                }
              )}
              onClick={onIconClick}
            ></i>
          )}
        </div>
        {hasError && (
          <span className="ml-4 text-xs font-bold text-red-dark">{error}</span>
        )}
      </div>
    );
  }
);

export default TextInput;
