import { useEffect, useState } from "react";
import useTimeout from "utils/hooks/useTimeout";

const SlowQueryWarningModal = ({
  isLoading,
  timeout = 5000,
}: {
  isLoading: boolean;
  timeout?: number;
}) => {
  const [show, setShow] = useState(false);

  // reset the show state after loading is over
  useEffect(() => {
    if (!isLoading) {
      setShow(false);
    }
  }, [isLoading]);

  useTimeout(
    () => {
      if (isLoading) {
        setShow(true);
      }
    },
    timeout,
    [isLoading]
  );

  if (show && isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 z-20 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center gap-2 rounded border border-dust-dark bg-white p-4 text-center shadow">
        <div className="text-lg font-bold text-navy">
          This is taking longer than usual
        </div>
        <div className="w-[330px] text-metal">
          Complex filters can take several seconds to load depending on the size
          of the data
        </div>
      </div>
    );
  }
  return <></>;
};

export default SlowQueryWarningModal;
