import classNames from "classnames";
import { groupBy } from "ramda";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getZonedDate } from "utils/dates";

import ActivityEmailSegment from "components/ActivityEmailSegment";
import Button from "components/Button";
import DayLine from "components/DayLine";
import { IMessage } from "features/message/message.interface";
import useScrolling from "utils/hooks/useScrolling";
import { EmailSkeleton } from "./EmailSkeletons";

const EmailNoMore = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-16 flex flex-col items-center justify-center gap-2">
      <i className="icon-email text-2xl font-bold text-navy" />
      <div className="text-lg font-bold text-navy">No more emails</div>
      <div className="max-w-[309px] pb-4 text-center text-lg text-metal">
        <p>You've reached the end of your email history.</p>
        <p className="mt-4">
          For historic email data, you can search for a contact or company in
          Relata Explore.
        </p>
      </div>
      <Button
        text="Go to Explore"
        iconRight
        icon="icon-right"
        color="navy"
        onClick={() => navigate("/explore/all-contacts")}
      />
    </div>
  );
};

const EmailList = ({
  messages,
  searchValue,
  searchTarget,
  isFetching,
  fetchMore,
  hasNoMore,
}: {
  messages: IMessage[];
  isFetching: boolean;
  searchValue: string;
  searchTarget: string;
  fetchMore: () => void;
  hasNoMore?: boolean;
}) => {
  const navigate = useNavigate();
  const {
    ref: scrollingRef,
    scrollToTop,
    fromTop,
    sentryRef,
  } = useScrolling({ callback: fetchMore, isFetching });

  const byDay = groupBy((message: IMessage) =>
    format(new Date(message.sendTimestamp), "yyyy-MM-dd")
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const messagesByDay = byDay(messages);
  const days = Object.keys(messagesByDay);

  return (
    <div ref={scrollingRef} className="h-full overflow-scroll">
      <div className="mx-auto w-full max-w-5xl pb-64">
        <div className="fixed z-20 flex w-full max-w-5xl justify-end">
          <div className="flex flex-row p-2 pr-7">
            {fromTop > 200 && (
              <Button
                color="transparent"
                icon="icon-increase text-metal"
                text="Today"
                onClick={scrollToTop}
              />
            )}
          </div>
        </div>
        {days.map((day, i) => (
          <div
            key={i}
            className={classNames("mb-6 flex flex-col px-4", {
              "mb-0": days.length === i + 1,
            })}
          >
            <DayLine
              date={getZonedDate(new Date(`${day}T00:00:00`), timezone)}
            />
            <div data-testid="email-items" className="flex flex-col">
              {messagesByDay[day].map((message, i) => (
                <ActivityEmailSegment
                  onClick={() => navigate(`email/${message.messageId}`)}
                  totalEngaged={message?.stats.totalEngagedRecipients}
                  totalRecipients={message?.stats.totalRecipients}
                  searchValue={searchValue}
                  searchTarget={searchTarget}
                  key={i}
                  subject={message.messageSubject}
                  date={new Date(message.sendTimestamp)}
                  recipients={message.recipients}
                />
              ))}
            </div>
          </div>
        ))}
        {(isFetching || !hasNoMore) && (
          <div ref={sentryRef}>
            <EmailSkeleton />
          </div>
        )}
        {hasNoMore && <EmailNoMore />}
      </div>
    </div>
  );
};
export default EmailList;
