import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { differenceInMinutes, formatDistanceToNow } from "date-fns";
import { head, propOr } from "ramda";

import {
  selectCubeLastRefresh,
  changeCubeLastRefresh,
} from "app/cubeLastRefreshSlice";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const getLastRefreshTime = (resultSet: any) => {
  const loadResponse = propOr({}, "loadResponse", resultSet);
  const row = head(propOr([], "results", loadResponse)) as any;

  const lastRefreshTime = row
    ? new Date(row["lastRefreshTime"] as string)
    : undefined;
  return lastRefreshTime;
};

const CubeLastRefresh = () => {
  const dispatch = useDispatch();
  const cubejsApi = useCubejsApi();
  const lastRefreshTimeStored = useSelector(selectCubeLastRefresh);

  useEffect(() => {
    const fetchCubeLastRefresh = async () => {
      try {
        const resultSet = await cubejsApi.load({
          dimensions: ["dim_user.uuid"],
          limit: 1,
        });
        const lastRefreshTime = getLastRefreshTime(resultSet);
        if (lastRefreshTime)
          dispatch(changeCubeLastRefresh({ time: lastRefreshTime }));
      } catch (error: any) {
        console.error(error);
      }
    };

    if (
      !lastRefreshTimeStored.time ||
      (!!lastRefreshTimeStored.lastUpdate &&
        differenceInMinutes(new Date(), lastRefreshTimeStored.lastUpdate) > 9)
    ) {
      fetchCubeLastRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!lastRefreshTimeStored.time)
    return <div data-testid="cube-last-refresh-empty"></div>;
  return (
    <div
      data-testid="cube-last-refresh"
      className="hidden text-base text-metal sm:flex"
    >
      Last refreshed {formatDistanceToNow(lastRefreshTimeStored.time)} ago
    </div>
  );
};

export default CubeLastRefresh;
