import { ReactNode, useState } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { Filter } from "@cubejs-client/core";

import TextInput from "components/TextInput";
import EntityIdentifier from "components/EntityIdentifier";
import DataTag from "components/atoms/DataTag";
import useDebounce from "utils/hooks/useDebounce";
import Pagination from "components/table/Pagination";

const EntityFrame = ({
  title,
  isLoading,
  children,
}: {
  title: string;
  isLoading: boolean;
  children: ReactNode;
}) => (
  <div className="flex flex-col">
    <div className="flex items-center rounded-t-lg border border-dust-dark bg-dust-light p-4">
      {isLoading ? (
        <div className="h-4 w-[120px] animate-pulse rounded bg-dust-dark"></div>
      ) : (
        <div className="text-metal">{title}</div>
      )}
    </div>
    <div className="flex h-[35vh] flex-col gap-4 overflow-scroll rounded-b-lg border border-t-0 border-dust-dark bg-white p-4 md:h-[65vh]">
      {children}
    </div>
  </div>
);

const EntityRowSkeleton = () => (
  <div className="flex gap-2 py-1">
    <div className="h-8 w-8 animate-pulse rounded bg-dust-dark"></div>
    <div className="flex flex-col gap-1">
      <div className="h-3 w-[170px] animate-pulse rounded bg-dust-light"></div>
      <div className="h-3 w-[70px] animate-pulse rounded bg-dust-dark"></div>
    </div>
    <div className="ml-auto flex gap-2">
      <div className="flex flex-col items-center gap-1">
        <div className="h-4 w-[80px] animate-pulse rounded bg-dust-dark"></div>
        <div className="h-3 w-[70px] animate-pulse rounded bg-dust"></div>
      </div>
      <div className="flex flex-col items-center gap-1">
        <div className="h-4 w-[80px] animate-pulse rounded bg-dust-dark"></div>
        <div className="h-3 w-[70px] animate-pulse rounded bg-dust"></div>
      </div>
    </div>
  </div>
);

const SkeletonRows = () => (
  <>
    {Array(20)
      .fill(null)
      .map((_, i) => (
        <EntityRowSkeleton key={i} />
      ))}
  </>
);

const EntityRow = ({
  id,
  company,
  email,
  type,
  name,
  interest,
  interestDelta,
  emails,
  emailsDelta,
  searchValue,
}: {
  id: string;
  company?: string;
  email?: any;
  type: "contact" | "company";
  name: string;
  interest: number;
  interestDelta: number;
  emails: number;
  emailsDelta: number;
  searchValue?: string;
}) => (
  <div className="flex items-center justify-between gap-4">
    <div className="flex h-12 items-center">
      <EntityIdentifier
        type={type}
        title={name}
        id={id}
        description={company}
        meta={{ email }}
        searchValue={searchValue}
      />
    </div>
    <div className="flex gap-4">
      <div className="flex flex-col items-center">
        <div className="flex items-center gap-2">
          <div className="font-bold text-navy">{emails}</div>
          <DataTag
            label={Math.abs(emailsDelta)}
            type={emailsDelta > 0 ? "increase" : "decrease"}
          />
        </div>
        <div className="text-sm text-metal-dark">Email mentions</div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex items-center gap-2">
          <div className="font-bold text-navy">{interest}</div>
          <DataTag
            label={Math.abs(interestDelta)}
            type={interestDelta > 0 ? "increase" : "decrease"}
          />
        </div>
        <div className="text-sm text-metal-dark">Interest points</div>
      </div>
    </div>
  </div>
);

const TopicInterest = ({ topic }: { topic: { id: any; name: any } }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [contactsPage, setContactsPage] = useState(1);
  const [companyPage, setCompanyPage] = useState(1);
  const limit = 100;

  const { resultSet: contactResultSet, isLoading: isContactsLoading } =
    useCubeQuery({
      dimensions: [
        "dim_contact.full_name",
        "dim_contact.uuid",
        "dim_company.display_name",
        "dim_contact.email",
      ],
      measures: [
        "fact_topic_scores.email_count_current",
        "fact_topic_scores.email_count_delta",
        "fact_topic_scores.interest_current",
        "fact_topic_scores.interest_delta",
      ],
      order: [["fact_topic_scores.interest_current", "desc"]],
      offset: (contactsPage - 1) * limit,
      limit,
      filters: [
        { member: "dim_topic.id", operator: "equals", values: [topic.id] },
        {
          member: "dim_company.uuid",
          operator: "set",
        },
        ...(debouncedSearchValue !== ""
          ? [
              {
                or: [
                  {
                    member: "dim_contact.full_name",
                    operator: "contains",
                    values: [debouncedSearchValue],
                  },
                  {
                    member: "dim_company.display_name",
                    operator: "contains",
                    values: [debouncedSearchValue],
                  },
                ],
              },
            ]
          : []),
      ] as Filter[],
      total: true,
    });
  const contacts = (contactResultSet?.tablePivot() || []) as any[];
  // @ts-ignore
  const totalContacts = contactResultSet?.loadResponse.results[0].total;
  const totalContactPages = totalContacts
    ? Math.ceil(totalContacts / limit)
    : 0;

  const { resultSet: companyResultSet, isLoading: isCompanyLoading } =
    useCubeQuery({
      dimensions: ["dim_company.display_name", "dim_company.uuid"],
      measures: [
        "fact_topic_scores.email_count_current",
        "fact_topic_scores.email_count_delta",
        "fact_topic_scores.interest_current",
        "fact_topic_scores.interest_delta",
      ],
      order: [["fact_topic_scores.interest_current", "desc"]],
      filters: [
        { member: "dim_topic.id", operator: "equals", values: [topic.id] },
        {
          member: "dim_company.uuid",
          operator: "set",
        },
        ...(debouncedSearchValue !== ""
          ? [
              {
                member: "dim_company.display_name",
                operator: "contains",
                values: [debouncedSearchValue],
              },
            ]
          : []),
      ] as Filter[],
      offset: (companyPage - 1) * limit,
      limit,
      total: true,
    });
  const companies = (companyResultSet?.tablePivot() || []) as any[];
  // @ts-ignore
  const totalCompanies = companyResultSet?.loadResponse.results[0].total;
  const totalCompanyPages = totalCompanies
    ? Math.ceil(totalCompanies / limit)
    : 0;

  const onSearchChange = (value: string) => {
    setContactsPage(1);
    setCompanyPage(1);
    setSearchValue(value);
  };

  return (
    <div className="flex w-full flex-col gap-4 p-4 text-base">
      <TextInput
        placeholder="Search for a contact or company"
        onChangeText={onSearchChange}
        value={searchValue}
        icon="icon-search"
        size="large"
        clearable
      />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <EntityFrame
          isLoading={isContactsLoading}
          title={`${totalContacts || 0} contacts`}
        >
          {isContactsLoading ? (
            <SkeletonRows />
          ) : (
            contacts.map((result) => (
              <EntityRow
                type="contact"
                id={result["dim_contact.uuid"]}
                key={result["dim_contact.uuid"]}
                company={result["dim_company.display_name"]}
                email={result["dim_contact.email"]}
                name={result["dim_contact.full_name"]}
                interest={+result["fact_topic_scores.interest_current"]}
                interestDelta={+result["fact_topic_scores.interest_delta"]}
                emails={+result["fact_topic_scores.email_count_current"]}
                emailsDelta={+result["fact_topic_scores.email_count_delta"]}
                searchValue={debouncedSearchValue}
              />
            ))
          )}
          {contacts.length === 0 &&
            !isContactsLoading &&
            debouncedSearchValue !== "" && (
              <div className="mt-10 flex flex-col items-center gap-2 text-navy">
                <i className="icon-contact text-3xl" />
                <div className="text-lg font-bold">No contacts found</div>
              </div>
            )}
          {totalContactPages > 1 && (
            <div className="p-4">
              <Pagination
                current={contactsPage}
                total={totalContactPages}
                onChange={setContactsPage}
              />
            </div>
          )}
        </EntityFrame>
        <EntityFrame
          isLoading={isCompanyLoading}
          title={`${totalCompanies || 0} companies`}
        >
          {isCompanyLoading ? (
            <SkeletonRows />
          ) : (
            companies.map((result) => (
              <EntityRow
                type="company"
                id={result["dim_company.uuid"]}
                key={result["dim_company.uuid"]}
                name={result["dim_company.display_name"]}
                interest={+result["fact_topic_scores.interest_current"]}
                interestDelta={+result["fact_topic_scores.interest_delta"]}
                emails={+result["fact_topic_scores.email_count_current"]}
                emailsDelta={+result["fact_topic_scores.email_count_delta"]}
                searchValue={debouncedSearchValue}
              />
            ))
          )}
          {companies.length === 0 &&
            !isCompanyLoading &&
            debouncedSearchValue !== "" && (
              <div className="mt-10 flex flex-col items-center gap-2 text-navy">
                <i className="icon-company text-3xl" />
                <div className="text-lg font-bold">No companies found</div>
              </div>
            )}
          {totalCompanyPages > 1 && (
            <div className="p-4">
              <Pagination
                current={companyPage}
                total={totalCompanyPages}
                onChange={setCompanyPage}
              />
            </div>
          )}
        </EntityFrame>
      </div>
    </div>
  );
};

export default TopicInterest;
