export const emailsMetricOptions = [
  {
    value: "all_emails",
    label: "All emails per weekday",
    legend: "All emails",
  },
  {
    value: "emails_sent",
    label: "All sent emails per weekday",
    legend: "Sent emails",
  },
  {
    value: "emails_received",
    label: "All received emails per weekday",
    legend: "Received emails",
  },
  {
    value: "emails_unprompted",
    label: "All unprompted emails per weekday",
    legend: "Unprompted emails",
  },
  {
    value: "my_all_emails",
    label: "Your emails per weekday",
    legend: "Your emails",
  },
  {
    value: "my_emails_sent",
    label: "Your sent emails per weekday",
    legend: "Your sent emails",
  },
  {
    value: "my_emails_received",
    label: "Your received emails per weekday",
    legend: "Your received emails",
  },
  {
    value: "my_emails_unprompted",
    label: "Your unprompted emails per weekday",
    legend: "Your unprompted emails",
  },
];

export const meetingsMetricOptions = [
  {
    value: "meetings_count",
    label: "All meetings per weekday",
    legend: "All meetings",
  },
  {
    value: "my_meetings_count",
    label: "Your meetings per weekday",
    legend: "Your meetings",
  },
];

export const emailEngagementMetricOptions = [
  {
    value: "engagement",
    label: "All emails by engagement",
  },
  {
    value: "my_engagement",
    label: "Your emails by engagement",
  },
];

export const meetingDurationMetricOptions = [
  {
    value: "duration",
    label: "All meetings by duration",
  },
  {
    value: "my_duration",
    label: "Your meetings by duration",
  },
];

export const meetingPlatformMetricOptions = [
  {
    value: "platform",
    label: "All meetings by platform",
  },
  {
    value: "my_platform",
    label: "Your meetings by platform",
  },
];

export const sentimentMetricOptions = [
  { label: "Over previous 30 days", value: "30" },
  { label: "Over previous 90 days", value: "90" },
  { label: "Over previous 180 days", value: "180" },
];
