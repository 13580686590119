import Button from "components/Button";
import TextInput from "components/TextInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { csrfInit } from "utils/requests";
import { isValidEmail } from "utils/emailValidation";
import { useLoginMutation } from "../profileApi";
import LoginContainer from "./LoginContainer";
import { propOr } from "ramda";
import { LoginComponentType } from ".";

const LoginStandard = ({
  switchComponent,
}: {
  switchComponent: (component: LoginComponentType) => void;
}) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [login, { isLoading }] = useLoginMutation();
  const [requestError, setRequestError] = useState<string | undefined>();

  const handleRequest = async () => {
    let valid = true;
    if (!isValidEmail(email)) {
      setEmailError("Enter a valid email address.");
      valid = false;
    }
    if (email.length === 0) {
      setEmailError("Enter an email address.");
      valid = false;
    }
    if (password.length === 0) {
      setPasswordError("Enter a password.");
      valid = false;
    }
    if (valid) {
      try {
        if (!(await csrfInit())) throw new Error("no CSRF set");
        await login({ email, password }).unwrap();
        navigate("/");
      } catch (error) {
        const data = propOr("", "data", error);
        if (data === "access forbidden") {
          setRequestError("The email address or password was incorect.");
        } else {
          console.error(error);
          switchComponent("forbidden");
        }
      }
    }
  };

  const handleChange = (target: string, value: string) => {
    switch (target) {
      case "email":
        setEmail(value);
        setEmailError(undefined);
        break;
      case "password":
        setPassword(value);
        setPasswordError(undefined);
        break;
    }
  };

  return (
    <LoginContainer text="Welcome to Relata">
      <div className="mx-4">
        <div className="mt-6 flex-grow text-lg font-bold text-navy">
          Email address
        </div>
        <div className="mb-4 mt-2">
          <TextInput
            error={emailError}
            id="login-email-input"
            type="email"
            placeholder="name@company.com"
            onChangeText={(value) => handleChange("email", value)}
          />
        </div>
        <div className="flex-grow text-lg font-bold text-navy">Password</div>
        <div className="mb-4 mt-2 flex flex-row gap-2">
          <TextInput
            error={passwordError}
            id="login-password-input"
            type={showPassword ? "text" : "password"}
            onChangeText={(value) => handleChange("password", value)}
          />
          <Button
            color="dust"
            text={showPassword ? "Hide" : "Show"}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>

        <div className="mb-4 mt-2 flex flex-col gap-4">
          {requestError && (
            <div className="font-bold text-red-dark">{requestError}</div>
          )}
          <Button
            isLoading={isLoading}
            color="navy"
            iconRight
            icon="icon-right"
            text="Login"
            id="login-button"
            onClick={handleRequest}
          />
          <Button
            color="dust"
            text="Request a secure login link"
            onClick={() => switchComponent("otp")}
          />
          <Button
            color="dust"
            text="Reset your password"
            onClick={() => switchComponent("reset-request")}
          />
        </div>
      </div>
    </LoginContainer>
  );
};

export default LoginStandard;
