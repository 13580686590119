import { faker } from "@faker-js/faker";
import fakeCubeResponse from "./cubeResponse";

const fakeSentiment = (override?: number) => {
  return fakeCubeResponse({
    query: {
      measures: ["fact_email.average_sentiment"],
    },
    data: [
      {
        "fact_email.average_sentiment":
          override ||
          faker.datatype.float({
            min: -1,
            max: 1,
          }),
      },
    ],
  });
};

export default fakeSentiment;
