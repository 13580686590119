import { useEffect, useState } from "react";
import { format } from "date-fns";
import { getZonedDate } from "utils/dates";

/**
 * useClock
 * returns the current time in a specified format pattern and timezone, updated every second.
 * @param {string} pattern - pattern to format date, refers to fns format documentation, e.g. HH:mm.
 * @param {string} timezone - IANA timezone to set date, e.g. America/Los_Angeles.
 * @param {number} refresh - number of seconds to refresh the hook.
 */
export const useClock = ({
  pattern,
  timezone,
  refresh = 1,
}: {
  pattern?: string;
  timezone?: string;
  refresh?: number;
}): string | Date => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => setDate(new Date()), refresh * 1000);
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  return pattern
    ? format(getZonedDate(date, timezone), pattern)
    : getZonedDate(date, timezone);
};

export default useClock;
