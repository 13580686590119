import { useDispatch } from "react-redux";
import { resetFilters } from "app/filtersSlice";
import Button from "components/Button";

const ErrorState = ({ title }: { title: string }) => {
  const dispatch = useDispatch();
  const clearFilters = () => {
    dispatch(resetFilters());
  };
  return (
    <div className="mt-[200px] flex items-center justify-center">
      <div className="flex flex-col items-center gap-2 text-center">
        <i className="icon-warning-hex text-3xl text-navy"></i>
        <div className="text-xl font-bold text-navy">We can't load {title}</div>
        <div className="w-[300px] text-metal">
          Try refreshing your browser or resetting your filters. If this issue
          persists, get in contact with our support team.
        </div>
        <div>
          <Button
            color="dust"
            text="Reset your filters"
            onClick={clearFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorState;
