import { createApi } from "@reduxjs/toolkit/query/react";
import { ICubeToken } from "./cubeToken.interfaces";
import { camelizeKeys } from "humps";
import prepareBaseQuery from "../prepareBaseQuery";
import Cookies from "js-cookie";

export const cubeTokenApi = createApi({
  reducerPath: "cubeTokenRequest",
  baseQuery: prepareBaseQuery({
    baseUrl: "/cube",
  }),
  endpoints: (builder) => ({
    getToken: builder.query<ICubeToken, void>({
      query: () => ({
        url: "/token",
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }),
      transformResponse: (response: any): ICubeToken => {
        const camelized = camelizeKeys(response) as ICubeToken;
        return camelized;
      },
    }),
  }),
});

export const { useGetTokenQuery } = cubeTokenApi;
