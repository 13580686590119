import Button from "components/Button";
import FeedbackMessage from "components/FeedbackMessage";
import FilterToggle from "components/FilterToggle";
import TextInput from "components/TextInput";
import React, { useState } from "react";

export const InteractionEmptySearch = () => (
  <FeedbackMessage
    icon="icon-search"
    title="No interactions found"
    paragraps={["Try adjusting your spelling"]}
  />
);

export type SearchTargetType = "default" | "messageSubject" | "fullName";
const InteractionSearch = ({
  searchValue,
  setSearchValue,
  setSearchTarget,
  setShowOptions,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  setSearchTarget: (value: SearchTargetType) => void;
  setShowOptions: () => void;
}) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);

  const filterOptions: { name: string; key: SearchTargetType }[] = [
    {
      name: "All results",
      key: "default",
    },
    {
      name: "Email subject only",
      key: "messageSubject",
    },
    {
      name: "Contact name only",
      key: "fullName",
    },
  ];

  return (
    <div className="flex flex-col gap-2 pb-6">
      <div className="flex flex-row items-center gap-x-2">
        <TextInput
          placeholder="Search for an interaction by subject or contact..."
          onChangeText={setSearchValue}
          value={searchValue}
          icon="icon-search"
          size="large"
          clearable
        />
        <Button
          icon="icon-filter-settings"
          color="white"
          onClick={setShowOptions}
          tooltip="Track options"
        />
      </div>

      {!!searchValue.length && (
        <FilterToggle
          options={filterOptions}
          activeIndex={activeFilterIndex}
          onChange={(index: number) => {
            setSearchTarget(filterOptions[index].key);
            setActiveFilterIndex(index);
          }}
        />
      )}
    </div>
  );
};
export default InteractionSearch;
