import { IToast } from "./toast.interfaces";

export const offlineToast: IToast = {
  id: "offline",
  text: "You are not connected to the internet. Data will be cached.",
  icon: "icon-plug",
};

export const newVersionToast: IToast = {
  id: "new-version",
  text: "A new version of Relata is available.",
  buttonType: "refresh",
};
