import classnames from "classnames";
import { Tooltip } from "react-tooltip";

import { eachDayOfInterval, format, startOfWeek } from "date-fns";
import { fromPairs, head, last, prop, sortBy, uniq, zipObj } from "ramda";
import { beginningOfMonthsAgo } from "utils/dates";

const calculateBounds = (mi: number, ma: number) => {
  const ms = mi + 1;
  const tierce = ms + Math.ceil((ma - ms) / 3);

  // data saturation too low
  if (isNaN(tierce)) {
    return [[0, 0]];
  }

  return [
    [0, 0],
    [ms, tierce],
    [tierce + 1, tierce * 2],
    [tierce * 2 + 1, ma],
  ];
};

const WaffleChart = ({
  id,
  data,
  isLoading,
  tooltip,
}: {
  id: string;
  data: { value: any; day: any }[];
  isLoading?: boolean;
  tooltip?: (date: string, value: any) => string;
}) => {
  const sorted = sortBy(prop("day"), data);
  const sortedVals = sortBy(prop("value"), data);

  const bounds = calculateBounds(0, last(sortedVals)?.value);
  const mapped = fromPairs(sorted.map(({ value, day }) => [day, value]));

  const getValue = (date: number | Date): number =>
    mapped[format(date, "yyyy-MM-dd")] || 0;

  const startDateRangeDefault = beginningOfMonthsAgo(6);
  const startDate = head(sorted)?.day
    ? new Date(head(sorted)?.day)
    : startDateRangeDefault;
  const endDate = last(sorted)?.day ? new Date(last(sorted)?.day) : new Date();

  const interval = eachDayOfInterval({
    start: startOfWeek(startDate, { weekStartsOn: 1 }),
    end: endDate,
  });

  const yLabels = uniq(interval.map((date) => format(date, "LLL-yy")));
  const xLabels = ["Mon", "Wed", "Fri", "Sun"];

  const colors = ["bg-dust-light", "bg-metal-light", "bg-metal", "bg-navy"];
  const legend = zipObj(colors, bounds);

  return (
    <div className="transition-default flex flex-col gap-2">
      <div className="flex w-full gap-4">
        <div className="flex flex-col justify-between gap-2">
          {xLabels.map((label) => (
            <div
              key={`x-label-${label}`}
              className={classnames("text-metal", {
                "animate-pulse text-dust": isLoading,
              })}
            >
              {label}
            </div>
          ))}
        </div>

        <div
          className={classnames("w-[1px] bg-metal-light", {
            "animate-pulse bg-dust": isLoading,
          })}
        />

        <Tooltip className="default-tooltip" id={`waffle-tooltip-${id}`} />
        <div className="grid w-full grid-flow-col grid-rows-7 gap-0.5">
          {interval.map((date: any, index: number) => {
            const value = getValue(date);
            const [item] = Object.entries(legend).filter(
              ([_, bound]) => value >= bound[0] && value <= bound[1]
            );
            const inRange = date >= startDate && date <= endDate;
            const color = inRange
              ? item
                ? item[0]
                : "bg-dust-light"
              : "bg-white";
            const tip = tooltip
              ? tooltip(format(date, "dd-MM-yyyy"), value)
              : `${format(date, "dd-MM-yyyy")}: ${value}`;
            return (
              <div key={`tile-${index}`}>
                <div
                  data-tooltip-id={`waffle-tooltip-${id}`}
                  data-tooltip-html={value > 0 ? tip : ""}
                  className={classnames(`h-4 rounded-sm ${color}`, {
                    "animate-pulse bg-dust-dark": isLoading && inRange,
                    "hover:saturate-150": value > 0,
                  })}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-between gap-2 pl-14">
        {yLabels.map((label) => (
          <div key={`y-label-${label}`} className="overflow-clip text-metal">
            {label.substring(0, 3)}
          </div>
        ))}
      </div>
      <div className="mt-2 flex justify-around gap-2">
        {Object.entries(legend).map(([key, [mi, _]]) => (
          <div key={`legend-${key}-${mi}`} className="flex gap-2">
            <div className={`${key} h-4 w-4 rounded-sm`}></div>
            <div className="text-metal">
              {mi}
              {mi === 0 ? "" : "+"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WaffleChart;
