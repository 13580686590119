const EmptyTableState = ({ title }: { title: string }) => (
  <div className="mt-[120px] flex items-center justify-center">
    <div className="flex flex-col text-center">
      <i className="icon-filter text-3xl text-navy"></i>
      <div className="mt-1 text-xl font-bold text-navy">{title}</div>
      <div className="mt-2 w-[300px] text-metal">
        Try adjusting your selected filters
      </div>
    </div>
  </div>
);

export default EmptyTableState;
