import classnames from "classnames";

const NavTab = ({
  active,
  text,
  onClick,
}: {
  active: boolean;
  text: string;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className={classnames(
      "flex h-12 cursor-pointer items-center whitespace-nowrap text-lg",
      {
        "border-b border-b-navy font-bold text-navy": active,
        "border-b border-b-transparent text-metal hover:border-b-metal hover:text-navy focus:border-b-navy focus:text-navy":
          !active,
      }
    )}
  >
    {text}
  </div>
);

export default NavTab;
