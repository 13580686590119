import { faker } from "@faker-js/faker";
import { IContactDetail } from "features/entity/entity.interfaces";
import { mergeRight } from "ramda";
import fakeCompany from "./company";

const fakeContact = (override: Partial<IContactDetail>): IContactDetail => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  return mergeRight(
    {
      uuid: faker.datatype.uuid(),
      email: faker.internet.email(firstName, lastName),
      fullName: firstName + " " + lastName,
      company: faker.helpers.maybe(() => fakeCompany({}), { probability: 0.9 }),
      roles: [faker.name.jobType()],
      flags: [],
      locations: [
        {
          location: faker.address.country(),
          timezone: faker.address.timeZone(),
        },
      ],
      phoneNumbers: [
        {
          type: faker.helpers.arrayElement(["phone", "mobile"]),
          phoneNumber: faker.phone.number(),
        },
      ],
    },
    override
  );
};

export default fakeContact;
