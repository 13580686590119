import React, { useEffect } from "react";
import classnames from "classnames";
import { CubeProvider } from "@cubejs-client/react";
import { Tooltip } from "react-tooltip";

import {
  Navigate,
  Outlet,
  useLocation,
  useMatches,
  useNavigate,
} from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { head, prop } from "ramda";
import env from "config";

import { useGetBuildQuery } from "app/versionApi";
import { refetchCubeToken } from "app/cubeTokenSlice";
import { useAppDispatch } from "app/store";
import useSetPageTitle from "utils/hooks/useSetPageTitle";

import MainNavigation from "components/MainNavigation";
import ImpersonationBar from "components/bars/ImpersonationBar";
import ToastContainer from "components/toast/ToastContainer";
import { useGetUserQuery } from "features/profile/profileApi";
import useSyncUserTimezone from "utils/hooks/useSyncUserTimezone";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

import ContactModalsContainer from "pages/explore/common/ContactModalsContainer";
import { Permissions } from "features/profile";
import RelataLoading from "assets/RelataLoading.svg";

const DEFAULT_ROUTE: string =
  `"${env("REACT_APP_DEFAULT_ROUTE")}"` || '"explore"';

const App = () => {
  const matches = useMatches();
  const location = useLocation();
  const navigate = useNavigate();
  const { identify, ready } = useAnalytics();
  const dispatch = useAppDispatch();
  const cubejsApi = useCubejsApi();
  const preferredLandingPage = JSON.parse(
    localStorage.getItem("landing-page") || DEFAULT_ROUTE
  );

  // set title with relation to section and subsection
  useSetPageTitle();

  // refetch token for cube interaction
  useEffect(() => {
    dispatch(refetchCubeToken());
  }, [dispatch]);

  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { data: build } = useGetBuildQuery();

  // clear cache after user started impersonating
  useEffect(() => {
    const cleanImpersonation: boolean = JSON.parse(
      window.localStorage.getItem("clean-impersonation") || "false"
    );
    if (user?.impersonating && !cleanImpersonation) {
      window.localStorage.clear();
      window.localStorage.setItem("clean-impersonation", "true");
      window.location.reload();
    }
  }, [user]);

  // synchronize user timezone
  useSyncUserTimezone(user);
  // initialize analytics
  useEffect(() => {
    if (user && user.hashedEmail && build) {
      const domain = window.location.hostname;
      const name = head(domain.split("."));
      ready(() => {
        identify(user.hashedEmail, {
          firstName: user.name,
          lastName: user.surname,
          roleType: user.roleType,
          userHash: user.intercomUserHash,
          name: `${user.name} ${user.surname}`,
          userId: user.hashedEmail,
          email: user.email,
          company: {
            id: name,
            website: domain,
          },
          version: build.version,
          isRelataUser: user.isRelataUser,
          isStaff: user.isStaff,
        });
      });
    }
  }, [user, identify, ready, build]);

  // redirect user from root / url
  useEffect(() => {
    if (matches.length === 1 && user) {
      // determine where users should be redirected based on permissions
      const choices = {
        explore: { permission: Permissions.USE_WATCHLIST, route: "/explore" },
        track: { permission: Permissions.USE_TRACK, route: "/track" },
        report: { permission: Permissions.USE_REPORT, route: "/report" },
        demo: { permission: () => user.isDemoUser || false, route: "/demo" },
      };

      const checkPermission = (permission: (() => boolean) | Permissions) => {
        if (typeof permission === "function") {
          return permission();
        } else {
          return user.permissions.includes(permission);
        }
      };

      // use user preferred choice if possible
      const preferredChoice = prop(preferredLandingPage, choices);
      if (
        preferredLandingPage &&
        preferredChoice &&
        user.permissions.includes(preferredChoice.permission)
      ) {
        navigate(preferredChoice.route);
      } else {
        // or first eligible option
        const choice = head(
          Object.values(choices).filter((choice) =>
            checkPermission(choice.permission)
          )
        );
        if (choice) {
          navigate(choice.route);
        }
      }
    }
  }, [user, matches, navigate, preferredLandingPage]);

  useEffect(() => {
    if (
      !isUserLoading &&
      !user &&
      !["/", "/login", "/welcome", "/reset-password"].includes(
        location.pathname
      )
    ) {
      localStorage.setItem(
        "redirect-path-at-login",
        JSON.stringify(location.pathname)
      );
    }
  }, [isUserLoading, user, location]);

  if (!isUserLoading && !user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <div className="mx-auto text-base">
        <ImpersonationBar />
        <MainNavigation />
        <ToastContainer />
        <ContactModalsContainer />

        <div
          className={classnames(
            "flex h-screen flex-col items-center justify-center gap-2",
            {
              "opacity-100": isUserLoading,
              "h-0 opacity-0": !isUserLoading,
            }
          )}
        >
          <img
            src={RelataLoading}
            className="h-[96px] w-[96px]"
            alt="loading spinner"
          />
          <div className="mt-14 text-2xl text-navy">Welcome to Relata</div>
          <div className="text-lg text-metal">
            The faster, simpler way to deepen client relationships
          </div>
        </div>
        <div
          className={classnames({
            hidden: isUserLoading,
            visible: !isUserLoading,
            "pt-12": user?.impersonating,
          })}
        >
          <Outlet />
        </div>
      </div>
      <Tooltip className="default-tooltip" id="default-tooltip" />
    </CubeProvider>
  );
};

export default App;
