import { useEffect, useState } from "react";
import { pluck, equals } from "ramda";

import Button from "components/Button";
import Modal from "components/Modal";

import SearchBar from "features/search/components/SearchBar";
import { IWatchListItem } from "features/watchlist/watchlist.interfaces";

import WatchListModalItem from "./WatchListModalItem";

const pluckEquals = (property: string, a: any[], b: any[]) =>
  equals(pluck(property, a), pluck(property, b));

const WatchListModal = ({
  watchlist,
  visible,
  onClose,
  onUpdate,
}: {
  watchlist: IWatchListItem[];
  visible: boolean;
  onClose: () => void;
  onUpdate: any;
}) => {
  const modalDescription = watchlist.length
    ? "Update your watchlist"
    : "Create your watchlist";

  const [internalWatchlist, setInternalWatchlist] = useState(watchlist);
  useEffect(() => {
    setInternalWatchlist(watchlist);
  }, [visible, watchlist]);
  const addItem = (item: any) => {
    if (!pluck("entityUuid", internalWatchlist).includes(item.entityUuid)) {
      setInternalWatchlist([...internalWatchlist, item]);
    }
  };

  const removeItem = (itemUuid: any) => {
    setInternalWatchlist(
      internalWatchlist.filter((item) => item.entityUuid !== itemUuid)
    );
  };

  const updateWatchlist = () => {
    onUpdate(internalWatchlist);
    onClose();
  };

  return (
    <Modal visible={visible} onClose={onClose} height="small">
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-watchlist-add"></i>
            {modalDescription}
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="mt-4">
          <SearchBar placeholder="Search..." onOpen={addItem} />
        </div>

        <div className="my-4 flex h-full flex-col gap-4 overflow-auto rounded-md border border-dust p-4 sm:h-96">
          {internalWatchlist.map((item) => (
            <WatchListModalItem
              id={item.entityUuid}
              title={item.title}
              type={item.entityType}
              subtitle={item.description}
              key={item.entityUuid}
              onRemove={() => removeItem(item.entityUuid)}
            />
          ))}
        </div>
        <Button
          color="navy"
          size="large"
          id="update-watchlist"
          text={modalDescription}
          onClick={updateWatchlist}
          disabled={pluckEquals("entityUuid", internalWatchlist, watchlist)}
          block
        />
      </div>
    </Modal>
  );
};

export default WatchListModal;
