import classnames from "classnames";

export type InformationTagColor = "teal" | "orange" | "dust" | "pink" | "metal";

const standardColors: InformationTagColor[] = ["teal", "orange", "pink"];

const InformationTag = ({
  color,
  label,
  tooltip,
  icon,
}: {
  color: InformationTagColor;
  label?: string;
  tooltip?: string;
  icon: string;
}) => (
  <div
    data-tooltip-id="default-tooltip"
    data-tooltip-content={tooltip}
    className={classnames(
      "flex h-fit w-fit items-center gap-1 rounded-sm px-1 py-0.5",
      {
        [`bg-${color}-lightest text-${color}-darkest`]:
          standardColors.includes(color),
        "bg-dust-light text-metal-dark": color === "dust",
        "bg-metal text-white": color === "metal",
      }
    )}
  >
    <i className={icon} />
    {!!label && <div className="pr-1 text-sm font-bold">{label}</div>}
  </div>
);

export default InformationTag;
