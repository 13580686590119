import { useState } from "react";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";

const WatchListOptionsModal = ({
  visible,
  options,
  active,
  onClose,
  onUpdate,
}: {
  visible: boolean;
  options: { label: string; field: string }[];
  active: string[];
  onClose: () => void;
  onUpdate: (updates: any) => void;
}) => {
  const update = () => {
    onUpdate({
      active: internalActive,
    });
    onClose();
  };

  const [internalActive, setInternalActive] = useState(active);

  const toggleCheckbox = (key: string) => {
    if (internalActive.includes(key)) {
      setInternalActive(internalActive.filter((value) => value !== key));
    } else {
      setInternalActive([...internalActive, key]);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} height="small">
      <div className="flex h-full flex-col">
        <div className="flex items-center">
          <h3 className="flex flex-1 items-center justify-center gap-2 text-lg text-navy">
            <i className="icon-filter-settings"></i>
            Watchlist options
          </h3>
          <div className="flex">
            <Button icon="icon-cross" color="dust" onClick={onClose} />
          </div>
        </div>
        <div className="my-4 flex flex-1 flex-col overflow-auto text-base">
          <div className="mt-6 mb-4">
            <div className="text-lg font-bold text-navy">Data labels</div>
            <div className="text-metal">
              Select the data shown on the watchlist items
            </div>
          </div>
          <div className="mb-6 grid grid-cols-1 gap-2">
            {options.map((option) => (
              <Checkbox
                checked={internalActive.includes(option.field)}
                onClick={() => toggleCheckbox(option.field)}
                size="small"
                label={option.label}
                key={option.field}
              />
            ))}
          </div>
        </div>
        <div>
          <Button
            color="navy"
            size="large"
            id="update-watchlist-options"
            text="Apply changes"
            onClick={update}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default WatchListOptionsModal;
