import { Filter, TQueryOrderArray } from "@cubejs-client/core";
import { useGetUserQuery } from "features/profile/profileApi";
import useExtraCube from "utils/hooks/useExtraCube";

const useLapsingEngagement = ({
  timeOption,
  relevantOption,
  orderOption,
}: {
  timeOption: string;
  relevantOption: string;
  orderOption: string;
}) => {
  const getOrder = (): TQueryOrderArray => {
    switch (orderOption) {
      case "least-lapsed":
        return [["fact_table.days_since_last_interaction", "asc"]];

      case "most-lapsed":
        return [["fact_table.days_since_last_interaction", "desc"]];

      default:
        return [
          ["fact_table.relationship_score_current", "desc"],
          ["fact_table.days_since_last_interaction", "desc"],
        ];
    }
  };

  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useExtraCube(
    {
      dimensions: [
        "dim_contact.uuid",
        "dim_contact.full_name",
        "dim_company.display_name",
        "dim_contact.email",
        ...(user?.showContactSignatures ? ["dim_contact.phone_numbers"] : []),
      ],
      measures: [
        "fact_table.relationship_score_current",
        "fact_table.days_since_last_interaction",
        "fact_table.last_interaction_details",
        "fact_table.last_meeting_details",
      ],
      order: getOrder(),
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
        {
          member: "fact_table.days_since_last_interaction",
          operator: "gt",
          values: [timeOption],
        },
        {
          member: "fact_table.relationship_score_current",
          operator: "gte",
          values: [relevantOption === "relevant" ? "8" : "2"],
        },
        {
          member: "dim_contact.is_leaver",
          operator: "equals",
          values: ["false"],
        },
        ...(relevantOption === "relevant"
          ? ([
              {
                member: "dim_contact.is_assistant",
                operator: "equals",
                values: ["false"],
              },
            ] as Filter[])
          : []),
      ],
    },
    {
      secondary: {
        key: "dim_contact.uuid",
        filters: [
          {
            member: "dim_user.email",
            operator: "notEquals",
            values: [user ? (user.email as string) : ""],
          },
        ],
        measures: [
          "fact_table.last_interaction_details",
          "fact_table.last_meeting_details",
        ],
      },
    }
  );
  return {
    resultSet: (resultSet || []) as any[],
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useLapsingEngagement;
