const NumberInput = ({
  value,
  onChange,
}: {
  value: number;
  onChange?: (value: string) => void;
}) => {
  const handleChange = (event: any) => {
    if (onChange) onChange(event.target.value);
  };
  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      className="h-8 w-20 rounded border-dust text-metal placeholder-metal-light hover:border-dust-dark hover:text-metal-dark hover:placeholder-metal focus:border focus:border-mint  focus:text-navy focus:placeholder-navy focus:ring-0"
    />
  );
};

export default NumberInput;
