import classnames from "classnames";

import { NavLink } from "react-router-dom";

const NavItem = ({
  to,
  text,
  notifications,
}: {
  to: string;
  text: string;
  notifications?: number;
}) => (
  <div className="flex flex-row items-center">
    <NavLink
      to={to}
      className={({ isActive }) =>
        classnames(
          "flex h-12 cursor-pointer items-center text-lg outline-none",
          {
            "border-b border-b-navy font-bold text-navy": isActive,
            "border-b border-b-transparent text-metal hover:border-b-metal hover:text-navy focus:border-b-navy focus:text-navy":
              !isActive,
          }
        )
      }
    >
      {text}
    </NavLink>
    {!!notifications && (
      <span className="ml-2 rounded-full bg-mint px-2 py-0.5 text-lg">
        {notifications > 99 ? "99+" : notifications}
      </span>
    )}
  </div>
);

export default NavItem;
