import { formatSeconds } from "utils/dates";
import DataTag from "components/atoms/DataTag";
import TooltipFrame from "components/frames/TooltipFrame";

const AverageTimeMiniStatistic = ({
  myValue,
  myPrevious,
  allValue,
  allPrevious,
  myOutValue,
  myOutPrevious,
  allOutValue,
  allOutPrevious,
  isLoading,
}: {
  myValue?: any;
  myPrevious?: any;
  allValue?: any;
  allPrevious?: any;
  myOutValue?: any;
  myOutPrevious?: any;
  allOutValue?: any;
  allOutPrevious?: any;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <TooltipFrame title="Average email response time">
        <div className="flex animate-pulse flex-col">
          <div className="flex gap-2 pb-4 pl-2 pt-4">
            <div className="w-64 text-metal">You respond to them</div>
            <div className="w-32 text-lg text-navy">{formatSeconds(0)}</div>
          </div>
          <div className="flex gap-2 pb-4 pl-2 pt-4">
            <div className="w-64 text-metal">They respond to you</div>
            <div className="w-32 text-lg text-navy">{formatSeconds(0)}</div>
          </div>
          <div className="flex gap-2 pb-4 pl-2 pt-4">
            <div className="w-64 text-metal">
              All colleagues respond to them
            </div>
            <div className="w-32 text-lg text-navy">{formatSeconds(0)}</div>
          </div>
          <div className="flex gap-2 pb-4 pl-2 pt-4">
            <div className="w-64 text-metal">
              They respond to all colleagues
            </div>
            <div className="w-32 text-lg text-navy">{formatSeconds(0)}</div>
          </div>
        </div>
      </TooltipFrame>
    );
  }

  const myValueNumber = Number(myValue) || 0; // 324;
  const myPreviousNumber = Number(myPrevious) || 0; // 312;
  const allValueNumber = Number(allValue) || 0; // 254;
  const allPreviousNumber = Number(allPrevious) || 0; // 431;
  const myOutValueNumber = Number(myOutValue) || 0; // 582;
  const myOutPreviousNumber = Number(myOutPrevious) || 0; // 524;
  const allOutValueNumber = Number(allOutValue) || 0; // 542;
  const allOutPreviousNumber = Number(allOutPrevious) || 0; // 645;

  const myDelta = myValueNumber - myPreviousNumber;
  const allDelta = allValueNumber - allPreviousNumber;
  const myOutDelta = myOutValueNumber - myOutPreviousNumber;
  const allOutDelta = allOutValueNumber - allOutPreviousNumber;

  const myTrend =
    myValueNumber > 0 && myDelta > 0
      ? "increase"
      : myValueNumber > 0 && myDelta < 0
      ? "decrease"
      : "consistent";
  const allTrend =
    allValueNumber > 0 && allDelta > 0
      ? "increase"
      : allValueNumber > 0 && allDelta < 0
      ? "decrease"
      : "consistent";
  const myOutTrend =
    myOutValueNumber > 0 && myOutDelta > 0
      ? "increase"
      : myOutValueNumber > 0 && myOutDelta < 0
      ? "decrease"
      : "consistent";
  const allOutTrend =
    allOutValueNumber > 0 && allOutDelta > 0
      ? "increase"
      : allOutValueNumber > 0 && allOutDelta < 0
      ? "decrease"
      : "consistent";

  const myColor =
    myValueNumber > 0 && myDelta > 0
      ? "red"
      : myValueNumber > 0 && myDelta < 0
      ? "green"
      : "gray";
  const allColor =
    allValueNumber > 0 && allDelta > 0
      ? "red"
      : allValueNumber > 0 && allDelta < 0
      ? "green"
      : "gray";
  const myOutColor =
    myOutValueNumber > 0 && myOutDelta > 0
      ? "red"
      : myOutValueNumber > 0 && myOutDelta < 0
      ? "green"
      : "gray";
  const allOutColor =
    allOutValueNumber > 0 && allOutDelta > 0
      ? "red"
      : allOutValueNumber > 0 && allOutDelta < 0
      ? "green"
      : "gray";

  return (
    <TooltipFrame title="Average email response time">
      <div className="flex flex-col">
        <div className="flex gap-2 pb-4 pl-2 pt-4">
          <div className="w-64 text-metal">You respond to them</div>
          <div className="w-32 text-lg text-navy">
            {formatSeconds(myOutValueNumber)}
          </div>
          {!!myOutPreviousNumber && (
            <DataTag
              type={myOutTrend}
              color={myOutColor}
              label={myOutDelta ? formatSeconds(myOutDelta) : ""}
            />
          )}
        </div>
        <div className="flex gap-2 pb-4 pl-2 pt-4">
          <div className="w-64 text-metal">They respond to you</div>
          <div className="w-32 text-lg text-navy">
            {formatSeconds(myValueNumber)}
          </div>
          {!!myPreviousNumber && (
            <DataTag
              type={myTrend}
              color={myColor}
              label={myDelta ? formatSeconds(myDelta) : ""}
            />
          )}
        </div>
        <div className="flex gap-2 pb-4 pl-2 pt-4">
          <div className="w-64 text-metal">All colleagues respond to them</div>
          <div className="w-32 text-lg text-navy">
            {formatSeconds(allOutValueNumber)}
          </div>
          {!!allOutPreviousNumber && (
            <DataTag
              type={allOutTrend}
              color={allOutColor}
              label={allOutDelta ? formatSeconds(allOutDelta) : ""}
            />
          )}
        </div>
        <div className="flex gap-2 pb-4 pl-2 pt-4">
          <div className="w-64 text-metal">They respond to all colleagues</div>
          <div className="w-32 text-lg text-navy">
            {formatSeconds(allValueNumber)}
          </div>
          {!!allPreviousNumber && (
            <DataTag
              type={allTrend}
              color={allColor}
              label={allDelta ? formatSeconds(allDelta) : ""}
            />
          )}
        </div>
      </div>
    </TooltipFrame>
  );
};

export default AverageTimeMiniStatistic;
