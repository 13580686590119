import classNames from "classnames";
import enhancedInteractions from "features/interaction/enhancedInteractions.json";
import { IEnhancedEvent } from "features/interaction/interaction.interface";

const InteractionTimelineTag = ({
  interaction,
  size = "large",
  squeeze,
}: {
  interaction: {
    enhancedEvent: IEnhancedEvent;
    isRevisit: boolean;
  };
  size?: "xs" | "small" | "large";
  squeeze?: boolean;
}) => {
  const revisitInteraction = enhancedInteractions.find(
    ({ id }) => id === "revisit"
  ) as IEnhancedEvent;
  return (
    <div
      className={classNames(
        "flex flex-row items-center whitespace-nowrap text-base font-bold",
        {
          "gap-0.5 px-0.5 sm:gap-0": squeeze,
        }
      )}
    >
      {interaction.isRevisit && (
        <div
          className={classNames(
            `sm:w-fit sm:min-w-min bg-${revisitInteraction.bgColor} text-${revisitInteraction.textColor}`,
            {
              "w-1 rounded-full sm:h-auto sm:rounded-none sm:rounded-l-sm sm:py-0.5 sm:px-2":
                squeeze,
              "h-4": size === "xs" && squeeze,
              "h-8": squeeze && size !== "xs",
              "rounded-l-sm py-0.5": !squeeze,
              "px-2": !squeeze && size === "large",
              "px-1": !squeeze && size === "small",
            }
          )}
        >
          <span className={classNames({ "hidden sm:block": squeeze })}>
            {size === "small" ? "Re" : revisitInteraction.label}
          </span>
        </div>
      )}
      <div
        className={classNames(
          `sm:w-fit sm:min-w-min bg-${interaction.enhancedEvent.bgColor} text-${interaction.enhancedEvent.textColor}`,
          {
            "w-1 rounded-full sm:h-auto sm:py-0.5 sm:px-2": squeeze,
            "h-4": size === "xs" && squeeze,
            "h-8": squeeze && size !== "xs",
            "px-2 py-0.5": !squeeze,
            "sm:rounded-none sm:rounded-r-sm": squeeze && interaction.isRevisit,
            "sm:rounded-sm": squeeze && !interaction.isRevisit,
            "rounded-none rounded-r-sm": !squeeze && interaction.isRevisit,
            "rounded-sm": !squeeze && !interaction.isRevisit,
          }
        )}
      >
        <span className={classNames({ "hidden sm:block": squeeze })}>
          {interaction.enhancedEvent.label}
        </span>
      </div>
    </div>
  );
};

export default InteractionTimelineTag;
