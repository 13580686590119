import { concat, filter, pluck } from "ramda";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Filter } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";

import { selectCubeFilters } from "app/filtersSlice";
import useDebounce from "utils/hooks/useDebounce";
import SelectOption from "components/SelectOption";
import TextInput from "components/TextInput";
import Pagination from "components/table/Pagination";
import ActivityItem, { ActivityItemSkeleton } from "./ActivityItem";

const Activities = ({
  isLoading,
  activities,
  participants,
  query,
}: {
  isLoading: boolean;
  activities: any[];
  participants: any[];
  query: string;
}) => {
  if (isLoading)
    return (
      <>
        {Array(5)
          .fill(null)
          .map((_, i) => (
            <ActivityItemSkeleton key={i} />
          ))}
      </>
    );

  if (activities.length > 0) {
    return (
      <>
        {activities.map((item) => (
          <ActivityItem
            type={item["fact_activity.type"]}
            subject={item["fact_activity.subject"] as string}
            user={item["fact_activity.user"] as string}
            date={new Date(item["fact_activity.date_time"] as string)}
            participants={participants.filter(
              (participant) =>
                participant["fact_activity.uuid"] === item["fact_activity.uuid"]
            )}
            key={item["fact_activity.uuid"] as string}
            searchValue={query}
          />
        ))}
      </>
    );
  }
  return (
    <div className="flex items-center justify-center pb-4">
      <div className="flex flex-col gap-1 text-center">
        <i className="icon-filter text-2xl text-navy"></i>
        <div className="text-lg font-bold text-navy">No activities found</div>
        <div className="text-metal">Try adjusting your filters</div>
      </div>
    </div>
  );
};

const ActivityTable = ({
  type,
  subtype,
}: {
  type: "contact" | "company";
  subtype: "emails" | "meetings";
}) => {
  const params = useParams();
  const cubeFilters = useSelector(selectCubeFilters);
  const [activityTypeOption, setActivityTypeOption] = useState(
    subtype === "emails" ? "email" : "calendar"
  );
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const limit = 10; // activities per page

  const activityTypeOptions =
    subtype === "emails"
      ? [
          { value: "email", label: "Showing all emails" },
          { value: "email-sent", label: "Showing sent emails only" },
          { value: "email-received", label: "Showing received emails only" },
        ]
      : [{ value: "calendar", label: "Showing all meetings" }];

  const debouncedQuery = useDebounce(query, 500);

  const onQueryChange = (value: string) => {
    setPage(1);
    setQuery(value);
  };

  const entityFilters = (): Filter[] => {
    return [
      {
        member: type === "contact" ? "dim_contact.uuid" : "dim_company.uuid",
        operator: "equals",
        values: [params.id as string],
      },
    ];
  };

  const activityTypeFilters = (): Filter[] => {
    if (
      activityTypeOption === "calendar" ||
      activityTypeOption === "email-received" ||
      activityTypeOption === "email-sent"
    ) {
      return [
        {
          member: "fact_activity.type",
          operator: "equals",
          values: [activityTypeOption],
        },
      ];
    } else if (activityTypeOption === "email") {
      return [
        {
          member: "fact_activity.type",
          operator: "equals",
          values: ["email-received", "email-sent"],
        },
      ];
    }
    return [];
  };

  const subjectFilters = (): Filter[] => [
    {
      member: "fact_activity.subject",
      operator: "contains",
      values: [debouncedQuery],
    },
  ];

  const { resultSet: activityResults, isLoading: isActivitiesLoading } =
    useCubeQuery({
      dimensions: [
        "fact_activity.subject",
        "fact_activity.type",
        "fact_activity.uuid",
        "fact_activity.date_time",
        "fact_activity.user",
      ],
      filters: [
        ...cubeFilters,
        ...activityTypeFilters(),
        ...subjectFilters(),
        ...entityFilters(),
      ],
      order: [["fact_activity.date_time", "desc"]],
      offset: (page - 1) * limit,
      limit,
      total: true,
    });
  const activities = activityResults?.tablePivot() || [];

  // @ts-ignore
  const total = activityResults?.loadResponse.results[0].total;
  const totalPages = total ? Math.ceil(total / limit) : 0;

  // fetching participants for sent emails and calendars from dim_contact table
  const contactActivities = filter(
    (activity) =>
      activity["fact_activity.type"] === "email-sent" ||
      activity["fact_activity.type"] === "calendar",
    activities
  );
  const contactActivityIds = pluck(
    "fact_activity.uuid",
    contactActivities
  ) as string[];
  const { resultSet: contactParticipantResults } = useCubeQuery(
    {
      dimensions: [
        "fact_activity.engagement_type",
        "dim_contact.full_name",
        "fact_activity.uuid",
      ],
      limit: limit * 10,
      order: [
        ["fact_activity.row_number", "asc"],
        ["fact_activity.engagement_type", "desc"],
      ],
      filters: [
        ...cubeFilters,
        {
          member: "fact_activity.uuid",
          operator: "equals",
          values: contactActivityIds,
        },
      ],
    },
    { skip: contactActivityIds.length === 0 }
  );
  const contactParticipants = contactParticipantResults?.tablePivot() || [];

  // fetching participants for received emails and calendars from dim_user table
  const userActivities = filter(
    (activity) =>
      activity["fact_activity.type"] === "email-received" ||
      activity["fact_activity.type"] === "calendar",
    activities
  );
  const userActivityIds = pluck(
    "fact_activity.uuid",
    userActivities
  ) as string[];
  const { resultSet: userParticipantsResults } = useCubeQuery(
    {
      dimensions: [
        "fact_activity.engagement_type",
        "dim_user.full_name",
        "fact_activity.uuid",
      ],
      limit: limit * 10,
      order: [
        ["fact_activity.row_number", "asc"],
        ["fact_activity.engagement_type", "desc"],
      ],
      filters: [
        ...cubeFilters,
        {
          member: "fact_activity.uuid",
          operator: "equals",
          values: userActivityIds,
        },
      ],
    },
    { skip: userActivityIds.length === 0 }
  );
  const userParticipants = userParticipantsResults?.tablePivot() || [];

  const searchPlaceholder =
    subtype === "emails"
      ? "Search for an email by subject..."
      : "Search for a meeting by subject...";

  return (
    <div className="rounded-lg border border-dust-dark">
      <div className="flex flex-col">
        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-col gap-4 sm:flex-row">
            <SelectOption
              options={activityTypeOptions}
              value={activityTypeOption}
              onChange={setActivityTypeOption}
            />
            <TextInput
              placeholder={searchPlaceholder}
              icon="icon-search"
              size="small"
              value={query}
              clearable
              onChangeText={onQueryChange}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Activities
            isLoading={isActivitiesLoading}
            participants={concat(userParticipants, contactParticipants)}
            activities={activities}
            query={debouncedQuery}
          />
          {totalPages > 1 && (
            <div className="p-4">
              <Pagination
                current={page}
                total={totalPages}
                onChange={setPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityTable;
