import Button from "components/Button";
import Modal from "components/Modal";
import { IUser } from "features/profile/user.interface";

const PasswordChangedModal = ({
  user,
  visible,
}: {
  user: IUser;
  visible: boolean;
}) => (
  <Modal visible={visible} onClose={() => {}}>
    <div className="flex flex-col items-center justify-center gap-y-8 text-center">
      <div className="flex gap-2 pt-1 pb-1 text-lg text-navy">
        <i className="icon-key" />
        {user.hasUsablePassword ? "Change password" : "Set a password"}
      </div>
      <div className="text-lg text-metal">
        To complete your password{" "}
        {user.hasUsablePassword ? "change" : "creation"}, we will close your
        session and ask you to log in again.
      </div>
      <Button
        block
        size="large"
        color="navy"
        iconRight
        icon="icon-right"
        text="Go to login screen"
        onClick={() => window.location.reload()}
      />
    </div>
  </Modal>
);

export default PasswordChangedModal;
