import classnames from "classnames";
import { highlightText } from "utils/highlightText";

export type InteractionTagColor =
  | "green"
  | "red"
  | "yellow"
  | "dust"
  | "navy"
  | "pink";
type InteractionTagSize = "small" | "large";

const InteractionTag = ({
  color,
  label,
  size,
  searchValue,
}: {
  color: InteractionTagColor;
  label: string;
  size?: InteractionTagSize;
  searchValue?: string;
}) => {
  const hasSearch = !!searchValue;
  return (
    <div
      className={classnames(
        "flex w-fit flex-row items-center gap-1 whitespace-nowrap rounded-sm text-sm font-bold",
        {
          "py-1": size === "large",
          "px-2": !hasSearch,
          "pr-2": hasSearch,
          "bg-dust-light text-metal-dark": hasSearch,

          "bg-green text-green-darkest": color === "green" && !hasSearch,
          "bg-red text-red-darkest": color === "red" && !hasSearch,
          "bg-pink text-pink-darkest": color === "pink" && !hasSearch,
          "bg-yellow text-yellow-darkest": color === "yellow" && !hasSearch,
          "bg-navy text-dust": color === "navy" && !hasSearch,
          "bg-dust-light text-metal": color === "dust" && !hasSearch,
        }
      )}
    >
      {hasSearch && <div className={`h-full w-1 rounded-full bg-${color}`} />}
      <div className="py-0.5">{highlightText(label, searchValue)}</div>
    </div>
  );
};

export default InteractionTag;
