import Button from "components/Button";

const Pagination = ({
  current,
  total,
  onChange,
}: {
  current: number;
  total: number;
  onChange: (num: number) => void;
}) => (
  <div className="flex items-center">
    <Button
      id="previous-page"
      color="dust"
      icon="icon-left"
      text="Previous"
      disabled={current === 1}
      onClick={() => onChange(current - 1)}
    />
    <div data-testid="page-count" className="px-8 text-navy">
      Page {current} of {total}
    </div>
    <Button
      id="next-page"
      color="dust"
      icon="icon-right"
      text="Next"
      iconRight
      disabled={current === total}
      onClick={() => onChange(current + 1)}
    />
  </div>
);

export default Pagination;
