import DustyBanana from "assets/DustyBanana.svg";
import classNames from "classnames";
import BareNavigation from "components/bars/BareNavigation";
import ImpersonationBar from "components/bars/ImpersonationBar";
import Button from "components/Button";
import { IDemoTag } from "features/demo/demo.interface";
import { useGetDemoTagsQuery } from "features/demo/demoApi";
import { useImpersonateMutation } from "features/profile/profileApi";
import useLocalStorage from "utils/hooks/useLocalStorage";

const DemoTagItem = ({
  tag,
  onClick,
}: {
  tag: IDemoTag;
  onClick: (tag: IDemoTag) => void;
}) => (
  <div className="flex w-[350px] flex-col items-center gap-2 rounded border border-dust-dark bg-white p-6 text-center shadow">
    <div className="text-lg font-bold text-navy">{tag.name}</div>
    <div className="text-metal">{tag.description}</div>
    <div className="mt-auto">
      <Button
        color={tag.isFeatured ? "navy" : "dust"}
        text="Try this experience"
        onClick={() => onClick(tag)}
      />
    </div>
  </div>
);

const DemoView = () => {
  const { data } = useGetDemoTagsQuery();
  const [impersonate] = useImpersonateMutation();
  const [, setDemoImpersonate] = useLocalStorage("demo-impersonating", null);

  const onClick = async (tag: IDemoTag) => {
    try {
      await impersonate({ email: tag.userEmail });
      setDemoImpersonate(`${tag.name}`);
      window.localStorage.setItem("clean-impersonation", "true");
    } catch (error) {
      console.error(error);
    }
    window.location.href = "/";
  };

  return (
    <div className="mx-auto h-full bg-gradient-to-b from-dust-light to-white text-base ">
      <ImpersonationBar />
      <BareNavigation />
      <div className="relative mt-28 flex w-full flex-col items-center gap-2 pt-[72px]">
        <div className="text-xl font-bold text-navy">
          Welcome to the Relata Demo
        </div>
        <div className="w-[288px] text-center text-metal">
          Experience Relata in the various configurations available to your
          users.
        </div>
        <div className="mb-10 mt-2 text-metal">
          Select a user type below to get started.
        </div>
        <div
          data-testid="demo-tags"
          className={classNames(
            "z-10 flex flex-col flex-wrap justify-center gap-4",
            {
              "md:flex-row": (data || []).length <= 2,
            }
          )}
        >
          {data?.map((tag) => (
            <DemoTagItem tag={tag} key={tag.order} onClick={onClick} />
          ))}
        </div>
        <img
          src={DustyBanana}
          alt="dusty banana"
          className="absolute left-[50%] top-[38%] z-0  max-w-none -translate-x-[50%]"
        />
      </div>
    </div>
  );
};

export default DemoView;
