import env from "config";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useGetUserQuery } from "../profileApi";
import LoginForbidden from "./LoginForbidden";
import LoginOtp from "./LoginOtp";
import LoginPasswordResetRequest from "./LoginPasswordResetRequest";
import LoginSso from "./LoginSso";
import LoginStandard from "./LoginStandard";

const SSO: boolean = !!+env("REACT_APP_IS_SSO");
export type LoginComponentType =
  | "otp"
  | "standard"
  | "forbidden"
  | "reset-request";

const Login = () => {
  const { data: profile, isLoading: isProfileLoading } = useGetUserQuery();
  const [component, setComponent] = useState<LoginComponentType>("otp");

  document.title = "Login | Relata";

  const switchComponent = (component: LoginComponentType) => {
    setComponent(component);
  };

  if (!isProfileLoading && profile) {
    const url = JSON.parse(
      localStorage.getItem("redirect-path-at-login") || '"/"'
    );
    return <Navigate to={url} replace />;
  }

  if (SSO) return <LoginSso />;
  switch (component) {
    case "otp":
      return <LoginOtp switchComponent={switchComponent} />;
    case "standard":
      return <LoginStandard switchComponent={switchComponent} />;
    case "forbidden":
      return <LoginForbidden switchComponent={switchComponent} />;
    case "reset-request":
      return <LoginPasswordResetRequest switchComponent={switchComponent} />;
  }
};

export default Login;
