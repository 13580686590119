const env = (name: string) => {
  const processValue = process.env[name];
  const windowValue = window.config && window.config[name];
  return processValue || windowValue;
};

declare global {
  interface Window {
    config?: any;
  }
}

export const isMockedAPI = (): boolean => {
  return env("NODE_ENV") === "test" || env("REACT_APP_ENABLE_MSW") === "true";
};

export default env;
