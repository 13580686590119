import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";

import cubeTokenReducer from "./cubeTokenSlice";
import newInteractionsReducer from "./newInteractionsSlice";
import notificationsReducer from "./notificationsSlice";
import breadcrumbReducer from "./breadcrumbSlice";
import cubeLastRefreshReducer from "./cubeLastRefreshSlice";
import filtersReducer from "./filtersSlice";
import contactModalsReducer from "./contactModalsSlice";

import { watchlistApi } from "../features/watchlist/watchlistApi";
import { profileApi } from "../features/profile/profileApi";
import { demoApi } from "../features/demo/demoApi";
import { searchApi } from "features/search/searchApi";
import { entityApi } from "features/entity/entityApi";
import { versionApi } from "./versionApi";
import { cubeTokenApi } from "features/cubeToken/cubeTokenApi";
import { interactionApi } from "features/interaction/interactionApi";
import { messageApi } from "features/message/messageApi";
import { teamApi } from "features/team/teamApi";
import { lensApi } from "features/lens/lensApi";
import persistConfig from "app/persistConfig";
import { linkApi } from "features/link/linkApi";

const rootReducer = combineReducers({
  cubeToken: cubeTokenReducer,
  newInteractions: newInteractionsReducer,
  notifications: notificationsReducer,
  contactModals: contactModalsReducer,
  breadcrumbs: breadcrumbReducer,
  cubeLastRefresh: cubeLastRefreshReducer,
  filters: filtersReducer,
  [watchlistApi.reducerPath]: watchlistApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [demoApi.reducerPath]: demoApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [interactionApi.reducerPath]: interactionApi.reducer,
  [messageApi.reducerPath]: messageApi.reducer,
  [linkApi.reducerPath]: linkApi.reducer,
  [lensApi.reducerPath]: lensApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [cubeTokenApi.reducerPath]: cubeTokenApi.reducer,
  [entityApi.reducerPath]: entityApi.reducer,
  [versionApi.reducerPath]: versionApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      watchlistApi.middleware,
      profileApi.middleware,
      demoApi.middleware,
      teamApi.middleware,
      interactionApi.middleware,
      messageApi.middleware,
      linkApi.middleware,
      lensApi.middleware,
      searchApi.middleware,
      cubeTokenApi.middleware,
      entityApi.middleware,
      versionApi.middleware,
    ]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
